import {
  ActionPopUpSingleButton,
  ImageWrapper,
  InfoDisplayPanel,
  LockToggleButton,
  NewSectionList,
  deserify,
  firstLetterImage,
  getEnvConfig,
  getHumanReadableTimestampString,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  setLocalStorage,
  theme,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { LessonInfo } from '@protos/learning_management/lms.lesson.common.apis_pb';
import { SEE_ALL_LESSON_PLAN } from '../../../../routeHandling/RoutesNomenclature';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { getSubjectsMap } from '../../../../utils/icons';
import { onLessonCardClick } from '../../../../utils/learn';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';
import { setHomeOngoingLessonList } from '../../reducer/homeDashboard.slice';
import SectionListSckeleton, { ShimmerOngoingLsCard } from '../../shimmer';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';
import {
  ContentLockStatusType,
  Feature,
  LessonNodeLock,
} from '@protos/school_management/school.db_pb';

interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}

export default function OngoingLessonList() {
  const { isOffline } = useDownloadContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const config = getEnvConfig();
  const prevClassSubjectInfo = useRef<number | undefined>(undefined);
  const isFirstRender = useRef(false); // Tracks if it's the initial render
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const sectionId = deserify(
    useAppSelector(
      (state) => state.auth.userInfo?.classSectionDetails?.sectionId
    )
  );
  const { ongoing_lesson_list } = deserify(
    useAppSelector((state) => state.home)
  );
  const selectedSubjectId = deserify(
    useAppSelector((state) => state.home.selected_subject_id)
  );

  const learnSubjects =
    deserify(useAppSelector((state) => state.auth.userInfo?.learnSubjects)) ||
    [];
  const subMap = getSubjectsMap(learnSubjects);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const {
    LessonLearnAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  useEffect(() => {
    const lastFetchTime = getLocalStorage('homeAPILastTime'); // Retrieve the last fetch time
    const currentTime = new Date().getTime();
    const shouldFetchFromAPI =
      !lastFetchTime ||
      (lastFetchTime &&
        currentTime - Number(lastFetchTime) >=
          Number(config.homeExpirationTime));

    if (shouldFetchFromAPI || !ongoing_lesson_list) {
      getStudentOngoingLessonList();
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = true;
      prevClassSubjectInfo.current = selectedSubjectId; // Initialize the ref
      return;
    }

    // Convert objects to strings for deep comparison
    const prevValueString = prevClassSubjectInfo.current;
    const currentValueString = selectedSubjectId;

    if (prevValueString !== currentValueString) {
      getStudentOngoingLessonList();
    }

    // Update the ref to the current value
    prevClassSubjectInfo.current = selectedSubjectId;
  }, [selectedSubjectId]);

  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const [lockedData, setLockedData] = useState<LessonNodeLock[]>();
  const [actionPopupState, setActionPopupState] = useState(false);
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  // const isContentLockFeature = useFeatureEnabled(
  //   featureInfo,
  //   Feature.CONTENT_LOCK
  // );
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });

  async function getStudentOngoingLessonList() {
    try {
      if (!studentId) {
        return;
      }
      setLoading(true);
      const response =
        await LessonLearnAPIServiceV1ClientWithStatusCodeHandler.getStudentOngoingLessonList(
          {
            studentId: studentId,
            subjectId: selectedSubjectId,
            sectionId: sectionId,
          }
        );
      if (response) {
        setLoading(false);
        if (response.data) {
          const lpList = response.data.lessons.sort(
            (a, b) =>
              (b.lastSessionTime?.toDate().getTime() ?? 0) -
              (a.lastSessionTime?.toDate().getTime() ?? 0)
          );
          const lessonPlanIds = lpList?.map((lesson) => lesson.lessonId);
          // if (isContentLockFeature && studentId) {
          //   await fetchLockContent(lessonPlanIds);
          // }
          // console.log('response.data.lessons', response.data);
          dispatch(setHomeOngoingLessonList(response.data.lessons));
          setLocalStorage('homeAPILastTime', String(Date.now()));
          return;
        }
      }
      dispatch(setHomeOngoingLessonList([]));
    } catch (err) {
      dispatch(setHomeOngoingLessonList(undefined));
      setLoading(false);
      setError(true);
      console.log(err);
    }
  }

  // const fetchLockContent = async (lessonPlanIds: string[]) => {
  //   const response =
  //     await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getLockStatusByIds(
  //       {
  //         personId: studentId,
  //         personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
  //         subjectId: selectedSubjectId,
  //         schoolClassSectionId: sectionId,
  //         lessonIds: lessonPlanIds,
  //       }
  //     );
  //   if (response) {
  //     setLockedData(response.lessonInfo);
  //   }
  // };

  const showLockPopup = () => {
    setPopupDetails({
      iconName: 'locked@2x',
      buttontext: 'ok',
      popupText:
        'Your school has locked this lessonplan. You will be able to access it once they unlock it',
      ctaHandler: () => setActionPopupState(false),
    });
    setActionPopupState(true);
  };

  const getOngoingLessonItems = () => {
    if (!ongoing_lesson_list) {
      return [];
    }
    return ongoing_lesson_list.slice(0, 20).map((val, index) => (
      <InfoDisplayPanel
        defaultImage="lessonplan-v1"
        key={'home_ongoing_lesson_' + index}
        variant={isIpadOnly ? 'large' : 'small'}
        blueSubText={subMap[val.subjectId.toString()]?.subjectName} // full subject name and grade missing
        lastSessionTime={
          val.lastSessionTime
            ? 'Taught ' + getHumanReadableTimestampString(val.lastSessionTime)
            : ''
        }
        mainHeading={val.title}
        iconDetails={[
          {
            iconName: 'clock',
            text: `${val.estimatedTimeInMin || 0} Min`,
          },
          {
            iconName: 'questions',
            text: `${val.resourceIds.length} resources`,
          },
        ]}
        rootStyle={{
          backgroundColor: 'common.white',
          width: { xs: pxToRem(199), md: pxTovW(350), lg: pxTovW(227) },
          height: { md: isIpadOnly ? pxTovW(400) : pxTovW(266) },
        }}
        image={getMediaBasePath(val.posterImageUrl, 'processedMediaBucket')}
        cardClickHandler={async () => {
          await interactionEvent({
            url: 'Student_Home',
            context: 'Ongoing_lessons',
            name: 'LESSON_OPEN',
            isOffline: isOffline,
          });
          // isContentLockFeature &&
          getLessonContentLockInfo(val.lessonId, lockedData)?.lockStatus ===
          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
            ? showLockPopup()
            : onLessonCardClick(
                dispatch,
                navigate,
                {
                  subjectId: val.subjectId,
                  lessonId: val.lessonId,
                  topicId: val.moduleId,
                  chapterId: val.parentModuleId,
                },
                location.pathname
              );
        }}
        status={<LessonPlanInfoPanelStatus lesson={val} />}
        // isLocked={
        //   isContentLockFeature
        //     ? getLessonContentLockInfo(val.lessonId, lockedData)?.lockStatus ===
        //       ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
        //     : false
        // }
      >
        <Box
          sx={{
            position: 'absolute',
            right: { xs: pxToRem(18), md: pxTovW(20) },
            top: { xs: pxToRem(18), md: pxTovW(20) },
          }}
        >
          {/* {isContentLockFeature && (
            <LockToggleButton
              isLocked={
                lockedData
                  ? getLessonContentLockInfo(val.lessonId, lockedData)
                      ?.lockStatus
                  : false
              }
            />
          )} */}
        </Box>
      </InfoDisplayPanel>
    ));
  };

  return loading === true ? (
    <SectionListSckeleton children={OngoingLsShimmerArray} />
  ) : (
    <>
      <NewSectionList
        noContentMessage="No Lessons Available"
        isError={error}
        itemsPerPage={4}
        sectionTitle="Ongoing Lessons"
        handleSeeAll={async () => {
          navigate(SEE_ALL_LESSON_PLAN);
          await interactionEvent({
            url: 'Student_Home',
            context: 'ongoing_lesson',
            name: 'SEE_ALL',
            isOffline: isOffline,
          });
        }}
        items={getOngoingLessonItems()}
      />
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
    </>
    // <SectionListSckeleton children={OngoingLsShimmerArray} />
  );
}

const OngoingLsShimmerArray = [
  <ShimmerOngoingLsCard key={1} />,
  <ShimmerOngoingLsCard key={2} />,
  <ShimmerOngoingLsCard key={3} />,
  <ShimmerOngoingLsCard key={4} />,
];

interface InfoDisplayPanelStatus {
  lesson: LessonInfo;
}
const LessonPlanInfoPanelStatus = ({ lesson }: InfoDisplayPanelStatus) => {
  const taughtTime = getHumanReadableTimestampString(
    lesson.lastSessionTime
  )?.split(' ');

  if (!lesson.teacherName) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
        pt: { xs: pxToRem(5), md: pxTovW(5) },
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: pxToRem(5), md: pxTovW(5) },
        }}
      >
        <ImageWrapper
          name={lesson.teacherName || 'Geneo'}
          type="png"
          parentFolder="icons"
          styles={{
            width: { xs: pxToRem(18), md: pxTovW(28) },
            height: { xs: pxToRem(18), md: pxTovW(28) },
            borderRadius: '50%',
          }}
          path={
            getMediaBasePath(lesson.teacherProfileImageUrl) ||
            firstLetterImage(lesson.teacherName) ||
            firstLetterImage('Geneo')
          }
        />

        <Typography variant="smallText" fontWeight="bold">
          {lesson.teacherName || 'Geneo'}
        </Typography>
      </Box>

      {taughtTime && lesson.teacherName && (
        <Typography variant="subText" color="text.disabled">
          {Number(taughtTime[0]) < 2
            ? 'Taught Today'
            : 'Taught ' + taughtTime?.join(' ')}
        </Typography>
      )}
    </Box>
  );
};

const getLessonContentLockInfo = (
  lessonId: string,
  contentLockData?: LessonNodeLock[]
) => {
  return contentLockData?.find((val) => val.lessonId === lessonId);
};
