import {
  Loader,
  ProfileComponent,
  deserify,
  isValidEmail,
  isValidMobileNumber,
  updateUserDetailsByUsername,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { OTPEnum, ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  PROFILE_OTP_SCREEN,
  SWITCH_PROFILE,
  VERIFY_EMAIL,
  VERIFY_PHONE,
} from '../../../routeHandling/RoutesNomenclature';
import {
  setOtpInfo,
  setUserInfo,
  setVerifyPath,
} from '../../Auth/reducer/auth.slice';
import { interactionEvent } from '../../Auth/Login/login_logout.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';

export const Profile = () => {
  const {
    userInfo,
    user_validation_info,
    is_logged_in_offline,
    multiple_profiles,
    is_mcb_user,
  } = deserify(useAppSelector((state) => state.auth));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const { isOffline } = useDownloadContext();
  const isOfflineFrontend = isOffline || is_logged_in_offline;
  useEffect(() => {
    getStudentProfile();
  }, []);
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const getStudentProfile = async () => {
    try {
      const studentProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.fetchStudentProfile(
          {
            studentProfileId: userInfo?.studentProfileId,
          }
        );
      if (studentProfile.data) {
        dispatch(setUserInfo(studentProfile.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addPhoneNumber = async (phoneNumber: string) => {
    if (!isValidMobileNumber(phoneNumber) || phoneNumber.length !== 10) {
      dispatch(
        setToastInfo({
          label: 'invalid Phone Number',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    if (isOfflineFrontend) {
      dispatch(
        setToastInfo({
          label: 'User is Offline',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    try {
      setLoading('loading');
      const updateProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateStudentProfile(
          {
            studentProfileId: userInfo?.studentProfileId,
            phoneNumber: phoneNumber,
            phoneVerify: 0,
          }
        );

      if (updateProfile.status === 200 && updateProfile.data) {
        setLoading('completed');
        dispatch(setUserInfo(updateProfile.data));
        await updateUserDetailsByUsername(updateProfile.data);
        dispatch(
          setToastInfo({
            label: `Phone Number ${
              userInfo?.phoneNumber ? 'Changed' : 'Added'
            } Successfully`,
            variant: 'success',
            open: true,
          })
        );
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: ' Phone Number Addition failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: 'invalid Phone Number',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    } finally {
      setLoading('completed');
    }
  };

  const deletePhoneNumber = async () => {
    try {
      if (isOfflineFrontend) {
        dispatch(
          setToastInfo({
            label: 'User is Offline',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      setLoading('loading');
      const updateProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateStudentProfile(
          {
            studentProfileId: userInfo?.studentProfileId,
            phoneNumber: '',
            phoneVerify: 0,
          }
        );

      if (updateProfile.status === 200 && updateProfile.data) {
        setLoading('completed');
        dispatch(setUserInfo(updateProfile.data));
        await updateUserDetailsByUsername(updateProfile.data);
        dispatch(
          setToastInfo({
            label: 'Phone Number Deleted Successfully',
            variant: 'success',
            open: true,
          })
        );
        await interactionEvent({
          url: '',
          context: 'Phone_number',
          name: 'DELETE',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: ' Phone Number deletion failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: ' Phone Number deletion failed',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    } finally {
      setLoading('completed');
    }
  };

  const addEmail = async (email: string) => {
    console.log('addEmail:', email);

    if (!isValidEmail(email)) {
      dispatch(
        setToastInfo({
          label: 'invalid Email Id',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    if (isOfflineFrontend) {
      dispatch(
        setToastInfo({
          label: 'User is Offline',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    try {
      setLoading('loading');
      const updateProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateStudentProfile(
          {
            studentProfileId: userInfo?.studentProfileId,
            email: email,
            emailVerify: 0,
          }
        );

      if (updateProfile.status === 200 && updateProfile.data) {
        setLoading('completed');
        dispatch(setUserInfo(updateProfile.data));
        await updateUserDetailsByUsername(updateProfile.data);
        dispatch(
          setToastInfo({
            label: `Email Id ${
              userInfo?.email ? 'Changed' : 'Added'
            } Successfully`,
            variant: 'success',
            open: true,
          })
        );
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'Email Id Addition failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: 'invalid Email Id',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    } finally {
      setLoading('completed');
    }
  };

  const deleteEmail = async () => {
    try {
      if (isOfflineFrontend) {
        dispatch(
          setToastInfo({
            label: 'User is Offline',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      setLoading('loading');
      const updateProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateStudentProfile(
          {
            studentProfileId: userInfo?.studentProfileId,
            email: '',
            emailVerify: 0,
          }
        );

      if (updateProfile.status === 200 && updateProfile.data) {
        setLoading('completed');

        dispatch(setUserInfo(updateProfile.data));
        await updateUserDetailsByUsername(updateProfile.data);

        dispatch(
          setToastInfo({
            label: 'Email Id Deleted Successfully',
            variant: 'success',
            open: true,
          })
        );
        await interactionEvent({
          url: '',
          context: 'email',
          name: 'DELETE',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'Email Id Deletion failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: 'Email Id Deletion failed',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    } finally {
      setLoading('completed');
    }
  };

  const verifyPhoneHandler = async () => {
    try {
      if (isOfflineFrontend) {
        dispatch(
          setToastInfo({
            label: 'User is Offline',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      setLoading('loading');
      const sendOtp =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getPhoneOTP({
          phoneNumber: userInfo?.phoneNumber,
          userName: userInfo?.userName,
          otpType: OTPEnum.STUDENT_VERIFICATION,
        });

      if (sendOtp.status === 200) {
        setLoading('completed');
        // dispatch(
        //   setToastInfo({
        //     label: 'otp sent to your phone',
        //     variant: 'success',
        //     open: true,
        //   })
        // );
        dispatch(
          setOtpInfo({
            verification_code: sendOtp.verificationCode,
            otp_type: OTPEnum.STUDENT_VERIFICATION,
          })
        );

        dispatch(setVerifyPath(VERIFY_PHONE));

        navigate(PROFILE_OTP_SCREEN);
        await interactionEvent({
          url: '',
          context: 'Phone_number',
          name: 'VERIFY',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'invalid Phone Number',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      console.log(error);
    }
  };
  const verifyEmailHandler = async () => {
    try {
      if (isOfflineFrontend) {
        dispatch(
          setToastInfo({
            label: 'User is Offline',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      setLoading('loading');
      const sendOtp =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getEmailOTP({
          email: userInfo?.email,
          userName: userInfo?.userName,
          otpType: OTPEnum.STUDENT_VERIFICATION,
        });

      if (sendOtp.status === 200) {
        setLoading('completed');
        // dispatch(
        //   setToastInfo({
        //     label: 'otp sent to your phone',
        //     variant: 'success',
        //     open: true,
        //   })
        // );
        dispatch(
          setOtpInfo({
            verification_code: sendOtp.verificationCode,
            otp_type: OTPEnum.STUDENT_VERIFICATION,
          })
        );

        dispatch(setVerifyPath(VERIFY_EMAIL));

        navigate(PROFILE_OTP_SCREEN);
        await interactionEvent({
          url: '',
          context: 'email',
          name: 'VERIFY',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'invalid Email Id',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error: any) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: error.message
            ? `${error.message.replace(/\[.*?\]/g, '')}`
            : 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    }
  };
  return userInfo ? (
    loading === 'loading' ? (
      <Loader />
    ) : (
      <ProfileComponent
        id={userInfo.studentProfileId}
        role={ProfileRolesEnum.PROFILE_ROLE_STUDENT}
        profilePics={userInfo.profilePics}
        isMCBuser={is_mcb_user}
        className={
          userInfo.classSectionDetails?.className +
          ' ' +
          userInfo.classSectionDetails?.sectionName
        }
        name={
          (userInfo.firstName || '') +
          (userInfo.lastName ? ' ' + userInfo.lastName : '')
        }
        phone={
          userInfo.phoneNumber === '' ? undefined : userInfo.phoneNumber
          // mergeCountryCodeAndPhone({
          //     countryCode: userInfo.phoneCountry,
          //     phoneNumber: userInfo.phoneNumber,
          //   })
        }
        countryCode={userInfo.phoneCountry}
        addPhoneNumber={addPhoneNumber}
        isEmailVerified={userInfo.emailVerify === 1}
        isPhoneVerified={userInfo.phoneVerify === 1}
        email={userInfo.email}
        verifyPhoneHandler={verifyPhoneHandler}
        verifyEmailHandler={verifyEmailHandler}
        deletePhoneNumber={deletePhoneNumber}
        addEmail={addEmail}
        deleteEmailId={deleteEmail}
        schoolDetails={userInfo.schoolDetails}
        boardMediumDetails={userInfo.boardMediumDetails}
        learnSubjects={userInfo.learnSubjects}
        showSwitchProfile={multiple_profiles && multiple_profiles?.length > 1}
        switchProfileClickHandler={() => {
          navigate(SWITCH_PROFILE);
        }}
        isOffline={isOffline}
        eventCollection={{
          addPhoneNumberEvent: async () => {
            await interactionEvent({
              url: '',
              context: 'Phone_number',
              name: 'ADD',
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
          },
          changePhoneNumberEvent: async () => {
            await interactionEvent({
              url: '',
              context: 'Phone_number',
              name: 'CHANGE',
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
          },
          addEmailEvent: async () => {
            await interactionEvent({
              url: '',
              context: 'email',
              name: 'ADD',
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
          },
          changeEmailEvent: async () => {
            await interactionEvent({
              url: '',
              context: 'email',
              name: 'CHANGE',
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
          },
        }}
      />
    )
  ) : null;
};
