import { Timestamp } from '@bufbuild/protobuf';
import {
  CustomUploadResponse,
  DragAndDropFiles,
  IStyles,
  ImageWrapper,
  InfoBar,
  InstructionsPanel,
  // InstructionBoxCHW,
  PrimaryButton,
  deserify,
  formatDateAsDayMonth,
  getFileExtensionType,
  getFileSizeInMB,
  getFileType,
  getImageType,
  pxToRem,
  pxTovW,
  uploadFile,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import { FileEnum } from '@protos/content_management/content.db_pb';
import {
  FileUploadPurpose,
  SignedUrlReponse,
} from '@protos/learning_management/lms.common.apis_pb';
import {
  SubmissionType,
  TaskStudentAttemptStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ChangeEvent, DragEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { HOMEWORK_SUBMITTED } from '../../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';
import { setStudentHwUploadData } from '../../reducer/homework.slice';
import { aiHomeworkOpenEvent } from '../../utils_homework/homework.events';
import { setToastInfo } from '../../../Home/reducer/homeDashboard.slice';
import { getMediaBasePath } from '@geneo2-web/shared-ui';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';

const styles: IStyles = {
  root: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    p: {
      xs: `${pxToRem(20)} ${pxToRem(18)} `,
      md: pxToRem(20),
      lg: `${pxTovW(37)} ${pxTovW(240)}`,
    },
    gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: { xs: pxToRem(20), md: pxTovW(20) },
    position: 'relative',
  },
  leftPanel: {
    width: { xs: '100%', md: '45%', lg: pxTovW(520) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
  rightPanel: {
    width: { xs: '100%', md: '50%', lg: pxTovW(814) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(30) },
    paddingBottom: { xs: pxToRem(75), md: 0 },
  },
  digitalSubmission: {
    width: { xs: '100%', lg: pxTovW(520) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(30), md: pxTovW(30) },
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // backgroundColor: 'red',
    marginX: { md: 'auto', lg: '0px' },
  },
  physicalSubmission: {
    width: { xs: '100%', lg: pxTovW(520) },
    height: { xs: pxToRem(286), md: pxToRem(250), lg: pxTovW(455) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(17), md: pxTovW(30) },
    justifyContent: 'center',
    textAlign: 'center',
    background: '#F5FAFF',
    border: '1px solid #93CBFF',
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    // backgroundColor: 'red',
    marginX: { md: 'auto', lg: '0px' },
  },
  buttonContainer: {
    position: { xs: 'fixed', md: 'unset' },
    top: { xs: '91%' },
    width: { xs: '90%', md: '100%', lg: pxTovW(397) },
    height: { xs: '100%', md: pxTovW(60) },
    marginX: 'auto',
  },
};

export const CustomHomework = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isIpadOnly } = useGlobalContext();
  const { isOffline } = useDownloadContext();
  const pathSegments = location.pathname.split('/');

  const {
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomewokStudentAPIServiceV2ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const homework_id =
    new URLSearchParams(location.search).get('homeworkId') || undefined;
  const sessionMode =
    new URLSearchParams(location.search).get('sessionMode') || undefined;

  const { setSelectedFunction } = useGlobalContext();
  const { userInfo } = deserify(useAppSelector((state) => state.auth));
  const {
    active_homework_content,
    selected_homework_data,
    active_homework_session_id,
    student_hw_upload_data,
  } = deserify(useAppSelector((state) => state.homework));

  const activeHomeworkContent =
    active_homework_content && homework_id
      ? active_homework_content[homework_id]
      : undefined;

  const submissionType = selected_homework_data?.SubmissionType;
  // const [userUploads, setUserUploads] = useState<CustomUploadFileResponse[]>(
  //   []
  // );

  // useEffect(() => {
  //   console.log(
  //     'useEffect >>> student_hw_upload_data :::',
  //     student_hw_upload_data
  //   );
  // }, [student_hw_upload_data]);

  const backButtonClick = async () => {
    dispatch(setStudentHwUploadData([]));
    navigate(-1);
  };
  useEffect(() => {
    setSelectedFunction(() => backButtonClick);

    (async () => {
      await aiHomeworkOpenEvent({
        homeworkId: selected_homework_data?.homeworkId,
        questionIds: selected_homework_data?.questionIds,
        isResume: sessionMode === '3' ? true : false,
        isReattempted: sessionMode === '2' ? true : false,
        homeworkType: activeHomeworkContent?.homework?.taskType,
        isOffline: isOffline,
      });
    })();

    return () => {
      setSelectedFunction(null);
    };
  }, []);
  const handleDropFunction = async (event: DragEvent<HTMLDivElement>) => {
    await handleUploadDoc(event.dataTransfer.files);
    await interactionEvent({
      url: '',
      context: 'Homework',
      name: 'DRAG_AND_DROP',
      pathSegments: pathSegments,
      isOffline: isOffline,
    });
  };

  const handleChangeFunction = async (event: ChangeEvent<HTMLInputElement>) => {
    await handleUploadDoc(event.target.files as FileList);
    await interactionEvent({
      url: '',
      context: 'Homework',
      name: 'UPLOAD',
      pathSegments: pathSegments,
      isOffline: isOffline,
    });
  };

  const handleDeleteFunction = async (indexToDelete: number) => {
    const tempUserUploads = [...student_hw_upload_data];
    tempUserUploads[indexToDelete].uploadFileData.isDeleted = true;
    // tempUserUploads.splice(indexToDelete, 1);
    // setUserUploads(tempUserUploads);
    dispatch(setStudentHwUploadData(tempUserUploads));
  };

  const pendingStateUpdate = async (filesToUpload: FileList) => {
    if (!filesToUpload) return;

    const tempUserUploadsArray: CustomUploadResponse[] = [];
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      const tempUserUploads: CustomUploadResponse = {
        uploadFileData: {
          signedUrl: '',
          isUploaded: false,
          originalImagePath: file.name,
          isDeleted: false,
          fileType: getFileType(file.type),
          fileExtensionType: getFileExtensionType(file.type),
          fileSizeInMb: getFileSizeInMB(file.size),
        },
        fileUploadStatus: 'pending',
        fileSize: file.size,
      };

      tempUserUploadsArray.push(tempUserUploads);
    }
    // setUserUploads([...(userUploads ?? []), ...tempUserUploadsArray]);
    dispatch(
      setStudentHwUploadData([
        ...student_hw_upload_data,
        ...tempUserUploadsArray,
      ])
    );
  };
  const handleUploadDoc = async (filesToUpload: FileList) => {
    // console.log('uploadedFiles:', uploadedFiles);
    try {
      if (!filesToUpload) return;
      const tempUserUploadsArray: CustomUploadResponse[] = [];

      //& init the state with file details and fileUploadStatus as pending
      await pendingStateUpdate(filesToUpload);

      for (let i = 0; i < filesToUpload.length; i++) {
        const file = filesToUpload[i];
        const tempUserUploads: CustomUploadResponse = {
          uploadFileData: {
            signedUrl: '',
            isUploaded: false,
            originalImagePath: file.name,
            isDeleted: false, // optional
            fileType: getFileType(file.type),
            fileExtensionType: getFileExtensionType(file.type),
            fileSizeInMb: getFileSizeInMB(file.size),
          },
          fileUploadStatus: 'pending',
          fileSize: file.size,
        };
        // console.log('tempUserUploads:', tempUserUploads);

        if (getFileType(file.type) === FileEnum.FILE_TYPE_UNKNOWN) {
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Invalid Type';
          tempUserUploadsArray.push(tempUserUploads);
          continue;
        }
        //! max file size to be 50 MB
        if (getFileSizeInMB(file.size) > 50) {
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Size Exceeded';
          tempUserUploadsArray.push(tempUserUploads);
          continue;
        }

        try {
          const signedUrlResponse: SignedUrlReponse =
            await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getSignedUrlForFileUpload(
              {
                personId: userInfo?.studentProfileId,
                homeworkId: Number(homework_id),
                hwSessionId: active_homework_session_id,
                filesInfo: [
                  {
                    originalFilePath: file.name,
                    mimeType: getImageType(file.type),
                    size: getFileSizeInMB(file.size),
                  },
                ],
                personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
                fileUploadPurpose:
                  FileUploadPurpose.FILE_UPLOAD_FOR_HW_SUBMISSION,
                sectionId: activeHomeworkContent?.homework?.sectionId,
                subjectId: activeHomeworkContent?.homework?.subjectId,
              }
            );

          if (signedUrlResponse && signedUrlResponse.response.length > 0) {
            const signedUrl = getMediaBasePath(
              signedUrlResponse.response[0].signedUrl
            );

            // Perform your file upload here
            const uploadSuccess = await uploadFile({ file, signedUrl });

            if (uploadSuccess.success === true) {
              // If upload is successful, update status to 'success'
              console.log(`File ${file.name} uploaded successfully.`);
              tempUserUploads.uploadFileData.signedUrl = signedUrl;
              tempUserUploads.uploadFileData.isUploaded = true;
              tempUserUploads.fileUploadStatus = 'success';
            } else {
              console.error(`Failed to upload file ${file.name}.`);
              tempUserUploads.uploadFileData.isUploaded = false;
              tempUserUploads.fileUploadStatus = 'Upload Error';
            }
          } else {
            console.error(`Failed to get signed URL for file ${file.name}.`);
            tempUserUploads.uploadFileData.isUploaded = false;
            tempUserUploads.fileUploadStatus = 'Upload Error';
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          tempUserUploads.uploadFileData.isUploaded = false;
          tempUserUploads.fileUploadStatus = 'Upload Error';
        }

        tempUserUploadsArray.push(tempUserUploads);
      }
      // After all uploads are done, update state and status
      // setUserUploads([...userUploads, ...tempUserUploadsArray]);

      dispatch(
        setStudentHwUploadData([
          ...student_hw_upload_data,
          ...tempUserUploadsArray,
        ])
      );
    } catch (error) {
      console.error('An error occurred during file upload:', error);
    }
  };

  const proceedClickHandler = async () => {
    //

    /*
      message UploadFileResponse {
        string signed_url = 1;
        bool is_uploaded = 2;
        string original_image_path = 3; //Image-name
        optional bool is_deleted = 4;
        geneo.content.db.FileEnum file_type = 5;
        geneo.content.db.FileExtensionEnum file_extension_type = 6;
      }
    */
    try {
      const uploadFileDataArray =
        student_hw_upload_data
          ?.filter((upload) => upload.fileUploadStatus === 'success')
          .map((upload) => upload.uploadFileData) || [];
      if (
        activeHomeworkContent?.homework?.homeworkTargetDate &&
        new Timestamp(
          activeHomeworkContent?.homework?.homeworkTargetDate
        ).toDate() < new Date(Date.now())
      ) {
        dispatch(
          setToastInfo({
            variant: 'error',
            label: 'Aplogies, Deadline for Homework has passed.',
            open: true,
          })
        );
      }
      const hwSubmitResponse =
        await LmsHomewokStudentAPIServiceV2ClientWithStatusCodeHandler.submitStudentHWResponse(
          {
            studentId: userInfo?.studentProfileId,
            homeworkId: Number(homework_id),
            homeworkAttemptStatus:
              TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_COMPLETED,
            // responses: activeHomeworkStudentResp.responses,
            timestamp: Timestamp.fromDate(new Date()),
            sessionId: active_homework_session_id,
            userUploads: uploadFileDataArray,
            taskType: activeHomeworkContent?.homework?.taskType,
          }
        );

      if (hwSubmitResponse.status === 200) {
        dispatch(setStudentHwUploadData([]));
        navigate(`${HOMEWORK_SUBMITTED}?homeworkId=${homework_id}`);
        await interactionEvent({
          url: '',
          context: 'Homework',
          name: 'PROCEED',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      } else {
        dispatch(
          setToastInfo({
            label: 'Question Submission failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.heading}>
        <Typography variant="h1" fontWeight={'medium'}>
          Homework
        </Typography>
      </Box>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.leftPanel}>
          <InfoBar
            contentList={[
              {
                iconName: 'calender',
                heading: formatDateAsDayMonth(
                  activeHomeworkContent?.homework?.homeworkTargetDate
                ),
                subHeading: 'Deadline',
              },

              ...(activeHomeworkContent?.homework?.maxMarks
                ? [
                    {
                      iconName: 'marks',
                      heading:
                        activeHomeworkContent?.homework?.maxMarks?.toString() ||
                        '0',
                      subHeading: 'Marks',
                    },
                  ]
                : []),
            ]}
          />
          {submissionType === SubmissionType.SUBMISSION_ONLINE ? (
            // {true ? (
            <Box sx={styles.digitalSubmission}>
              <DragAndDropFiles
                handleDropFunction={handleDropFunction}
                handleChangeFunction={handleChangeFunction}
                handleDeleteFunction={handleDeleteFunction}
                userUploads={student_hw_upload_data}
              />
              <Box sx={styles.buttonContainer}>
                <PrimaryButton
                  fullWidth={isIpadOnly ? true : false}
                  disabled={
                    !student_hw_upload_data?.some(
                      (item) =>
                        item.fileUploadStatus === 'success' &&
                        !item.uploadFileData.isDeleted
                    )
                  }
                  onClick={proceedClickHandler}
                >
                  <Typography
                    variant="h3"
                    color={'common.white'}
                    fontWeight={700}
                    sx={{ fontSize: { xs: pxToRem(18), md: pxTovW(21) } }}
                  >
                    PROCEED
                  </Typography>
                </PrimaryButton>
              </Box>
            </Box>
          ) : (
            <Box sx={styles.physicalSubmission}>
              <Typography variant="h2" fontWeight={600}>
                Physical Submission
              </Typography>
              <ImageWrapper
                name="physicalSubmission"
                type="png"
                styles={{
                  width: { xs: pxToRem(219), md: pxTovW(313) },
                  height: { xs: pxToRem(153), md: pxTovW(241) },
                  alignSelf: 'center',
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  width: { xs: pxToRem(219), md: pxTovW(313) },
                  alignSelf: 'center',
                }}
              >
                Submit Your Homework Directly To Your Teacher
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={styles.rightPanel}>
          <Box>
            <InstructionsPanel
              customSx={{
                // backgroundColor: 'red',
                display: 'flex',
                flexGrow: 1,
                width: { xs: '100%', lg: pxTovW(814) },
              }}
              AttachmentList={
                activeHomeworkContent?.homework?.taskUploads.filter(
                  (e) => e.isDeleted === false
                ) || undefined
              }
              // Katexvalue={TextEditorValue?.toString() || '<p></p>'}
              Katexvalue={
                activeHomeworkContent?.homework?.homeworkInstructions.join(
                  '\n'
                ) || '<p></p>'
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
