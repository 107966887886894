import {
  callStudentEventsApi,
  clearLocalStorageKeys,
  deserify,
  getDeviceType,
  getEnvConfig,
  getLocalStorage,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  DeviceEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  aiLogoutEvent,
  interactionEvent,
} from '../pages/Auth/Login/login_logout.events';
import {
  resetAuthState,
  setContactPopupOpen,
} from '../pages/Auth/reducer/auth.slice';
import { resetHomeState } from '../pages/Home/reducer/homeDashboard.slice';
import {
  resetHomeworkState,
  setActiveHomeworSessionId,
  setStudentHwUploadData,
} from '../pages/Homework/reducer/homework.slice';
import { resetLearnState } from '../pages/Learn/reducer/learn.slice';
import {
  resetPerformanceState,
  setPerformanceId,
} from '../pages/Performance/reducer/performance.slice';
import { useAppDispatch, useAppSelector } from '../reduxStore/reduxHooks';
import {
  CHANGE_PASSWORD,
  DISCLAIMER,
  DOWNLOAD_SETTINGS,
  HELP,
  LOGIN,
  PROFILE,
} from '../routeHandling/RoutesNomenclature';

import { useDownloadContext } from '../app/Context/DownloadContextProviderV2';
import {
  aiHomeworkCloseEvent,
  aiHomeworkReviewCloseEvent,
} from '../pages/Homework/utils_homework/homework.events';
import {
  aiBookCloseEvent,
  aiChapterCloseEvent,
  aiContentCloseEvent,
  aiLessonCloseEvent,
  aiResourceCloseEvent,
  aiResourcePageCloseEvent,
  aiTopicCloseEvent,
} from '../pages/Learn/utils_learn/learn.events';
import { aiPerformanceCloseEvent } from '../pages/Performance/utils_performance/performance.events';

declare global {
  interface Window {
    Android: any;
  }
}

export const useSideBarOptions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const android = window.Android;
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;
  const { isOffline } = useDownloadContext();
  const { is_logged_in_offline, ssoRedirectionUrl } = deserify(
    useAppSelector((state) => state.auth)
  );
  const isOfflineFrontend = isOffline || is_logged_in_offline;

  // // Check device type
  const deviceType = getDeviceType();
  const isMCBUser = deserify(useAppSelector((state) => state.auth.is_mcb_user));
  const config = getEnvConfig();

  const { selected_book_id } = deserify(useAppSelector((state) => state.home));

  const { selected_chapter_resources } = deserify(
    useAppSelector((state) => state.learn)
  );
  const location = useLocation();
  const lessonSessionId =
    new URLSearchParams(location.search).get('lessonSessionId') || undefined;
  const {
    selected_module_lessons,
    selected_lesson_content,
    selected_resource_content,
    selected_resource_info,
    selected_resource_session_id,
  } = deserify(useAppSelector((state) => state.learn));

  const { performance_id } = deserify(
    useAppSelector((state) => state.performance)
  );
  const { selected_homework_data, active_homework_session_id } = deserify(
    useAppSelector((state) => state.homework)
  );

  const pathSegments = location.pathname.split('/');

  const userId = getLocalStorage('userId');
  const fcmToken = getLocalStorage('fcmToken'); // Convert fcmToken to string
  const deviceId = getLocalStorage('deviceID');
  const notificationAPI = async () => {
    if (!userId) {
      console.log('User ID not found.');
      return;
    }

    try {
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        deviceId: deviceId ? BigInt(deviceId) : undefined,
        profileId: BigInt(userId),
        role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
        deviceType: DeviceEnum.DEVICE_TYPE_WEB,
        deviceToken: fcmToken?.toString(),
        isActive: false,
      });
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        deviceToken: fcmToken?.toString(),
        topic: `${String(userId)}_${ProfileRolesEnum.PROFILE_ROLE_STUDENT}`,
        subscribeTopic: false,
      });
    } catch (err) {
      console.error('Error in Notification API:', err);
    }
  };

  const commonDispatch = async () => {
    dispatch(setActiveHomeworSessionId(undefined));
    dispatch(setStudentHwUploadData([]));
  };

  const commonAiCloseEvents = async (name: string) => {
    await aiContentCloseEvent({
      pageNumber: selected_resource_info?.pageNumber,
      pageContent: selected_resource_info?.pageContent,
      isOffline: isOffline,
    });
    await aiResourcePageCloseEvent({
      resourceId: selected_resource_content?.parsedData.resourceId,
      pageNumber: selected_resource_info?.pageNumber,
      isOffline: isOffline,
    });
    await aiResourceCloseEvent({
      resourceId: selected_resource_content?.parsedData.resourceId,
      isOffline: isOffline,
    });
    await aiLessonCloseEvent({
      lessonId: selected_lesson_content?.lessonId,
      isOffline: isOffline,
    });
    await aiTopicCloseEvent({
      topicId: selected_module_lessons?.moduleId,
      isOffline: isOffline,
    });
    await aiChapterCloseEvent({
      chapterId: selected_chapter_resources?.chapterId,
      isOffline: isOffline,
    });

    await aiPerformanceCloseEvent({
      performanceId: performance_id,
      isOffline: isOffline,
    });
    dispatch(setPerformanceId(undefined));

    await aiHomeworkCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
      isOffline: isOffline,
    });
    await aiHomeworkReviewCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
      isOffline: isOffline,
    });

    await interactionEvent({
      url: '',
      context: 'navigation_bar',
      name: name,
      pathSegments: pathSegments,
      homeworkSesssionId: active_homework_session_id?.toString(),
      lessonSesssionId: lessonSessionId,
      resourceSesssionId: selected_resource_session_id?.toString(),
      isOffline: isOffline,
    });

    commonDispatch();
  };

  const sidebarArray = [
    {
      name: 'Profile',
      icon: 'profile-icon',
      onClick: async () => {
        await commonAiCloseEvents('PROFILE');
        navigate(PROFILE);
      },
      route: PROFILE,
    },
    // {
    //   name: 'Change Password',
    //   icon: 'change-password',
    //   onClick: async () => {
    //     // navigate(CHANGE_PASSWORD);
    //     await commonAiCloseEvents('CHANGE_PASSWORD');
    //   },
    // },
    // {
    //   name: 'Notification',
    //   icon: 'bell',
    //   onClick: () => {
    //     navigate(NOTIFICATION)
    //   }
    // },
    {
      name: 'Contact Us',
      icon: 'contact',
      onClick: async () => {
        // console.log('contact clicked');
        await interactionEvent({
          url: '',
          context: 'navigation_bar',
          name: 'CONTACT',
          pathSegments: pathSegments,
          homeworkSesssionId: active_homework_session_id?.toString(),
          lessonSesssionId: lessonSessionId,
          resourceSesssionId: selected_resource_session_id?.toString(),
          isOffline: isOffline,
        });
        dispatch(setContactPopupOpen(true));
      },
      route: undefined,
    },
    {
      name: 'Help & Support',
      icon: 'support1',
      onClick: async () => {
        await commonAiCloseEvents('HELP_&_SUPPORT');
        navigate(HELP);
      },
      route: HELP,
    },
    {
      name: 'Terms & Conditions',
      icon: 'tnc',
      onClick: async () => {
        await commonAiCloseEvents('DISCLAIMER_&_POLICIES');
        navigate(DISCLAIMER);
      },
      route: DISCLAIMER,
    },
    {
      name: 'Download Settings',
      icon: 'headerSettings',
      onClick: async () => {
        await commonAiCloseEvents('DOWNLOAD_SETTINGS');
        navigate(DOWNLOAD_SETTINGS);
      },
      route: DOWNLOAD_SETTINGS,
    },
    {
      name: 'Sign out',
      icon: 'sign-out',
      onClick: async () => {
        try {
          await aiBookCloseEvent({
            bookId: selected_book_id,
            isOffline: isOffline,
          });
          await commonAiCloseEvents('SIGN_OUT');
          await aiLogoutEvent({ isOffline: isOffline });
          !isOffline &&
            (await callStudentEventsApi(commonServiceClientContext));
          if (fcmToken) {
            await notificationAPI();
          }
          const response =
            await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.logout({
              profileId: getLocalStorage('userId'),
              role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            });
          if (deviceType === 'android') {
            android.userLoggedOut(true);
          }
          if (response) {
            // aiBookCloseEvent(selected_book_id);
            // commonAiCloseEvents();
            // aiLogoutEvent();
          }
        } catch (err) {
          console.log(err);
        } finally {
          let redirectUrl = undefined;
          if (isMCBUser && ssoRedirectionUrl) {
            redirectUrl = ssoRedirectionUrl;
          }
          dispatch(resetAuthState());
          dispatch(resetHomeState());
          dispatch(resetLearnState());
          dispatch(resetPerformanceState());
          dispatch(resetHomeworkState());
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            navigate(LOGIN);
          }
          // localStorage.clear();
          clearLocalStorageKeys();
        }
      },
    },
  ];

  if (!isOfflineFrontend && !isMCBUser) {
    sidebarArray.splice(1, 0, {
      name: 'Change Password',
      icon: 'change-password',
      onClick: async () => {
        navigate(CHANGE_PASSWORD);
        await commonAiCloseEvents('CHANGE_PASSWORD');
      },
    });
  }

  return sidebarArray;
};
