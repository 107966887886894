import { useEffect, useState } from 'react';

import { Box, Grid, Pagination, Typography } from '@mui/material';

import { Timestamp } from '@bufbuild/protobuf';
import {
  EarlyHomeworkPopup,
  IStyles,
  InfoDisplayCard,
  NoContentCard,
  ShimmerManageHwCard,
  StudentSubjectPopup,
  deserify,
  formatDateAsDayMonth,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  remainingTimeInHoursAndMins,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { TaskStudentAttemptStatusEnum } from '@protos/learning_management/lms.db_pb';
import { StudentHomeworkTask } from '@protos/learning_management/lms.hw.student.apis_pb';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { HOME } from '../../../routeHandling/RoutesNomenclature';
import { onHomeworkCardClick } from '../../../utils/homework';
import { interactionEvent } from '../../Auth/Login/login_logout.events';
import { setHomeSelectedSubjectId } from '../../Home/reducer/homeDashboard.slice';
import {
  setActiveHomeworkList,
  setAssignedData,
  setEndedData,
  setEndedHomeworkList,
  setHwInfoData,
} from '../reducer/homework.slice';
import { HwDashSubjectFilter } from './component/HwDashSubjectFilter';
import { TabComp } from './component/TabComp';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { checkFeatureData } from '../utils_homework/homeworkUtil';

const styles: IStyles = {
  root: {
    boxSizing: 'border-box',
    pb: { xs: pxToRem(30), md: pxTovW(50) },
    // backgroundColor: 'red',
  },

  mainHeadingBox: {
    p: { md: pxToRem(20), lg: `${pxTovW(27)} ${pxTovW(241)} 0 ${pxTovW(241)}` },

    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(21), md: pxTovW(34.5) },
  },

  hwMapper: {
    boxSizing: 'border-box',
    p: {
      xs: `${pxToRem(25)} ${pxToRem(20)}`,
      lg: `${pxTovW(39)} ${pxTovW(419)}`,
    },

    idcStyle: {
      border: '1px solid red',
      width: { xs: pxToRem(319), md: pxTovW(347) },
      height: { xs: pxToRem(160), md: pxTovW(153) },
    },
    margin: 'auto',
    width: { xs: '100%', md: 'auto' },
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: { xs: 'column', md: 'unset' },
    gap: { xs: pxToRem(15), md: pxTovW(20) },
  },
  title: {
    paddingTop: { xs: pxToRem(15), md: 0 },
    paddingLeft: { xs: pxToRem(15), md: 0 },
    // backgroundColor: 'red',
  },
};

export const StudentHwDash = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setSelectedFunction } = useGlobalContext();
  const { isOffline } = useDownloadContext();

  const { userInfo } = deserify(useAppSelector((state) => state.auth));

  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const {
    assigned_homework,
    ended_homework,
    hw_info,
    active_homework_content,
    selected_homework_data,
  } = deserify(useAppSelector((state) => state.homework));
  const activeHomeworkContent =
    active_homework_content && selected_homework_data?.homeworkId
      ? active_homework_content[selected_homework_data?.homeworkId]
      : undefined;

  const learnSubjects =
    deserify(useAppSelector((state) => state.auth.userInfo?.learnSubjects)) ||
    [];

  const { selected_subject_id } = deserify(
    useAppSelector((state) => state.home)
  );
  const { isIpadOnly } = useGlobalContext();
  const mapData = ['Active', 'Ended'];
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [startTime, setStartTime] = useState<Timestamp | undefined>(undefined);
  const [modalState, setModalState] = useState(false);
  const [value, setValue] = useState(mapData[0]);
  const [pageNumber, setPageNumber] = useState(1);
  const { LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const handleChange = async (newValue: string) => {
    setValue(newValue);
    setPageNumber(1);
    await interactionEvent({
      url: 'Student_Homework_dash',
      context: 'your_homework',
      name: newValue.toUpperCase(),
      isOffline: isOffline,
    });
  };
  // const subMap = getSubjectsMap(learnSubjects);

  const getSortedHomeworkList = (
    filter: string | undefined,
    homeworkList: StudentHomeworkTask[] | undefined
  ) => {
    switch (filter) {
      case 'Questions':
        return homeworkList?.sort((a, b) => a.noOfQuestions - b.noOfQuestions);
      case 'Score':
        return homeworkList?.sort((a, b) => a.scorePercent - b.scorePercent);
      case 'Time Left':
        return homeworkList?.sort(
          (a, b) => Number(a.endDate?.seconds) - Number(b.endDate?.seconds)
        );
      case 'Deadline':
        return homeworkList?.sort(
          (a, b) => Number(a.endDate?.seconds) - Number(b.endDate?.seconds)
        );
      case undefined:
        return homeworkList;
    }
  };

  useEffect(() => {
    const redirectToHome = () => {
      navigate(HOME);
    };
    setSelectedFunction(() => redirectToHome);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  useEffect(() => {
    getStudentHomeworkList();
  }, [pageNumber, value, selected_subject_id]);

  const getStudentHomeworkList = async () => {
    try {
      // setLoading('loading');

      if (
        (value === 'Active' &&
          assigned_homework &&
          !assigned_homework[pageNumber]) ||
        (value === 'Ended' && ended_homework && !ended_homework[pageNumber])
      ) {
        setLoading('loading');
      } else {
        setLoading('completed');
      }

      const response =
        await LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentHomeworkList(
          {
            studentId: studentId,
            pageNumber: pageNumber,
            subjectId: selected_subject_id,
          }
        );

      const countInPagination = response.data?.hwListInfo?.countInPagination;

      dispatch(setActiveHomeworkList(response.data?.allHomeworks?.active));
      dispatch(setEndedHomeworkList(response.data?.allHomeworks?.ended));

      dispatch(
        setAssignedData({
          ...assigned_homework,
          [pageNumber]: response.data?.allHomeworks?.active,
        })
      );
      dispatch(
        setEndedData({
          ...ended_homework,
          [pageNumber]: response.data?.allHomeworks?.ended,
        })
      );

      dispatch(
        setHwInfoData({
          ...hw_info,
          hwListInfo: response.data?.hwListInfo,
          totalAssignedPages: Math.ceil(
            (response.data?.hwListInfo?.activeHwCount || 0) /
              (countInPagination || 1)
          ),
          totalEndedPages: Math.ceil(
            (response.data?.hwListInfo?.endedHwCount || 0) /
              (countInPagination || 1)
          ),
        })
      );

      setLoading('completed');
    } catch (err) {
      console.log(err);
      setLoading('error');
      dispatch(setActiveHomeworkList([]));
      dispatch(setEndedHomeworkList([]));
    }
  };
  const sortFunction = (key?: string) => {
    // setFilter(key);
    const newData = getSortedHomeworkList(
      key,
      value === 'Active' && assigned_homework
        ? assigned_homework[pageNumber]
        : value === 'Ended' && ended_homework
        ? ended_homework[pageNumber]
        : []
    );
    // console.log('newData:', newData);

    if (value === 'Active') {
      dispatch(
        setAssignedData({ ...assigned_homework, [pageNumber]: newData })
      );
    } else if (value === 'Ended') {
      dispatch(setEndedData({ ...ended_homework, [pageNumber]: newData }));
    }
  };

  const showPagination = () => {
    return (
      (value === 'Active' && hw_info && hw_info.totalAssignedPages > 0) ||
      (value === 'Ended' && hw_info && hw_info.totalEndedPages > 0)
    );
  };

  const renderHomeworkList = (
    homeworkList: StudentHomeworkTask[] | undefined
  ) => {
    return homeworkList?.map((val, index) => (
      <InfoDisplayCard
        key={
          (value === 'Active' ? 'Active_Homework_' : 'Ended_Homework_') + index
        }
        currentTabValue={value}
        image={getMediaBasePath(
          val.homeworkPosterImgUrl,
          'processedMediaBucket'
        )}
        variant={isIpadOnly ? 'large' : 'small'}
        submissionType={val.submissionType}
        displayScore={checkFeatureData({ userInfo: userInfo }).display_score}
        homeworkItem={{
          subject: val.subject,
          chapter: val.moduleName,
          hwName: val.homeworkTitle,
          teacherName: val.teacherName,
          maxMarks: val.maxMarks,
          teacherProfileImageUrl: getMediaBasePath(val.teacherProfileImageUrl),
          completed:
            val.studentAttemptStatus ===
            TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_COMPLETED
              ? val.scorePercent
                ? val.scorePercent.toString()
                : '0'
              : undefined,
          taskType: val.taskType,
        }}
        iconDetails={[
          {
            iconName: 'questions',
            text: val.noOfQuestions.toString(),
            label: 'Questions',
          },
          {
            iconName: value === 'Active' ? 'clock' : 'calender',
            text:
              value === 'Active'
                ? remainingTimeInHoursAndMins(val.endDate)
                : formatDateAsDayMonth(val.endDate),
            label: value === 'Active' ? 'Remaining' : 'Deadline',
          },
        ]}
        rootStyle={{
          width: { xs: '100%', md: '32%', lg: '32%' },
          height: { xs: pxToRem(165), md: pxToRem(110), lg: pxTovW(170) },
          maxWidth: { xs: '100%', md: '32%', lg: pxTovW(347) },
        }}
        onCardClick={async () => {
          if (!studentId) {
            return;
          }

          if (
            value === 'Active'
            // || checkFeatureData({
            //   assignmentConfigInfo:
            //     activeHomeworkContent?.homework?.assignmentConfigInfo
            //       ?.assignmentInfo,
            // }).submission_after_deadline
          ) {
            if (val.startDate) {
              if (
                new Date(new Timestamp(val.startDate).toDate()) > new Date()
              ) {
                setStartTime(val.startDate);
                return;
              }
            }
            // dispatch(setSelectedHomeworkData({ homeworkId: val.homeworkId }));

            onHomeworkCardClick(
              dispatch,
              navigate,
              val.homeworkId,
              studentId,
              'active',
              location.pathname
            );
            // navigate(HOMEWORK_START_COVER);
          } else {
            // if (val.submissionType === SubmissionType.SUBMISSION_OFFLINE)
            //   return;
            onHomeworkCardClick(
              dispatch,
              navigate,
              val.homeworkId,
              studentId,
              'ended',
              location.pathname
            );
          }

          await interactionEvent({
            url: '',
            context: value === 'Active' ? 'active' : 'ended',
            name:
              value === 'Active'
                ? 'ACTIVE_HOMEWORK_OPEN'
                : 'ENDED_HOMEWORK_OPEN',
            pathSegments: pathSegments,
            isOffline: isOffline,
          });
        }}
      />
    ));
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.mainHeadingBox}>
        <Typography variant="h1" sx={styles.title}>
          Your Homework
        </Typography>

        <TabComp
          handleChange={handleChange}
          mapData={mapData}
          stateValue={value}
          // count={filteredHomeworkList.length}
          count={
            value === 'Active'
              ? hw_info?.hwListInfo?.activeHwCount || 0
              : value === 'Ended'
              ? hw_info?.hwListInfo?.endedHwCount || 0
              : 0
          }
        />
      </Box>

      <HwDashSubjectFilter
        setModalState={setModalState}
        sortFunction={sortFunction}
        tab={value}
      />

      {loading === 'loading' ? (
        // <Loader />
        <ShimmerComponent />
      ) : loading === 'error' ? (
        <NoContentCard variant="error" icon="error" text="Error Occured" />
      ) : (
        <Box sx={styles.hwMapper}>
          {renderHomeworkList(
            value === 'Active' && assigned_homework
              ? assigned_homework[pageNumber]
              : value === 'Ended' && ended_homework
              ? ended_homework[pageNumber]
              : []
          )}
        </Box>
      )}

      {showPagination() ? (
        <Pagination
          page={pageNumber}
          // count={10}
          count={
            value === 'Active'
              ? hw_info?.totalAssignedPages
              : value === 'Ended'
              ? hw_info?.totalEndedPages
              : 0
          }
          color="primary"
          onChange={async (event: React.ChangeEvent<unknown>, page: number) => {
            setPageNumber(page);
            if (pageNumber !== page) {
              await interactionEvent({
                url: 'Student_homework_dash',
                context: 'navigation_bar',
                name: 'PAGE_CHANGE',
                isOffline: isOffline,
              });
            }
          }}
          sx={{ width: 'max-content', margin: 'auto' }}
        />
      ) : (
        <Box sx={{ width: 'max-content', margin: 'auto' }}>
          <Typography variant="h2" fontWeight="bold" margin="auto">
            No {value} Homework
          </Typography>
        </Box>
      )}

      <StudentSubjectPopup
        modalState={modalState}
        setModalState={setModalState}
        displayData={learnSubjects.map((sub) => ({
          subject: sub.subjectName,
          icon: getMediaBasePath(sub.iconUrl, 'schoolnetStaticAssetBucket'),
          color: sub.textColor,
          onClick: async () => {
            await interactionEvent({
              url: 'Student_Learn_dashboard',
              context: 'subject_selection',
              name: sub.subjectName.toUpperCase().replace(/ /g, '_'),
              isOffline: isOffline,
            });
            dispatch(setHomeSelectedSubjectId(sub.subjectId));
            setModalState(false);
            setPageNumber(1);
          },
        }))}
        title="Choose Subject"
      />
      <EarlyHomeworkPopup
        open={!!startTime}
        okHandler={() => setStartTime(undefined)}
        startTime={startTime}
      />
    </Box>
  );
};

const ShimmerComponent = () => {
  return (
    <Grid
      container
      rowGap={3}
      sx={{
        boxSizing: 'border-box',
        width: { md: pxTovW(1100) },
        margin: 'auto',
        p: { xs: pxToRem(20), md: pxTovW(0) },
      }}
    >
      {Array.from({ length: 12 }, (_, index) => (
        <Grid item xs={11} md={4} key={index}>
          <ShimmerManageHwCard key={index} />
        </Grid>
      ))}
    </Grid>
  );
};

type HomeworkFilterType = 'ques' | 'time' | 'score';
