import {
  AssignmentConfigEnum,
  AssignmentInfo,
} from '@protos/learning_management/lms.db_pb';
import { Feature } from '@protos/school_management/school.db_pb';
import { StudentLoginResponseType } from '@protos/user_management/ums.login.apis_pb';

// -----------------------------------------------------------------------

export const isHomeworkReattemptDisabled = (schoolId?: number) => {
  const schoolIds = [209, 256, 258, 259, 260, 261, 263];
  if (schoolId && schoolIds.includes(schoolId)) {
    return true;
  }
  return false;
};

// -----------------------------------------------------------------------

interface IReturnData {
  submission_after_deadline: boolean;
  reattempt: boolean;
  shuffling: boolean;
  timed: boolean;
  display_meme: boolean;
  display_score: boolean; // missing in enum
}
interface ICheckFeatureData {
  assignmentConfigInfo?: AssignmentInfo[];
  userInfo?: StudentLoginResponseType;
}
export const checkFeatureData = (props: ICheckFeatureData): IReturnData => {
  const { assignmentConfigInfo, userInfo } = props;
  const returnData: IReturnData = {
    submission_after_deadline: false,
    reattempt: true,
    shuffling: false,
    timed: false,
    display_meme: true,
    display_score: true,
  };

  const schoolId = userInfo?.schoolDetails?.schoolId;
  if (assignmentConfigInfo) {
    assignmentConfigInfo.forEach((configInfo) => {
      if (
        configInfo.configName ===
        AssignmentConfigEnum.ASSIGNMENT_CONFIG_ALLOW_LATE_SUBMISSION
      ) {
        returnData.submission_after_deadline = configInfo.isEnabled;
      } else if (
        configInfo.configName ===
        AssignmentConfigEnum.ASSIGNMENT_CONFIG_REATTEMPT
      ) {
        returnData.reattempt =
          isHomeworkReattemptDisabled(schoolId) === true
            ? true
            : configInfo.isEnabled;
      } else if (
        configInfo.configName ===
        AssignmentConfigEnum.ASSIGNMENT_CONFIG_SHUFFLING
      ) {
        returnData.shuffling = configInfo.isEnabled;
      } else if (
        configInfo.configName === AssignmentConfigEnum.ASSIGNMENT_CONFIG_TIMED
      ) {
        returnData.timed = configInfo.isEnabled;
      }
    });
  }

  const featureData = userInfo?.schoolDetails?.featuresPlanInfo?.featureInfo;
  if (featureData) {
    featureData.forEach((featureInfo) => {
      if (featureInfo.feature === Feature.SHOW_MEMES) {
        returnData.display_meme = featureInfo.isEnabled;
      }
      // else if (featureInfo.feature === Feature.) {
      //   returnData.display_score = featureInfo.isEnabled;
      // }
    });
  }

  return returnData;
};
