import {
  IStyles,
  ImageWrapper,
  InputFieldContainer,
  LinkButton,
  Loader,
  OtpInputs,
  PrimaryButton,
  deserify,
  pxToRem,
  pxTovW,
  updateUserDetailsByUsername,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { OTPEnum } from '@protos/user_management/ums.db_pb';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  PROFILE,
  VERIFY_EMAIL,
  VERIFY_PHONE,
} from '../../../routeHandling/RoutesNomenclature';
import { setOtpInfo } from '../../Auth/reducer/auth.slice';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';

const styles: IStyles = {
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: { xs: 'start', md: 'center' },
    // backgroundColor: 'red',
    // mt: { xs: pxToRem(20), md: pxTovW(20) },
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(5),
    textAlign: { xs: 'left', md: 'center' },
    flexGrow: 1,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(32), md: pxTovW(41) },
  },
  inputContainer: {
    mt: { xs: pxToRem(50), md: pxTovW(60) },
  },
  skip: {
    width: { xs: pxToRem(75), md: pxTovW(140) },
    height: { xs: pxToRem(42), md: pxTovW(82) },
    '& > span': {
      fontSize: pxToRem(16),
      color: '#007CDC',
      // backgroundColor: 'blue',
      padding: '0px',
    },
  },
  forgotAndOtpBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(25), md: pxTovW(22) },
  },

  secondaryButtonBox: {
    width: { xs: '100%', md: pxTovW(517) },
    margin: 'auto',
    mt: { xs: pxToRem(100), md: pxTovW(40) },
  },
};
export const ProfileOtpScreen = () => {
  const [otp, setotp] = useState('');
  const [seconds, setSeconds] = useState(30);
  const verifyPath = useAppSelector((state) => state.auth.verify_path);
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [helperText, setHelperText] = useState<string>(
    verifyPath === VERIFY_EMAIL
      ? 'Otp sent to your email'
      : 'Otp sent to your phone number'
  );
  const [helperTextVariant, setHelperTextVariant] = useState<
    'success' | 'error'
  >('success');
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const { selectedFunction, setSelectedFunction } = useGlobalContext();

  const backClick = () => {
    navigate(PROFILE);
  };
  useEffect(() => {
    setSelectedFunction(() => backClick);
  }, []);
  const { userInfo, otp_info } = deserify(
    useAppSelector((state) => state.auth)
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verifyClickHandler = async () => {
    setLoading('loading');
    try {
      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.verifyOTP({
          otp: otp,
          verificationCode: otp_info?.verification_code,
          check:
            verifyPath === VERIFY_EMAIL
              ? userInfo?.email
              : userInfo?.phoneNumber,
        });

      if (response.status === 200) {
        setLoading('completed');
        dispatch(
          setToastInfo({
            label: `${
              verifyPath === VERIFY_EMAIL ? 'Email Id' : 'Phone Number'
            } verified successfully`,
            variant: 'success',
            open: true,
          })
        );
        const studentProfiles = response.data?.studentProfiles || [];
        for (let i = 0; i < studentProfiles.length; i++) {
          await updateUserDetailsByUsername(studentProfiles[i]);
        }
        navigate(PROFILE);
      } else {
        console.log(response.message);
        setLoading('error');
        dispatch(
          setToastInfo({
            label: response.message
              ? `${response.message}`
              : `${
                  verifyPath === VERIFY_EMAIL ? 'Email Id' : 'Phone Number'
                } verification failed`,
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error: any) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: error.message
            ? `${error.message.replace(/\[.*?\]/g, '')}`
            : `${
                verifyPath === VERIFY_EMAIL ? 'Email Id' : 'Phone Number'
              } verification failed`,
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    }
  };
  const sendOtp = async () => {
    setSeconds(30);
    setLoading('loading');
    console.log(loading);
    try {
      if (verifyPath === VERIFY_EMAIL) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getEmailOTP({
            email: userInfo?.email,
            userName: userInfo?.userName,
            otpType: OTPEnum.STUDENT_VERIFICATION,
          });
        if (response.status === 200) {
          setLoading('completed');
          // dispatch(
          //   setToastInfo({
          //     label: 'otp sent to your email',
          //     variant: 'success',
          //     open: true,
          //   })
          // );
          setHelperText('Otp resent to your email');
          setHelperTextVariant('success');
          dispatch(
            setOtpInfo({
              verification_code: response.verificationCode,
              otp_type: OTPEnum.STUDENT_VERIFICATION,
            })
          );
        } else {
          setLoading('error');
          setHelperText('Otp resent to your email failed');
          setHelperTextVariant('error');
          // dispatch(
          //   setToastInfo({
          //     label: 'invalid email id',
          //     variant: 'error',
          //     open: true,
          //   })
          // );
        }
      } else if (verifyPath === VERIFY_PHONE) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getPhoneOTP({
            phoneNumber: userInfo?.phoneNumber,
            userName: userInfo?.userName,
            otpType: OTPEnum.STUDENT_VERIFICATION,
          });
        if (response.status === 200) {
          setLoading('completed');
          // dispatch(
          //   setToastInfo({
          //     label: 'otp sent to your phone',
          //     variant: 'success',
          //     open: true,
          //   })
          // );
          setHelperText('Otp resent to your phone ');
          setHelperTextVariant('success');
          dispatch(
            setOtpInfo({
              verification_code: response.verificationCode,
              otp_type: OTPEnum.STUDENT_VERIFICATION,
            })
          );
        } else {
          // console.log(response.message);
          setLoading('error');
          setHelperText('Otp resent to your phone failed');
          setHelperTextVariant('error');
          // dispatch(
          //   setToastInfo({
          //     label: 'invalid Phone Number',
          //     variant: 'error',
          //     open: true,
          //   })
          // );
        }
      }
    } catch (error: any) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: error.message
            ? `${error.message.replace(/\[.*?\]/g, '')}`
            : 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    }
  };

  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Grid container sx={styles.container}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          background: '#E5E5E5',
          height: '100vh',
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          alignItems: 'center',
          // justifyContent: 'center',
        }}
      >
        <ImageWrapper
          name="otp-verification"
          type="png"
          parentFolder="images"
          styles={{
            height: pxTovW(550),
            width: pxTovW(494.48),
            maxWidth: '100%',
          }}
        />
        <Typography variant="h1" fontWeight="bold">
          {verifyPath === '/verify-email'
            ? 'Verify your Email Id'
            : 'Verify your Phone Number'}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: { xs: pxToRem(34), md: 0 },
          height: { xs: '100vh', md: '100vh' },
          overflowY: 'auto',
          padding: { xs: pxToRem(20), md: `${pxTovW(74)} ${pxTovW(94)}` },
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              ...styles.root,
              width: { xs: '100%', md: '50%' },
              display: 'flex',
            }}
          >
            <Box>
              <Box sx={styles.mainContainer}>
                <Box>
                  <InputFieldContainer
                    helperTextvariant={helperTextVariant}
                    topLabel=""
                    value={
                      (verifyPath === VERIFY_EMAIL
                        ? userInfo?.email
                        : userInfo?.phoneNumber) || ''
                    }
                    // onChange={handleCredentialChange}
                    helperText={helperText}
                    nonEditable={true}
                  />
                </Box>
              </Box>

              <Box sx={styles.forgotAndOtpBox}>
                <OtpInputs
                  enteredOtp={otp}
                  otpWrong={false}
                  setEnteredOtp={setotp}
                  otpLength={5}
                  onPressEnter={verifyClickHandler}
                />
                {seconds === 0 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignSelf: 'start',
                    }}
                  >
                    <LinkButton onClick={sendOtp}>Resend otp</LinkButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignSelf: { xs: 'center', md: 'start' },
                      gap: { xs: pxToRem(4), md: pxTovW(4) },
                    }}
                  >
                    <Typography variant="cardText" sx={{ color: '#007CDC' }}>
                      Resend otp in {'  '}
                    </Typography>
                    <Typography
                      sx={{ color: '#0AA34F' }}
                      variant="bodyText"
                      fontWeight="bold"
                    >
                      <Timer seconds={seconds} setSeconds={setSeconds} />
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box sx={styles.secondaryButtonBox}>
                <PrimaryButton fullWidth onClick={verifyClickHandler}>
                  <Typography variant="h1" color="success.light">
                    Verify
                  </Typography>
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

interface TimerProps {
  seconds: number;
  setSeconds: Dispatch<SetStateAction<number>>;
}
const Timer = (props: TimerProps) => {
  const { seconds, setSeconds } = props;
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <Typography sx={{ color: '#0AA34F' }} variant="bodyText" fontWeight="bold">
      {seconds}
    </Typography>
  );
};
