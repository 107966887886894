import { Timestamp } from '@bufbuild/protobuf';

import { IInteractionButtonEventArgs } from './inputArgs';

import { getSessionDataWithIncrimentedIndex } from './utils';

import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { LOCAL_STORAGE } from '../../storage';

export function createInteractionButtonEvent(
  args: IInteractionButtonEventArgs
): StudentEvent {
  // const { error } = InteractionButtonActionArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }

  const {
    url,
    name,
    context,
    lessonSesssionId,
    homeworkSesssionId,
    resourceSesssionId,
    isOffline,
  } = args;
  const sessionData = getSessionDataWithIncrimentedIndex();
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: Timestamp.fromDate(new Date()),
    eventIndex: sessionData.eventIndex,
    activeSesssionId: LOCAL_STORAGE.getItem('activeSession') ?? undefined,
    lessonSesssionId: lessonSesssionId,
    homeworkSesssionId: homeworkSesssionId,
    resourceSesssionId: resourceSesssionId,
    isOffline: isOffline,
    EventType: {
      case: 'interactionButton',
      value: {
        url,
        context,
        name,
      },
    },
  });
}
