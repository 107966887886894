import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type Crop,
} from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';

import {
  deserify,
  getMediaBasePath,
  ImageWrapper,
  pxToRem,
  pxTovW,
  uploadFile,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BlockIcon from '@mui/icons-material/Block';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconProps,
  Typography,
} from '@mui/material';
import {
  MimeEnum,
  SignedUrlReponse,
} from '@protos/learning_management/lms.hw.student.apis_pb';
import { createRef, useEffect, useRef, useState } from 'react';
import { FileUploadReport } from './FileUploadReport';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { minHeight } from '@mui/system';

const styles = {
  modal: {
    '& .MuiDialog-paper': {
      background: 'rgba(0,0,0,0.6)',
    },
  },
  root: {
    display: { xs: 'flex', md: 'flex' },
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'center',
    // border: '2px solid white',
    mt: { xs: '5vh', md: '1vh' },
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    gap: { xs: pxToRem(20), md: 'unset' },
  },
  closeBox: {
    border: '5px solid transparent',
    height: { xs: pxToRem(30), md: pxToRem(50) },
    width: { xs: pxToRem(30), md: pxToRem(50) },
    cursor: 'pointer',
  },
  selectInputIcon: {
    height: { xs: pxToRem(50), md: pxToRem(50) },
    width: { xs: pxToRem(50), md: pxToRem(50) },
    cursor: 'pointer',
  },
  img: { maxWidth: '100vw', width: 'auto', maxHeight: '75vh' },
  inputButton: { position: 'absolute', bottom: 10, left: 50 },
  imgSelectCover: {
    cursor: 'pointer',
    width: { xs: pxToRem(75), md: pxToRem(100) },
    height: { xs: pxToRem(75), md: pxToRem(100) },
    background: 'rgba(0,0,0,0.5)',
  },
};
interface IProps {
  open: boolean;
  handleClose?: () => void;
  studentId?: bigint;
  homeworkId?: number;
  questionId?: string;
  sessionId?: number;
  handleAnswerChange?: (value: string, index?: number) => void;
}
export enum CropStep {
  OriginalImage,
  ImagePreview,
  CanvasPreview,
  Processing,
}
interface Imgs {
  src: string;
  crop?: Crop;
}
interface imgDetails {
  src: string;
  name: string;
  type: string;
}
export type IStatus =
  | 'success'
  | 'failure-upload'
  | 'failure-conversion'
  | 'not-started'
  | 'failure';
export default function ImageUploadPopup(props: IProps) {
  const {
    open,
    handleClose,
    studentId,
    homeworkId,
    questionId,
    sessionId,
    handleAnswerChange,
  } = props;

  const { all_answers } = deserify(useAppSelector((state) => state.homework));

  const [displayedImgSrc, setDisplayImgSrc] = useState('');
  const [originalImageSrc, setOriginalImageSrc] = useState<imgDetails[]>([]);
  const [selectedImgSrcs, setSelectedImgSrcs] = useState<Imgs[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentCrop, setCurrentCrop] = useState<Crop>();
  const [loading, setLoading] = useState<boolean>(false);
  // const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRefs = useRef<(HTMLCanvasElement | null)[]>([]);
  const [editedFlag, setEditedFlag] = useState();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imageDetails, setImageDetails] = useState<{
    name: string;
    type: string;
  }>({ name: '', type: '' });
  const [step, setStep] = useState<CropStep>(CropStep.OriginalImage);
  //not required
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [imageStatuses, setImageStatuses] = useState<IStatus[]>(
    Array(0).fill('not-started')
  );
  const { LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  useEffect(() => {
    setStep(CropStep.OriginalImage);
    setErrorMessage(null);
    setSelectedImgSrcs([]);
    setOriginalImageSrc([]);
    setDisplayImgSrc('');
    canvasRefs.current = [];
  }, [open]);
  useEffect(() => {
    // Initialize canvasRefs with references to canvas elements
    canvasRefs.current = selectedImgSrcs.map(
      () => createRef<HTMLCanvasElement>().current
    );
    const arr = Array(originalImageSrc.length).fill('not-started');
    setImageStatuses(arr);
    if (originalImageSrc.length > 0) {
      const lastImgSrc = originalImageSrc[originalImageSrc.length - 1].src;
      if (lastImgSrc) {
        setDisplayImgSrc(lastImgSrc);
        setSelectedIndex(originalImageSrc.length - 1);
      }
    }
  }, [originalImageSrc]);
  useEffect(() => {
    if (loading === false) {
      if (imageStatuses.every((status) => status === 'success')) {
        // Close the popup if all images were processed successfully
        closeHandler();
      }
    }
  }, [loading]);

  const ASPECT_RATIO = 1;
  const MIN_DIMENSION = 150;

  const addSelectedImage = async (images: FileList) => {
    const newImages: File[] = [];
    let errorMessage = '';
    const checkImageDimensions = (image: File): Promise<File | undefined> => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(image);
        img.onload = () => {
          // if (img.width < 150 || img.height < 150) {
          //   // If image dimensions are less than 150x150
          //   errorMessage += `Failed to select${image.name}. image size should ge greater than 150px.`;
          // } else {
          // Image meets criteria, resolve with the image
          resolve(image);
          // }
        };
      });
    };
    // Iterate over each image in the FileList
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (image.size > 10 * 1024 * 1024) {
        // If image size exceeds 10 MB
        errorMessage += `Failed to select ${image.name}. image size should ge less than 10mb. `;
      } else if (getImageType(image.type) === MimeEnum.MIME_UNDEFINED) {
        errorMessage += `Unsupported image type `;
      } else {
        // Check image dimensions asynchronously
        const validImage = await checkImageDimensions(image);
        if (validImage) {
          // If image dimensions are valid, add it to newImages
          newImages.push(validImage);
        }
      }
    }
    if (errorMessage !== '') {
      // Display error message to the user
      alert(errorMessage);
    }
    console.log('new Images', newImages, 'errorMessage', errorMessage);
    // Update the state with new images
    setSelectedImgSrcs((prevImages) => [
      ...prevImages,
      ...newImages.map((image) => ({
        src: typeof image === 'string' ? image : URL.createObjectURL(image),
        crop: undefined,
      })),
    ]);

    setOriginalImageSrc((prevOriginalImages) => [
      ...prevOriginalImages,
      ...newImages.map((image) => ({
        src: URL.createObjectURL(image),
        name: image.name,
        type: image.type,
      })),
    ]);
    // if (!newImages.length) {
    //   setDisplayImgSrc(URL.createObjectURL(newImages[0]));
    // }
  };

  // Function to update crop data for a specific image
  const updateCrop = (index: number, crop: Crop | undefined) => {
    setCurrentCrop(crop);
    setSelectedImgSrcs((prevImages) =>
      prevImages.map((image, i) =>
        i === index ? { ...image, crop: crop } : image
      )
    );
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    const files = e.target.files;
    if (!files) return;
    addSelectedImage(files);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
    const cropNew = makeAspectCrop(
      {
        unit: '%',
        width: 100,
        height: 100,
      },
      ASPECT_RATIO,
      width,
      height
    );
    if (selectedImgSrcs[selectedIndex].crop) {
      const crp = selectedImgSrcs[selectedIndex].crop;
      setCurrentCrop(crp);
    } else {
      const centeredCrop = centerCrop(cropNew, width, height);
      setCurrentCrop(centeredCrop);
      // updateCrop(selectedIndex, centeredCrop);
    }
    // setStep(CropStep.ImagePreview); // Move to ImagePreview step after loading the image
  };
  const cropHandler = () => {
    setErrorMessage(null);
    updateCrop(selectedIndex, currentCrop);
    // const canvas = canvasRef.current as HTMLCanvasElement;
    const canvas = canvasRefs.current[selectedIndex] as HTMLCanvasElement;
    // console.log('cropHandler canvas', selectedIndex, canvas);
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        if (ctx && currentCrop) {
          // Drawing logic
          const x = (img.width * currentCrop.x) / 100;
          const y = (img.height * currentCrop.y) / 100;
          const width = (img.width * currentCrop.width) / 100;
          const height = (img.height * currentCrop.height) / 100;
          // console.log({
          //   Imgwidth: img.width,
          //   naturalWidth: img.naturalWidth,
          //   Imgheight: img.height,
          //   naturalHeight: img.naturalHeight,
          //   width,
          //   height,
          // });
          // Ensure canvas dimensions do not exceed image dimensions
          canvas.width = Math.min(width, img.width);
          canvas.height = Math.min(height, img.height);
          ctx.drawImage(
            img,
            x,
            y,
            width,
            height,
            0,
            0,
            canvas.width,
            canvas.height
          );
          // Convert canvas content to a data URL

          const croppedImageUrl = canvas.toDataURL();
          // Set the cropped image as the new imgSrc
          setDisplayImgSrc(croppedImageUrl);
        }
      };
      img.src = displayedImgSrc;
    }
  };
  const createCroppedImageFile = (
    index: number,
    selectedImgSrc: string,
    originalImageDetails?: imgDetails
  ) => {
    return new Promise<File | null>((resolve) => {
      const canvas = canvasRefs.current[index] as HTMLCanvasElement;
      const imageSrc = selectedImgSrc; // For using the selected image directly
      if (canvas && selectedImgSrcs[index]?.crop) {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              // Create a File object from the Blob
              const croppedFile = new File(
                [blob],
                originalImageDetails?.name ||
                'image_' + Math.random().toFixed(2),
                {
                  type: blob.type,
                }
              );
              resolve(croppedFile);
            } else {
              console.error('Blob is null');
              resolve(null);
            }
          },
          originalImageDetails?.type ? originalImageDetails.type : 'image/png', // Specify the MIME type for the blob
          1
        );
      } else if (imageSrc && !selectedImgSrcs[index]?.crop) {
        fetch(imageSrc)
          .then((res) => res.blob())
          .then((blob) => {
            // Create a File object from the Blob
            const newFile = new File(
              [blob],
              originalImageDetails?.name || 'image_' + Math.random().toFixed(2),
              {
                type: blob.type,
              }
            );

            console.log(newFile, blob);
            resolve(newFile);
          })
          .catch((error) => {
            console.error('Error fetching image data:', error);
            resolve(null);
          });
      } else {
        console.error('Canvas is null');
        resolve(null);
      }
    });
  };
  const cancelHandler = () => {
    setStep(CropStep.OriginalImage); // Move back to OriginalImage step when cancel is clicked
    // setCrop(undefined); // Clear crop data
  };

  const doneHandler = () => {
    cropHandler();
    // const canvas = canvasRef.current;
    const canvas = canvasRefs.current[selectedIndex] as HTMLCanvasElement;

    if (canvas) {
      const url = canvas.toDataURL(); // Convert canvas content to a data URL
      setDisplayImgSrc(url);
      setSelectedImgSrcs((prevImages) =>
        prevImages.map((img, index) =>
          index === selectedIndex ? { ...img, src: url } : img
        )
      );
      setStep(CropStep.OriginalImage);
    }
  };
  const saveHandler = async () => {
    setErrorMessage(null);
    setLoading(true);
    setStep(CropStep.Processing);
    try {
      let answer = '';
      for (let i = 0; i < selectedImgSrcs.length; i++) {
        if (imageStatuses[i] === 'success') continue;
        let assumedError: IStatus = 'success';
        try {
          assumedError = 'failure-upload';
          const signedUrlResponse: SignedUrlReponse =
            await LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler.getSignedUrlForImageUpload(
              {
                studentId,
                homeworkId,
                sessionId,
                questionId,
                filesInfo: [
                  {
                    originalImagePath: originalImageSrc[i]?.name,
                    mimeType: getImageType(originalImageSrc[i]?.type),
                  },
                ],
              }
            );
          console.log(`${i}thCHAIN: 1 - res`, signedUrlResponse);
          if (!signedUrlResponse) {
            setImageStatuses((prevStatuses) => {
              const newStatuses = [...prevStatuses];
              newStatuses[i] = assumedError;
              return newStatuses;
            });
            continue;
          }
          const croppedImageFile = await createCroppedImageFile(
            i,
            selectedImgSrcs[i].src,
            originalImageSrc[i]
          );
          console.log(`${i}thCHAIN:: 2 - croppedImageFile`, croppedImageFile);
          if (!croppedImageFile) {
            setImageStatuses((prevStatuses) => {
              const newStatuses = [...prevStatuses];
              newStatuses[i] = 'failure';
              return newStatuses;
            });
            throw new Error(
              `Failed to create cropped image file for image ${i}`
            );
            continue;
          }
          const signedUrl = getMediaBasePath(
            signedUrlResponse.response[0].signedUrl
          );
          const uploadImageStatus = await uploadFile({
            file: croppedImageFile,
            signedUrl,
          });
          console.log(`${i}thCHAIN: 3 - uploadImageStatus`, uploadImageStatus);
          if (!uploadImageStatus.success) {
            setImageStatuses((prevStatuses) => {
              const newStatuses = [...prevStatuses];
              newStatuses[i] = assumedError;
              return newStatuses;
            });
            continue;
          }
          assumedError = 'failure-conversion';

          // if (i > 0) {
          //   setImageStatuses((prevStatuses) => {
          //     const newStatuses = [...prevStatuses];
          //     newStatuses[i] = assumedError;
          //     return newStatuses;
          //   });

          //   continue;
          // }

          const imageTextResponse =
            await LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler.getOCRTextFromImage(
              {
                studentId,
                homeworkId,
                sessionId,
                questionId,
                uploadFileResponse: [
                  {
                    signedUrl: signedUrlResponse.response[0].signedUrl,
                    isUploaded: true,
                    originalImagePath:
                      signedUrlResponse.response[0].originalImagePath,
                  },
                ],
              }
            );
          console.log(`${i}thCHAIN:: 4 - imageTextResponse`, imageTextResponse);
          if (!imageTextResponse) {
            setImageStatuses((prevStatuses) => {
              const newStatuses = [...prevStatuses];
              newStatuses[i] = assumedError;
              return newStatuses;
            });
            continue;
          }
          setImageStatuses((prevStatuses) => {
            const newStatuses = [...prevStatuses];
            newStatuses[i] = 'success';
            return newStatuses;
          });
          assumedError = 'success';
          answer += imageTextResponse.ocrTextBlocks[0];
        } catch (err) {
          console.error(`An error occurred while processing image ${i}:`, err);
          if (err instanceof Error) {
            // setErrorMessage(err.message);
            if (assumedError !== 'success') {
              setImageStatuses((prevStatuses) => {
                const newStatuses = [...prevStatuses];
                newStatuses[i] = assumedError;
                return newStatuses;
              });
            }
          }
        }
      }
      if (handleAnswerChange) {
        // handleAnswerChange(answer);
        handleAnswerChange(all_answers.join(' ') + ' ' + answer);
      }
      setErrorMessage(null);
      setLoading(false);
      // closeHandler();
    } catch (err) {
      console.error('An error occurred:', err);
      if (err instanceof Error) {
        setErrorMessage(err.message);
      }
    }
  };
  const handleClickImageAddWrapper = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleClickImageCover = (src: string, index: number) => {
    const crop = selectedImgSrcs[index].crop;
    if (crop) {
      // If a crop is available, set displayImgSrc to the cropped version
      const canvas = canvasRefs.current[index];
      if (canvas) {
        const croppedImageUrl = canvas.toDataURL();
        setDisplayImgSrc(croppedImageUrl);
      }
    } else {
      // If no crop is available, set displayImgSrc to the original image source
      setDisplayImgSrc(src);
    }
    setSelectedIndex(index);
  };

  const deleteIndividualImgHandler = (indexToDelete: number) => {
    const length = selectedImgSrcs.length;
    if (length > 1) {
      setSelectedImgSrcs((prevImages) => {
        const updatedImages = prevImages.filter(
          (_, index) => index !== indexToDelete
        );
        return updatedImages;
      });
      setOriginalImageSrc((prevOriginalImages) => {
        const updatedOriginalImages = prevOriginalImages.filter(
          (_, index) => index !== indexToDelete
        );
        return updatedOriginalImages;
      });
      canvasRefs.current.splice(indexToDelete, 1);
      setSelectedIndex(indexToDelete);
      setDisplayImgSrc(selectedImgSrcs[indexToDelete].src);
    } else {
      setSelectedImgSrcs([]);
      setOriginalImageSrc([]);
      setSelectedIndex(0);
      setDisplayImgSrc('');
      canvasRefs.current = [];
    }
  };
  const closeHandler = () => {
    if (handleClose) {
      setErrorMessage(null);
      setSelectedImgSrcs([]);
      setOriginalImageSrc([]);
      setDisplayImgSrc('');
      canvasRefs.current = [];
      handleClose();
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={closeHandler} sx={styles.modal}>
      <Box sx={styles.root}>
        <ImageWrapper
          name="close-yellow"
          type="png"
          parentFolder="icons"
          onClick={closeHandler}
          styles={styles.closeBox}
        />

        {errorMessage && (
          <Typography variant="elementBodyText" color="error.main">
            {errorMessage}
          </Typography>
        )}

        {!displayedImgSrc && step !== CropStep.Processing && (
          <ImageWrapper
            name="InputImage"
            type="png"
            parentFolder="icons"
            onClick={handleClickImageAddWrapper}
            styles={{
              ...styles.selectInputIcon,
              position: 'absolute',
              bottom: '50%',
            }}
          />
        )}

        {step === CropStep.OriginalImage && (
          <>
            {displayedImgSrc && (
              <img
                style={styles.img}
                src={displayedImgSrc}
                alt="cropped_image"
              />
            )}
            <Box
              sx={{
                position: 'absolute',
                bottom: 20,
                display: 'flex',
                flexDirection: 'column',
                gap: pxToRem(15),
                width: '100vw',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: pxToRem(10),
                  alignItems: 'center',
                  px: pxToRem(20),
                  overflowX: 'auto',
                  height: { xs: pxToRem(95), md: pxToRem(120) },
                }}
              >
                {originalImageSrc.map((e, i) => (
                  <Badge
                    overlap="circular"
                    badgeContent={
                      <DelButton
                        display={i === selectedIndex}
                        deleteHandler={() => deleteIndividualImgHandler(i)}
                      />
                    }
                    sx={{ position: 'relative' }}
                  >
                    <Box
                      component="img"
                      onClick={() => handleClickImageCover(e.src, i)}
                      src={e.src}
                      sx={styles.imgSelectCover}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: ' 50%',
                        left: ' 50%',
                        transform: ' translate(-50%, -50%)',
                      }}
                    >
                      <StatusIcon
                        status={imageStatuses[i]}
                        sx={{ fontSize: pxToRem(40) }}
                      />
                    </Box>
                  </Badge>
                ))}
                {selectedImgSrcs.length && (
                  <Button
                    onClick={handleClickImageAddWrapper}
                    sx={styles.imgSelectCover}
                  >
                    <AddCircleIcon
                      color="warning"
                      sx={styles.selectInputIcon}
                    />
                  </Button>
                )}
              </Box>

              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                style={{ display: 'none' }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ visibility: displayedImgSrc ? 'visible' : 'hidden' }}
                  // style={{ position: 'absolute', bottom: 50, left: 500 }}
                  onClick={saveHandler}
                  disabled={loading === true}
                >
                  Convert to text
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  sx={{ visibility: displayedImgSrc ? 'visible' : 'hidden' }}
                  onClick={() => {
                    if (originalImageSrc[selectedIndex]) {
                      setDisplayImgSrc(originalImageSrc[selectedIndex].src);
                    }
                    setStep(CropStep.ImagePreview);
                  }}
                >
                  Crop
                </Button>
              </Box>
            </Box>
          </>
        )}

        {step === CropStep.ImagePreview && (
          <>
            <ReactCrop
              crop={currentCrop}
              onChange={(pxCrop, psCrop) => updateCrop(selectedIndex, psCrop)}
            >
              <img
                style={styles.img}
                src={displayedImgSrc}
                onLoad={onImageLoad}
                alt="ss"
              />
            </ReactCrop>
            <Box
              sx={{
                position: 'absolute',
                bottom: 50,
                display: 'flex',
                gap: pxToRem(10),
                width: '100vw',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={cancelHandler}
              >
                Cancel
              </Button>

              <Button variant="contained" color="warning" onClick={doneHandler}>
                Done
              </Button>
            </Box>
          </>
        )}

        {step === CropStep.Processing && (
          <Box sx={styles.img}>
            <FileUploadReport
              imageStatuses={imageStatuses}
              saveHandler={saveHandler}
              setStep={setStep}
              originalImageSrc={originalImageSrc}
              loading={loading}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 50,
          left: 50,
          display: 'flex',
          gap: '10px',
        }}
      ></Box>
      {selectedImgSrcs.map((_, index) => (
        <canvas
          key={index}
          ref={(canvas) => (canvasRefs.current[index] = canvas)}
          style={{
            display: 'none',
            border: '1px solid black',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />
      ))}
    </Dialog>
  );
}

interface IDelBtnProps {
  deleteHandler: () => void;
  display: boolean;
}
const DelButton = (props: IDelBtnProps) => {
  const { deleteHandler, display } = props;
  return (
    <Button
      sx={{
        display: display ? 'block' : 'none',
        background: 'white',
        border: '1px solid grey',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
      onClick={deleteHandler}
    >
      <DeleteIcon fontSize="medium" color="error" />
    </Button>
  );
};
interface IstatusProps extends IconProps {
  status: IStatus;
}
export const StatusIcon = (props: IstatusProps) => {
  const { status } = props;
  switch (status) {
    case 'failure-upload':
      return <BlockIcon color="error" sx={{ ...props.sx }} />;
    case 'success':
      return <CheckBoxIcon color="success" sx={{ ...props.sx }} />;
    case 'failure-conversion':
      return <ErrorIcon color="warning" sx={{ ...props.sx }} />;
    case 'failure':
      return <BrokenImageIcon color="error" sx={{ ...props.sx }} />;
    default:
      return <></>;
  }
};

const downloadFile = (file: File, filename: string) => {
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = `filename_${filename}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

function getImageType(fileType: string) {
  switch (fileType.toLowerCase()) {
    case 'image/png':
      return MimeEnum.MIME_PNG;
    case 'image/jpeg':
      return MimeEnum.MIME_JPEG;
    case 'image/jpg':
      return MimeEnum.MIME_JPG;
    // case 'image/svg':
    //   return MimeEnum.MIME_SVG;
    default:
      return MimeEnum.MIME_UNDEFINED;
  }
}
