import {
  ContentDetailCard,
  DashboardGrid,
  IStyles,
  Loader,
  deserify,
  getMediaBasePath,
  getResourceCategory,
  pxToRem,
  pxTovW,
  resourceTypeName,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ResourceInfo } from '@protos/content_management/content.common.apis_pb';
import {
  ResourceCategoryEnum,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { ResourceSelectionFromTypeEnum } from '@protos/geneo_ai/ai.student.events_pb';
import { SessionModeEnum } from '@protos/learning_management/lms.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { HOMEWORK_COMPLETED } from '../../../routeHandling/RoutesNomenclature';
import { onResourceClick } from '../../../utils/learn';
import { interactionEvent } from '../../Auth/Login/login_logout.events';
import { setResourceRedirectionPath } from '../../Learn/reducer/learn.slice';
import { aiResourceOpenEvent } from '../../Learn/utils_learn/learn.events';
import { RecommendationReqSendFromTypeEnum } from '@protos/geneo_ai/ai.student.recommendation.apis_pb';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';

const styles: IStyles = {
  root: {
    width: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    paddingLeft: { xs: pxToRem(20), lg: pxTovW(241) },
    paddingRight: { xs: pxToRem(20), lg: pxTovW(241) },
    paddingTop: { xs: pxToRem(20), lg: pxTovW(40) },
    paddingBottom: { xs: pxToRem(20), lg: pxTovW(40) },
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: { xs: pxToRem(10), md: pxTovW(40) },
  },
  card: {
    // paddingLeft: { xs: pxToRem(20), md: pxTovW(120) },
    paddingTop: { xs: pxToRem(10), md: pxTovW(40) },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
    // paddingRight: { xs: pxToRem(20), md: pxTovW(40) },

    borderRadius: { md: pxTovW(15) },
    backgroundColor: '#FFFFFFFF',
    boxSizing: 'border-box',
    boxShadow: { md: `0px 0px ${pxToRem(11)} #E7E7E7D9` },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: { xs: 'auto', md: 'auto auto auto ' },
    '&  > div > div': {
      borderRight: { md: '1px solid #E7F4E7' },
    },
    '& > div': {
      paddingBottom: '10px',
      paddingTop: '10px',
      borderBottom: { md: '1px solid #E7F4E7' },
    },
    '&  > :nth-last-child(-n+3) ': {
      borderBottom: 'none',
    },
    '&  > :nth-child(3n) > div': {
      borderRight: 'none',
    },
  },
};
export const ViewRecommendations = () => {
  const { subject_id, topic_id, chapter_id } = useParams();
  const { isOffline } = useDownloadContext();
  const [loading, setLoading] = useState(false);
  const { isIpadOnly } = useGlobalContext();
  const [error, setError] = useState<boolean>(false);
  const [resources, setResources] = useState<ResourceInfo[]>([]);
  const [recommendationId, setRecommendationId] = useState<string | undefined>(
    undefined
  );
  const topicId = Number(topic_id);
  const chapterId = Number(chapter_id);
  const subjectId = Number(subject_id);
  const navigate = useNavigate();
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const homeworkId = deserify(
    useAppSelector((state) => state.homework.selected_homework_data?.homeworkId)
  );
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const { setSelectedFunction } = useGlobalContext();
  const { AiStudentAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const backButtonClick = async () => {
    navigate(`${HOMEWORK_COMPLETED}?homeworkId=${homeworkId}`);
  };

  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  useEffect(() => {
    fetchRecommendations();
  }, [subjectId, topicId, chapterId]);

  async function fetchRecommendations() {
    try {
      if (!studentId) {
        return;
      }
      setLoading(true);
      // const response =
      //   await LmsStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentResourceRecommendation({
      //     studentId: studentId,
      //     subjectId,
      //     chapterId,
      //     topicId,
      //   });
      const response =
        await AiStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentResourceRecommendationfromGeneoAi(
          {
            studentId: studentId,
            subjectId,
            topicId,
            chapterId,
            sendFrom:
              RecommendationReqSendFromTypeEnum.HOMEWORK_ENDED_RECOMMENDATION,
          }
        );
      if (response) {
        setLoading(false);
        if (response.data) {
          setResources(response.data?.resources || []);
          setRecommendationId(response.data.recommendationId);
          return;
        }
      }
      setResources([]);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
      setResources([]);
      // setLoading('error');
    }
  }

  const items = resources.map((val, index) => (
    <Box
      key={'recommendation_res_' + index}
      sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={async () => {
        await interactionEvent({
          url: 'student-homework-view-recommendation',
          context: 'view_recomendations',
          name: 'RECOMENDATIONS_OPEN',
          isOffline: isOffline,
        });
        dispatch(setResourceRedirectionPath(location.pathname));
        await aiResourceOpenEvent({
          resourceId: val.resourceId,
          selectedPath:
            ResourceSelectionFromTypeEnum.HOMEWORK_ENDED_RECOMMENDATION,
          rank: index + 1,
          recommendationId: recommendationId,
          isOffline: isOffline,
        });
        onResourceClick(
          navigate,
          {
            resourceId: val.resourceId,
            subjectId,
            chapterId,
            topicId,
          },
          SessionModeEnum.SESSION_MODE_RESOURCE
        );
      }}
    >
      <ContentDetailCard
        tagName={getResourceCategory(val.resourceCategoryType)}
        variant={isIpadOnly ? 'small' : 'medium'}
        image={getMediaBasePath(
          val.posterImageUrl,
          val.resourceCategoryType ===
            ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
            ? 'userDataBucket'
            : 'processedMediaBucket'
        )}
        heading={val.title}
        showVideoIcon={
          val.resourceType === Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
        }
        iconDetails={[
          { iconName: 'clock', text: `${val.estimatedTimeInMin} Min` },
          {
            iconName: resourceTypeName(val.resourceType).icon,
            text: resourceTypeName(val.resourceType)?.name,
          },
        ]}
        rootStyle={{
          height: {
            xs: pxToRem(127),
            md: pxToRem(80),
            lg: pxTovW(121),
          },
          width: {
            xs: '100%',
          },
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        }}
      />
      {!isMobile && (
        <ChevronRightIcon
          fontSize="medium"
          fontWeight="normal"
          sx={{ color: '#828282' }}
        />
      )}
    </Box>
  ));

  return loading === true ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Typography variant="h1">View Recommendations</Typography>
      </Box>
      {/* <Box sx={styles.card}> */}
      <DashboardGrid
        rootStyle={{
          boxShadow: 'none',
          backgroundColor: {
            md: 'common.white',
          },
        }}
        hiddenBorderBottom={isMobile}
        items={items}
        WebNoOfCols={3}
        mobileNoOfCols={1}
      />
      {/* </Box> */}
    </Box>
  );
};
