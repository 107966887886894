// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/content_management/content.db.proto (package geneo.content.db, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Board_BoardEnum, Class_ClassEnum, Subject_SubjectEnum } from "@protos/common/common.db_pb";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";

/**
 * Enum for LevelEnum
 *
 * @generated from enum geneo.content.db.DifficultyLevelEnum
 */
export enum DifficultyLevelEnum {
  /**
   * @generated from enum value: DIFFICULTY_LEVEL_UNDEFINED = 0;
   */
  DIFFICULTY_LEVEL_UNDEFINED = 0,

  /**
   * Represents high level type.
   *
   * @generated from enum value: DIFFICULTY_LEVEL_HIGH = 1;
   */
  DIFFICULTY_LEVEL_HIGH = 1,

  /**
   * Represents medium level type.
   *
   * @generated from enum value: DIFFICULTY_LEVEL_MEDIUM = 2;
   */
  DIFFICULTY_LEVEL_MEDIUM = 2,

  /**
   * Represents low level type.
   *
   * @generated from enum value: DIFFICULTY_LEVEL_LOW = 3;
   */
  DIFFICULTY_LEVEL_LOW = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DifficultyLevelEnum)
proto3.util.setEnumType(DifficultyLevelEnum, "geneo.content.db.DifficultyLevelEnum", [
  { no: 0, name: "DIFFICULTY_LEVEL_UNDEFINED" },
  { no: 1, name: "DIFFICULTY_LEVEL_HIGH" },
  { no: 2, name: "DIFFICULTY_LEVEL_MEDIUM" },
  { no: 3, name: "DIFFICULTY_LEVEL_LOW" },
]);

/**
 * Enum for CourseApprovalStatusEnum
 *
 * @generated from enum geneo.content.db.CourseApprovalStatusEnum
 */
export enum CourseApprovalStatusEnum {
  /**
   * Course approval status: Undefined.
   *
   * @generated from enum value: COURSE_APPROVAL_STATUS_UNDEFINED = 0;
   */
  COURSE_APPROVAL_STATUS_UNDEFINED = 0,

  /**
   * Course approval status: Not submitted.
   *
   * @generated from enum value: COURSE_APPROVAL_STATUS_NOT_SUBMITTED = 1;
   */
  COURSE_APPROVAL_STATUS_NOT_SUBMITTED = 1,

  /**
   * Course approval status: Submitted.
   *
   * @generated from enum value: COURSE_APPROVAL_STATUS_SUBMITTED = 2;
   */
  COURSE_APPROVAL_STATUS_SUBMITTED = 2,

  /**
   * Course approval status: In review.
   *
   * @generated from enum value: COURSE_APPROVAL_STATUS_IN_REVIEW = 3;
   */
  COURSE_APPROVAL_STATUS_IN_REVIEW = 3,

  /**
   * Course approval status: Reverted.
   *
   * @generated from enum value: COURSE_APPROVAL_STATUS_REVERTED = 4;
   */
  COURSE_APPROVAL_STATUS_REVERTED = 4,

  /**
   * Course approval status: Approved.
   *
   * @generated from enum value: COURSE_APPROVAL_STATUS_APPROVED = 5;
   */
  COURSE_APPROVAL_STATUS_APPROVED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(CourseApprovalStatusEnum)
proto3.util.setEnumType(CourseApprovalStatusEnum, "geneo.content.db.CourseApprovalStatusEnum", [
  { no: 0, name: "COURSE_APPROVAL_STATUS_UNDEFINED" },
  { no: 1, name: "COURSE_APPROVAL_STATUS_NOT_SUBMITTED" },
  { no: 2, name: "COURSE_APPROVAL_STATUS_SUBMITTED" },
  { no: 3, name: "COURSE_APPROVAL_STATUS_IN_REVIEW" },
  { no: 4, name: "COURSE_APPROVAL_STATUS_REVERTED" },
  { no: 5, name: "COURSE_APPROVAL_STATUS_APPROVED" },
]);

/**
 * Enum for ContentElementTypesEnum
 *
 * @generated from enum geneo.content.db.ContentElementTypesEnum
 */
export enum ContentElementTypesEnum {
  /**
   * Represents undefined content type.
   *
   * @generated from enum value: CONTENT_ELEMENT_TYPE_UNDEFINED = 0;
   */
  CONTENT_ELEMENT_TYPE_UNDEFINED = 0,

  /**
   * Represents text content type.
   *
   * @generated from enum value: CONTENT_ELEMENT_TYPE_TEXT = 1;
   */
  CONTENT_ELEMENT_TYPE_TEXT = 1,

  /**
   * Represents image content type.
   *
   * @generated from enum value: CONTENT_ELEMENT_TYPE_IMAGE = 2;
   */
  CONTENT_ELEMENT_TYPE_IMAGE = 2,

  /**
   * Represents audio content type.
   *
   * @generated from enum value: CONTENT_ELEMENT_TYPE_AUDIO = 3;
   */
  CONTENT_ELEMENT_TYPE_AUDIO = 3,

  /**
   * Represents video content type.
   *
   * @generated from enum value: CONTENT_ELEMENT_TYPE_VIDEO = 4;
   */
  CONTENT_ELEMENT_TYPE_VIDEO = 4,

  /**
   * Represents PDF content type.
   *
   * @generated from enum value: CONTENT_ELEMENT_TYPE_PDF = 5;
   */
  CONTENT_ELEMENT_TYPE_PDF = 5,

  /**
   * Represents ZIP content type.
   *
   * @generated from enum value: CONTENT_ELEMENT_TYPE_ZIP = 6;
   */
  CONTENT_ELEMENT_TYPE_ZIP = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentElementTypesEnum)
proto3.util.setEnumType(ContentElementTypesEnum, "geneo.content.db.ContentElementTypesEnum", [
  { no: 0, name: "CONTENT_ELEMENT_TYPE_UNDEFINED" },
  { no: 1, name: "CONTENT_ELEMENT_TYPE_TEXT" },
  { no: 2, name: "CONTENT_ELEMENT_TYPE_IMAGE" },
  { no: 3, name: "CONTENT_ELEMENT_TYPE_AUDIO" },
  { no: 4, name: "CONTENT_ELEMENT_TYPE_VIDEO" },
  { no: 5, name: "CONTENT_ELEMENT_TYPE_PDF" },
  { no: 6, name: "CONTENT_ELEMENT_TYPE_ZIP" },
]);

/**
 * Enum for TaskCreationStatusEnum
 *
 * @generated from enum geneo.content.db.CreationStatusEnum
 */
export enum CreationStatusEnum {
  /**
   * Task creation status: Undefined.
   *
   * @generated from enum value: CREATION_STATUS_UNDEFINED = 0;
   */
  CREATION_STATUS_UNDEFINED = 0,

  /**
   * Task creation status: Initialized.
   *
   * @generated from enum value: CREATION_STATUS_INITIALIZED = 1;
   */
  CREATION_STATUS_INITIALIZED = 1,

  /**
   * Task creation status: Draft.
   *
   * @generated from enum value: CREATION_STATUS_DRAFT = 2;
   */
  CREATION_STATUS_DRAFT = 2,

  /**
   * Task creation status: Approved.
   *
   * @generated from enum value: CREATION_STATUS_APPROVED = 3;
   */
  CREATION_STATUS_APPROVED = 3,

  /**
   * Task creation status: Duplicated.
   *
   * @generated from enum value: CREATION_STATUS_DUPLICATED = 4;
   */
  CREATION_STATUS_DUPLICATED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(CreationStatusEnum)
proto3.util.setEnumType(CreationStatusEnum, "geneo.content.db.CreationStatusEnum", [
  { no: 0, name: "CREATION_STATUS_UNDEFINED" },
  { no: 1, name: "CREATION_STATUS_INITIALIZED" },
  { no: 2, name: "CREATION_STATUS_DRAFT" },
  { no: 3, name: "CREATION_STATUS_APPROVED" },
  { no: 4, name: "CREATION_STATUS_DUPLICATED" },
]);

/**
 * @generated from enum geneo.content.db.LLMQContextStatus
 */
export enum LLMQContextStatus {
  /**
   * @generated from enum value: LLM_Q_CONTEXT_STATUS_UNDEFINED = 0;
   */
  LLM_Q_CONTEXT_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: LLM_Q_CONTEXT_STATUS_PENDING = 1;
   */
  LLM_Q_CONTEXT_STATUS_PENDING = 1,

  /**
   * @generated from enum value: LLM_Q_CONTEXT_STATUS_IN_PROGRESS = 2;
   */
  LLM_Q_CONTEXT_STATUS_IN_PROGRESS = 2,

  /**
   * @generated from enum value: LLM_Q_CONTEXT_STATUS_COMPLETED = 3;
   */
  LLM_Q_CONTEXT_STATUS_COMPLETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LLMQContextStatus)
proto3.util.setEnumType(LLMQContextStatus, "geneo.content.db.LLMQContextStatus", [
  { no: 0, name: "LLM_Q_CONTEXT_STATUS_UNDEFINED" },
  { no: 1, name: "LLM_Q_CONTEXT_STATUS_PENDING" },
  { no: 2, name: "LLM_Q_CONTEXT_STATUS_IN_PROGRESS" },
  { no: 3, name: "LLM_Q_CONTEXT_STATUS_COMPLETED" },
]);

/**
 * @generated from enum geneo.content.db.FileEnum
 */
export enum FileEnum {
  /**
   * @generated from enum value: FILE_TYPE_UNKNOWN = 0;
   */
  FILE_TYPE_UNKNOWN = 0,

  /**
   * @generated from enum value: FILE_TYPE_TEXT = 1;
   */
  FILE_TYPE_TEXT = 1,

  /**
   * @generated from enum value: FILE_TYPE_IMAGE = 2;
   */
  FILE_TYPE_IMAGE = 2,

  /**
   * @generated from enum value: FILE_TYPE_AUDIO = 3;
   */
  FILE_TYPE_AUDIO = 3,

  /**
   * @generated from enum value: FILE_TYPE_VIDEO = 4;
   */
  FILE_TYPE_VIDEO = 4,

  /**
   * @generated from enum value: FILE_TYPE_DOCUMENT = 5;
   */
  FILE_TYPE_DOCUMENT = 5,

  /**
   * @generated from enum value: FILE_TYPE_SPREADSHEET = 6;
   */
  FILE_TYPE_SPREADSHEET = 6,

  /**
   * @generated from enum value: FILE_TYPE_PRESENTATION = 7;
   */
  FILE_TYPE_PRESENTATION = 7,

  /**
   * @generated from enum value: FILE_TYPE_ARCHIVE = 8;
   */
  FILE_TYPE_ARCHIVE = 8,

  /**
   * @generated from enum value: FILE_TYPE_PDF = 9;
   */
  FILE_TYPE_PDF = 9,

  /**
   * @generated from enum value: FILE_TYPE_URL = 10;
   */
  FILE_TYPE_URL = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(FileEnum)
proto3.util.setEnumType(FileEnum, "geneo.content.db.FileEnum", [
  { no: 0, name: "FILE_TYPE_UNKNOWN" },
  { no: 1, name: "FILE_TYPE_TEXT" },
  { no: 2, name: "FILE_TYPE_IMAGE" },
  { no: 3, name: "FILE_TYPE_AUDIO" },
  { no: 4, name: "FILE_TYPE_VIDEO" },
  { no: 5, name: "FILE_TYPE_DOCUMENT" },
  { no: 6, name: "FILE_TYPE_SPREADSHEET" },
  { no: 7, name: "FILE_TYPE_PRESENTATION" },
  { no: 8, name: "FILE_TYPE_ARCHIVE" },
  { no: 9, name: "FILE_TYPE_PDF" },
  { no: 10, name: "FILE_TYPE_URL" },
]);

/**
 * @generated from enum geneo.content.db.FileExtensionEnum
 */
export enum FileExtensionEnum {
  /**
   * @generated from enum value: FILE_EXTENSION_UNKNOWN = 0;
   */
  FILE_EXTENSION_UNKNOWN = 0,

  /**
   * @generated from enum value: FILE_EXTENSION_TXT = 1;
   */
  FILE_EXTENSION_TXT = 1,

  /**
   * @generated from enum value: FILE_EXTENSION_JPG = 2;
   */
  FILE_EXTENSION_JPG = 2,

  /**
   * @generated from enum value: FILE_EXTENSION_JPEG = 3;
   */
  FILE_EXTENSION_JPEG = 3,

  /**
   * @generated from enum value: FILE_EXTENSION_GIF = 4;
   */
  FILE_EXTENSION_GIF = 4,

  /**
   * @generated from enum value: FILE_EXTENSION_PNG = 5;
   */
  FILE_EXTENSION_PNG = 5,

  /**
   * @generated from enum value: FILE_EXTENSION_MP3 = 6;
   */
  FILE_EXTENSION_MP3 = 6,

  /**
   * @generated from enum value: FILE_EXTENSION_MP4 = 7;
   */
  FILE_EXTENSION_MP4 = 7,

  /**
   * @generated from enum value: FILE_EXTENSION_DOCX = 8;
   */
  FILE_EXTENSION_DOCX = 8,

  /**
   * @generated from enum value: FILE_EXTENSION_DOC = 9;
   */
  FILE_EXTENSION_DOC = 9,

  /**
   * @generated from enum value: FILE_EXTENSION_XLSX = 10;
   */
  FILE_EXTENSION_XLSX = 10,

  /**
   * @generated from enum value: FILE_EXTENSION_PPTX = 11;
   */
  FILE_EXTENSION_PPTX = 11,

  /**
   * @generated from enum value: FILE_EXTENSION_PPT = 12;
   */
  FILE_EXTENSION_PPT = 12,

  /**
   * @generated from enum value: FILE_EXTENSION_ZIP = 13;
   */
  FILE_EXTENSION_ZIP = 13,

  /**
   * @generated from enum value: FILE_EXTENSION_HTTPS = 14;
   */
  FILE_EXTENSION_HTTPS = 14,

  /**
   * @generated from enum value: FILE_EXTENSION_PDF = 15;
   */
  FILE_EXTENSION_PDF = 15,
}
// Retrieve enum metadata with: proto3.getEnumType(FileExtensionEnum)
proto3.util.setEnumType(FileExtensionEnum, "geneo.content.db.FileExtensionEnum", [
  { no: 0, name: "FILE_EXTENSION_UNKNOWN" },
  { no: 1, name: "FILE_EXTENSION_TXT" },
  { no: 2, name: "FILE_EXTENSION_JPG" },
  { no: 3, name: "FILE_EXTENSION_JPEG" },
  { no: 4, name: "FILE_EXTENSION_GIF" },
  { no: 5, name: "FILE_EXTENSION_PNG" },
  { no: 6, name: "FILE_EXTENSION_MP3" },
  { no: 7, name: "FILE_EXTENSION_MP4" },
  { no: 8, name: "FILE_EXTENSION_DOCX" },
  { no: 9, name: "FILE_EXTENSION_DOC" },
  { no: 10, name: "FILE_EXTENSION_XLSX" },
  { no: 11, name: "FILE_EXTENSION_PPTX" },
  { no: 12, name: "FILE_EXTENSION_PPT" },
  { no: 13, name: "FILE_EXTENSION_ZIP" },
  { no: 14, name: "FILE_EXTENSION_HTTPS" },
  { no: 15, name: "FILE_EXTENSION_PDF" },
]);

/**
 * @generated from enum geneo.content.db.ContentGeneratorEnum
 */
export enum ContentGeneratorEnum {
  /**
   * @generated from enum value: CONTENT_GENERATOR_TYPE_UNDEFINED = 0;
   */
  CONTENT_GENERATOR_TYPE_UNDEFINED = 0,

  /**
   * @generated from enum value: CONTENT_GENERATOR_TYPE_CONTENT_UTILITY = 1;
   */
  CONTENT_GENERATOR_TYPE_CONTENT_UTILITY = 1,

  /**
   * @generated from enum value: CONTENT_GENERATOR_TYPE_LLM = 2;
   */
  CONTENT_GENERATOR_TYPE_LLM = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentGeneratorEnum)
proto3.util.setEnumType(ContentGeneratorEnum, "geneo.content.db.ContentGeneratorEnum", [
  { no: 0, name: "CONTENT_GENERATOR_TYPE_UNDEFINED" },
  { no: 1, name: "CONTENT_GENERATOR_TYPE_CONTENT_UTILITY" },
  { no: 2, name: "CONTENT_GENERATOR_TYPE_LLM" },
]);

/**
 * Enum for ResourceCategoryEnum
 *
 * @generated from enum geneo.content.db.ResourceCategoryEnum
 */
export enum ResourceCategoryEnum {
  /**
   * Represents undefined category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_UNDEFINED = 0;
   */
  RESOURCE_CATEGORY_UNDEFINED = 0,

  /**
   * Represents hook resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_HOOK = 1;
   */
  RESOURCE_CATEGORY_HOOK = 1,

  /**
   * Represents explanation resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_EXPLANATION = 2;
   */
  RESOURCE_CATEGORY_EXPLANATION = 2,

  /**
   * Represents solved examples resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_SOLVED_EXAMPLES = 3;
   */
  RESOURCE_CATEGORY_SOLVED_EXAMPLES = 3,

  /**
   * Represents examples resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_EXAMPLES = 4;
   */
  RESOURCE_CATEGORY_EXAMPLES = 4,

  /**
   * Represents practice resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_PRACTICE = 5;
   */
  RESOURCE_CATEGORY_PRACTICE = 5,

  /**
   * Represents revision resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_REVISION = 6;
   */
  RESOURCE_CATEGORY_REVISION = 6,

  /**
   * Represents activity resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_ACTIVITY = 7;
   */
  RESOURCE_CATEGORY_ACTIVITY = 7,

  /**
   * Represents real-life connect resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_REAL_LIFE_CONNECT = 8;
   */
  RESOURCE_CATEGORY_REAL_LIFE_CONNECT = 8,

  /**
   * Represents competency-based questions resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_COMPETENCY_BASED_QUESTIONS = 9;
   */
  RESOURCE_CATEGORY_COMPETENCY_BASED_QUESTIONS = 9,

  /**
   * Represents introduction resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_INTRODUCTION = 10;
   */
  RESOURCE_CATEGORY_INTRODUCTION = 10,

  /**
   * Represents competitive exam resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_COMPETITIVE_EXAM = 11;
   */
  RESOURCE_CATEGORY_COMPETITIVE_EXAM = 11,

  /**
   * Represents reading resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_READING = 12;
   */
  RESOURCE_CATEGORY_READING = 12,

  /**
   * Represents recitation resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_RECITATION = 13;
   */
  RESOURCE_CATEGORY_RECITATION = 13,

  /**
   * Represents end of chapter resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_END_OF_CHAPTER = 14;
   */
  RESOURCE_CATEGORY_END_OF_CHAPTER = 14,

  /**
   * Represents grammar resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_GRAMMAR = 15;
   */
  RESOURCE_CATEGORY_GRAMMAR = 15,

  /**
   * Represents end of poem resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_END_OF_POEM = 16;
   */
  RESOURCE_CATEGORY_END_OF_POEM = 16,

  /**
   * Represents test paper resource category.
   *
   * @generated from enum value: RESOURCE_CATEGORY_TEST_PAPER = 17;
   */
  RESOURCE_CATEGORY_TEST_PAPER = 17,

  /**
   * @generated from enum value: RESOURCE_CATEGORY_WORKSHEET = 18;
   */
  RESOURCE_CATEGORY_WORKSHEET = 18,

  /**
   * @generated from enum value: RESOURCE_CATEGORY_BOOK = 19;
   */
  RESOURCE_CATEGORY_BOOK = 19,

  /**
   * @generated from enum value: RESOURCE_CATEGORY_MY_RESOURCES = 20;
   */
  RESOURCE_CATEGORY_MY_RESOURCES = 20,
}
// Retrieve enum metadata with: proto3.getEnumType(ResourceCategoryEnum)
proto3.util.setEnumType(ResourceCategoryEnum, "geneo.content.db.ResourceCategoryEnum", [
  { no: 0, name: "RESOURCE_CATEGORY_UNDEFINED" },
  { no: 1, name: "RESOURCE_CATEGORY_HOOK" },
  { no: 2, name: "RESOURCE_CATEGORY_EXPLANATION" },
  { no: 3, name: "RESOURCE_CATEGORY_SOLVED_EXAMPLES" },
  { no: 4, name: "RESOURCE_CATEGORY_EXAMPLES" },
  { no: 5, name: "RESOURCE_CATEGORY_PRACTICE" },
  { no: 6, name: "RESOURCE_CATEGORY_REVISION" },
  { no: 7, name: "RESOURCE_CATEGORY_ACTIVITY" },
  { no: 8, name: "RESOURCE_CATEGORY_REAL_LIFE_CONNECT" },
  { no: 9, name: "RESOURCE_CATEGORY_COMPETENCY_BASED_QUESTIONS" },
  { no: 10, name: "RESOURCE_CATEGORY_INTRODUCTION" },
  { no: 11, name: "RESOURCE_CATEGORY_COMPETITIVE_EXAM" },
  { no: 12, name: "RESOURCE_CATEGORY_READING" },
  { no: 13, name: "RESOURCE_CATEGORY_RECITATION" },
  { no: 14, name: "RESOURCE_CATEGORY_END_OF_CHAPTER" },
  { no: 15, name: "RESOURCE_CATEGORY_GRAMMAR" },
  { no: 16, name: "RESOURCE_CATEGORY_END_OF_POEM" },
  { no: 17, name: "RESOURCE_CATEGORY_TEST_PAPER" },
  { no: 18, name: "RESOURCE_CATEGORY_WORKSHEET" },
  { no: 19, name: "RESOURCE_CATEGORY_BOOK" },
  { no: 20, name: "RESOURCE_CATEGORY_MY_RESOURCES" },
]);

/**
 * @generated from enum geneo.content.db.ContentRejectionEnum
 */
export enum ContentRejectionEnum {
  /**
   * @generated from enum value: CONTENT_REJECTION_REASON_UNDEFINED = 0;
   */
  CONTENT_REJECTION_REASON_UNDEFINED = 0,

  /**
   * @generated from enum value: CONTENT_REJECTION_REASON_INCORRECT_DATA = 1;
   */
  CONTENT_REJECTION_REASON_INCORRECT_DATA = 1,

  /**
   * @generated from enum value: CONTENT_REJECTION_REASON_MISSING_INFO = 2;
   */
  CONTENT_REJECTION_REASON_MISSING_INFO = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentRejectionEnum)
proto3.util.setEnumType(ContentRejectionEnum, "geneo.content.db.ContentRejectionEnum", [
  { no: 0, name: "CONTENT_REJECTION_REASON_UNDEFINED" },
  { no: 1, name: "CONTENT_REJECTION_REASON_INCORRECT_DATA" },
  { no: 2, name: "CONTENT_REJECTION_REASON_MISSING_INFO" },
]);

/**
 * @generated from enum geneo.content.db.ContentApprovalStatusEnum
 */
export enum ContentApprovalStatusEnum {
  /**
   * @generated from enum value: CONTENT_APPROVAL_STATUS_UNDEFINED = 0;
   */
  CONTENT_APPROVAL_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: CONTENT_APPROVAL_STATUS_PENDING = 1;
   */
  CONTENT_APPROVAL_STATUS_PENDING = 1,

  /**
   * @generated from enum value: CONTENT_APPROVAL_STATUS_APPROVED = 2;
   */
  CONTENT_APPROVAL_STATUS_APPROVED = 2,

  /**
   * @generated from enum value: CONTENT_APPROVAL_STATUS_REJECTED = 3;
   */
  CONTENT_APPROVAL_STATUS_REJECTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentApprovalStatusEnum)
proto3.util.setEnumType(ContentApprovalStatusEnum, "geneo.content.db.ContentApprovalStatusEnum", [
  { no: 0, name: "CONTENT_APPROVAL_STATUS_UNDEFINED" },
  { no: 1, name: "CONTENT_APPROVAL_STATUS_PENDING" },
  { no: 2, name: "CONTENT_APPROVAL_STATUS_APPROVED" },
  { no: 3, name: "CONTENT_APPROVAL_STATUS_REJECTED" },
]);

/**
 * @generated from enum geneo.content.db.ContentDeletionStatusEnum
 */
export enum ContentDeletionStatusEnum {
  /**
   * @generated from enum value: CONTENT_DELETION_STATUS_UNDEFINED = 0;
   */
  CONTENT_DELETION_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: CONTENT_DELETION_STATUS_PENDING = 1;
   */
  CONTENT_DELETION_STATUS_PENDING = 1,

  /**
   * @generated from enum value: CONTENT_DELETION_STATUS_APPROVED = 2;
   */
  CONTENT_DELETION_STATUS_APPROVED = 2,

  /**
   * @generated from enum value: CONTENT_DELETION_STATUS_REJECTED = 3;
   */
  CONTENT_DELETION_STATUS_REJECTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentDeletionStatusEnum)
proto3.util.setEnumType(ContentDeletionStatusEnum, "geneo.content.db.ContentDeletionStatusEnum", [
  { no: 0, name: "CONTENT_DELETION_STATUS_UNDEFINED" },
  { no: 1, name: "CONTENT_DELETION_STATUS_PENDING" },
  { no: 2, name: "CONTENT_DELETION_STATUS_APPROVED" },
  { no: 3, name: "CONTENT_DELETION_STATUS_REJECTED" },
]);

/**
 * @generated from enum geneo.content.db.ResolutionEnum
 */
export enum ResolutionEnum {
  /**
   * @generated from enum value: RESOLUTION_360p = 0;
   */
  RESOLUTION_360p = 0,

  /**
   * Represents high level type.
   *
   * @generated from enum value: RESOLUTION_480p = 1;
   */
  RESOLUTION_480p = 1,

  /**
   * Represents medium level type.
   *
   * @generated from enum value: RESOLUTION_1080p = 2;
   */
  RESOLUTION_1080p = 2,

  /**
   * Represents low level type.
   *
   * @generated from enum value: RESOLUTION_1440p = 3;
   */
  RESOLUTION_1440p = 3,

  /**
   * @generated from enum value: RESOLUTION_2160p = 4;
   */
  RESOLUTION_2160p = 4,

  /**
   * @generated from enum value: RESOLUTION_ALLp = 5;
   */
  RESOLUTION_ALLp = 5,

  /**
   * @generated from enum value: RESOLUTION_720p = 6;
   */
  RESOLUTION_720p = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ResolutionEnum)
proto3.util.setEnumType(ResolutionEnum, "geneo.content.db.ResolutionEnum", [
  { no: 0, name: "RESOLUTION_360p" },
  { no: 1, name: "RESOLUTION_480p" },
  { no: 2, name: "RESOLUTION_1080p" },
  { no: 3, name: "RESOLUTION_1440p" },
  { no: 4, name: "RESOLUTION_2160p" },
  { no: 5, name: "RESOLUTION_ALLp" },
  { no: 6, name: "RESOLUTION_720p" },
]);

/**
 * @generated from enum geneo.content.db.TranscoderStatusEnum
 */
export enum TranscoderStatusEnum {
  /**
   * @generated from enum value: NOT_STARTED = 0;
   */
  NOT_STARTED = 0,

  /**
   * @generated from enum value: STARTED = 1;
   */
  STARTED = 1,

  /**
   * @generated from enum value: PENDING = 2;
   */
  PENDING = 2,

  /**
   * @generated from enum value: SUCCEEDED = 3;
   */
  SUCCEEDED = 3,

  /**
   * @generated from enum value: FAILED = 4;
   */
  FAILED = 4,

  /**
   * @generated from enum value: RUNNING = 5;
   */
  RUNNING = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(TranscoderStatusEnum)
proto3.util.setEnumType(TranscoderStatusEnum, "geneo.content.db.TranscoderStatusEnum", [
  { no: 0, name: "NOT_STARTED" },
  { no: 1, name: "STARTED" },
  { no: 2, name: "PENDING" },
  { no: 3, name: "SUCCEEDED" },
  { no: 4, name: "FAILED" },
  { no: 5, name: "RUNNING" },
]);

/**
 * @generated from enum geneo.content.db.PDFConversionStatus
 */
export enum PDFConversionStatus {
  /**
   * @generated from enum value: PDF_CONVERSION_STATUS_UNDEFINED = 0;
   */
  PDF_CONVERSION_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: PDF_CONVERSION_STATUS_PENDING = 1;
   */
  PDF_CONVERSION_STATUS_PENDING = 1,

  /**
   * @generated from enum value: PDF_CONVERSION_STATUS_STARTED = 2;
   */
  PDF_CONVERSION_STATUS_STARTED = 2,

  /**
   * @generated from enum value: PDF_CONVERSION_STATUS_IN_PROGRESS = 3;
   */
  PDF_CONVERSION_STATUS_IN_PROGRESS = 3,

  /**
   * @generated from enum value: PDF_CONVERSION_STATUS_FAILED = 4;
   */
  PDF_CONVERSION_STATUS_FAILED = 4,

  /**
   * @generated from enum value: PDF_CONVERSION_STATUS_COMPLETED = 5;
   */
  PDF_CONVERSION_STATUS_COMPLETED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(PDFConversionStatus)
proto3.util.setEnumType(PDFConversionStatus, "geneo.content.db.PDFConversionStatus", [
  { no: 0, name: "PDF_CONVERSION_STATUS_UNDEFINED" },
  { no: 1, name: "PDF_CONVERSION_STATUS_PENDING" },
  { no: 2, name: "PDF_CONVERSION_STATUS_STARTED" },
  { no: 3, name: "PDF_CONVERSION_STATUS_IN_PROGRESS" },
  { no: 4, name: "PDF_CONVERSION_STATUS_FAILED" },
  { no: 5, name: "PDF_CONVERSION_STATUS_COMPLETED" },
]);

/**
 * @generated from enum geneo.content.db.UploadPurpose
 */
export enum UploadPurpose {
  /**
   * @generated from enum value: UPLOAD_FOR_UNDEFINED = 0;
   */
  UPLOAD_FOR_UNDEFINED = 0,

  /**
   * @generated from enum value: UPLOAD_FOR_QUESTION_ASSET = 1;
   */
  UPLOAD_FOR_QUESTION_ASSET = 1,

  /**
   * @generated from enum value: UPLOAD_FOR_CONTENT_ASSET = 2;
   */
  UPLOAD_FOR_CONTENT_ASSET = 2,

  /**
   * @generated from enum value: UPLOAD_FOR_RESOURCE_ASSET = 3;
   */
  UPLOAD_FOR_RESOURCE_ASSET = 3,

  /**
   * @generated from enum value: UPLOAD_FOR_HW_ASSET = 4;
   */
  UPLOAD_FOR_HW_ASSET = 4,

  /**
   * @generated from enum value: UPLOAD_FOR_INSTRUCTION_ASSET = 5;
   */
  UPLOAD_FOR_INSTRUCTION_ASSET = 5,

  /**
   * @generated from enum value: UPLOAD_FOR_PROFILE = 6;
   */
  UPLOAD_FOR_PROFILE = 6,

  /**
   * @generated from enum value: UPLOAD_FOR_HW_SUBMISSION = 7;
   */
  UPLOAD_FOR_HW_SUBMISSION = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(UploadPurpose)
proto3.util.setEnumType(UploadPurpose, "geneo.content.db.UploadPurpose", [
  { no: 0, name: "UPLOAD_FOR_UNDEFINED" },
  { no: 1, name: "UPLOAD_FOR_QUESTION_ASSET" },
  { no: 2, name: "UPLOAD_FOR_CONTENT_ASSET" },
  { no: 3, name: "UPLOAD_FOR_RESOURCE_ASSET" },
  { no: 4, name: "UPLOAD_FOR_HW_ASSET" },
  { no: 5, name: "UPLOAD_FOR_INSTRUCTION_ASSET" },
  { no: 6, name: "UPLOAD_FOR_PROFILE" },
  { no: 7, name: "UPLOAD_FOR_HW_SUBMISSION" },
]);

/**
 * Table structure for content_management.content_default_instructions
 *
 * @generated from message geneo.content.db.ContentDefaultInstructions
 */
export class ContentDefaultInstructions extends Message<ContentDefaultInstructions> {
  /**
   * Primary key for content default instructions
   *
   * @generated from field: optional int32 content_instruction_id = 1;
   */
  contentInstructionId?: number;

  /**
   * Type of content
   *
   * @generated from field: geneo.content.db.Content.ContentEnum content_type = 2;
   */
  contentType = Content_ContentEnum.CONTENT_TYPE_UNDEFINED;

  /**
   * Array of instructions for content
   *
   * @generated from field: repeated string instructions = 3;
   */
  instructions: string[] = [];

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 4;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 5;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 6;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 7;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string nano_id = 8;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<ContentDefaultInstructions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ContentDefaultInstructions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_instruction_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "content_type", kind: "enum", T: proto3.getEnumType(Content_ContentEnum) },
    { no: 3, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "created_on", kind: "message", T: Timestamp },
    { no: 5, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentDefaultInstructions {
    return new ContentDefaultInstructions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentDefaultInstructions {
    return new ContentDefaultInstructions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentDefaultInstructions {
    return new ContentDefaultInstructions().fromJsonString(jsonString, options);
  }

  static equals(a: ContentDefaultInstructions | PlainMessage<ContentDefaultInstructions> | undefined, b: ContentDefaultInstructions | PlainMessage<ContentDefaultInstructions> | undefined): boolean {
    return proto3.util.equals(ContentDefaultInstructions, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ContentMetaInfo
 */
export class ContentMetaInfo extends Message<ContentMetaInfo> {
  /**
   * @generated from field: repeated geneo.common.db.Class.ClassEnum class = 1;
   */
  class: Class_ClassEnum[] = [];

  /**
   * @generated from field: repeated geneo.common.db.Board.BoardEnum board = 2;
   */
  board: Board_BoardEnum[] = [];

  /**
   * @generated from field: repeated geneo.common.db.Subject.SubjectEnum subject = 3;
   */
  subject: Subject_SubjectEnum[] = [];

  /**
   * @generated from field: repeated string book_id = 4;
   */
  bookId: string[] = [];

  /**
   * @generated from field: geneo.content.db.ContentMetaInfo.ContextEnum contextualization = 5;
   */
  contextualization = ContentMetaInfo_ContextEnum.CONTEXT_UNDEFINED;

  /**
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 6;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * @generated from field: repeated string credits = 7;
   */
  credits: string[] = [];

  /**
   * @generated from field: repeated string concept = 8;
   */
  concept: string[] = [];

  constructor(data?: PartialMessage<ContentMetaInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ContentMetaInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum), repeated: true },
    { no: 2, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum), repeated: true },
    { no: 3, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum), repeated: true },
    { no: 4, name: "book_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "contextualization", kind: "enum", T: proto3.getEnumType(ContentMetaInfo_ContextEnum) },
    { no: 6, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 7, name: "credits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "concept", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentMetaInfo {
    return new ContentMetaInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentMetaInfo {
    return new ContentMetaInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentMetaInfo {
    return new ContentMetaInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ContentMetaInfo | PlainMessage<ContentMetaInfo> | undefined, b: ContentMetaInfo | PlainMessage<ContentMetaInfo> | undefined): boolean {
    return proto3.util.equals(ContentMetaInfo, a, b);
  }
}

/**
 * Enum for ContextEnum
 *
 * @generated from enum geneo.content.db.ContentMetaInfo.ContextEnum
 */
export enum ContentMetaInfo_ContextEnum {
  /**
   * @generated from enum value: CONTEXT_UNDEFINED = 0;
   */
  CONTEXT_UNDEFINED = 0,

  /**
   * Represents direct context type.
   *
   * @generated from enum value: CONTEXT_DIRECT = 1;
   */
  CONTEXT_DIRECT = 1,

  /**
   * Represents enhanced context type.
   *
   * @generated from enum value: CONTEXT_ENHANCED = 2;
   */
  CONTEXT_ENHANCED = 2,

  /**
   * Represents geneo context type.
   *
   * @generated from enum value: CONTEXT_GENIUS = 3;
   */
  CONTEXT_GENIUS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentMetaInfo_ContextEnum)
proto3.util.setEnumType(ContentMetaInfo_ContextEnum, "geneo.content.db.ContentMetaInfo.ContextEnum", [
  { no: 0, name: "CONTEXT_UNDEFINED" },
  { no: 1, name: "CONTEXT_DIRECT" },
  { no: 2, name: "CONTEXT_ENHANCED" },
  { no: 3, name: "CONTEXT_GENIUS" },
]);

/**
 * @generated from message geneo.content.db.QuestionMetaInfo
 */
export class QuestionMetaInfo extends Message<QuestionMetaInfo> {
  /**
   * @generated from field: repeated geneo.common.db.Class.ClassEnum class = 1;
   */
  class: Class_ClassEnum[] = [];

  /**
   * @generated from field: repeated geneo.common.db.Board.BoardEnum board = 2;
   */
  board: Board_BoardEnum[] = [];

  /**
   * @generated from field: repeated geneo.common.db.Subject.SubjectEnum subject = 3;
   */
  subject: Subject_SubjectEnum[] = [];

  /**
   * @generated from field: repeated string book_id = 4;
   */
  bookId: string[] = [];

  /**
   * @generated from field: geneo.content.db.QuestionMetaInfo.QuestionContextEnum contextualization = 5;
   */
  contextualization = QuestionMetaInfo_QuestionContextEnum.QUESTION_CONTEXT_UNDEFINED;

  /**
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 6;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * @generated from field: repeated string credits = 7;
   */
  credits: string[] = [];

  /**
   * @generated from field: optional geneo.content.db.LLMQuestionContextStatusInfo llm_question_context_info = 8;
   */
  llmQuestionContextInfo?: LLMQuestionContextStatusInfo;

  /**
   * @generated from field: repeated string remedyRids = 9;
   */
  remedyRids: string[] = [];

  constructor(data?: PartialMessage<QuestionMetaInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.QuestionMetaInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum), repeated: true },
    { no: 2, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum), repeated: true },
    { no: 3, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum), repeated: true },
    { no: 4, name: "book_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "contextualization", kind: "enum", T: proto3.getEnumType(QuestionMetaInfo_QuestionContextEnum) },
    { no: 6, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 7, name: "credits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "llm_question_context_info", kind: "message", T: LLMQuestionContextStatusInfo, opt: true },
    { no: 9, name: "remedyRids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionMetaInfo {
    return new QuestionMetaInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionMetaInfo {
    return new QuestionMetaInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionMetaInfo {
    return new QuestionMetaInfo().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionMetaInfo | PlainMessage<QuestionMetaInfo> | undefined, b: QuestionMetaInfo | PlainMessage<QuestionMetaInfo> | undefined): boolean {
    return proto3.util.equals(QuestionMetaInfo, a, b);
  }
}

/**
 * Enum for ContextEnum
 *
 * @generated from enum geneo.content.db.QuestionMetaInfo.QuestionContextEnum
 */
export enum QuestionMetaInfo_QuestionContextEnum {
  /**
   * @generated from enum value: QUESTION_CONTEXT_UNDEFINED = 0;
   */
  QUESTION_CONTEXT_UNDEFINED = 0,

  /**
   * Represents direct context type.
   *
   * @generated from enum value: QUESTION_CONTEXT_DIRECT = 1;
   */
  QUESTION_CONTEXT_DIRECT = 1,

  /**
   * Represents enhanced context type.
   *
   * @generated from enum value: QUESTION_CONTEXT_ENHANCED = 2;
   */
  QUESTION_CONTEXT_ENHANCED = 2,

  /**
   * Represents geneo context type.
   *
   * @generated from enum value: QUESTION_CONTEXT_GENIUS = 3;
   */
  QUESTION_CONTEXT_GENIUS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(QuestionMetaInfo_QuestionContextEnum)
proto3.util.setEnumType(QuestionMetaInfo_QuestionContextEnum, "geneo.content.db.QuestionMetaInfo.QuestionContextEnum", [
  { no: 0, name: "QUESTION_CONTEXT_UNDEFINED" },
  { no: 1, name: "QUESTION_CONTEXT_DIRECT" },
  { no: 2, name: "QUESTION_CONTEXT_ENHANCED" },
  { no: 3, name: "QUESTION_CONTEXT_GENIUS" },
]);

/**
 * @generated from message geneo.content.db.LLMQuestionContextStatusInfo
 */
export class LLMQuestionContextStatusInfo extends Message<LLMQuestionContextStatusInfo> {
  /**
   * @generated from field: geneo.content.db.LLMQContextStatus is_processed = 1;
   */
  isProcessed = LLMQContextStatus.LLM_Q_CONTEXT_STATUS_UNDEFINED;

  /**
   * @generated from field: optional string llm_task_id = 2;
   */
  llmTaskId?: string;

  /**
   * @generated from field: repeated string super_answer = 3;
   */
  superAnswer: string[] = [];

  constructor(data?: PartialMessage<LLMQuestionContextStatusInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.LLMQuestionContextStatusInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_processed", kind: "enum", T: proto3.getEnumType(LLMQContextStatus) },
    { no: 2, name: "llm_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "super_answer", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LLMQuestionContextStatusInfo {
    return new LLMQuestionContextStatusInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LLMQuestionContextStatusInfo {
    return new LLMQuestionContextStatusInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LLMQuestionContextStatusInfo {
    return new LLMQuestionContextStatusInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LLMQuestionContextStatusInfo | PlainMessage<LLMQuestionContextStatusInfo> | undefined, b: LLMQuestionContextStatusInfo | PlainMessage<LLMQuestionContextStatusInfo> | undefined): boolean {
    return proto3.util.equals(LLMQuestionContextStatusInfo, a, b);
  }
}

/**
 * Element types:
 *
 * @generated from message geneo.content.db.TextType
 */
export class TextType extends Message<TextType> {
  /**
   * @generated from field: string text = 1;
   */
  text = "";

  constructor(data?: PartialMessage<TextType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TextType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextType {
    return new TextType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextType {
    return new TextType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextType {
    return new TextType().fromJsonString(jsonString, options);
  }

  static equals(a: TextType | PlainMessage<TextType> | undefined, b: TextType | PlainMessage<TextType> | undefined): boolean {
    return proto3.util.equals(TextType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TextBoxType
 */
export class TextBoxType extends Message<TextBoxType> {
  /**
   * @generated from field: string box_text = 1;
   */
  boxText = "";

  constructor(data?: PartialMessage<TextBoxType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TextBoxType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "box_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextBoxType {
    return new TextBoxType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextBoxType {
    return new TextBoxType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextBoxType {
    return new TextBoxType().fromJsonString(jsonString, options);
  }

  static equals(a: TextBoxType | PlainMessage<TextBoxType> | undefined, b: TextBoxType | PlainMessage<TextBoxType> | undefined): boolean {
    return proto3.util.equals(TextBoxType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TextLeftType
 */
export class TextLeftType extends Message<TextLeftType> {
  /**
   * @generated from field: string text_left = 1;
   */
  textLeft = "";

  constructor(data?: PartialMessage<TextLeftType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TextLeftType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text_left", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextLeftType {
    return new TextLeftType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextLeftType {
    return new TextLeftType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextLeftType {
    return new TextLeftType().fromJsonString(jsonString, options);
  }

  static equals(a: TextLeftType | PlainMessage<TextLeftType> | undefined, b: TextLeftType | PlainMessage<TextLeftType> | undefined): boolean {
    return proto3.util.equals(TextLeftType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TextRightType
 */
export class TextRightType extends Message<TextRightType> {
  /**
   * @generated from field: string text_right = 1;
   */
  textRight = "";

  constructor(data?: PartialMessage<TextRightType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TextRightType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text_right", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextRightType {
    return new TextRightType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextRightType {
    return new TextRightType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextRightType {
    return new TextRightType().fromJsonString(jsonString, options);
  }

  static equals(a: TextRightType | PlainMessage<TextRightType> | undefined, b: TextRightType | PlainMessage<TextRightType> | undefined): boolean {
    return proto3.util.equals(TextRightType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TextCenterType
 */
export class TextCenterType extends Message<TextCenterType> {
  /**
   * @generated from field: string text_center = 1;
   */
  textCenter = "";

  constructor(data?: PartialMessage<TextCenterType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TextCenterType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text_center", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextCenterType {
    return new TextCenterType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextCenterType {
    return new TextCenterType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextCenterType {
    return new TextCenterType().fromJsonString(jsonString, options);
  }

  static equals(a: TextCenterType | PlainMessage<TextCenterType> | undefined, b: TextCenterType | PlainMessage<TextCenterType> | undefined): boolean {
    return proto3.util.equals(TextCenterType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.HeadingType
 */
export class HeadingType extends Message<HeadingType> {
  /**
   * @generated from field: string heading = 1;
   */
  heading = "";

  constructor(data?: PartialMessage<HeadingType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.HeadingType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heading", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HeadingType {
    return new HeadingType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HeadingType {
    return new HeadingType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HeadingType {
    return new HeadingType().fromJsonString(jsonString, options);
  }

  static equals(a: HeadingType | PlainMessage<HeadingType> | undefined, b: HeadingType | PlainMessage<HeadingType> | undefined): boolean {
    return proto3.util.equals(HeadingType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.SubHeadingType
 */
export class SubHeadingType extends Message<SubHeadingType> {
  /**
   * @generated from field: string sub_heading = 1;
   */
  subHeading = "";

  constructor(data?: PartialMessage<SubHeadingType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.SubHeadingType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sub_heading", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubHeadingType {
    return new SubHeadingType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubHeadingType {
    return new SubHeadingType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubHeadingType {
    return new SubHeadingType().fromJsonString(jsonString, options);
  }

  static equals(a: SubHeadingType | PlainMessage<SubHeadingType> | undefined, b: SubHeadingType | PlainMessage<SubHeadingType> | undefined): boolean {
    return proto3.util.equals(SubHeadingType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ComprehensionPassage
 */
export class ComprehensionPassage extends Message<ComprehensionPassage> {
  /**
   * @generated from field: string comprehension_passage_text = 1;
   */
  comprehensionPassageText = "";

  constructor(data?: PartialMessage<ComprehensionPassage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ComprehensionPassage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "comprehension_passage_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComprehensionPassage {
    return new ComprehensionPassage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComprehensionPassage {
    return new ComprehensionPassage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComprehensionPassage {
    return new ComprehensionPassage().fromJsonString(jsonString, options);
  }

  static equals(a: ComprehensionPassage | PlainMessage<ComprehensionPassage> | undefined, b: ComprehensionPassage | PlainMessage<ComprehensionPassage> | undefined): boolean {
    return proto3.util.equals(ComprehensionPassage, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ImageType
 */
export class ImageType extends Message<ImageType> {
  /**
   * @generated from field: string image_url = 1;
   */
  imageUrl = "";

  /**
   * @generated from field: string image_name = 2;
   */
  imageName = "";

  /**
   * @generated from field: optional string image_description = 3;
   */
  imageDescription?: string;

  /**
   * @generated from field: repeated string credits = 4;
   */
  credits: string[] = [];

  /**
   * @generated from field: optional float file_size_in_mb = 5;
   */
  fileSizeInMb?: number;

  /**
   * @generated from field: optional geneo.content.db.FileExtensionEnum file_extension_type = 6;
   */
  fileExtensionType?: FileExtensionEnum;

  constructor(data?: PartialMessage<ImageType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ImageType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "image_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "image_description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "credits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "file_size_in_mb", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 6, name: "file_extension_type", kind: "enum", T: proto3.getEnumType(FileExtensionEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageType {
    return new ImageType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageType {
    return new ImageType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageType {
    return new ImageType().fromJsonString(jsonString, options);
  }

  static equals(a: ImageType | PlainMessage<ImageType> | undefined, b: ImageType | PlainMessage<ImageType> | undefined): boolean {
    return proto3.util.equals(ImageType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.VideoType
 */
export class VideoType extends Message<VideoType> {
  /**
   * @generated from field: string video_url = 1;
   */
  videoUrl = "";

  /**
   * @generated from field: string video_name = 2;
   */
  videoName = "";

  /**
   * @generated from field: optional string video_description = 3;
   */
  videoDescription?: string;

  /**
   * @generated from field: optional string thumbnail_image_url = 4;
   */
  thumbnailImageUrl?: string;

  /**
   * @generated from field: repeated string credits = 5;
   */
  credits: string[] = [];

  /**
   * @generated from field: repeated geneo.content.db.SubTitlesInfoModel sub_titles = 6;
   */
  subTitles: SubTitlesInfoModel[] = [];

  /**
   * @generated from field: optional string external_subtitles_srt_url = 7;
   */
  externalSubtitlesSrtUrl?: string;

  /**
   * @generated from field: optional string video_title = 8;
   */
  videoTitle?: string;

  /**
   * @generated from field: optional float duration_in_minutes = 9;
   */
  durationInMinutes?: number;

  constructor(data?: PartialMessage<VideoType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.VideoType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "video_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "video_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "video_description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "thumbnail_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "credits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "sub_titles", kind: "message", T: SubTitlesInfoModel, repeated: true },
    { no: 7, name: "external_subtitles_srt_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "video_title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "duration_in_minutes", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoType {
    return new VideoType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoType {
    return new VideoType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoType {
    return new VideoType().fromJsonString(jsonString, options);
  }

  static equals(a: VideoType | PlainMessage<VideoType> | undefined, b: VideoType | PlainMessage<VideoType> | undefined): boolean {
    return proto3.util.equals(VideoType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.AudioType
 */
export class AudioType extends Message<AudioType> {
  /**
   * @generated from field: string audio_url = 1;
   */
  audioUrl = "";

  /**
   * @generated from field: optional string audio_name = 2;
   */
  audioName?: string;

  /**
   * @generated from field: optional string audio_description = 3;
   */
  audioDescription?: string;

  /**
   * @generated from field: optional string thumbnail_image_url = 4;
   */
  thumbnailImageUrl?: string;

  /**
   * @generated from field: repeated string credits = 5;
   */
  credits: string[] = [];

  /**
   * @generated from field: repeated geneo.content.db.SubTitlesInfoModel sub_titles = 6;
   */
  subTitles: SubTitlesInfoModel[] = [];

  /**
   * @generated from field: optional string external_subtitles_srt_url = 7;
   */
  externalSubtitlesSrtUrl?: string;

  /**
   * @generated from field: optional float duration_in_minutes = 8;
   */
  durationInMinutes?: number;

  constructor(data?: PartialMessage<AudioType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.AudioType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "audio_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "audio_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "audio_description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "thumbnail_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "credits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "sub_titles", kind: "message", T: SubTitlesInfoModel, repeated: true },
    { no: 7, name: "external_subtitles_srt_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "duration_in_minutes", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AudioType {
    return new AudioType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AudioType {
    return new AudioType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AudioType {
    return new AudioType().fromJsonString(jsonString, options);
  }

  static equals(a: AudioType | PlainMessage<AudioType> | undefined, b: AudioType | PlainMessage<AudioType> | undefined): boolean {
    return proto3.util.equals(AudioType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.SubTitlesInfoModel
 */
export class SubTitlesInfoModel extends Message<SubTitlesInfoModel> {
  /**
   * @generated from field: google.protobuf.Timestamp start_time = 1;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: int32 media_total_duration = 3;
   */
  mediaTotalDuration = 0;

  /**
   * @generated from field: repeated string lines = 4;
   */
  lines: string[] = [];

  constructor(data?: PartialMessage<SubTitlesInfoModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.SubTitlesInfoModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
    { no: 3, name: "media_total_duration", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "lines", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubTitlesInfoModel {
    return new SubTitlesInfoModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubTitlesInfoModel {
    return new SubTitlesInfoModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubTitlesInfoModel {
    return new SubTitlesInfoModel().fromJsonString(jsonString, options);
  }

  static equals(a: SubTitlesInfoModel | PlainMessage<SubTitlesInfoModel> | undefined, b: SubTitlesInfoModel | PlainMessage<SubTitlesInfoModel> | undefined): boolean {
    return proto3.util.equals(SubTitlesInfoModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TableType
 */
export class TableType extends Message<TableType> {
  /**
   * @generated from field: geneo.content.db.TableDataModel table = 1;
   */
  table?: TableDataModel;

  constructor(data?: PartialMessage<TableType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TableType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "table", kind: "message", T: TableDataModel },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TableType {
    return new TableType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TableType {
    return new TableType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TableType {
    return new TableType().fromJsonString(jsonString, options);
  }

  static equals(a: TableType | PlainMessage<TableType> | undefined, b: TableType | PlainMessage<TableType> | undefined): boolean {
    return proto3.util.equals(TableType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TableDataModel
 */
export class TableDataModel extends Message<TableDataModel> {
  /**
   * @generated from field: optional string title = 1;
   */
  title?: string;

  /**
   * @generated from field: optional bool has_header = 2;
   */
  hasHeader?: boolean;

  /**
   * @generated from field: optional bool has_footer = 3;
   */
  hasFooter?: boolean;

  /**
   * @generated from field: repeated geneo.content.db.CellContent content = 4;
   */
  content: CellContent[] = [];

  /**
   * @generated from field: repeated geneo.content.db.MergedCellsInfo merged_cells = 5;
   */
  mergedCells: MergedCellsInfo[] = [];

  constructor(data?: PartialMessage<TableDataModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TableDataModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "has_header", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 3, name: "has_footer", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "content", kind: "message", T: CellContent, repeated: true },
    { no: 5, name: "merged_cells", kind: "message", T: MergedCellsInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TableDataModel {
    return new TableDataModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TableDataModel {
    return new TableDataModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TableDataModel {
    return new TableDataModel().fromJsonString(jsonString, options);
  }

  static equals(a: TableDataModel | PlainMessage<TableDataModel> | undefined, b: TableDataModel | PlainMessage<TableDataModel> | undefined): boolean {
    return proto3.util.equals(TableDataModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.CellContent
 */
export class CellContent extends Message<CellContent> {
  /**
   * @generated from field: int32 row = 1;
   */
  row = 0;

  /**
   * @generated from field: int32 column = 2;
   */
  column = 0;

  /**
   * @generated from field: optional geneo.content.db.TextType text_type = 3;
   */
  textType?: TextType;

  /**
   * @generated from field: optional geneo.content.db.ImageType image_type = 4;
   */
  imageType?: ImageType;

  constructor(data?: PartialMessage<CellContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.CellContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "row", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "column", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "text_type", kind: "message", T: TextType, opt: true },
    { no: 4, name: "image_type", kind: "message", T: ImageType, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CellContent {
    return new CellContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CellContent {
    return new CellContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CellContent {
    return new CellContent().fromJsonString(jsonString, options);
  }

  static equals(a: CellContent | PlainMessage<CellContent> | undefined, b: CellContent | PlainMessage<CellContent> | undefined): boolean {
    return proto3.util.equals(CellContent, a, b);
  }
}

/**
 * @generated from message geneo.content.db.MergedCellsInfo
 */
export class MergedCellsInfo extends Message<MergedCellsInfo> {
  /**
   * @generated from field: geneo.content.db.Cell start = 1;
   */
  start?: Cell;

  /**
   * @generated from field: geneo.content.db.Cell end = 2;
   */
  end?: Cell;

  constructor(data?: PartialMessage<MergedCellsInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.MergedCellsInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start", kind: "message", T: Cell },
    { no: 2, name: "end", kind: "message", T: Cell },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MergedCellsInfo {
    return new MergedCellsInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MergedCellsInfo {
    return new MergedCellsInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MergedCellsInfo {
    return new MergedCellsInfo().fromJsonString(jsonString, options);
  }

  static equals(a: MergedCellsInfo | PlainMessage<MergedCellsInfo> | undefined, b: MergedCellsInfo | PlainMessage<MergedCellsInfo> | undefined): boolean {
    return proto3.util.equals(MergedCellsInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.Cell
 */
export class Cell extends Message<Cell> {
  /**
   * @generated from field: int32 row = 1;
   */
  row = 0;

  /**
   * @generated from field: int32 column = 2;
   */
  column = 0;

  constructor(data?: PartialMessage<Cell>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.Cell";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "row", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "column", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cell {
    return new Cell().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cell {
    return new Cell().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cell {
    return new Cell().fromJsonString(jsonString, options);
  }

  static equals(a: Cell | PlainMessage<Cell> | undefined, b: Cell | PlainMessage<Cell> | undefined): boolean {
    return proto3.util.equals(Cell, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TableContentType
 */
export class TableContentType extends Message<TableContentType> {
  /**
   * @generated from oneof geneo.content.db.TableContentType.type
   */
  type: {
    /**
     * @generated from field: geneo.content.db.TextType text_type = 1;
     */
    value: TextType;
    case: "textType";
  } | {
    /**
     * @generated from field: geneo.content.db.ImageType image_type = 2;
     */
    value: ImageType;
    case: "imageType";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<TableContentType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TableContentType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text_type", kind: "message", T: TextType, oneof: "type" },
    { no: 2, name: "image_type", kind: "message", T: ImageType, oneof: "type" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TableContentType {
    return new TableContentType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TableContentType {
    return new TableContentType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TableContentType {
    return new TableContentType().fromJsonString(jsonString, options);
  }

  static equals(a: TableContentType | PlainMessage<TableContentType> | undefined, b: TableContentType | PlainMessage<TableContentType> | undefined): boolean {
    return proto3.util.equals(TableContentType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExcerptType
 */
export class ExcerptType extends Message<ExcerptType> {
  /**
   * @generated from field: string excerpt = 1;
   */
  excerpt = "";

  constructor(data?: PartialMessage<ExcerptType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExcerptType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "excerpt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExcerptType {
    return new ExcerptType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExcerptType {
    return new ExcerptType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExcerptType {
    return new ExcerptType().fromJsonString(jsonString, options);
  }

  static equals(a: ExcerptType | PlainMessage<ExcerptType> | undefined, b: ExcerptType | PlainMessage<ExcerptType> | undefined): boolean {
    return proto3.util.equals(ExcerptType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.BulletedListType
 */
export class BulletedListType extends Message<BulletedListType> {
  /**
   * @generated from field: repeated string list_items = 1;
   */
  listItems: string[] = [];

  constructor(data?: PartialMessage<BulletedListType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.BulletedListType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list_items", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BulletedListType {
    return new BulletedListType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BulletedListType {
    return new BulletedListType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BulletedListType {
    return new BulletedListType().fromJsonString(jsonString, options);
  }

  static equals(a: BulletedListType | PlainMessage<BulletedListType> | undefined, b: BulletedListType | PlainMessage<BulletedListType> | undefined): boolean {
    return proto3.util.equals(BulletedListType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.NumberedListType
 */
export class NumberedListType extends Message<NumberedListType> {
  /**
   * @generated from field: repeated string list_items = 1;
   */
  listItems: string[] = [];

  constructor(data?: PartialMessage<NumberedListType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.NumberedListType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "list_items", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NumberedListType {
    return new NumberedListType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NumberedListType {
    return new NumberedListType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NumberedListType {
    return new NumberedListType().fromJsonString(jsonString, options);
  }

  static equals(a: NumberedListType | PlainMessage<NumberedListType> | undefined, b: NumberedListType | PlainMessage<NumberedListType> | undefined): boolean {
    return proto3.util.equals(NumberedListType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExternalResourceType
 */
export class ExternalResourceType extends Message<ExternalResourceType> {
  /**
   * @generated from field: geneo.content.db.FileEnum file_type = 1;
   */
  fileType = FileEnum.FILE_TYPE_UNKNOWN;

  /**
   * @generated from field: string file_name = 2;
   */
  fileName = "";

  /**
   * @generated from field: optional float file_size_in_mb = 3;
   */
  fileSizeInMb?: number;

  /**
   * @generated from field: string file_url = 4;
   */
  fileUrl = "";

  /**
   * @generated from field: optional string external_web_resource_url = 5;
   */
  externalWebResourceUrl?: string;

  /**
   * @generated from field: geneo.content.db.FileExtensionEnum file_extension_type = 6;
   */
  fileExtensionType = FileExtensionEnum.FILE_EXTENSION_UNKNOWN;

  /**
   * @generated from field: optional int32 start_page_number = 7;
   */
  startPageNumber?: number;

  /**
   * @generated from field: optional int32 end_page_number = 8;
   */
  endPageNumber?: number;

  /**
   * @generated from field: optional int32 book_id = 9;
   */
  bookId?: number;

  /**
   * @generated from field: optional string cover_image_url = 10;
   */
  coverImageUrl?: string;

  /**
   * @generated from field: optional string preview_url = 11;
   */
  previewUrl?: string;

  /**
   * @generated from field: optional int64 person_id = 12;
   */
  personId?: bigint;

  constructor(data?: PartialMessage<ExternalResourceType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExternalResourceType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_type", kind: "enum", T: proto3.getEnumType(FileEnum) },
    { no: 2, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "file_size_in_mb", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 4, name: "file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "external_web_resource_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "file_extension_type", kind: "enum", T: proto3.getEnumType(FileExtensionEnum) },
    { no: 7, name: "start_page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "end_page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "cover_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExternalResourceType {
    return new ExternalResourceType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExternalResourceType {
    return new ExternalResourceType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExternalResourceType {
    return new ExternalResourceType().fromJsonString(jsonString, options);
  }

  static equals(a: ExternalResourceType | PlainMessage<ExternalResourceType> | undefined, b: ExternalResourceType | PlainMessage<ExternalResourceType> | undefined): boolean {
    return proto3.util.equals(ExternalResourceType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ContentElementType
 */
export class ContentElementType extends Message<ContentElementType> {
  /**
   * @generated from oneof geneo.content.db.ContentElementType.model
   */
  model: {
    /**
     * @generated from field: geneo.content.db.HeadingType heading = 1;
     */
    value: HeadingType;
    case: "heading";
  } | {
    /**
     * @generated from field: geneo.content.db.SubHeadingType sub_heading = 2;
     */
    value: SubHeadingType;
    case: "subHeading";
  } | {
    /**
     * @generated from field: geneo.content.db.TextType text = 3;
     */
    value: TextType;
    case: "text";
  } | {
    /**
     * @generated from field: geneo.content.db.TextLeftType text_left = 4;
     */
    value: TextLeftType;
    case: "textLeft";
  } | {
    /**
     * @generated from field: geneo.content.db.TextCenterType text_center = 5;
     */
    value: TextCenterType;
    case: "textCenter";
  } | {
    /**
     * @generated from field: geneo.content.db.TextRightType text_right = 6;
     */
    value: TextRightType;
    case: "textRight";
  } | {
    /**
     * @generated from field: geneo.content.db.TableType table = 7;
     */
    value: TableType;
    case: "table";
  } | {
    /**
     * @generated from field: geneo.content.db.ExcerptType excerpt = 8;
     */
    value: ExcerptType;
    case: "excerpt";
  } | {
    /**
     * @generated from field: geneo.content.db.ImageType image = 9;
     */
    value: ImageType;
    case: "image";
  } | {
    /**
     * @generated from field: geneo.content.db.VideoType video = 10;
     */
    value: VideoType;
    case: "video";
  } | {
    /**
     * @generated from field: geneo.content.db.AudioType audio = 11;
     */
    value: AudioType;
    case: "audio";
  } | {
    /**
     * @generated from field: geneo.content.db.ExternalResourceType external_resource = 12;
     */
    value: ExternalResourceType;
    case: "externalResource";
  } | {
    /**
     * @generated from field: geneo.content.db.ComprehensionPassage comprehension_passage = 13;
     */
    value: ComprehensionPassage;
    case: "comprehensionPassage";
  } | {
    /**
     * @generated from field: geneo.content.db.BulletedListType bullet_list = 14;
     */
    value: BulletedListType;
    case: "bulletList";
  } | {
    /**
     * @generated from field: geneo.content.db.NumberedListType number_list = 16;
     */
    value: NumberedListType;
    case: "numberList";
  } | {
    /**
     * @generated from field: geneo.content.db.TextBoxType text_box = 15;
     */
    value: TextBoxType;
    case: "textBox";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ContentElementType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ContentElementType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heading", kind: "message", T: HeadingType, oneof: "model" },
    { no: 2, name: "sub_heading", kind: "message", T: SubHeadingType, oneof: "model" },
    { no: 3, name: "text", kind: "message", T: TextType, oneof: "model" },
    { no: 4, name: "text_left", kind: "message", T: TextLeftType, oneof: "model" },
    { no: 5, name: "text_center", kind: "message", T: TextCenterType, oneof: "model" },
    { no: 6, name: "text_right", kind: "message", T: TextRightType, oneof: "model" },
    { no: 7, name: "table", kind: "message", T: TableType, oneof: "model" },
    { no: 8, name: "excerpt", kind: "message", T: ExcerptType, oneof: "model" },
    { no: 9, name: "image", kind: "message", T: ImageType, oneof: "model" },
    { no: 10, name: "video", kind: "message", T: VideoType, oneof: "model" },
    { no: 11, name: "audio", kind: "message", T: AudioType, oneof: "model" },
    { no: 12, name: "external_resource", kind: "message", T: ExternalResourceType, oneof: "model" },
    { no: 13, name: "comprehension_passage", kind: "message", T: ComprehensionPassage, oneof: "model" },
    { no: 14, name: "bullet_list", kind: "message", T: BulletedListType, oneof: "model" },
    { no: 16, name: "number_list", kind: "message", T: NumberedListType, oneof: "model" },
    { no: 15, name: "text_box", kind: "message", T: TextBoxType, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentElementType {
    return new ContentElementType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentElementType {
    return new ContentElementType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentElementType {
    return new ContentElementType().fromJsonString(jsonString, options);
  }

  static equals(a: ContentElementType | PlainMessage<ContentElementType> | undefined, b: ContentElementType | PlainMessage<ContentElementType> | undefined): boolean {
    return proto3.util.equals(ContentElementType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.QuestionElementType
 */
export class QuestionElementType extends Message<QuestionElementType> {
  /**
   * @generated from oneof geneo.content.db.QuestionElementType.model
   */
  model: {
    /**
     * @generated from field: geneo.content.db.HeadingType heading = 1;
     */
    value: HeadingType;
    case: "heading";
  } | {
    /**
     * @generated from field: geneo.content.db.SubHeadingType sub_heading = 2;
     */
    value: SubHeadingType;
    case: "subHeading";
  } | {
    /**
     * @generated from field: geneo.content.db.TextType text = 3;
     */
    value: TextType;
    case: "text";
  } | {
    /**
     * @generated from field: geneo.content.db.TextLeftType text_left = 4;
     */
    value: TextLeftType;
    case: "textLeft";
  } | {
    /**
     * @generated from field: geneo.content.db.TextCenterType text_center = 5;
     */
    value: TextCenterType;
    case: "textCenter";
  } | {
    /**
     * @generated from field: geneo.content.db.TextRightType text_right = 6;
     */
    value: TextRightType;
    case: "textRight";
  } | {
    /**
     * @generated from field: geneo.content.db.TableType table = 7;
     */
    value: TableType;
    case: "table";
  } | {
    /**
     * @generated from field: geneo.content.db.ExcerptType excerpt = 8;
     */
    value: ExcerptType;
    case: "excerpt";
  } | {
    /**
     * @generated from field: geneo.content.db.ImageType image = 9;
     */
    value: ImageType;
    case: "image";
  } | {
    /**
     * @generated from field: geneo.content.db.VideoType video = 10;
     */
    value: VideoType;
    case: "video";
  } | {
    /**
     * @generated from field: geneo.content.db.AudioType audio = 11;
     */
    value: AudioType;
    case: "audio";
  } | {
    /**
     * @generated from field: geneo.content.db.ExternalResourceType external_resource = 12;
     */
    value: ExternalResourceType;
    case: "externalResource";
  } | {
    /**
     * @generated from field: geneo.content.db.ComprehensionPassage comprehension_passage = 13;
     */
    value: ComprehensionPassage;
    case: "comprehensionPassage";
  } | {
    /**
     * @generated from field: geneo.content.db.BulletedListType bullet_list = 14;
     */
    value: BulletedListType;
    case: "bulletList";
  } | {
    /**
     * @generated from field: geneo.content.db.NumberedListType number_list = 16;
     */
    value: NumberedListType;
    case: "numberList";
  } | {
    /**
     * @generated from field: geneo.content.db.TextBoxType text_box = 15;
     */
    value: TextBoxType;
    case: "textBox";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<QuestionElementType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.QuestionElementType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heading", kind: "message", T: HeadingType, oneof: "model" },
    { no: 2, name: "sub_heading", kind: "message", T: SubHeadingType, oneof: "model" },
    { no: 3, name: "text", kind: "message", T: TextType, oneof: "model" },
    { no: 4, name: "text_left", kind: "message", T: TextLeftType, oneof: "model" },
    { no: 5, name: "text_center", kind: "message", T: TextCenterType, oneof: "model" },
    { no: 6, name: "text_right", kind: "message", T: TextRightType, oneof: "model" },
    { no: 7, name: "table", kind: "message", T: TableType, oneof: "model" },
    { no: 8, name: "excerpt", kind: "message", T: ExcerptType, oneof: "model" },
    { no: 9, name: "image", kind: "message", T: ImageType, oneof: "model" },
    { no: 10, name: "video", kind: "message", T: VideoType, oneof: "model" },
    { no: 11, name: "audio", kind: "message", T: AudioType, oneof: "model" },
    { no: 12, name: "external_resource", kind: "message", T: ExternalResourceType, oneof: "model" },
    { no: 13, name: "comprehension_passage", kind: "message", T: ComprehensionPassage, oneof: "model" },
    { no: 14, name: "bullet_list", kind: "message", T: BulletedListType, oneof: "model" },
    { no: 16, name: "number_list", kind: "message", T: NumberedListType, oneof: "model" },
    { no: 15, name: "text_box", kind: "message", T: TextBoxType, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionElementType {
    return new QuestionElementType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionElementType {
    return new QuestionElementType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionElementType {
    return new QuestionElementType().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionElementType | PlainMessage<QuestionElementType> | undefined, b: QuestionElementType | PlainMessage<QuestionElementType> | undefined): boolean {
    return proto3.util.equals(QuestionElementType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.SolutionElementType
 */
export class SolutionElementType extends Message<SolutionElementType> {
  /**
   * @generated from oneof geneo.content.db.SolutionElementType.model
   */
  model: {
    /**
     * @generated from field: geneo.content.db.HeadingType heading = 1;
     */
    value: HeadingType;
    case: "heading";
  } | {
    /**
     * @generated from field: geneo.content.db.SubHeadingType sub_heading = 2;
     */
    value: SubHeadingType;
    case: "subHeading";
  } | {
    /**
     * @generated from field: geneo.content.db.TextType text = 3;
     */
    value: TextType;
    case: "text";
  } | {
    /**
     * @generated from field: geneo.content.db.TextLeftType text_left = 4;
     */
    value: TextLeftType;
    case: "textLeft";
  } | {
    /**
     * @generated from field: geneo.content.db.TextCenterType text_center = 5;
     */
    value: TextCenterType;
    case: "textCenter";
  } | {
    /**
     * @generated from field: geneo.content.db.TextRightType text_right = 6;
     */
    value: TextRightType;
    case: "textRight";
  } | {
    /**
     * @generated from field: geneo.content.db.TableType table = 7;
     */
    value: TableType;
    case: "table";
  } | {
    /**
     * @generated from field: geneo.content.db.ExcerptType excerpt = 8;
     */
    value: ExcerptType;
    case: "excerpt";
  } | {
    /**
     * @generated from field: geneo.content.db.ImageType image = 9;
     */
    value: ImageType;
    case: "image";
  } | {
    /**
     * @generated from field: geneo.content.db.VideoType video = 10;
     */
    value: VideoType;
    case: "video";
  } | {
    /**
     * @generated from field: geneo.content.db.AudioType audio = 11;
     */
    value: AudioType;
    case: "audio";
  } | {
    /**
     * @generated from field: geneo.content.db.ExternalResourceType external_resource = 12;
     */
    value: ExternalResourceType;
    case: "externalResource";
  } | {
    /**
     * @generated from field: geneo.content.db.ComprehensionPassage comprehension_passage = 13;
     */
    value: ComprehensionPassage;
    case: "comprehensionPassage";
  } | {
    /**
     * @generated from field: geneo.content.db.BulletedListType bullet_list = 14;
     */
    value: BulletedListType;
    case: "bulletList";
  } | {
    /**
     * @generated from field: geneo.content.db.NumberedListType number_list = 16;
     */
    value: NumberedListType;
    case: "numberList";
  } | {
    /**
     * @generated from field: geneo.content.db.TextBoxType text_box = 15;
     */
    value: TextBoxType;
    case: "textBox";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SolutionElementType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.SolutionElementType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heading", kind: "message", T: HeadingType, oneof: "model" },
    { no: 2, name: "sub_heading", kind: "message", T: SubHeadingType, oneof: "model" },
    { no: 3, name: "text", kind: "message", T: TextType, oneof: "model" },
    { no: 4, name: "text_left", kind: "message", T: TextLeftType, oneof: "model" },
    { no: 5, name: "text_center", kind: "message", T: TextCenterType, oneof: "model" },
    { no: 6, name: "text_right", kind: "message", T: TextRightType, oneof: "model" },
    { no: 7, name: "table", kind: "message", T: TableType, oneof: "model" },
    { no: 8, name: "excerpt", kind: "message", T: ExcerptType, oneof: "model" },
    { no: 9, name: "image", kind: "message", T: ImageType, oneof: "model" },
    { no: 10, name: "video", kind: "message", T: VideoType, oneof: "model" },
    { no: 11, name: "audio", kind: "message", T: AudioType, oneof: "model" },
    { no: 12, name: "external_resource", kind: "message", T: ExternalResourceType, oneof: "model" },
    { no: 13, name: "comprehension_passage", kind: "message", T: ComprehensionPassage, oneof: "model" },
    { no: 14, name: "bullet_list", kind: "message", T: BulletedListType, oneof: "model" },
    { no: 16, name: "number_list", kind: "message", T: NumberedListType, oneof: "model" },
    { no: 15, name: "text_box", kind: "message", T: TextBoxType, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SolutionElementType {
    return new SolutionElementType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SolutionElementType {
    return new SolutionElementType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SolutionElementType {
    return new SolutionElementType().fromJsonString(jsonString, options);
  }

  static equals(a: SolutionElementType | PlainMessage<SolutionElementType> | undefined, b: SolutionElementType | PlainMessage<SolutionElementType> | undefined): boolean {
    return proto3.util.equals(SolutionElementType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.QuestionCommonContentModel
 */
export class QuestionCommonContentModel extends Message<QuestionCommonContentModel> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  /**
   * Seconds
   *
   * @generated from field: int32 time = 2;
   */
  time = 0;

  /**
   * @generated from field: repeated float marks = 3;
   */
  marks: number[] = [];

  /**
   * @generated from field: repeated geneo.content.db.SolutionElementType solution = 4;
   */
  solution: SolutionElementType[] = [];

  /**
   * @generated from field: repeated string ans_explanation = 5;
   */
  ansExplanation: string[] = [];

  /**
   * @generated from field: repeated string hint = 6;
   */
  hint: string[] = [];

  /**
   * @generated from field: optional string video_solution_content_id = 7;
   */
  videoSolutionContentId?: string;

  constructor(data?: PartialMessage<QuestionCommonContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.QuestionCommonContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
    { no: 2, name: "time", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
    { no: 4, name: "solution", kind: "message", T: SolutionElementType, repeated: true },
    { no: 5, name: "ans_explanation", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "hint", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "video_solution_content_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionCommonContentModel {
    return new QuestionCommonContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionCommonContentModel {
    return new QuestionCommonContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionCommonContentModel {
    return new QuestionCommonContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionCommonContentModel | PlainMessage<QuestionCommonContentModel> | undefined, b: QuestionCommonContentModel | PlainMessage<QuestionCommonContentModel> | undefined): boolean {
    return proto3.util.equals(QuestionCommonContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TextOption
 */
export class TextOption extends Message<TextOption> {
  /**
   * @generated from field: string option_text = 1;
   */
  optionText = "";

  /**
   * @generated from field: optional string correct_incorrect_explanation_text = 2;
   */
  correctIncorrectExplanationText?: string;

  constructor(data?: PartialMessage<TextOption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TextOption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "option_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "correct_incorrect_explanation_text", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextOption {
    return new TextOption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextOption {
    return new TextOption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextOption {
    return new TextOption().fromJsonString(jsonString, options);
  }

  static equals(a: TextOption | PlainMessage<TextOption> | undefined, b: TextOption | PlainMessage<TextOption> | undefined): boolean {
    return proto3.util.equals(TextOption, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ImageOption
 */
export class ImageOption extends Message<ImageOption> {
  /**
   * @generated from field: geneo.content.db.ImageType image = 1;
   */
  image?: ImageType;

  /**
   * @generated from field: optional string correct_incorrect_explanation_text = 2;
   */
  correctIncorrectExplanationText?: string;

  constructor(data?: PartialMessage<ImageOption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ImageOption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image", kind: "message", T: ImageType },
    { no: 2, name: "correct_incorrect_explanation_text", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageOption {
    return new ImageOption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageOption {
    return new ImageOption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageOption {
    return new ImageOption().fromJsonString(jsonString, options);
  }

  static equals(a: ImageOption | PlainMessage<ImageOption> | undefined, b: ImageOption | PlainMessage<ImageOption> | undefined): boolean {
    return proto3.util.equals(ImageOption, a, b);
  }
}

/**
 * @generated from message geneo.content.db.OptionsType
 */
export class OptionsType extends Message<OptionsType> {
  /**
   * @generated from oneof geneo.content.db.OptionsType.optionType
   */
  optionType: {
    /**
     * @generated from field: geneo.content.db.TextOption text_option = 1;
     */
    value: TextOption;
    case: "textOption";
  } | {
    /**
     * @generated from field: geneo.content.db.ImageOption image_option = 2;
     */
    value: ImageOption;
    case: "imageOption";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<OptionsType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.OptionsType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text_option", kind: "message", T: TextOption, oneof: "optionType" },
    { no: 2, name: "image_option", kind: "message", T: ImageOption, oneof: "optionType" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OptionsType {
    return new OptionsType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OptionsType {
    return new OptionsType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OptionsType {
    return new OptionsType().fromJsonString(jsonString, options);
  }

  static equals(a: OptionsType | PlainMessage<OptionsType> | undefined, b: OptionsType | PlainMessage<OptionsType> | undefined): boolean {
    return proto3.util.equals(OptionsType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.FibContentModel
 */
export class FibContentModel extends Message<FibContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * correct: string[][] //TODO
   *
   * @generated from field: repeated geneo.content.db.BlankCorrectAnswerInfo correct_answer_info = 2;
   */
  correctAnswerInfo: BlankCorrectAnswerInfo[] = [];

  constructor(data?: PartialMessage<FibContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.FibContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "correct_answer_info", kind: "message", T: BlankCorrectAnswerInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FibContentModel {
    return new FibContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FibContentModel {
    return new FibContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FibContentModel {
    return new FibContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: FibContentModel | PlainMessage<FibContentModel> | undefined, b: FibContentModel | PlainMessage<FibContentModel> | undefined): boolean {
    return proto3.util.equals(FibContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.BlankCorrectAnswerInfo
 */
export class BlankCorrectAnswerInfo extends Message<BlankCorrectAnswerInfo> {
  /**
   * @generated from field: int32 blank_position = 1;
   */
  blankPosition = 0;

  /**
   * @generated from field: repeated string correct = 2;
   */
  correct: string[] = [];

  constructor(data?: PartialMessage<BlankCorrectAnswerInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.BlankCorrectAnswerInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "blank_position", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "correct", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlankCorrectAnswerInfo {
    return new BlankCorrectAnswerInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlankCorrectAnswerInfo {
    return new BlankCorrectAnswerInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlankCorrectAnswerInfo {
    return new BlankCorrectAnswerInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BlankCorrectAnswerInfo | PlainMessage<BlankCorrectAnswerInfo> | undefined, b: BlankCorrectAnswerInfo | PlainMessage<BlankCorrectAnswerInfo> | undefined): boolean {
    return proto3.util.equals(BlankCorrectAnswerInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TfContentModel
 */
export class TfContentModel extends Message<TfContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * @generated from field: geneo.content.db.TfContentModel.AnswerEnum correct = 2;
   */
  correct = TfContentModel_AnswerEnum.TF_ANSWER_UNDEFINED;

  constructor(data?: PartialMessage<TfContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TfContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "correct", kind: "enum", T: proto3.getEnumType(TfContentModel_AnswerEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TfContentModel {
    return new TfContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TfContentModel {
    return new TfContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TfContentModel {
    return new TfContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: TfContentModel | PlainMessage<TfContentModel> | undefined, b: TfContentModel | PlainMessage<TfContentModel> | undefined): boolean {
    return proto3.util.equals(TfContentModel, a, b);
  }
}

/**
 * @generated from enum geneo.content.db.TfContentModel.AnswerEnum
 */
export enum TfContentModel_AnswerEnum {
  /**
   * Represents undefined answer type.
   *
   * @generated from enum value: TF_ANSWER_UNDEFINED = 0;
   */
  TF_ANSWER_UNDEFINED = 0,

  /**
   * Represents tf true answer type.
   *
   * @generated from enum value: TF_ANSWER_T = 1;
   */
  TF_ANSWER_T = 1,

  /**
   * Represents tf false answer type.
   *
   * @generated from enum value: TF_ANSWER_F = 2;
   */
  TF_ANSWER_F = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TfContentModel_AnswerEnum)
proto3.util.setEnumType(TfContentModel_AnswerEnum, "geneo.content.db.TfContentModel.AnswerEnum", [
  { no: 0, name: "TF_ANSWER_UNDEFINED" },
  { no: 1, name: "TF_ANSWER_T" },
  { no: 2, name: "TF_ANSWER_F" },
]);

/**
 * @generated from message geneo.content.db.McqSingleContentModel
 */
export class McqSingleContentModel extends Message<McqSingleContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * @generated from field: repeated geneo.content.db.OptionsType options = 2;
   */
  options: OptionsType[] = [];

  /**
   * @generated from field: string correct = 3;
   */
  correct = "";

  constructor(data?: PartialMessage<McqSingleContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.McqSingleContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "options", kind: "message", T: OptionsType, repeated: true },
    { no: 3, name: "correct", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): McqSingleContentModel {
    return new McqSingleContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): McqSingleContentModel {
    return new McqSingleContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): McqSingleContentModel {
    return new McqSingleContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: McqSingleContentModel | PlainMessage<McqSingleContentModel> | undefined, b: McqSingleContentModel | PlainMessage<McqSingleContentModel> | undefined): boolean {
    return proto3.util.equals(McqSingleContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.McqMultipleContentModel
 */
export class McqMultipleContentModel extends Message<McqMultipleContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * @generated from field: repeated geneo.content.db.OptionsType options = 2;
   */
  options: OptionsType[] = [];

  /**
   * @generated from field: repeated string correct = 3;
   */
  correct: string[] = [];

  constructor(data?: PartialMessage<McqMultipleContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.McqMultipleContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "options", kind: "message", T: OptionsType, repeated: true },
    { no: 3, name: "correct", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): McqMultipleContentModel {
    return new McqMultipleContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): McqMultipleContentModel {
    return new McqMultipleContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): McqMultipleContentModel {
    return new McqMultipleContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: McqMultipleContentModel | PlainMessage<McqMultipleContentModel> | undefined, b: McqMultipleContentModel | PlainMessage<McqMultipleContentModel> | undefined): boolean {
    return proto3.util.equals(McqMultipleContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.EvaluationRubrics
 */
export class EvaluationRubrics extends Message<EvaluationRubrics> {
  /**
   * @generated from field: repeated string generics = 1;
   */
  generics: string[] = [];

  /**
   * @generated from field: repeated string specifics = 2;
   */
  specifics: string[] = [];

  constructor(data?: PartialMessage<EvaluationRubrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.EvaluationRubrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "generics", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "specifics", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EvaluationRubrics {
    return new EvaluationRubrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EvaluationRubrics {
    return new EvaluationRubrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EvaluationRubrics {
    return new EvaluationRubrics().fromJsonString(jsonString, options);
  }

  static equals(a: EvaluationRubrics | PlainMessage<EvaluationRubrics> | undefined, b: EvaluationRubrics | PlainMessage<EvaluationRubrics> | undefined): boolean {
    return proto3.util.equals(EvaluationRubrics, a, b);
  }
}

/**
 * @generated from message geneo.content.db.SampleAnswer
 */
export class SampleAnswer extends Message<SampleAnswer> {
  /**
   * @generated from field: repeated string answer = 1;
   */
  answer: string[] = [];

  /**
   * @generated from field: geneo.content.db.ContentGeneratorEnum generated_by = 2;
   */
  generatedBy = ContentGeneratorEnum.CONTENT_GENERATOR_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<SampleAnswer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.SampleAnswer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "generated_by", kind: "enum", T: proto3.getEnumType(ContentGeneratorEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SampleAnswer {
    return new SampleAnswer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SampleAnswer {
    return new SampleAnswer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SampleAnswer {
    return new SampleAnswer().fromJsonString(jsonString, options);
  }

  static equals(a: SampleAnswer | PlainMessage<SampleAnswer> | undefined, b: SampleAnswer | PlainMessage<SampleAnswer> | undefined): boolean {
    return proto3.util.equals(SampleAnswer, a, b);
  }
}

/**
 * @generated from message geneo.content.db.BookReference
 */
export class BookReference extends Message<BookReference> {
  /**
   * @generated from field: int32 book_id = 1;
   */
  bookId = 0;

  /**
   * @generated from field: string pdf_url = 2;
   */
  pdfUrl = "";

  /**
   * @generated from field: int32 start_page = 3;
   */
  startPage = 0;

  /**
   * @generated from field: int32 end_page = 4;
   */
  endPage = 0;

  constructor(data?: PartialMessage<BookReference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.BookReference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "pdf_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "end_page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookReference {
    return new BookReference().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookReference {
    return new BookReference().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookReference {
    return new BookReference().fromJsonString(jsonString, options);
  }

  static equals(a: BookReference | PlainMessage<BookReference> | undefined, b: BookReference | PlainMessage<BookReference> | undefined): boolean {
    return proto3.util.equals(BookReference, a, b);
  }
}

/**
 * @generated from message geneo.content.db.LongQuesContentModel
 */
export class LongQuesContentModel extends Message<LongQuesContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * @generated from field: repeated geneo.content.db.SampleAnswer sampleAnswers = 2;
   */
  sampleAnswers: SampleAnswer[] = [];

  /**
   * @generated from field: optional geneo.content.db.EvaluationRubrics evaluation_rubrics = 3;
   */
  evaluationRubrics?: EvaluationRubrics;

  /**
   * @generated from field: repeated geneo.content.db.ExternalResourceType context_references = 4;
   */
  contextReferences: ExternalResourceType[] = [];

  constructor(data?: PartialMessage<LongQuesContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.LongQuesContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "sampleAnswers", kind: "message", T: SampleAnswer, repeated: true },
    { no: 3, name: "evaluation_rubrics", kind: "message", T: EvaluationRubrics, opt: true },
    { no: 4, name: "context_references", kind: "message", T: ExternalResourceType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LongQuesContentModel {
    return new LongQuesContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LongQuesContentModel {
    return new LongQuesContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LongQuesContentModel {
    return new LongQuesContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: LongQuesContentModel | PlainMessage<LongQuesContentModel> | undefined, b: LongQuesContentModel | PlainMessage<LongQuesContentModel> | undefined): boolean {
    return proto3.util.equals(LongQuesContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ShortQuesContentModel
 */
export class ShortQuesContentModel extends Message<ShortQuesContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * @generated from field: repeated geneo.content.db.SampleAnswer sampleAnswers = 2;
   */
  sampleAnswers: SampleAnswer[] = [];

  /**
   * @generated from field: optional geneo.content.db.EvaluationRubrics evaluation_rubrics = 3;
   */
  evaluationRubrics?: EvaluationRubrics;

  /**
   * @generated from field: repeated geneo.content.db.ExternalResourceType context_references = 4;
   */
  contextReferences: ExternalResourceType[] = [];

  constructor(data?: PartialMessage<ShortQuesContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ShortQuesContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "sampleAnswers", kind: "message", T: SampleAnswer, repeated: true },
    { no: 3, name: "evaluation_rubrics", kind: "message", T: EvaluationRubrics, opt: true },
    { no: 4, name: "context_references", kind: "message", T: ExternalResourceType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShortQuesContentModel {
    return new ShortQuesContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShortQuesContentModel {
    return new ShortQuesContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShortQuesContentModel {
    return new ShortQuesContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: ShortQuesContentModel | PlainMessage<ShortQuesContentModel> | undefined, b: ShortQuesContentModel | PlainMessage<ShortQuesContentModel> | undefined): boolean {
    return proto3.util.equals(ShortQuesContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.VeryShortQuesContentModel
 */
export class VeryShortQuesContentModel extends Message<VeryShortQuesContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * @generated from field: repeated geneo.content.db.SampleAnswer sampleAnswers = 2;
   */
  sampleAnswers: SampleAnswer[] = [];

  /**
   * @generated from field: optional geneo.content.db.EvaluationRubrics evaluation_rubrics = 3;
   */
  evaluationRubrics?: EvaluationRubrics;

  /**
   * @generated from field: repeated geneo.content.db.ExternalResourceType context_references = 4;
   */
  contextReferences: ExternalResourceType[] = [];

  constructor(data?: PartialMessage<VeryShortQuesContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.VeryShortQuesContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "sampleAnswers", kind: "message", T: SampleAnswer, repeated: true },
    { no: 3, name: "evaluation_rubrics", kind: "message", T: EvaluationRubrics, opt: true },
    { no: 4, name: "context_references", kind: "message", T: ExternalResourceType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VeryShortQuesContentModel {
    return new VeryShortQuesContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VeryShortQuesContentModel {
    return new VeryShortQuesContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VeryShortQuesContentModel {
    return new VeryShortQuesContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: VeryShortQuesContentModel | PlainMessage<VeryShortQuesContentModel> | undefined, b: VeryShortQuesContentModel | PlainMessage<VeryShortQuesContentModel> | undefined): boolean {
    return proto3.util.equals(VeryShortQuesContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.MTFSingleQuesContentModel
 */
export class MTFSingleQuesContentModel extends Message<MTFSingleQuesContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * ["a", "img_b", "c"]
   *
   * @generated from field: repeated geneo.content.db.OptionsType left_options = 2;
   */
  leftOptions: OptionsType[] = [];

  /**
   * ["x", "img_y", "z"]
   *
   * @generated from field: repeated geneo.content.db.OptionsType right_options = 3;
   */
  rightOptions: OptionsType[] = [];

  /**
   * ["a": ["x", "img_y"], "b": ["img_y"], "c": ["z"]]
   *
   * @generated from field: repeated geneo.content.db.MTFSingleCorrectAnswersInfo correct_response_indices = 4;
   */
  correctResponseIndices: MTFSingleCorrectAnswersInfo[] = [];

  constructor(data?: PartialMessage<MTFSingleQuesContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.MTFSingleQuesContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "left_options", kind: "message", T: OptionsType, repeated: true },
    { no: 3, name: "right_options", kind: "message", T: OptionsType, repeated: true },
    { no: 4, name: "correct_response_indices", kind: "message", T: MTFSingleCorrectAnswersInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MTFSingleQuesContentModel {
    return new MTFSingleQuesContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MTFSingleQuesContentModel {
    return new MTFSingleQuesContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MTFSingleQuesContentModel {
    return new MTFSingleQuesContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: MTFSingleQuesContentModel | PlainMessage<MTFSingleQuesContentModel> | undefined, b: MTFSingleQuesContentModel | PlainMessage<MTFSingleQuesContentModel> | undefined): boolean {
    return proto3.util.equals(MTFSingleQuesContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.MTFMultipleQuesContentModel
 */
export class MTFMultipleQuesContentModel extends Message<MTFMultipleQuesContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * ["a", "img_b", "c"]
   *
   * @generated from field: repeated geneo.content.db.OptionsType left_options = 2;
   */
  leftOptions: OptionsType[] = [];

  /**
   * ["x", "img_y", "z"]
   *
   * @generated from field: repeated geneo.content.db.OptionsType right_options = 3;
   */
  rightOptions: OptionsType[] = [];

  /**
   * ["a": ["x", "img_y"], "b": ["img_y"], "c": ["z"]]
   *
   * @generated from field: repeated geneo.content.db.MTFMultipleCorrectAnswersInfo correct_response_indices = 4;
   */
  correctResponseIndices: MTFMultipleCorrectAnswersInfo[] = [];

  constructor(data?: PartialMessage<MTFMultipleQuesContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.MTFMultipleQuesContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "left_options", kind: "message", T: OptionsType, repeated: true },
    { no: 3, name: "right_options", kind: "message", T: OptionsType, repeated: true },
    { no: 4, name: "correct_response_indices", kind: "message", T: MTFMultipleCorrectAnswersInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MTFMultipleQuesContentModel {
    return new MTFMultipleQuesContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MTFMultipleQuesContentModel {
    return new MTFMultipleQuesContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MTFMultipleQuesContentModel {
    return new MTFMultipleQuesContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: MTFMultipleQuesContentModel | PlainMessage<MTFMultipleQuesContentModel> | undefined, b: MTFMultipleQuesContentModel | PlainMessage<MTFMultipleQuesContentModel> | undefined): boolean {
    return proto3.util.equals(MTFMultipleQuesContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.MTFSingleCorrectAnswersInfo
 */
export class MTFSingleCorrectAnswersInfo extends Message<MTFSingleCorrectAnswersInfo> {
  /**
   * @generated from field: int32 left_option_index = 1;
   */
  leftOptionIndex = 0;

  /**
   * @generated from field: int32 right_option_index = 2;
   */
  rightOptionIndex = 0;

  constructor(data?: PartialMessage<MTFSingleCorrectAnswersInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.MTFSingleCorrectAnswersInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "left_option_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "right_option_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MTFSingleCorrectAnswersInfo {
    return new MTFSingleCorrectAnswersInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MTFSingleCorrectAnswersInfo {
    return new MTFSingleCorrectAnswersInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MTFSingleCorrectAnswersInfo {
    return new MTFSingleCorrectAnswersInfo().fromJsonString(jsonString, options);
  }

  static equals(a: MTFSingleCorrectAnswersInfo | PlainMessage<MTFSingleCorrectAnswersInfo> | undefined, b: MTFSingleCorrectAnswersInfo | PlainMessage<MTFSingleCorrectAnswersInfo> | undefined): boolean {
    return proto3.util.equals(MTFSingleCorrectAnswersInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.MTFMultipleCorrectAnswersInfo
 */
export class MTFMultipleCorrectAnswersInfo extends Message<MTFMultipleCorrectAnswersInfo> {
  /**
   * @generated from field: int32 left_option_index = 1;
   */
  leftOptionIndex = 0;

  /**
   * @generated from field: repeated int32 right_option_indexes = 2;
   */
  rightOptionIndexes: number[] = [];

  constructor(data?: PartialMessage<MTFMultipleCorrectAnswersInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.MTFMultipleCorrectAnswersInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "left_option_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "right_option_indexes", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MTFMultipleCorrectAnswersInfo {
    return new MTFMultipleCorrectAnswersInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MTFMultipleCorrectAnswersInfo {
    return new MTFMultipleCorrectAnswersInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MTFMultipleCorrectAnswersInfo {
    return new MTFMultipleCorrectAnswersInfo().fromJsonString(jsonString, options);
  }

  static equals(a: MTFMultipleCorrectAnswersInfo | PlainMessage<MTFMultipleCorrectAnswersInfo> | undefined, b: MTFMultipleCorrectAnswersInfo | PlainMessage<MTFMultipleCorrectAnswersInfo> | undefined): boolean {
    return proto3.util.equals(MTFMultipleCorrectAnswersInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ArrangeQuestionContentModel
 */
export class ArrangeQuestionContentModel extends Message<ArrangeQuestionContentModel> {
  /**
   * @generated from field: geneo.content.db.QuestionCommonContentModel common_question_content = 1;
   */
  commonQuestionContent?: QuestionCommonContentModel;

  /**
   * ["a", "img_b", "c"]
   *
   * @generated from field: repeated geneo.content.db.OptionsType options = 2;
   */
  options: OptionsType[] = [];

  /**
   * @generated from field: repeated int32 correct_sequence = 3;
   */
  correctSequence: number[] = [];

  constructor(data?: PartialMessage<ArrangeQuestionContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ArrangeQuestionContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "common_question_content", kind: "message", T: QuestionCommonContentModel },
    { no: 2, name: "options", kind: "message", T: OptionsType, repeated: true },
    { no: 3, name: "correct_sequence", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArrangeQuestionContentModel {
    return new ArrangeQuestionContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArrangeQuestionContentModel {
    return new ArrangeQuestionContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArrangeQuestionContentModel {
    return new ArrangeQuestionContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: ArrangeQuestionContentModel | PlainMessage<ArrangeQuestionContentModel> | undefined, b: ArrangeQuestionContentModel | PlainMessage<ArrangeQuestionContentModel> | undefined): boolean {
    return proto3.util.equals(ArrangeQuestionContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.QuestionContentModel
 */
export class QuestionContentModel extends Message<QuestionContentModel> {
  /**
   * @generated from oneof geneo.content.db.QuestionContentModel.model
   */
  model: {
    /**
     * @generated from field: geneo.content.db.FibContentModel fib_content_model = 1;
     */
    value: FibContentModel;
    case: "fibContentModel";
  } | {
    /**
     * @generated from field: geneo.content.db.TfContentModel tf_content_model = 2;
     */
    value: TfContentModel;
    case: "tfContentModel";
  } | {
    /**
     * @generated from field: geneo.content.db.McqSingleContentModel mcq_single_content_model = 3;
     */
    value: McqSingleContentModel;
    case: "mcqSingleContentModel";
  } | {
    /**
     * @generated from field: geneo.content.db.McqMultipleContentModel mcq_multiple_content_model = 4;
     */
    value: McqMultipleContentModel;
    case: "mcqMultipleContentModel";
  } | {
    /**
     * @generated from field: geneo.content.db.VeryShortQuesContentModel very_short_ques_content_model = 6;
     */
    value: VeryShortQuesContentModel;
    case: "veryShortQuesContentModel";
  } | {
    /**
     * @generated from field: geneo.content.db.ShortQuesContentModel short_ques_content_model = 7;
     */
    value: ShortQuesContentModel;
    case: "shortQuesContentModel";
  } | {
    /**
     * @generated from field: geneo.content.db.LongQuesContentModel long_ques_content_model = 5;
     */
    value: LongQuesContentModel;
    case: "longQuesContentModel";
  } | {
    /**
     * @generated from field: geneo.content.db.MTFSingleQuesContentModel mtf_single_ques_content_model = 8;
     */
    value: MTFSingleQuesContentModel;
    case: "mtfSingleQuesContentModel";
  } | {
    /**
     * @generated from field: geneo.content.db.MTFMultipleQuesContentModel mtf_multiple_ques_content_model = 9;
     */
    value: MTFMultipleQuesContentModel;
    case: "mtfMultipleQuesContentModel";
  } | {
    /**
     * @generated from field: geneo.content.db.ArrangeQuestionContentModel arrange_question_content_model = 10;
     */
    value: ArrangeQuestionContentModel;
    case: "arrangeQuestionContentModel";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<QuestionContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.QuestionContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fib_content_model", kind: "message", T: FibContentModel, oneof: "model" },
    { no: 2, name: "tf_content_model", kind: "message", T: TfContentModel, oneof: "model" },
    { no: 3, name: "mcq_single_content_model", kind: "message", T: McqSingleContentModel, oneof: "model" },
    { no: 4, name: "mcq_multiple_content_model", kind: "message", T: McqMultipleContentModel, oneof: "model" },
    { no: 6, name: "very_short_ques_content_model", kind: "message", T: VeryShortQuesContentModel, oneof: "model" },
    { no: 7, name: "short_ques_content_model", kind: "message", T: ShortQuesContentModel, oneof: "model" },
    { no: 5, name: "long_ques_content_model", kind: "message", T: LongQuesContentModel, oneof: "model" },
    { no: 8, name: "mtf_single_ques_content_model", kind: "message", T: MTFSingleQuesContentModel, oneof: "model" },
    { no: 9, name: "mtf_multiple_ques_content_model", kind: "message", T: MTFMultipleQuesContentModel, oneof: "model" },
    { no: 10, name: "arrange_question_content_model", kind: "message", T: ArrangeQuestionContentModel, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionContentModel {
    return new QuestionContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionContentModel {
    return new QuestionContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionContentModel {
    return new QuestionContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionContentModel | PlainMessage<QuestionContentModel> | undefined, b: QuestionContentModel | PlainMessage<QuestionContentModel> | undefined): boolean {
    return proto3.util.equals(QuestionContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.PassageContentModel
 */
export class PassageContentModel extends Message<PassageContentModel> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  constructor(data?: PartialMessage<PassageContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.PassageContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PassageContentModel {
    return new PassageContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PassageContentModel {
    return new PassageContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PassageContentModel {
    return new PassageContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: PassageContentModel | PlainMessage<PassageContentModel> | undefined, b: PassageContentModel | PlainMessage<PassageContentModel> | undefined): boolean {
    return proto3.util.equals(PassageContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.GeneralAllowedElements
 */
export class GeneralAllowedElements extends Message<GeneralAllowedElements> {
  /**
   * @generated from oneof geneo.content.db.GeneralAllowedElements.model
   */
  model: {
    /**
     * Heading element
     *
     * @generated from field: geneo.content.db.HeadingType heading = 1;
     */
    value: HeadingType;
    case: "heading";
  } | {
    /**
     * Subheading element
     *
     * @generated from field: geneo.content.db.SubHeadingType sub_heading = 2;
     */
    value: SubHeadingType;
    case: "subHeading";
  } | {
    /**
     * Text element
     *
     * @generated from field: geneo.content.db.TextType text = 3;
     */
    value: TextType;
    case: "text";
  } | {
    /**
     * Text aligned to the left
     *
     * @generated from field: geneo.content.db.TextLeftType text_left = 4;
     */
    value: TextLeftType;
    case: "textLeft";
  } | {
    /**
     * Text aligned to the center
     *
     * @generated from field: geneo.content.db.TextCenterType text_center = 5;
     */
    value: TextCenterType;
    case: "textCenter";
  } | {
    /**
     * Text aligned to the right
     *
     * @generated from field: geneo.content.db.TextRightType text_right = 6;
     */
    value: TextRightType;
    case: "textRight";
  } | {
    /**
     * Table element
     *
     * @generated from field: geneo.content.db.TableType table = 7;
     */
    value: TableType;
    case: "table";
  } | {
    /**
     * Excerpt element
     *
     * @generated from field: geneo.content.db.ExcerptType excerpt = 8;
     */
    value: ExcerptType;
    case: "excerpt";
  } | {
    /**
     * Image element
     *
     * @generated from field: geneo.content.db.ImageType image = 9;
     */
    value: ImageType;
    case: "image";
  } | {
    /**
     * Video element
     *
     * @generated from field: geneo.content.db.VideoType video = 10;
     */
    value: VideoType;
    case: "video";
  } | {
    /**
     * Audio element
     *
     * @generated from field: geneo.content.db.AudioType audio = 11;
     */
    value: AudioType;
    case: "audio";
  } | {
    /**
     * External resource element
     *
     * @generated from field: geneo.content.db.ExternalResourceType external_resource = 12;
     */
    value: ExternalResourceType;
    case: "externalResource";
  } | {
    /**
     * Note element
     *
     * @generated from field: string note = 13;
     */
    value: string;
    case: "note";
  } | {
    /**
     * Ask instruction element
     *
     * @generated from field: geneo.content.db.AskInstructionModel ask = 14;
     */
    value: AskInstructionModel;
    case: "ask";
  } | {
    /**
     * Say element
     *
     * @generated from field: string say = 15;
     */
    value: string;
    case: "say";
  } | {
    /**
     * Do element
     *
     * @generated from field: string do = 16;
     */
    value: string;
    case: "do";
  } | {
    /**
     * Explain element
     *
     * @generated from field: string explain = 17;
     */
    value: string;
    case: "explain";
  } | {
    /**
     * Discuss element
     *
     * @generated from field: string discuss = 18;
     */
    value: string;
    case: "discuss";
  } | {
    /**
     * Teacher's note element
     *
     * @generated from field: string teacher_note = 19;
     */
    value: string;
    case: "teacherNote";
  } | {
    /**
     * Teacher's video element
     *
     * @generated from field: geneo.content.db.VideoType teacher_video = 20;
     */
    value: VideoType;
    case: "teacherVideo";
  } | {
    /**
     * Teacher's image element
     *
     * @generated from field: geneo.content.db.ImageType teacher_image = 21;
     */
    value: ImageType;
    case: "teacherImage";
  } | {
    /**
     * @generated from field: geneo.content.db.BulletedListType bullet_list = 22;
     */
    value: BulletedListType;
    case: "bulletList";
  } | {
    /**
     * @generated from field: geneo.content.db.NumberedListType number_list = 24;
     */
    value: NumberedListType;
    case: "numberList";
  } | {
    /**
     * @generated from field: geneo.content.db.TextBoxType text_box = 23;
     */
    value: TextBoxType;
    case: "textBox";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GeneralAllowedElements>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.GeneralAllowedElements";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heading", kind: "message", T: HeadingType, oneof: "model" },
    { no: 2, name: "sub_heading", kind: "message", T: SubHeadingType, oneof: "model" },
    { no: 3, name: "text", kind: "message", T: TextType, oneof: "model" },
    { no: 4, name: "text_left", kind: "message", T: TextLeftType, oneof: "model" },
    { no: 5, name: "text_center", kind: "message", T: TextCenterType, oneof: "model" },
    { no: 6, name: "text_right", kind: "message", T: TextRightType, oneof: "model" },
    { no: 7, name: "table", kind: "message", T: TableType, oneof: "model" },
    { no: 8, name: "excerpt", kind: "message", T: ExcerptType, oneof: "model" },
    { no: 9, name: "image", kind: "message", T: ImageType, oneof: "model" },
    { no: 10, name: "video", kind: "message", T: VideoType, oneof: "model" },
    { no: 11, name: "audio", kind: "message", T: AudioType, oneof: "model" },
    { no: 12, name: "external_resource", kind: "message", T: ExternalResourceType, oneof: "model" },
    { no: 13, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "model" },
    { no: 14, name: "ask", kind: "message", T: AskInstructionModel, oneof: "model" },
    { no: 15, name: "say", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "model" },
    { no: 16, name: "do", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "model" },
    { no: 17, name: "explain", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "model" },
    { no: 18, name: "discuss", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "model" },
    { no: 19, name: "teacher_note", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "model" },
    { no: 20, name: "teacher_video", kind: "message", T: VideoType, oneof: "model" },
    { no: 21, name: "teacher_image", kind: "message", T: ImageType, oneof: "model" },
    { no: 22, name: "bullet_list", kind: "message", T: BulletedListType, oneof: "model" },
    { no: 24, name: "number_list", kind: "message", T: NumberedListType, oneof: "model" },
    { no: 23, name: "text_box", kind: "message", T: TextBoxType, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeneralAllowedElements {
    return new GeneralAllowedElements().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeneralAllowedElements {
    return new GeneralAllowedElements().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeneralAllowedElements {
    return new GeneralAllowedElements().fromJsonString(jsonString, options);
  }

  static equals(a: GeneralAllowedElements | PlainMessage<GeneralAllowedElements> | undefined, b: GeneralAllowedElements | PlainMessage<GeneralAllowedElements> | undefined): boolean {
    return proto3.util.equals(GeneralAllowedElements, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TextElementType
 */
export class TextElementType extends Message<TextElementType> {
  /**
   * @generated from oneof geneo.content.db.TextElementType.model
   */
  model: {
    /**
     * @generated from field: geneo.content.db.HeadingType heading = 1;
     */
    value: HeadingType;
    case: "heading";
  } | {
    /**
     * @generated from field: geneo.content.db.SubHeadingType sub_heading = 2;
     */
    value: SubHeadingType;
    case: "subHeading";
  } | {
    /**
     * @generated from field: geneo.content.db.TextType text = 3;
     */
    value: TextType;
    case: "text";
  } | {
    /**
     * @generated from field: geneo.content.db.TextLeftType text_left = 4;
     */
    value: TextLeftType;
    case: "textLeft";
  } | {
    /**
     * @generated from field: geneo.content.db.TextCenterType text_center = 5;
     */
    value: TextCenterType;
    case: "textCenter";
  } | {
    /**
     * @generated from field: geneo.content.db.TextRightType text_right = 6;
     */
    value: TextRightType;
    case: "textRight";
  } | {
    /**
     * @generated from field: geneo.content.db.BulletedListType bullet_list = 7;
     */
    value: BulletedListType;
    case: "bulletList";
  } | {
    /**
     * @generated from field: geneo.content.db.NumberedListType number_list = 9;
     */
    value: NumberedListType;
    case: "numberList";
  } | {
    /**
     * @generated from field: geneo.content.db.TextBoxType text_box = 8;
     */
    value: TextBoxType;
    case: "textBox";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<TextElementType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TextElementType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heading", kind: "message", T: HeadingType, oneof: "model" },
    { no: 2, name: "sub_heading", kind: "message", T: SubHeadingType, oneof: "model" },
    { no: 3, name: "text", kind: "message", T: TextType, oneof: "model" },
    { no: 4, name: "text_left", kind: "message", T: TextLeftType, oneof: "model" },
    { no: 5, name: "text_center", kind: "message", T: TextCenterType, oneof: "model" },
    { no: 6, name: "text_right", kind: "message", T: TextRightType, oneof: "model" },
    { no: 7, name: "bullet_list", kind: "message", T: BulletedListType, oneof: "model" },
    { no: 9, name: "number_list", kind: "message", T: NumberedListType, oneof: "model" },
    { no: 8, name: "text_box", kind: "message", T: TextBoxType, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextElementType {
    return new TextElementType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextElementType {
    return new TextElementType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextElementType {
    return new TextElementType().fromJsonString(jsonString, options);
  }

  static equals(a: TextElementType | PlainMessage<TextElementType> | undefined, b: TextElementType | PlainMessage<TextElementType> | undefined): boolean {
    return proto3.util.equals(TextElementType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExperimentAimInfo
 */
export class ExperimentAimInfo extends Message<ExperimentAimInfo> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  constructor(data?: PartialMessage<ExperimentAimInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExperimentAimInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExperimentAimInfo {
    return new ExperimentAimInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExperimentAimInfo {
    return new ExperimentAimInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExperimentAimInfo {
    return new ExperimentAimInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ExperimentAimInfo | PlainMessage<ExperimentAimInfo> | undefined, b: ExperimentAimInfo | PlainMessage<ExperimentAimInfo> | undefined): boolean {
    return proto3.util.equals(ExperimentAimInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExperimentResourcesRequiredInfo
 */
export class ExperimentResourcesRequiredInfo extends Message<ExperimentResourcesRequiredInfo> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  constructor(data?: PartialMessage<ExperimentResourcesRequiredInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExperimentResourcesRequiredInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExperimentResourcesRequiredInfo {
    return new ExperimentResourcesRequiredInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExperimentResourcesRequiredInfo {
    return new ExperimentResourcesRequiredInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExperimentResourcesRequiredInfo {
    return new ExperimentResourcesRequiredInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ExperimentResourcesRequiredInfo | PlainMessage<ExperimentResourcesRequiredInfo> | undefined, b: ExperimentResourcesRequiredInfo | PlainMessage<ExperimentResourcesRequiredInfo> | undefined): boolean {
    return proto3.util.equals(ExperimentResourcesRequiredInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExperimentProcedureInfo
 */
export class ExperimentProcedureInfo extends Message<ExperimentProcedureInfo> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  constructor(data?: PartialMessage<ExperimentProcedureInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExperimentProcedureInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExperimentProcedureInfo {
    return new ExperimentProcedureInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExperimentProcedureInfo {
    return new ExperimentProcedureInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExperimentProcedureInfo {
    return new ExperimentProcedureInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ExperimentProcedureInfo | PlainMessage<ExperimentProcedureInfo> | undefined, b: ExperimentProcedureInfo | PlainMessage<ExperimentProcedureInfo> | undefined): boolean {
    return proto3.util.equals(ExperimentProcedureInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExperimentCautionInfo
 */
export class ExperimentCautionInfo extends Message<ExperimentCautionInfo> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  constructor(data?: PartialMessage<ExperimentCautionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExperimentCautionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExperimentCautionInfo {
    return new ExperimentCautionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExperimentCautionInfo {
    return new ExperimentCautionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExperimentCautionInfo {
    return new ExperimentCautionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ExperimentCautionInfo | PlainMessage<ExperimentCautionInfo> | undefined, b: ExperimentCautionInfo | PlainMessage<ExperimentCautionInfo> | undefined): boolean {
    return proto3.util.equals(ExperimentCautionInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExperimentObservationsInfo
 */
export class ExperimentObservationsInfo extends Message<ExperimentObservationsInfo> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  constructor(data?: PartialMessage<ExperimentObservationsInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExperimentObservationsInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExperimentObservationsInfo {
    return new ExperimentObservationsInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExperimentObservationsInfo {
    return new ExperimentObservationsInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExperimentObservationsInfo {
    return new ExperimentObservationsInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ExperimentObservationsInfo | PlainMessage<ExperimentObservationsInfo> | undefined, b: ExperimentObservationsInfo | PlainMessage<ExperimentObservationsInfo> | undefined): boolean {
    return proto3.util.equals(ExperimentObservationsInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExperimentConclusionInfo
 */
export class ExperimentConclusionInfo extends Message<ExperimentConclusionInfo> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  constructor(data?: PartialMessage<ExperimentConclusionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExperimentConclusionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExperimentConclusionInfo {
    return new ExperimentConclusionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExperimentConclusionInfo {
    return new ExperimentConclusionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExperimentConclusionInfo {
    return new ExperimentConclusionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ExperimentConclusionInfo | PlainMessage<ExperimentConclusionInfo> | undefined, b: ExperimentConclusionInfo | PlainMessage<ExperimentConclusionInfo> | undefined): boolean {
    return proto3.util.equals(ExperimentConclusionInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExperimentFaqInfo
 */
export class ExperimentFaqInfo extends Message<ExperimentFaqInfo> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  constructor(data?: PartialMessage<ExperimentFaqInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExperimentFaqInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExperimentFaqInfo {
    return new ExperimentFaqInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExperimentFaqInfo {
    return new ExperimentFaqInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExperimentFaqInfo {
    return new ExperimentFaqInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ExperimentFaqInfo | PlainMessage<ExperimentFaqInfo> | undefined, b: ExperimentFaqInfo | PlainMessage<ExperimentFaqInfo> | undefined): boolean {
    return proto3.util.equals(ExperimentFaqInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ExperimentContentModel
 */
export class ExperimentContentModel extends Message<ExperimentContentModel> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: optional geneo.content.db.ExperimentAimInfo aim = 2;
   */
  aim?: ExperimentAimInfo;

  /**
   * @generated from field: optional geneo.content.db.ExperimentResourcesRequiredInfo resources_required = 3;
   */
  resourcesRequired?: ExperimentResourcesRequiredInfo;

  /**
   * @generated from field: optional geneo.content.db.ExperimentProcedureInfo procedure = 4;
   */
  procedure?: ExperimentProcedureInfo;

  /**
   * @generated from field: optional geneo.content.db.ExperimentCautionInfo caution = 5;
   */
  caution?: ExperimentCautionInfo;

  /**
   * @generated from field: optional geneo.content.db.ExperimentObservationsInfo observations = 6;
   */
  observations?: ExperimentObservationsInfo;

  /**
   * @generated from field: optional geneo.content.db.ExperimentConclusionInfo conclusion = 7;
   */
  conclusion?: ExperimentConclusionInfo;

  /**
   * @generated from field: optional geneo.content.db.ExperimentFaqInfo faq = 8;
   */
  faq?: ExperimentFaqInfo;

  constructor(data?: PartialMessage<ExperimentContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ExperimentContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "aim", kind: "message", T: ExperimentAimInfo, opt: true },
    { no: 3, name: "resources_required", kind: "message", T: ExperimentResourcesRequiredInfo, opt: true },
    { no: 4, name: "procedure", kind: "message", T: ExperimentProcedureInfo, opt: true },
    { no: 5, name: "caution", kind: "message", T: ExperimentCautionInfo, opt: true },
    { no: 6, name: "observations", kind: "message", T: ExperimentObservationsInfo, opt: true },
    { no: 7, name: "conclusion", kind: "message", T: ExperimentConclusionInfo, opt: true },
    { no: 8, name: "faq", kind: "message", T: ExperimentFaqInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExperimentContentModel {
    return new ExperimentContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExperimentContentModel {
    return new ExperimentContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExperimentContentModel {
    return new ExperimentContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: ExperimentContentModel | PlainMessage<ExperimentContentModel> | undefined, b: ExperimentContentModel | PlainMessage<ExperimentContentModel> | undefined): boolean {
    return proto3.util.equals(ExperimentContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ContentVideoContentModel
 */
export class ContentVideoContentModel extends Message<ContentVideoContentModel> {
  /**
   * @generated from field: repeated geneo.content.db.ContentElementType elements = 1;
   */
  elements: ContentElementType[] = [];

  /**
   * @generated from field: string primary_video_url = 2;
   */
  primaryVideoUrl = "";

  constructor(data?: PartialMessage<ContentVideoContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ContentVideoContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: ContentElementType, repeated: true },
    { no: 2, name: "primary_video_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentVideoContentModel {
    return new ContentVideoContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentVideoContentModel {
    return new ContentVideoContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentVideoContentModel {
    return new ContentVideoContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: ContentVideoContentModel | PlainMessage<ContentVideoContentModel> | undefined, b: ContentVideoContentModel | PlainMessage<ContentVideoContentModel> | undefined): boolean {
    return proto3.util.equals(ContentVideoContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ContentVideoElementType
 */
export class ContentVideoElementType extends Message<ContentVideoElementType> {
  constructor(data?: PartialMessage<ContentVideoElementType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ContentVideoElementType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentVideoElementType {
    return new ContentVideoElementType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentVideoElementType {
    return new ContentVideoElementType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentVideoElementType {
    return new ContentVideoElementType().fromJsonString(jsonString, options);
  }

  static equals(a: ContentVideoElementType | PlainMessage<ContentVideoElementType> | undefined, b: ContentVideoElementType | PlainMessage<ContentVideoElementType> | undefined): boolean {
    return proto3.util.equals(ContentVideoElementType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.FlashCardContentModel
 */
export class FlashCardContentModel extends Message<FlashCardContentModel> {
  /**
   * @generated from field: repeated geneo.content.db.FlashCardElementType card_front = 1;
   */
  cardFront: FlashCardElementType[] = [];

  /**
   * @generated from field: repeated geneo.content.db.FlashCardElementType card_back = 2;
   */
  cardBack: FlashCardElementType[] = [];

  /**
   * @generated from field: repeated string hint = 3;
   */
  hint: string[] = [];

  constructor(data?: PartialMessage<FlashCardContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.FlashCardContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card_front", kind: "message", T: FlashCardElementType, repeated: true },
    { no: 2, name: "card_back", kind: "message", T: FlashCardElementType, repeated: true },
    { no: 3, name: "hint", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlashCardContentModel {
    return new FlashCardContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlashCardContentModel {
    return new FlashCardContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlashCardContentModel {
    return new FlashCardContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: FlashCardContentModel | PlainMessage<FlashCardContentModel> | undefined, b: FlashCardContentModel | PlainMessage<FlashCardContentModel> | undefined): boolean {
    return proto3.util.equals(FlashCardContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.FlashCardElementType
 */
export class FlashCardElementType extends Message<FlashCardElementType> {
  /**
   * @generated from oneof geneo.content.db.FlashCardElementType.model
   */
  model: {
    /**
     * @generated from field: geneo.content.db.HeadingType heading = 1;
     */
    value: HeadingType;
    case: "heading";
  } | {
    /**
     * @generated from field: geneo.content.db.SubHeadingType sub_heading = 2;
     */
    value: SubHeadingType;
    case: "subHeading";
  } | {
    /**
     * @generated from field: geneo.content.db.TextType text = 3;
     */
    value: TextType;
    case: "text";
  } | {
    /**
     * @generated from field: geneo.content.db.TextLeftType text_left = 4;
     */
    value: TextLeftType;
    case: "textLeft";
  } | {
    /**
     * @generated from field: geneo.content.db.TextCenterType text_center = 5;
     */
    value: TextCenterType;
    case: "textCenter";
  } | {
    /**
     * @generated from field: geneo.content.db.TextRightType text_right = 6;
     */
    value: TextRightType;
    case: "textRight";
  } | {
    /**
     * @generated from field: geneo.content.db.TableType table = 7;
     */
    value: TableType;
    case: "table";
  } | {
    /**
     * @generated from field: geneo.content.db.ExcerptType excerpt = 8;
     */
    value: ExcerptType;
    case: "excerpt";
  } | {
    /**
     * @generated from field: geneo.content.db.ImageType image = 9;
     */
    value: ImageType;
    case: "image";
  } | {
    /**
     * @generated from field: geneo.content.db.VideoType video = 10;
     */
    value: VideoType;
    case: "video";
  } | {
    /**
     * @generated from field: geneo.content.db.AudioType audio = 11;
     */
    value: AudioType;
    case: "audio";
  } | {
    /**
     * @generated from field: geneo.content.db.ExternalResourceType external_resource = 12;
     */
    value: ExternalResourceType;
    case: "externalResource";
  } | {
    /**
     * @generated from field: geneo.content.db.ComprehensionPassage comprehension_passage = 13;
     */
    value: ComprehensionPassage;
    case: "comprehensionPassage";
  } | {
    /**
     * @generated from field: geneo.content.db.BulletedListType bullet_list = 14;
     */
    value: BulletedListType;
    case: "bulletList";
  } | {
    /**
     * @generated from field: geneo.content.db.NumberedListType number_list = 16;
     */
    value: NumberedListType;
    case: "numberList";
  } | {
    /**
     * @generated from field: geneo.content.db.TextBoxType text_box = 15;
     */
    value: TextBoxType;
    case: "textBox";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<FlashCardElementType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.FlashCardElementType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heading", kind: "message", T: HeadingType, oneof: "model" },
    { no: 2, name: "sub_heading", kind: "message", T: SubHeadingType, oneof: "model" },
    { no: 3, name: "text", kind: "message", T: TextType, oneof: "model" },
    { no: 4, name: "text_left", kind: "message", T: TextLeftType, oneof: "model" },
    { no: 5, name: "text_center", kind: "message", T: TextCenterType, oneof: "model" },
    { no: 6, name: "text_right", kind: "message", T: TextRightType, oneof: "model" },
    { no: 7, name: "table", kind: "message", T: TableType, oneof: "model" },
    { no: 8, name: "excerpt", kind: "message", T: ExcerptType, oneof: "model" },
    { no: 9, name: "image", kind: "message", T: ImageType, oneof: "model" },
    { no: 10, name: "video", kind: "message", T: VideoType, oneof: "model" },
    { no: 11, name: "audio", kind: "message", T: AudioType, oneof: "model" },
    { no: 12, name: "external_resource", kind: "message", T: ExternalResourceType, oneof: "model" },
    { no: 13, name: "comprehension_passage", kind: "message", T: ComprehensionPassage, oneof: "model" },
    { no: 14, name: "bullet_list", kind: "message", T: BulletedListType, oneof: "model" },
    { no: 16, name: "number_list", kind: "message", T: NumberedListType, oneof: "model" },
    { no: 15, name: "text_box", kind: "message", T: TextBoxType, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlashCardElementType {
    return new FlashCardElementType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlashCardElementType {
    return new FlashCardElementType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlashCardElementType {
    return new FlashCardElementType().fromJsonString(jsonString, options);
  }

  static equals(a: FlashCardElementType | PlainMessage<FlashCardElementType> | undefined, b: FlashCardElementType | PlainMessage<FlashCardElementType> | undefined): boolean {
    return proto3.util.equals(FlashCardElementType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.MindMapContentModel
 */
export class MindMapContentModel extends Message<MindMapContentModel> {
  /**
   * @generated from field: repeated geneo.content.db.MindMapNodeInfo mind_map = 1;
   */
  mindMap: MindMapNodeInfo[] = [];

  constructor(data?: PartialMessage<MindMapContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.MindMapContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mind_map", kind: "message", T: MindMapNodeInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MindMapContentModel {
    return new MindMapContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MindMapContentModel {
    return new MindMapContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MindMapContentModel {
    return new MindMapContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: MindMapContentModel | PlainMessage<MindMapContentModel> | undefined, b: MindMapContentModel | PlainMessage<MindMapContentModel> | undefined): boolean {
    return proto3.util.equals(MindMapContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.MindMapNodeInfo
 */
export class MindMapNodeInfo extends Message<MindMapNodeInfo> {
  /**
   * @generated from field: optional string node_id = 1;
   */
  nodeId?: string;

  /**
   * @generated from field: repeated geneo.content.db.ContentElementType contents = 2;
   */
  contents: ContentElementType[] = [];

  /**
   * @generated from field: repeated geneo.content.db.MindMapNodeInfo children = 3;
   */
  children: MindMapNodeInfo[] = [];

  constructor(data?: PartialMessage<MindMapNodeInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.MindMapNodeInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "node_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "contents", kind: "message", T: ContentElementType, repeated: true },
    { no: 3, name: "children", kind: "message", T: MindMapNodeInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MindMapNodeInfo {
    return new MindMapNodeInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MindMapNodeInfo {
    return new MindMapNodeInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MindMapNodeInfo {
    return new MindMapNodeInfo().fromJsonString(jsonString, options);
  }

  static equals(a: MindMapNodeInfo | PlainMessage<MindMapNodeInfo> | undefined, b: MindMapNodeInfo | PlainMessage<MindMapNodeInfo> | undefined): boolean {
    return proto3.util.equals(MindMapNodeInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.AskInstructionModel
 */
export class AskInstructionModel extends Message<AskInstructionModel> {
  /**
   * @generated from field: string question = 1;
   */
  question = "";

  /**
   * @generated from field: string answer = 2;
   */
  answer = "";

  constructor(data?: PartialMessage<AskInstructionModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.AskInstructionModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AskInstructionModel {
    return new AskInstructionModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AskInstructionModel {
    return new AskInstructionModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AskInstructionModel {
    return new AskInstructionModel().fromJsonString(jsonString, options);
  }

  static equals(a: AskInstructionModel | PlainMessage<AskInstructionModel> | undefined, b: AskInstructionModel | PlainMessage<AskInstructionModel> | undefined): boolean {
    return proto3.util.equals(AskInstructionModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.InstructionElementType
 */
export class InstructionElementType extends Message<InstructionElementType> {
  /**
   * @generated from oneof geneo.content.db.InstructionElementType.instruction
   */
  instruction: {
    /**
     * Heading element
     *
     * @generated from field: geneo.content.db.HeadingType heading = 1;
     */
    value: HeadingType;
    case: "heading";
  } | {
    /**
     * Subheading element
     *
     * @generated from field: geneo.content.db.SubHeadingType sub_heading = 2;
     */
    value: SubHeadingType;
    case: "subHeading";
  } | {
    /**
     * Text element
     *
     * @generated from field: geneo.content.db.TextType text = 3;
     */
    value: TextType;
    case: "text";
  } | {
    /**
     * Text aligned to the left
     *
     * @generated from field: geneo.content.db.TextLeftType text_left = 4;
     */
    value: TextLeftType;
    case: "textLeft";
  } | {
    /**
     * Text aligned to the center
     *
     * @generated from field: geneo.content.db.TextCenterType text_center = 5;
     */
    value: TextCenterType;
    case: "textCenter";
  } | {
    /**
     * Text aligned to the right
     *
     * @generated from field: geneo.content.db.TextRightType text_right = 6;
     */
    value: TextRightType;
    case: "textRight";
  } | {
    /**
     * Table element
     *
     * @generated from field: geneo.content.db.TableType table = 7;
     */
    value: TableType;
    case: "table";
  } | {
    /**
     * Bulleted list element
     *
     * @generated from field: geneo.content.db.BulletedListType bullet_list = 8;
     */
    value: BulletedListType;
    case: "bulletList";
  } | {
    /**
     * Numbered list element
     *
     * @generated from field: geneo.content.db.NumberedListType number_list = 9;
     */
    value: NumberedListType;
    case: "numberList";
  } | {
    /**
     * Text box element
     *
     * @generated from field: geneo.content.db.TextBoxType text_box = 10;
     */
    value: TextBoxType;
    case: "textBox";
  } | {
    /**
     * Note element
     *
     * @generated from field: string note = 12;
     */
    value: string;
    case: "note";
  } | {
    /**
     * Ask instruction element
     *
     * @generated from field: geneo.content.db.AskInstructionModel ask = 13;
     */
    value: AskInstructionModel;
    case: "ask";
  } | {
    /**
     * @generated from field: string ask_question = 26;
     */
    value: string;
    case: "askQuestion";
  } | {
    /**
     * @generated from field: string ask_answer = 27;
     */
    value: string;
    case: "askAnswer";
  } | {
    /**
     * Say element
     *
     * @generated from field: string say = 14;
     */
    value: string;
    case: "say";
  } | {
    /**
     * Do element
     *
     * @generated from field: string do = 15;
     */
    value: string;
    case: "do";
  } | {
    /**
     * Explain element
     *
     * @generated from field: string explain = 16;
     */
    value: string;
    case: "explain";
  } | {
    /**
     * Discuss element
     *
     * @generated from field: string discuss = 17;
     */
    value: string;
    case: "discuss";
  } | {
    /**
     * Teacher note element
     *
     * @generated from field: string teacher_note = 18;
     */
    value: string;
    case: "teacherNote";
  } | {
    /**
     * Teacher video element
     *
     * @generated from field: geneo.content.db.VideoType teacher_video = 19;
     */
    value: VideoType;
    case: "teacherVideo";
  } | {
    /**
     * Teacher image element
     *
     * @generated from field: geneo.content.db.ImageType teacher_image = 20;
     */
    value: ImageType;
    case: "teacherImage";
  } | {
    /**
     * Excerpt element
     *
     * @generated from field: geneo.content.db.ExcerptType excerpt = 21;
     */
    value: ExcerptType;
    case: "excerpt";
  } | {
    /**
     * Video element
     *
     * @generated from field: geneo.content.db.VideoType video = 22;
     */
    value: VideoType;
    case: "video";
  } | {
    /**
     * Image element
     *
     * @generated from field: geneo.content.db.ImageType image = 23;
     */
    value: ImageType;
    case: "image";
  } | {
    /**
     * Audio element
     *
     * @generated from field: geneo.content.db.AudioType audio = 24;
     */
    value: AudioType;
    case: "audio";
  } | {
    /**
     * External resource element
     *
     * @generated from field: geneo.content.db.ExternalResourceType external_resource = 25;
     */
    value: ExternalResourceType;
    case: "externalResource";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<InstructionElementType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.InstructionElementType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heading", kind: "message", T: HeadingType, oneof: "instruction" },
    { no: 2, name: "sub_heading", kind: "message", T: SubHeadingType, oneof: "instruction" },
    { no: 3, name: "text", kind: "message", T: TextType, oneof: "instruction" },
    { no: 4, name: "text_left", kind: "message", T: TextLeftType, oneof: "instruction" },
    { no: 5, name: "text_center", kind: "message", T: TextCenterType, oneof: "instruction" },
    { no: 6, name: "text_right", kind: "message", T: TextRightType, oneof: "instruction" },
    { no: 7, name: "table", kind: "message", T: TableType, oneof: "instruction" },
    { no: 8, name: "bullet_list", kind: "message", T: BulletedListType, oneof: "instruction" },
    { no: 9, name: "number_list", kind: "message", T: NumberedListType, oneof: "instruction" },
    { no: 10, name: "text_box", kind: "message", T: TextBoxType, oneof: "instruction" },
    { no: 12, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "instruction" },
    { no: 13, name: "ask", kind: "message", T: AskInstructionModel, oneof: "instruction" },
    { no: 26, name: "ask_question", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "instruction" },
    { no: 27, name: "ask_answer", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "instruction" },
    { no: 14, name: "say", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "instruction" },
    { no: 15, name: "do", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "instruction" },
    { no: 16, name: "explain", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "instruction" },
    { no: 17, name: "discuss", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "instruction" },
    { no: 18, name: "teacher_note", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "instruction" },
    { no: 19, name: "teacher_video", kind: "message", T: VideoType, oneof: "instruction" },
    { no: 20, name: "teacher_image", kind: "message", T: ImageType, oneof: "instruction" },
    { no: 21, name: "excerpt", kind: "message", T: ExcerptType, oneof: "instruction" },
    { no: 22, name: "video", kind: "message", T: VideoType, oneof: "instruction" },
    { no: 23, name: "image", kind: "message", T: ImageType, oneof: "instruction" },
    { no: 24, name: "audio", kind: "message", T: AudioType, oneof: "instruction" },
    { no: 25, name: "external_resource", kind: "message", T: ExternalResourceType, oneof: "instruction" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstructionElementType {
    return new InstructionElementType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstructionElementType {
    return new InstructionElementType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstructionElementType {
    return new InstructionElementType().fromJsonString(jsonString, options);
  }

  static equals(a: InstructionElementType | PlainMessage<InstructionElementType> | undefined, b: InstructionElementType | PlainMessage<InstructionElementType> | undefined): boolean {
    return proto3.util.equals(InstructionElementType, a, b);
  }
}

/**
 * @generated from message geneo.content.db.InstructionModel
 */
export class InstructionModel extends Message<InstructionModel> {
  /**
   * @generated from field: repeated geneo.content.db.InstructionElementType elements = 1;
   */
  elements: InstructionElementType[] = [];

  constructor(data?: PartialMessage<InstructionModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.InstructionModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "elements", kind: "message", T: InstructionElementType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstructionModel {
    return new InstructionModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstructionModel {
    return new InstructionModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstructionModel {
    return new InstructionModel().fromJsonString(jsonString, options);
  }

  static equals(a: InstructionModel | PlainMessage<InstructionModel> | undefined, b: InstructionModel | PlainMessage<InstructionModel> | undefined): boolean {
    return proto3.util.equals(InstructionModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ContentModel
 */
export class ContentModel extends Message<ContentModel> {
  /**
   * @generated from oneof geneo.content.db.ContentModel.model
   */
  model: {
    /**
     * @generated from field: geneo.content.db.PassageContentModel passage = 1;
     */
    value: PassageContentModel;
    case: "passage";
  } | {
    /**
     * @generated from field: geneo.content.db.ExperimentContentModel experiment = 2;
     */
    value: ExperimentContentModel;
    case: "experiment";
  } | {
    /**
     * @generated from field: geneo.content.db.ContentVideoContentModel content_video = 3;
     */
    value: ContentVideoContentModel;
    case: "contentVideo";
  } | {
    /**
     * @generated from field: geneo.content.db.FlashCardContentModel flash_card = 4;
     */
    value: FlashCardContentModel;
    case: "flashCard";
  } | {
    /**
     * @generated from field: geneo.content.db.MindMapContentModel mind_map = 5;
     */
    value: MindMapContentModel;
    case: "mindMap";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "passage", kind: "message", T: PassageContentModel, oneof: "model" },
    { no: 2, name: "experiment", kind: "message", T: ExperimentContentModel, oneof: "model" },
    { no: 3, name: "content_video", kind: "message", T: ContentVideoContentModel, oneof: "model" },
    { no: 4, name: "flash_card", kind: "message", T: FlashCardContentModel, oneof: "model" },
    { no: 5, name: "mind_map", kind: "message", T: MindMapContentModel, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentModel {
    return new ContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentModel {
    return new ContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentModel {
    return new ContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: ContentModel | PlainMessage<ContentModel> | undefined, b: ContentModel | PlainMessage<ContentModel> | undefined): boolean {
    return proto3.util.equals(ContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ImplementedContentModel
 */
export class ImplementedContentModel extends Message<ImplementedContentModel> {
  /**
   * @generated from oneof geneo.content.db.ImplementedContentModel.model
   */
  model: {
    /**
     * @generated from field: geneo.content.db.Content content = 1;
     */
    value: Content;
    case: "content";
  } | {
    /**
     * @generated from field: geneo.content.db.Question question = 2;
     */
    value: Question;
    case: "question";
  } | {
    /**
     * @generated from field: geneo.content.db.ResourceTeacherInstruction instruction = 3;
     */
    value: ResourceTeacherInstruction;
    case: "instruction";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ImplementedContentModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ImplementedContentModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: Content, oneof: "model" },
    { no: 2, name: "question", kind: "message", T: Question, oneof: "model" },
    { no: 3, name: "instruction", kind: "message", T: ResourceTeacherInstruction, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImplementedContentModel {
    return new ImplementedContentModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImplementedContentModel {
    return new ImplementedContentModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImplementedContentModel {
    return new ImplementedContentModel().fromJsonString(jsonString, options);
  }

  static equals(a: ImplementedContentModel | PlainMessage<ImplementedContentModel> | undefined, b: ImplementedContentModel | PlainMessage<ImplementedContentModel> | undefined): boolean {
    return proto3.util.equals(ImplementedContentModel, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ResourceContentModelType
 */
export class ResourceContentModelType extends Message<ResourceContentModelType> {
  /**
   * @generated from oneof geneo.content.db.ResourceContentModelType.model
   */
  model: {
    /**
     * @generated from field: geneo.content.db.Content content = 1;
     */
    value: Content;
    case: "content";
  } | {
    /**
     * @generated from field: geneo.content.db.Question question = 2;
     */
    value: Question;
    case: "question";
  } | {
    /**
     * @generated from field: geneo.content.db.ResourceTeacherInstruction instruction = 3;
     */
    value: ResourceTeacherInstruction;
    case: "instruction";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ResourceContentModelType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ResourceContentModelType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: Content, oneof: "model" },
    { no: 2, name: "question", kind: "message", T: Question, oneof: "model" },
    { no: 3, name: "instruction", kind: "message", T: ResourceTeacherInstruction, oneof: "model" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContentModelType {
    return new ResourceContentModelType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContentModelType {
    return new ResourceContentModelType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContentModelType {
    return new ResourceContentModelType().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContentModelType | PlainMessage<ResourceContentModelType> | undefined, b: ResourceContentModelType | PlainMessage<ResourceContentModelType> | undefined): boolean {
    return proto3.util.equals(ResourceContentModelType, a, b);
  }
}

/**
 * Table structure for content_management.content
 *
 * @generated from message geneo.content.db.Content
 */
export class Content extends Message<Content> {
  /**
   * Primary key for content
   *
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * Referencing key for content
   *
   * @generated from field: optional string content_id = 2;
   */
  contentId?: string;

  /**
   * Title of the content
   *
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * Description of the content (optional)
   *
   * @generated from field: optional string description = 4;
   */
  description?: string;

  /**
   * URL of the poster image (optional)
   *
   * @generated from field: optional string poster_image_url = 5;
   */
  posterImageUrl?: string;

  /**
   * Type of content
   *
   * @generated from field: geneo.content.db.Content.ContentEnum content_type = 6;
   */
  contentType = Content_ContentEnum.CONTENT_TYPE_UNDEFINED;

  /**
   * JSONB content
   *
   * @generated from field: geneo.content.db.ContentModel content = 7;
   */
  content?: ContentModel;

  /**
   * Complete content in a single text string for search engine
   *
   * @generated from field: string content_text = 8;
   */
  contentText = "";

  /**
   * Array of learning outcome
   *
   * @generated from field: repeated string learning_outcomes_ids = 9;
   */
  learningOutcomesIds: string[] = [];

  /**
   * Foreign key referencing teacher_profile.teacher_id
   *
   * @generated from field: optional int64 teacher_id = 10;
   */
  teacherId?: bigint;

  /**
   * Content meta key for saving meta info
   *
   * @generated from field: geneo.content.db.ContentMetaInfo content_meta = 11;
   */
  contentMeta?: ContentMetaInfo;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 12;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: int64 created_by = 13;
   */
  createdBy = protoInt64.zero;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 14;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: int64 modified_by = 15;
   */
  modifiedBy = protoInt64.zero;

  /**
   * Exists only for content-video model for solution as independent content-video for specific question_id
   *
   * @generated from field: optional string video_solution_of_question_Id = 16;
   */
  videoSolutionOfQuestionId?: string;

  /**
   * @generated from field: optional string nano_id = 17;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 18;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 19;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<Content>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.Content";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "content_type", kind: "enum", T: proto3.getEnumType(Content_ContentEnum) },
    { no: 7, name: "content", kind: "message", T: ContentModel },
    { no: 8, name: "content_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "learning_outcomes_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "content_meta", kind: "message", T: ContentMetaInfo },
    { no: 12, name: "created_on", kind: "message", T: Timestamp },
    { no: 13, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "modified_on", kind: "message", T: Timestamp },
    { no: 15, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 16, name: "video_solution_of_question_Id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 19, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Content {
    return new Content().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Content {
    return new Content().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Content {
    return new Content().fromJsonString(jsonString, options);
  }

  static equals(a: Content | PlainMessage<Content> | undefined, b: Content | PlainMessage<Content> | undefined): boolean {
    return proto3.util.equals(Content, a, b);
  }
}

/**
 * Enum for ContentEnum
 *
 * @generated from enum geneo.content.db.Content.ContentEnum
 */
export enum Content_ContentEnum {
  /**
   * Represents undefined content type.
   *
   * @generated from enum value: CONTENT_TYPE_UNDEFINED = 0;
   */
  CONTENT_TYPE_UNDEFINED = 0,

  /**
   * Represents passage content type.
   *
   * @generated from enum value: CONTENT_TYPE_PASSAGE = 1;
   */
  CONTENT_TYPE_PASSAGE = 1,

  /**
   * Represents experiment content type.
   *
   * @generated from enum value: CONTENT_TYPE_EXPERIMENT = 2;
   */
  CONTENT_TYPE_EXPERIMENT = 2,

  /**
   * Represents content video content type.
   *
   * @generated from enum value: CONTENT_TYPE_CONTENT_VIDEO = 3;
   */
  CONTENT_TYPE_CONTENT_VIDEO = 3,

  /**
   * Represents flashcard content type.
   *
   * @generated from enum value: CONTENT_TYPE_FLASHCARD = 4;
   */
  CONTENT_TYPE_FLASHCARD = 4,

  /**
   * Represents mindmap content type.
   *
   * @generated from enum value: CONTENT_TYPE_MINDMAP = 5;
   */
  CONTENT_TYPE_MINDMAP = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Content_ContentEnum)
proto3.util.setEnumType(Content_ContentEnum, "geneo.content.db.Content.ContentEnum", [
  { no: 0, name: "CONTENT_TYPE_UNDEFINED" },
  { no: 1, name: "CONTENT_TYPE_PASSAGE" },
  { no: 2, name: "CONTENT_TYPE_EXPERIMENT" },
  { no: 3, name: "CONTENT_TYPE_CONTENT_VIDEO" },
  { no: 4, name: "CONTENT_TYPE_FLASHCARD" },
  { no: 5, name: "CONTENT_TYPE_MINDMAP" },
]);

/**
 * Table structure for content_management.question
 *
 * @generated from message geneo.content.db.Question
 */
export class Question extends Message<Question> {
  /**
   * Primary key for content
   *
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * Referencing key for question
   *
   * @generated from field: optional string question_id = 2;
   */
  questionId?: string;

  /**
   * Title of the question
   *
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * Description of the question (optional)
   *
   * @generated from field: optional string description = 4;
   */
  description?: string;

  /**
   * URL of the poster image (optional)
   *
   * @generated from field: optional string poster_image_url = 5;
   */
  posterImageUrl?: string;

  /**
   * Type of question
   *
   * @generated from field: geneo.content.db.Question.QuestionEnum question_type = 6;
   */
  questionType = Question_QuestionEnum.QUESTION_TYPE_UNDEFINED;

  /**
   * JSONB question
   *
   * @generated from field: geneo.content.db.QuestionContentModel question = 7;
   */
  question?: QuestionContentModel;

  /**
   * Complete question in a single text string for search engine
   *
   * @generated from field: string question_text = 8;
   */
  questionText = "";

  /**
   * Array of learning outcome Referencing key
   *
   * @generated from field: repeated string learning_outcomes_ids = 9;
   */
  learningOutcomesIds: string[] = [];

  /**
   * Foreign key referencing teacher_profile.teacher_id
   *
   * @generated from field: optional int64 teacher_id = 10;
   */
  teacherId?: bigint;

  /**
   * Content meta key for saving meta info
   *
   * @generated from field: geneo.content.db.QuestionMetaInfo question_meta = 11;
   */
  questionMeta?: QuestionMetaInfo;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 12;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: int64 created_by = 13;
   */
  createdBy = protoInt64.zero;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 14;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: int64 modified_by = 15;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: optional string nano_id = 16;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 17;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 18;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<Question>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.Question";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "question_type", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum) },
    { no: 7, name: "question", kind: "message", T: QuestionContentModel },
    { no: 8, name: "question_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "learning_outcomes_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "question_meta", kind: "message", T: QuestionMetaInfo },
    { no: 12, name: "created_on", kind: "message", T: Timestamp },
    { no: 13, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "modified_on", kind: "message", T: Timestamp },
    { no: 15, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 16, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 18, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Question {
    return new Question().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Question {
    return new Question().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Question {
    return new Question().fromJsonString(jsonString, options);
  }

  static equals(a: Question | PlainMessage<Question> | undefined, b: Question | PlainMessage<Question> | undefined): boolean {
    return proto3.util.equals(Question, a, b);
  }
}

/**
 * Enum for QuestionEnum
 *
 * @generated from enum geneo.content.db.Question.QuestionEnum
 */
export enum Question_QuestionEnum {
  /**
   * Represents undefined question type.
   *
   * @generated from enum value: QUESTION_TYPE_UNDEFINED = 0;
   */
  QUESTION_TYPE_UNDEFINED = 0,

  /**
   * Represents fill in the blanks question type.
   *
   * @generated from enum value: QUESTION_TYPE_FIB = 1;
   */
  QUESTION_TYPE_FIB = 1,

  /**
   * Represents match the following question type.
   *
   * @generated from enum value: QUESTION_TYPE_MTF = 2;
   */
  QUESTION_TYPE_MTF = 2,

  /**
   * Represents multiple-choice single answer question type.
   *
   * @generated from enum value: QUESTION_TYPE_MCQS = 3;
   */
  QUESTION_TYPE_MCQS = 3,

  /**
   * Represents multiple-choice multiple answers question type.
   *
   * @generated from enum value: QUESTION_TYPE_MCQM = 4;
   */
  QUESTION_TYPE_MCQM = 4,

  /**
   * Represents true/false question type.
   *
   * @generated from enum value: QUESTION_TYPE_TF = 5;
   */
  QUESTION_TYPE_TF = 5,

  /**
   * Represents short answer question type.
   *
   * @generated from enum value: QUESTION_TYPE_VERY_SHORT = 6;
   */
  QUESTION_TYPE_VERY_SHORT = 6,

  /**
   * Represents short answer question type.
   *
   * @generated from enum value: QUESTION_TYPE_SHORT = 7;
   */
  QUESTION_TYPE_SHORT = 7,

  /**
   * Represents long answer question type.
   *
   * @generated from enum value: QUESTION_TYPE_LONG = 8;
   */
  QUESTION_TYPE_LONG = 8,

  /**
   * Represents arrange in order question type.
   *
   * @generated from enum value: QUESTION_TYPE_ARRANGE = 9;
   */
  QUESTION_TYPE_ARRANGE = 9,

  /**
   * Represents label question type.
   *
   * @generated from enum value: QUESTION_TYPE_LABEL = 10;
   */
  QUESTION_TYPE_LABEL = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(Question_QuestionEnum)
proto3.util.setEnumType(Question_QuestionEnum, "geneo.content.db.Question.QuestionEnum", [
  { no: 0, name: "QUESTION_TYPE_UNDEFINED" },
  { no: 1, name: "QUESTION_TYPE_FIB" },
  { no: 2, name: "QUESTION_TYPE_MTF" },
  { no: 3, name: "QUESTION_TYPE_MCQS" },
  { no: 4, name: "QUESTION_TYPE_MCQM" },
  { no: 5, name: "QUESTION_TYPE_TF" },
  { no: 6, name: "QUESTION_TYPE_VERY_SHORT" },
  { no: 7, name: "QUESTION_TYPE_SHORT" },
  { no: 8, name: "QUESTION_TYPE_LONG" },
  { no: 9, name: "QUESTION_TYPE_ARRANGE" },
  { no: 10, name: "QUESTION_TYPE_LABEL" },
]);

/**
 * Table structure for content_management.module
 *
 * @generated from message geneo.content.db.Module
 */
export class Module extends Message<Module> {
  /**
   * Primary key for module
   *
   * @generated from field: optional int32 module_id = 1;
   */
  moduleId?: number;

  /**
   * Name of the module
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * Short description of the module
   *
   * @generated from field: string short_description = 4;
   */
  shortDescription = "";

  /**
   * Long description of the module
   *
   * @generated from field: string long_description = 5;
   */
  longDescription = "";

  /**
   * Foreign key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 parent_module_id = 6;
   */
  parentModuleId?: number;

  /**
   * Foreign key referencing common.book.book_id
   *
   * @generated from field: optional int32 book_id = 7;
   */
  bookId?: number;

  /**
   * Category of the module
   *
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 8;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * Level of the module
   *
   * @generated from field: int32 level = 9;
   */
  level = 0;

  /**
   * @generated from field: int32 rank = 10;
   */
  rank = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 11;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 12;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 13;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 14;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 15;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 16;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<Module>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.Module";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "long_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "parent_module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 9, name: "level", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "created_on", kind: "message", T: Timestamp },
    { no: 12, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 15, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 16, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Module {
    return new Module().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Module {
    return new Module().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Module {
    return new Module().fromJsonString(jsonString, options);
  }

  static equals(a: Module | PlainMessage<Module> | undefined, b: Module | PlainMessage<Module> | undefined): boolean {
    return proto3.util.equals(Module, a, b);
  }
}

/**
 * Enum for ModuleCategoryEnum
 *
 * @generated from enum geneo.content.db.Module.ModuleCategoryEnum
 */
export enum Module_ModuleCategoryEnum {
  /**
   * Represents undefined module category.
   *
   * @generated from enum value: MODULE_CATEGORY_UNDEFINED = 0;
   */
  MODULE_CATEGORY_UNDEFINED = 0,

  /**
   * Represents book module category.
   *
   * @generated from enum value: MODULE_CATEGORY_BOOK = 1;
   */
  MODULE_CATEGORY_BOOK = 1,

  /**
   * Represents chapter module category.
   *
   * @generated from enum value: MODULE_CATEGORY_CHAPTER = 2;
   */
  MODULE_CATEGORY_CHAPTER = 2,

  /**
   * Represents topic module category.
   *
   * @generated from enum value: MODULE_CATEGORY_TOPIC = 3;
   */
  MODULE_CATEGORY_TOPIC = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Module_ModuleCategoryEnum)
proto3.util.setEnumType(Module_ModuleCategoryEnum, "geneo.content.db.Module.ModuleCategoryEnum", [
  { no: 0, name: "MODULE_CATEGORY_UNDEFINED" },
  { no: 1, name: "MODULE_CATEGORY_BOOK" },
  { no: 2, name: "MODULE_CATEGORY_CHAPTER" },
  { no: 3, name: "MODULE_CATEGORY_TOPIC" },
]);

/**
 * @generated from message geneo.content.db.PDFResource
 */
export class PDFResource extends Message<PDFResource> {
  /**
   * @generated from field: string pdf_url = 1;
   */
  pdfUrl = "";

  /**
   * @generated from field: int32 no_of_pages = 2;
   */
  noOfPages = 0;

  /**
   * @generated from field: optional int32 start_page = 3;
   */
  startPage?: number;

  /**
   * @generated from field: optional int32 end_page = 4;
   */
  endPage?: number;

  constructor(data?: PartialMessage<PDFResource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.PDFResource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pdf_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "no_of_pages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "start_page", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "end_page", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PDFResource {
    return new PDFResource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PDFResource {
    return new PDFResource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PDFResource {
    return new PDFResource().fromJsonString(jsonString, options);
  }

  static equals(a: PDFResource | PlainMessage<PDFResource> | undefined, b: PDFResource | PlainMessage<PDFResource> | undefined): boolean {
    return proto3.util.equals(PDFResource, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ModuleBookContent
 */
export class ModuleBookContent extends Message<ModuleBookContent> {
  /**
   * Primary key for bppk-module
   *
   * @generated from field: optional int32 book_module_id = 1;
   */
  bookModuleId?: number;

  /**
   * Foreign key for module
   *
   * @generated from field: optional int32 module_id = 2;
   */
  moduleId?: number;

  /**
   * Foreign key referencing common.book.book_id
   *
   * @generated from field: optional int32 book_id = 3;
   */
  bookId?: number;

  /**
   * @generated from field: optional int32 child_count = 4;
   */
  childCount?: number;

  /**
   * URL of the book's PDF
   *
   * @generated from field: geneo.content.db.PDFResource pdf = 5;
   */
  pdf?: PDFResource;

  /**
   * @generated from field: repeated string html_content = 6;
   */
  htmlContent: string[] = [];

  /**
   * @generated from field: repeated string image_page_urls = 7;
   */
  imagePageUrls: string[] = [];

  /**
   * URL of the book's cover image
   *
   * @generated from field: string cover_image_url = 8;
   */
  coverImageUrl = "";

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 9;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 10;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<ModuleBookContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ModuleBookContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book_module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "child_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "pdf", kind: "message", T: PDFResource },
    { no: 6, name: "html_content", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "image_page_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "cover_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleBookContent {
    return new ModuleBookContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleBookContent {
    return new ModuleBookContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleBookContent {
    return new ModuleBookContent().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleBookContent | PlainMessage<ModuleBookContent> | undefined, b: ModuleBookContent | PlainMessage<ModuleBookContent> | undefined): boolean {
    return proto3.util.equals(ModuleBookContent, a, b);
  }
}

/**
 * Table structure for content_management.learning_outcome
 *
 * @generated from message geneo.content.db.LearningOutcome
 */
export class LearningOutcome extends Message<LearningOutcome> {
  /**
   * Primary key for learning outcome
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Referencing key for learning outcome
   *
   * @generated from field: optional string learning_outcome_id = 2;
   */
  learningOutcomeId?: string;

  /**
   * Name of the learning outcome
   *
   * @generated from field: string learning_outcome = 3;
   */
  learningOutcome = "";

  /**
   * Short description of the learning outcome
   *
   * @generated from field: string short_description = 4;
   */
  shortDescription = "";

  /**
   * Long description of the learning outcome
   *
   * @generated from field: string long_description = 5;
   */
  longDescription = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 6;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 7;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 9;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string nano_id = 10;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<LearningOutcome>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.LearningOutcome";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "learning_outcome_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "learning_outcome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "long_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_on", kind: "message", T: Timestamp },
    { no: 7, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LearningOutcome {
    return new LearningOutcome().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LearningOutcome {
    return new LearningOutcome().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LearningOutcome {
    return new LearningOutcome().fromJsonString(jsonString, options);
  }

  static equals(a: LearningOutcome | PlainMessage<LearningOutcome> | undefined, b: LearningOutcome | PlainMessage<LearningOutcome> | undefined): boolean {
    return proto3.util.equals(LearningOutcome, a, b);
  }
}

/**
 * Table structure for content_management.resource
 *
 * @generated from message geneo.content.db.Resource
 */
export class Resource extends Message<Resource> {
  /**
   * Primary key for resource
   *
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * Referencing key for resource
   *
   * @generated from field: optional string resource_id = 2;
   */
  resourceId?: string;

  /**
   * Title of the resource
   *
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * URL of the resource's poster image
   *
   * @generated from field: string poster_image_url = 4;
   */
  posterImageUrl = "";

  /**
   * Estimated time in minutes for the resource
   *
   * @generated from field: int32 estimated_time_in_min = 5;
   */
  estimatedTimeInMin = 0;

  /**
   * Type of the resource
   *
   * @generated from field: geneo.content.db.Resource.ResourceEnum resource_type = 6;
   */
  resourceType = Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED;

  /**
   * Array of learning outcome IDs
   *
   * @generated from field: repeated string learning_outcomes_ids = 7;
   */
  learningOutcomesIds: string[] = [];

  /**
   * Category type of the resource
   *
   * @generated from field: geneo.content.db.ResourceCategoryEnum resource_category = 8;
   */
  resourceCategory = ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 9;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: int64 created_by = 10;
   */
  createdBy = protoInt64.zero;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 11;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: int64 modified_by = 12;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: optional geneo.content.db.SchoolContentInfo school_content_info = 13;
   */
  schoolContentInfo?: SchoolContentInfo;

  /**
   * @generated from field: optional int32 school_id = 14;
   */
  schoolId?: number;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 15;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 16;
   */
  nanoId?: string;

  /**
   * optional string school_id = 18 ;
   *
   * @generated from field: optional int32 school_device_id = 17;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<Resource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.Resource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum) },
    { no: 7, name: "learning_outcomes_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "resource_category", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum) },
    { no: 9, name: "created_on", kind: "message", T: Timestamp },
    { no: 10, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "modified_on", kind: "message", T: Timestamp },
    { no: 12, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "school_content_info", kind: "message", T: SchoolContentInfo, opt: true },
    { no: 14, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 15, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 16, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Resource {
    return new Resource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Resource {
    return new Resource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Resource {
    return new Resource().fromJsonString(jsonString, options);
  }

  static equals(a: Resource | PlainMessage<Resource> | undefined, b: Resource | PlainMessage<Resource> | undefined): boolean {
    return proto3.util.equals(Resource, a, b);
  }
}

/**
 * Enum for ResourceEnum
 *
 * @generated from enum geneo.content.db.Resource.ResourceEnum
 */
export enum Resource_ResourceEnum {
  /**
   * Represents undefined resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_UNDEFINED = 0;
   */
  RESOURCE_TYPE_UNDEFINED = 0,

  /**
   * Represents contain primary video resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_VIDEO = 1;
   */
  RESOURCE_TYPE_VIDEO = 1,

  /**
   * Represents contain primary audio resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_AUDIO = 2;
   */
  RESOURCE_TYPE_AUDIO = 2,

  /**
   * Represents contain primary reading resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_READING = 3;
   */
  RESOURCE_TYPE_READING = 3,

  /**
   * Represents contain primary question set resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_QUESTION_SET = 4;
   */
  RESOURCE_TYPE_QUESTION_SET = 4,

  /**
   * Represents contain primary question resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_QUESTION = 5;
   */
  RESOURCE_TYPE_QUESTION = 5,

  /**
   * Represents contain primary practice resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_PRACTICE = 6;
   */
  RESOURCE_TYPE_PRACTICE = 6,

  /**
   * Represents contain primary test resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_TEST = 7;
   */
  RESOURCE_TYPE_TEST = 7,

  /**
   * Represents contain primary activity resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_ACTIVITY = 8;
   */
  RESOURCE_TYPE_ACTIVITY = 8,

  /**
   * Represents contain primary simulation resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_SIMULATION = 9;
   */
  RESOURCE_TYPE_SIMULATION = 9,

  /**
   * Represents contain primary exploriment resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_EXPLORIMENT = 10;
   */
  RESOURCE_TYPE_EXPLORIMENT = 10,

  /**
   * Represents contain primary game resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_GAME = 11;
   */
  RESOURCE_TYPE_GAME = 11,

  /**
   * Represents contain primary AR/VR resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_AR_VR = 12;
   */
  RESOURCE_TYPE_AR_VR = 12,

  /**
   * Represents contain primary PPT resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_PPT = 13;
   */
  RESOURCE_TYPE_PPT = 13,

  /**
   * Represents contain primary e-book resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_E_BOOK = 14;
   */
  RESOURCE_TYPE_E_BOOK = 14,

  /**
   * Represents contain primary PDF resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_PDF = 15;
   */
  RESOURCE_TYPE_PDF = 15,

  /**
   * Represents contain primary flashcards resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_FLASHCARDS = 16;
   */
  RESOURCE_TYPE_FLASHCARDS = 16,

  /**
   * Represents contain primary mindmap resource type.
   *
   * @generated from enum value: RESOURCE_TYPE_MINDMAP = 17;
   */
  RESOURCE_TYPE_MINDMAP = 17,

  /**
   * @generated from enum value: RESOURCE_TYPE_WORKSHEET = 18;
   */
  RESOURCE_TYPE_WORKSHEET = 18,

  /**
   * @generated from enum value: RESOURCE_TYPE_TEACHER_UPLOAD = 19;
   */
  RESOURCE_TYPE_TEACHER_UPLOAD = 19,

  /**
   * @generated from enum value: RESOURCE_TYPE_ADMIN_UPLOAD = 20;
   */
  RESOURCE_TYPE_ADMIN_UPLOAD = 20,
}
// Retrieve enum metadata with: proto3.getEnumType(Resource_ResourceEnum)
proto3.util.setEnumType(Resource_ResourceEnum, "geneo.content.db.Resource.ResourceEnum", [
  { no: 0, name: "RESOURCE_TYPE_UNDEFINED" },
  { no: 1, name: "RESOURCE_TYPE_VIDEO" },
  { no: 2, name: "RESOURCE_TYPE_AUDIO" },
  { no: 3, name: "RESOURCE_TYPE_READING" },
  { no: 4, name: "RESOURCE_TYPE_QUESTION_SET" },
  { no: 5, name: "RESOURCE_TYPE_QUESTION" },
  { no: 6, name: "RESOURCE_TYPE_PRACTICE" },
  { no: 7, name: "RESOURCE_TYPE_TEST" },
  { no: 8, name: "RESOURCE_TYPE_ACTIVITY" },
  { no: 9, name: "RESOURCE_TYPE_SIMULATION" },
  { no: 10, name: "RESOURCE_TYPE_EXPLORIMENT" },
  { no: 11, name: "RESOURCE_TYPE_GAME" },
  { no: 12, name: "RESOURCE_TYPE_AR_VR" },
  { no: 13, name: "RESOURCE_TYPE_PPT" },
  { no: 14, name: "RESOURCE_TYPE_E_BOOK" },
  { no: 15, name: "RESOURCE_TYPE_PDF" },
  { no: 16, name: "RESOURCE_TYPE_FLASHCARDS" },
  { no: 17, name: "RESOURCE_TYPE_MINDMAP" },
  { no: 18, name: "RESOURCE_TYPE_WORKSHEET" },
  { no: 19, name: "RESOURCE_TYPE_TEACHER_UPLOAD" },
  { no: 20, name: "RESOURCE_TYPE_ADMIN_UPLOAD" },
]);

/**
 * @generated from message geneo.content.db.SchoolContentInfo
 */
export class SchoolContentInfo extends Message<SchoolContentInfo> {
  /**
   * @generated from field: optional int32 school_group_id = 1;
   */
  schoolGroupId?: number;

  /**
   * @generated from field: optional int32 school_id = 2;
   */
  schoolId?: number;

  /**
   * @generated from field: int32 course_id = 3;
   */
  courseId = 0;

  /**
   * @generated from field: int32 module_id = 4;
   */
  moduleId = 0;

  /**
   * @generated from field: optional bool open_for_all_sections = 5;
   */
  openForAllSections?: boolean;

  /**
   * @generated from field: optional geneo.content.db.Module.ModuleCategoryEnum module_category = 6;
   */
  moduleCategory?: Module_ModuleCategoryEnum;

  /**
   * @generated from field: int64 person_id = 7;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 8;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: geneo.content.db.ApprovalInfo approval_info = 9;
   */
  approvalInfo?: ApprovalInfo;

  /**
   * @generated from field: optional int32 created_in_section_id = 10;
   */
  createdInSectionId?: number;

  /**
   * Teacher specific key (as per all sections of teacher)
   *
   * @generated from field: repeated int32 open_for_section_ids = 11;
   */
  openForSectionIds: number[] = [];

  /**
   * @generated from field: geneo.content.db.DeletionInfo deletion_info = 12;
   */
  deletionInfo?: DeletionInfo;

  constructor(data?: PartialMessage<SchoolContentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.SchoolContentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "open_for_all_sections", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum), opt: true },
    { no: 7, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 9, name: "approval_info", kind: "message", T: ApprovalInfo },
    { no: 10, name: "created_in_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "open_for_section_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 12, name: "deletion_info", kind: "message", T: DeletionInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolContentInfo {
    return new SchoolContentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolContentInfo {
    return new SchoolContentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolContentInfo {
    return new SchoolContentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolContentInfo | PlainMessage<SchoolContentInfo> | undefined, b: SchoolContentInfo | PlainMessage<SchoolContentInfo> | undefined): boolean {
    return proto3.util.equals(SchoolContentInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.DeletionInfo
 */
export class DeletionInfo extends Message<DeletionInfo> {
  /**
   * @generated from field: optional int64 admin_id = 1;
   */
  adminId?: bigint;

  /**
   * @generated from field: geneo.content.db.ContentDeletionStatusEnum deletion_status = 2;
   */
  deletionStatus = ContentDeletionStatusEnum.CONTENT_DELETION_STATUS_UNDEFINED;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp request_submission_date = 3;
   */
  requestSubmissionDate?: Timestamp;

  /**
   * @generated from field: bool is_delete_for_all_sections = 4;
   */
  isDeleteForAllSections = false;

  /**
   * @generated from field: repeated int32 deleted_for_section_ids = 5;
   */
  deletedForSectionIds: number[] = [];

  /**
   * @generated from field: optional geneo.content.db.Remark remark = 6;
   */
  remark?: Remark;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp request_approval_date = 7;
   */
  requestApprovalDate?: Timestamp;

  constructor(data?: PartialMessage<DeletionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.DeletionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "deletion_status", kind: "enum", T: proto3.getEnumType(ContentDeletionStatusEnum) },
    { no: 3, name: "request_submission_date", kind: "message", T: Timestamp },
    { no: 4, name: "is_delete_for_all_sections", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "deleted_for_section_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 6, name: "remark", kind: "message", T: Remark, opt: true },
    { no: 7, name: "request_approval_date", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletionInfo {
    return new DeletionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletionInfo {
    return new DeletionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletionInfo {
    return new DeletionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DeletionInfo | PlainMessage<DeletionInfo> | undefined, b: DeletionInfo | PlainMessage<DeletionInfo> | undefined): boolean {
    return proto3.util.equals(DeletionInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ApprovalInfo
 */
export class ApprovalInfo extends Message<ApprovalInfo> {
  /**
   * @generated from field: optional int64 admin_id = 1;
   */
  adminId?: bigint;

  /**
   * @generated from field: geneo.content.db.ContentApprovalStatusEnum approval_status = 2;
   */
  approvalStatus = ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_UNDEFINED;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp request_submission_date = 3;
   */
  requestSubmissionDate?: Timestamp;

  /**
   * @generated from field: optional geneo.content.db.Remark remark = 4;
   */
  remark?: Remark;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp request_approval_date = 5;
   */
  requestApprovalDate?: Timestamp;

  constructor(data?: PartialMessage<ApprovalInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ApprovalInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "approval_status", kind: "enum", T: proto3.getEnumType(ContentApprovalStatusEnum) },
    { no: 3, name: "request_submission_date", kind: "message", T: Timestamp },
    { no: 4, name: "remark", kind: "message", T: Remark, opt: true },
    { no: 5, name: "request_approval_date", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApprovalInfo {
    return new ApprovalInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApprovalInfo {
    return new ApprovalInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApprovalInfo {
    return new ApprovalInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ApprovalInfo | PlainMessage<ApprovalInfo> | undefined, b: ApprovalInfo | PlainMessage<ApprovalInfo> | undefined): boolean {
    return proto3.util.equals(ApprovalInfo, a, b);
  }
}

/**
 * for self-registration db
 *
 * @generated from message geneo.content.db.Remark
 */
export class Remark extends Message<Remark> {
  /**
   * @generated from field: geneo.content.db.ContentRejectionEnum reason = 1;
   */
  reason = ContentRejectionEnum.CONTENT_REJECTION_REASON_UNDEFINED;

  /**
   * @generated from field: repeated string comments = 2;
   */
  comments: string[] = [];

  constructor(data?: PartialMessage<Remark>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.Remark";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reason", kind: "enum", T: proto3.getEnumType(ContentRejectionEnum) },
    { no: 2, name: "comments", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Remark {
    return new Remark().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Remark {
    return new Remark().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Remark {
    return new Remark().fromJsonString(jsonString, options);
  }

  static equals(a: Remark | PlainMessage<Remark> | undefined, b: Remark | PlainMessage<Remark> | undefined): boolean {
    return proto3.util.equals(Remark, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ResourceContentRevisionInfo
 */
export class ResourceContentRevisionInfo extends Message<ResourceContentRevisionInfo> {
  /**
   * @generated from field: int32 revision_number = 1;
   */
  revisionNumber = 0;

  /**
   * @generated from field: string created_by = 2;
   */
  createdBy = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_on = 3;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp deactivated_on = 4;
   */
  deactivatedOn?: Timestamp;

  constructor(data?: PartialMessage<ResourceContentRevisionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ResourceContentRevisionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "revision_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_on", kind: "message", T: Timestamp },
    { no: 4, name: "deactivated_on", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContentRevisionInfo {
    return new ResourceContentRevisionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContentRevisionInfo {
    return new ResourceContentRevisionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContentRevisionInfo {
    return new ResourceContentRevisionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContentRevisionInfo | PlainMessage<ResourceContentRevisionInfo> | undefined, b: ResourceContentRevisionInfo | PlainMessage<ResourceContentRevisionInfo> | undefined): boolean {
    return proto3.util.equals(ResourceContentRevisionInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ResourceContentData
 */
export class ResourceContentData extends Message<ResourceContentData> {
  /**
   * @generated from field: optional string resource_id = 1;
   */
  resourceId?: string;

  /**
   * @generated from field: int32 num_of_pages = 2;
   */
  numOfPages = 0;

  /**
   * @generated from field: repeated string content_ids = 3;
   */
  contentIds: string[] = [];

  /**
   * @generated from field: repeated int32 instruction_ids = 4;
   */
  instructionIds: number[] = [];

  /**
   * @generated from field: repeated string question_ids = 5;
   */
  questionIds: string[] = [];

  /**
   * JSONB
   *
   * @generated from field: repeated geneo.content.db.PageContentInfo content_flow = 6;
   */
  contentFlow: PageContentInfo[] = [];

  constructor(data?: PartialMessage<ResourceContentData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ResourceContentData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "num_of_pages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "content_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "instruction_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 5, name: "question_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "content_flow", kind: "message", T: PageContentInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContentData {
    return new ResourceContentData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContentData {
    return new ResourceContentData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContentData {
    return new ResourceContentData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContentData | PlainMessage<ResourceContentData> | undefined, b: ResourceContentData | PlainMessage<ResourceContentData> | undefined): boolean {
    return proto3.util.equals(ResourceContentData, a, b);
  }
}

/**
 * @generated from message geneo.content.db.PageContentInfo
 */
export class PageContentInfo extends Message<PageContentInfo> {
  /**
   * @generated from field: int32 page_number = 1;
   */
  pageNumber = 0;

  /**
   * @generated from field: repeated string content_ids = 2;
   */
  contentIds: string[] = [];

  /**
   * @generated from field: repeated string question_ids = 3;
   */
  questionIds: string[] = [];

  /**
   * @generated from field: repeated string instruction_ids = 4;
   */
  instructionIds: string[] = [];

  /**
   * @generated from field: repeated string over_all_id_sequence = 5;
   */
  overAllIdSequence: string[] = [];

  /**
   * @generated from field: repeated geneo.content.db.ImplementedContentModel contents = 6;
   */
  contents: ImplementedContentModel[] = [];

  constructor(data?: PartialMessage<PageContentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.PageContentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "content_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "question_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "instruction_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "over_all_id_sequence", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "contents", kind: "message", T: ImplementedContentModel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PageContentInfo {
    return new PageContentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PageContentInfo {
    return new PageContentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PageContentInfo {
    return new PageContentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: PageContentInfo | PlainMessage<PageContentInfo> | undefined, b: PageContentInfo | PlainMessage<PageContentInfo> | undefined): boolean {
    return proto3.util.equals(PageContentInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ResourceContentDataInfo
 */
export class ResourceContentDataInfo extends Message<ResourceContentDataInfo> {
  /**
   * @generated from field: repeated geneo.content.db.PageContentInfo page_content = 1;
   */
  pageContent: PageContentInfo[] = [];

  constructor(data?: PartialMessage<ResourceContentDataInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ResourceContentDataInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_content", kind: "message", T: PageContentInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContentDataInfo {
    return new ResourceContentDataInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContentDataInfo {
    return new ResourceContentDataInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContentDataInfo {
    return new ResourceContentDataInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContentDataInfo | PlainMessage<ResourceContentDataInfo> | undefined, b: ResourceContentDataInfo | PlainMessage<ResourceContentDataInfo> | undefined): boolean {
    return proto3.util.equals(ResourceContentDataInfo, a, b);
  }
}

/**
 * Table structure for content_management.resource_content
 *
 * @generated from message geneo.content.db.ResourceContent
 */
export class ResourceContent extends Message<ResourceContent> {
  /**
   * Primary key for resource content
   *
   * @generated from field: optional int32 resource_content_id = 1;
   */
  resourceContentId?: number;

  /**
   * Foreign key referencing content_management.resource.resource_id
   *
   * @generated from field: optional string resource_id = 2;
   */
  resourceId?: string;

  /**
   * Version of this resource content
   *
   * @generated from field: string resource_content_version = 3;
   */
  resourceContentVersion = "";

  /**
   * @generated from field: geneo.content.db.ResourceContentRevisionInfo content_revision_info = 4;
   */
  contentRevisionInfo?: ResourceContentRevisionInfo;

  /**
   * Indicates if the resource content is active/inactive
   *
   * @generated from field: bool is_active = 5;
   */
  isActive = false;

  /**
   * JSONB resource content data
   *
   * @generated from field: geneo.content.db.ResourceContentDataInfo resource_content_data = 6;
   */
  resourceContentData?: ResourceContentDataInfo;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 7;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 8;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 9;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 10;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string gcp_json_url = 11;
   */
  gcpJsonUrl?: string;

  /**
   * @generated from field: optional string nano_id = 12;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 17;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 18;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<ResourceContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ResourceContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_content_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "resource_content_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "content_revision_info", kind: "message", T: ResourceContentRevisionInfo },
    { no: 5, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "resource_content_data", kind: "message", T: ResourceContentDataInfo },
    { no: 7, name: "created_on", kind: "message", T: Timestamp },
    { no: 8, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "gcp_json_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 18, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContent {
    return new ResourceContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContent {
    return new ResourceContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContent {
    return new ResourceContent().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContent | PlainMessage<ResourceContent> | undefined, b: ResourceContent | PlainMessage<ResourceContent> | undefined): boolean {
    return proto3.util.equals(ResourceContent, a, b);
  }
}

/**
 * Table structure for content_management.resource_teacher_instruction
 *
 * @generated from message geneo.content.db.ResourceTeacherInstruction
 */
export class ResourceTeacherInstruction extends Message<ResourceTeacherInstruction> {
  /**
   * Primary key for resource teacher instruction
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Referencing key for resource teacher instruction
   *
   * @generated from field: optional string resource_teacher_instruction_id = 2;
   */
  resourceTeacherInstructionId?: string;

  /**
   * Foreign key referencing content_management.resource.resource_id
   *
   * @generated from field: optional string resource_id = 3;
   */
  resourceId?: string;

  /**
   * @generated from field: geneo.content.db.InstructionModel instruction_content = 4;
   */
  instructionContent?: InstructionModel;

  /**
   * Foreign key referencing teacher_profile.teacher_id
   *
   * @generated from field: optional int64 teacher_id = 5;
   */
  teacherId?: bigint;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 6;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 7;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 9;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string nano_id = 10;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 11;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 12;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<ResourceTeacherInstruction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ResourceTeacherInstruction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "resource_teacher_instruction_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "instruction_content", kind: "message", T: InstructionModel },
    { no: 5, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "created_on", kind: "message", T: Timestamp },
    { no: 7, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceTeacherInstruction {
    return new ResourceTeacherInstruction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceTeacherInstruction {
    return new ResourceTeacherInstruction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceTeacherInstruction {
    return new ResourceTeacherInstruction().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceTeacherInstruction | PlainMessage<ResourceTeacherInstruction> | undefined, b: ResourceTeacherInstruction | PlainMessage<ResourceTeacherInstruction> | undefined): boolean {
    return proto3.util.equals(ResourceTeacherInstruction, a, b);
  }
}

/**
 * Table structure for content_management.module_resource_tag
 *
 * @generated from message geneo.content.db.ModuleResourceTag
 */
export class ModuleResourceTag extends Message<ModuleResourceTag> {
  /**
   * Primary key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Foreign key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 module_id = 2;
   */
  moduleId?: number;

  /**
   * Foreign key referencing content_management.resource.resource_id
   *
   * @generated from field: optional string resource_id = 3;
   */
  resourceId?: string;

  /**
   * Rank of the module resource tag
   *
   * @generated from field: int32 rank = 4;
   */
  rank = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 9;
   */
  deletedAt?: Timestamp;

  /**
   * Category type of the resource
   *
   * @generated from field: optional geneo.content.db.ResourceCategoryEnum module_resource_category = 10;
   */
  moduleResourceCategory?: ResourceCategoryEnum;

  /**
   * @generated from field: optional string nano_id = 11;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<ModuleResourceTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ModuleResourceTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "module_resource_category", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum), opt: true },
    { no: 11, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleResourceTag {
    return new ModuleResourceTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleResourceTag {
    return new ModuleResourceTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleResourceTag {
    return new ModuleResourceTag().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleResourceTag | PlainMessage<ModuleResourceTag> | undefined, b: ModuleResourceTag | PlainMessage<ModuleResourceTag> | undefined): boolean {
    return proto3.util.equals(ModuleResourceTag, a, b);
  }
}

/**
 * Table structure for content_management.lesson
 *
 * @generated from message geneo.content.db.Lesson
 */
export class Lesson extends Message<Lesson> {
  /**
   * Primary key for lesson plan
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Referencing key for lesson plan
   *
   * @generated from field: optional string lesson_id = 2;
   */
  lessonId?: string;

  /**
   * Title of the lesson plan
   *
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * Foreign key referencing content_management.course.course_id
   *
   * @generated from field: optional int32 course_id = 4;
   */
  courseId?: number;

  /**
   * Foreign key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 module_id = 5;
   */
  moduleId?: number;

  /**
   * Estimated time for the lesson plan
   *
   * @generated from field: int32 estimated_time_in_min = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * Array of resource IDs
   *
   * @generated from field: repeated string resource_ids = 7;
   */
  resourceIds: string[] = [];

  /**
   * Creation status of the lesson plan
   *
   * @generated from field: geneo.content.db.CreationStatusEnum creation_status = 8;
   */
  creationStatus = CreationStatusEnum.CREATION_STATUS_UNDEFINED;

  /**
   * ID of the source lesson plan
   *
   * @generated from field: optional string source_lesson_id = 9;
   */
  sourceLessonId?: string;

  /**
   * Array of learning outcome IDs
   *
   * @generated from field: repeated string learning_outcomes_ids = 10;
   */
  learningOutcomesIds: string[] = [];

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 11;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: int64 created_by = 12;
   */
  createdBy = protoInt64.zero;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 13;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: int64 modified_by = 14;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: optional string poster_image_url = 15;
   */
  posterImageUrl?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 16;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional int64 teacher_id = 17;
   */
  teacherId?: bigint;

  /**
   * @generated from field: optional int32 school_class_section_id = 18;
   */
  schoolClassSectionId?: number;

  /**
   * @generated from field: optional string nano_id = 19;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 20;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 21;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<Lesson>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.Lesson";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "creation_status", kind: "enum", T: proto3.getEnumType(CreationStatusEnum) },
    { no: 9, name: "source_lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "learning_outcomes_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "created_on", kind: "message", T: Timestamp },
    { no: 12, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "modified_on", kind: "message", T: Timestamp },
    { no: 14, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 17, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 18, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 19, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 21, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Lesson {
    return new Lesson().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Lesson {
    return new Lesson().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Lesson {
    return new Lesson().fromJsonString(jsonString, options);
  }

  static equals(a: Lesson | PlainMessage<Lesson> | undefined, b: Lesson | PlainMessage<Lesson> | undefined): boolean {
    return proto3.util.equals(Lesson, a, b);
  }
}

/**
 * Table structure for content_management.module_resource_tag
 *
 * @generated from message geneo.content.db.ModuleLessonTag
 */
export class ModuleLessonTag extends Message<ModuleLessonTag> {
  /**
   * Primary key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Foreign key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 module_id = 2;
   */
  moduleId?: number;

  /**
   * Foreign key referencing content_management.resource.resource_id
   *
   * @generated from field: optional string lesson_id = 3;
   */
  lessonId?: string;

  /**
   * Rank of the module resource tag
   *
   * @generated from field: int32 rank = 4;
   */
  rank = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 9;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 10;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<ModuleLessonTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ModuleLessonTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleLessonTag {
    return new ModuleLessonTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleLessonTag {
    return new ModuleLessonTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleLessonTag {
    return new ModuleLessonTag().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleLessonTag | PlainMessage<ModuleLessonTag> | undefined, b: ModuleLessonTag | PlainMessage<ModuleLessonTag> | undefined): boolean {
    return proto3.util.equals(ModuleLessonTag, a, b);
  }
}

/**
 * Table structure for content_management.content_upload
 *
 * @generated from message geneo.content.db.ContentUpload
 */
export class ContentUpload extends Message<ContentUpload> {
  /**
   * Primary key for content upload
   *
   * @generated from field: optional string content_id = 1;
   */
  contentId?: string;

  /**
   * Profile ID of the content uploader
   *
   * @generated from field: optional int32 profile_id = 2;
   */
  profileId?: number;

  /**
   * Role of the uploader
   *
   * @generated from field: geneo.ums.db.ProfileRolesEnum profile_role = 3;
   */
  profileRole = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * Type of the content
   *
   * @generated from field: geneo.content.db.Content.ContentEnum content_type = 4;
   */
  contentType = Content_ContentEnum.CONTENT_TYPE_UNDEFINED;

  /**
   * Name of the uploaded file
   *
   * @generated from field: string filename = 5;
   */
  filename = "";

  /**
   * URL of the uploaded file
   *
   * @generated from field: string file_url = 6;
   */
  fileUrl = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 7;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 8;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 9;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 10;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string nano_id = 11;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<ContentUpload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ContentUpload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "profile_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "profile_role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 4, name: "content_type", kind: "enum", T: proto3.getEnumType(Content_ContentEnum) },
    { no: 5, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "created_on", kind: "message", T: Timestamp },
    { no: 8, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentUpload {
    return new ContentUpload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentUpload {
    return new ContentUpload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentUpload {
    return new ContentUpload().fromJsonString(jsonString, options);
  }

  static equals(a: ContentUpload | PlainMessage<ContentUpload> | undefined, b: ContentUpload | PlainMessage<ContentUpload> | undefined): boolean {
    return proto3.util.equals(ContentUpload, a, b);
  }
}

/**
 * @generated from message geneo.content.db.QuestionBank
 */
export class QuestionBank extends Message<QuestionBank> {
  /**
   * Primary key for question bank
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Referencing key for  question bank
   *
   * @generated from field: optional string question_bank_id = 2;
   */
  questionBankId?: string;

  /**
   * Title of the  question bank
   *
   * @generated from field: optional string title = 3;
   */
  title?: string;

  /**
   * @generated from field: optional string poster_image_url = 4;
   */
  posterImageUrl?: string;

  /**
   * Foreign key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 module_id = 5;
   */
  moduleId?: number;

  /**
   * Estimated time for the  question bank
   *
   * @generated from field: int32 estimated_time_in_min = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * Array of resource IDs
   *
   * @generated from field: repeated string question_ids = 7;
   */
  questionIds: string[] = [];

  /**
   * Array of learning outcome IDs
   *
   * @generated from field: repeated string learning_outcomes_ids = 8;
   */
  learningOutcomesIds: string[] = [];

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 9;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: int64 created_by = 10;
   */
  createdBy = protoInt64.zero;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 11;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: int64 modified_by = 12;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: optional string nano_id = 13;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<QuestionBank>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.QuestionBank";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "question_bank_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "question_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "learning_outcomes_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "created_on", kind: "message", T: Timestamp },
    { no: 10, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "modified_on", kind: "message", T: Timestamp },
    { no: 12, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionBank {
    return new QuestionBank().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionBank {
    return new QuestionBank().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionBank {
    return new QuestionBank().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionBank | PlainMessage<QuestionBank> | undefined, b: QuestionBank | PlainMessage<QuestionBank> | undefined): boolean {
    return proto3.util.equals(QuestionBank, a, b);
  }
}

/**
 * Enum for QuestionBankCategoryEnum
 *
 * @generated from enum geneo.content.db.QuestionBank.QuestionBankCategoryEnum
 */
export enum QuestionBank_QuestionBankCategoryEnum {
  /**
   * Represents undefined module category.
   *
   * @generated from enum value: QUESTION_BANK_CATEGORY_UNDEFINED = 0;
   */
  QUESTION_BANK_CATEGORY_UNDEFINED = 0,

  /**
   * Represents book module category.
   *
   * @generated from enum value: QUESTION_BANK_CATEGORY_BOOK = 1;
   */
  QUESTION_BANK_CATEGORY_BOOK = 1,

  /**
   * Represents chapter module category.
   *
   * @generated from enum value: QUESTION_BANK_CATEGORY_CHAPTER = 2;
   */
  QUESTION_BANK_CATEGORY_CHAPTER = 2,

  /**
   * Represents topic module category.
   *
   * @generated from enum value: QUESTION_BANK_CATEGORY_TOPIC = 3;
   */
  QUESTION_BANK_CATEGORY_TOPIC = 3,

  /**
   * Represents topic module category.
   *
   * @generated from enum value: QUESTION_BANK_CATEGORY_CONECPT = 4;
   */
  QUESTION_BANK_CATEGORY_CONECPT = 4,

  /**
   * Represents topic module category.
   *
   * @generated from enum value: QUESTION_BANK_CATEGORY_LO = 5;
   */
  QUESTION_BANK_CATEGORY_LO = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(QuestionBank_QuestionBankCategoryEnum)
proto3.util.setEnumType(QuestionBank_QuestionBankCategoryEnum, "geneo.content.db.QuestionBank.QuestionBankCategoryEnum", [
  { no: 0, name: "QUESTION_BANK_CATEGORY_UNDEFINED" },
  { no: 1, name: "QUESTION_BANK_CATEGORY_BOOK" },
  { no: 2, name: "QUESTION_BANK_CATEGORY_CHAPTER" },
  { no: 3, name: "QUESTION_BANK_CATEGORY_TOPIC" },
  { no: 4, name: "QUESTION_BANK_CATEGORY_CONECPT" },
  { no: 5, name: "QUESTION_BANK_CATEGORY_LO" },
]);

/**
 * Table structure for content_management.module_resource_tag
 *
 * @generated from message geneo.content.db.ModuleQuestionBankTag
 */
export class ModuleQuestionBankTag extends Message<ModuleQuestionBankTag> {
  /**
   * Primary key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Foreign key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 module_id = 2;
   */
  moduleId?: number;

  /**
   * Foreign key referencing content_management.question_bank.question_bank
   *
   * @generated from field: optional string question_bank_id = 3;
   */
  questionBankId?: string;

  /**
   * Rank of the module resource tag
   *
   * @generated from field: int32 rank = 4;
   */
  rank = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 9;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 10;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<ModuleQuestionBankTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ModuleQuestionBankTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "question_bank_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleQuestionBankTag {
    return new ModuleQuestionBankTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleQuestionBankTag {
    return new ModuleQuestionBankTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleQuestionBankTag {
    return new ModuleQuestionBankTag().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleQuestionBankTag | PlainMessage<ModuleQuestionBankTag> | undefined, b: ModuleQuestionBankTag | PlainMessage<ModuleQuestionBankTag> | undefined): boolean {
    return proto3.util.equals(ModuleQuestionBankTag, a, b);
  }
}

/**
 * @generated from message geneo.content.db.VideoResolutionConversion
 */
export class VideoResolutionConversion extends Message<VideoResolutionConversion> {
  /**
   * Primary key for learning outcome
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Referencing key for learning outcome
   *
   * @generated from field: optional int32 video_resoultion_id = 2;
   */
  videoResoultionId?: number;

  /**
   * Name of the learning outcome
   *
   * @generated from field: string raw_file_name = 3;
   */
  rawFileName = "";

  /**
   * Short description of the learning outcome
   *
   * @generated from field: string raw_file_path = 4;
   */
  rawFilePath = "";

  /**
   * Long description of the learning outcome
   *
   * @generated from field: optional string processed_file_path = 5;
   */
  processedFilePath?: string;

  /**
   * Long description of the learning outcome
   *
   * @generated from field: optional geneo.content.db.ResolutionEnum resolution = 6;
   */
  resolution?: ResolutionEnum;

  /**
   * @generated from field: optional geneo.content.db.TranscoderStatusEnum transcoderStatus = 7;
   */
  transcoderStatus?: TranscoderStatusEnum;

  /**
   * @generated from field: optional string jobId = 8;
   */
  jobId?: string;

  /**
   * @generated from field: optional int32 number_of_run = 19;
   */
  numberOfRun?: number;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 10;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 11;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 12;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 13;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string nano_id = 14;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<VideoResolutionConversion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.VideoResolutionConversion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "video_resoultion_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "raw_file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "raw_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "processed_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "resolution", kind: "enum", T: proto3.getEnumType(ResolutionEnum), opt: true },
    { no: 7, name: "transcoderStatus", kind: "enum", T: proto3.getEnumType(TranscoderStatusEnum), opt: true },
    { no: 8, name: "jobId", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "number_of_run", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "created_on", kind: "message", T: Timestamp },
    { no: 11, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 13, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 14, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoResolutionConversion {
    return new VideoResolutionConversion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoResolutionConversion {
    return new VideoResolutionConversion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoResolutionConversion {
    return new VideoResolutionConversion().fromJsonString(jsonString, options);
  }

  static equals(a: VideoResolutionConversion | PlainMessage<VideoResolutionConversion> | undefined, b: VideoResolutionConversion | PlainMessage<VideoResolutionConversion> | undefined): boolean {
    return proto3.util.equals(VideoResolutionConversion, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ResourceMetaInfo
 */
export class ResourceMetaInfo extends Message<ResourceMetaInfo> {
  /**
   * @generated from field: google.protobuf.Timestamp updateTimestamp = 1;
   */
  updateTimestamp?: Timestamp;

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: int32 order = 3;
   */
  order = 0;

  constructor(data?: PartialMessage<ResourceMetaInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ResourceMetaInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updateTimestamp", kind: "message", T: Timestamp },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceMetaInfo {
    return new ResourceMetaInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceMetaInfo {
    return new ResourceMetaInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceMetaInfo {
    return new ResourceMetaInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceMetaInfo | PlainMessage<ResourceMetaInfo> | undefined, b: ResourceMetaInfo | PlainMessage<ResourceMetaInfo> | undefined): boolean {
    return proto3.util.equals(ResourceMetaInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.LessonPlanMetaInfo
 */
export class LessonPlanMetaInfo extends Message<LessonPlanMetaInfo> {
  /**
   * @generated from field: google.protobuf.Timestamp updateTimestamp = 1;
   */
  updateTimestamp?: Timestamp;

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: int32 order = 3;
   */
  order = 0;

  /**
   * @generated from field: repeated geneo.content.db.ResourceMetaInfo resources = 4;
   */
  resources: ResourceMetaInfo[] = [];

  /**
   * @generated from field: optional int32 school_class_section_id = 5;
   */
  schoolClassSectionId?: number;

  /**
   * @generated from field: optional int64 teacherId = 6;
   */
  teacherId?: bigint;

  /**
   * @generated from field: optional int32 courseId = 7;
   */
  courseId?: number;

  constructor(data?: PartialMessage<LessonPlanMetaInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.LessonPlanMetaInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updateTimestamp", kind: "message", T: Timestamp },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "resources", kind: "message", T: ResourceMetaInfo, repeated: true },
    { no: 5, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "teacherId", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "courseId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonPlanMetaInfo {
    return new LessonPlanMetaInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonPlanMetaInfo {
    return new LessonPlanMetaInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonPlanMetaInfo {
    return new LessonPlanMetaInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LessonPlanMetaInfo | PlainMessage<LessonPlanMetaInfo> | undefined, b: LessonPlanMetaInfo | PlainMessage<LessonPlanMetaInfo> | undefined): boolean {
    return proto3.util.equals(LessonPlanMetaInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.TopicMetaInfo
 */
export class TopicMetaInfo extends Message<TopicMetaInfo> {
  /**
   * @generated from field: google.protobuf.Timestamp updateTimestamp = 1;
   */
  updateTimestamp?: Timestamp;

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  /**
   * @generated from field: int32 order = 3;
   */
  order = 0;

  /**
   * @generated from field: repeated geneo.content.db.LessonPlanMetaInfo lessonPlans = 4;
   */
  lessonPlans: LessonPlanMetaInfo[] = [];

  constructor(data?: PartialMessage<TopicMetaInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.TopicMetaInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updateTimestamp", kind: "message", T: Timestamp },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "lessonPlans", kind: "message", T: LessonPlanMetaInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicMetaInfo {
    return new TopicMetaInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicMetaInfo {
    return new TopicMetaInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicMetaInfo {
    return new TopicMetaInfo().fromJsonString(jsonString, options);
  }

  static equals(a: TopicMetaInfo | PlainMessage<TopicMetaInfo> | undefined, b: TopicMetaInfo | PlainMessage<TopicMetaInfo> | undefined): boolean {
    return proto3.util.equals(TopicMetaInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ChapterMetaInfo
 */
export class ChapterMetaInfo extends Message<ChapterMetaInfo> {
  /**
   * @generated from field: google.protobuf.Timestamp updateTimestamp = 1;
   */
  updateTimestamp?: Timestamp;

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  /**
   * @generated from field: int32 order = 3;
   */
  order = 0;

  /**
   * @generated from field: int32 chapter_id = 4;
   */
  chapterId = 0;

  /**
   * @generated from field: repeated geneo.content.db.TopicMetaInfo topics = 5;
   */
  topics: TopicMetaInfo[] = [];

  constructor(data?: PartialMessage<ChapterMetaInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ChapterMetaInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updateTimestamp", kind: "message", T: Timestamp },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "topics", kind: "message", T: TopicMetaInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterMetaInfo {
    return new ChapterMetaInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterMetaInfo {
    return new ChapterMetaInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterMetaInfo {
    return new ChapterMetaInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterMetaInfo | PlainMessage<ChapterMetaInfo> | undefined, b: ChapterMetaInfo | PlainMessage<ChapterMetaInfo> | undefined): boolean {
    return proto3.util.equals(ChapterMetaInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.OfflineMeta
 */
export class OfflineMeta extends Message<OfflineMeta> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: int32 chapter_id = 2;
   */
  chapterId = 0;

  /**
   * @generated from field: google.protobuf.Timestamp updateTimestamp = 3;
   */
  updateTimestamp?: Timestamp;

  /**
   * @generated from field: int32 order = 4;
   */
  order = 0;

  /**
   * @generated from field: repeated geneo.content.db.TopicMetaInfo topics = 5;
   */
  topics: TopicMetaInfo[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp createdOn = 6;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: int64 created_by = 7;
   */
  createdBy = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: int64 modified_by = 9;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: optional string nano_id = 10;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 11;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 12;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<OfflineMeta>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.OfflineMeta";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "updateTimestamp", kind: "message", T: Timestamp },
    { no: 4, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "topics", kind: "message", T: TopicMetaInfo, repeated: true },
    { no: 6, name: "createdOn", kind: "message", T: Timestamp },
    { no: 7, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp },
    { no: 9, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OfflineMeta {
    return new OfflineMeta().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OfflineMeta {
    return new OfflineMeta().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OfflineMeta {
    return new OfflineMeta().fromJsonString(jsonString, options);
  }

  static equals(a: OfflineMeta | PlainMessage<OfflineMeta> | undefined, b: OfflineMeta | PlainMessage<OfflineMeta> | undefined): boolean {
    return proto3.util.equals(OfflineMeta, a, b);
  }
}

/**
 * @generated from message geneo.content.db.DownloadedAsset
 */
export class DownloadedAsset extends Message<DownloadedAsset> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: string cacheKey = 2;
   */
  cacheKey = "";

  /**
   * @generated from field: geneo.content.db.DownloadedAsset.CacheKeyType type = 3;
   */
  type = DownloadedAsset_CacheKeyType.IMAGE;

  /**
   * @generated from field: int64 size = 4;
   */
  size = protoInt64.zero;

  /**
   * @generated from field: geneo.content.db.DownloadedAsset children = 5;
   */
  children?: DownloadedAsset;

  /**
   * @generated from field: optional bool is_resource_asset = 6;
   */
  isResourceAsset?: boolean;

  constructor(data?: PartialMessage<DownloadedAsset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.DownloadedAsset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cacheKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(DownloadedAsset_CacheKeyType) },
    { no: 4, name: "size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "children", kind: "message", T: DownloadedAsset },
    { no: 6, name: "is_resource_asset", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadedAsset {
    return new DownloadedAsset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadedAsset {
    return new DownloadedAsset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadedAsset {
    return new DownloadedAsset().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadedAsset | PlainMessage<DownloadedAsset> | undefined, b: DownloadedAsset | PlainMessage<DownloadedAsset> | undefined): boolean {
    return proto3.util.equals(DownloadedAsset, a, b);
  }
}

/**
 * @generated from enum geneo.content.db.DownloadedAsset.CacheKeyType
 */
export enum DownloadedAsset_CacheKeyType {
  /**
   * @generated from enum value: IMAGE = 0;
   */
  IMAGE = 0,

  /**
   * @generated from enum value: VIDEO_MP4 = 1;
   */
  VIDEO_MP4 = 1,

  /**
   * @generated from enum value: VIDEO_M3U8 = 2;
   */
  VIDEO_M3U8 = 2,

  /**
   * @generated from enum value: VIDEO_M3U8_SEGMENT = 3;
   */
  VIDEO_M3U8_SEGMENT = 3,

  /**
   * @generated from enum value: AUDIO_M3U8 = 4;
   */
  AUDIO_M3U8 = 4,

  /**
   * @generated from enum value: AUDIO_M3U8_SEGMENT = 5;
   */
  AUDIO_M3U8_SEGMENT = 5,

  /**
   * @generated from enum value: AUDIO_MP3 = 6;
   */
  AUDIO_MP3 = 6,

  /**
   * @generated from enum value: EXTERNAL_RESOURCE = 7;
   */
  EXTERNAL_RESOURCE = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(DownloadedAsset_CacheKeyType)
proto3.util.setEnumType(DownloadedAsset_CacheKeyType, "geneo.content.db.DownloadedAsset.CacheKeyType", [
  { no: 0, name: "IMAGE" },
  { no: 1, name: "VIDEO_MP4" },
  { no: 2, name: "VIDEO_M3U8" },
  { no: 3, name: "VIDEO_M3U8_SEGMENT" },
  { no: 4, name: "AUDIO_M3U8" },
  { no: 5, name: "AUDIO_M3U8_SEGMENT" },
  { no: 6, name: "AUDIO_MP3" },
  { no: 7, name: "EXTERNAL_RESOURCE" },
]);

/**
 * @generated from message geneo.content.db.DownloadedSubject
 */
export class DownloadedSubject extends Message<DownloadedSubject> {
  /**
   * @generated from field: int32 subjectId = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string bookImageUrl = 3;
   */
  bookImageUrl = "";

  /**
   * @generated from field: map<int32, geneo.content.db.DownloadedChapter> chapters = 4;
   */
  chapters: { [key: number]: DownloadedChapter } = {};

  /**
   * @generated from field: map<string, geneo.content.db.DownloadedAsset> assets = 5;
   */
  assets: { [key: string]: DownloadedAsset } = {};

  /**
   * @generated from field: optional bool is_downloaded = 6;
   */
  isDownloaded?: boolean;

  /**
   * @generated from field: optional string userId = 7;
   */
  userId?: string;

  /**
   * @generated from field: optional string offline_access_key = 8;
   */
  offlineAccessKey?: string;

  /**
   * @generated from field: optional int32 version = 9;
   */
  version?: number;

  constructor(data?: PartialMessage<DownloadedSubject>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.DownloadedSubject";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subjectId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "bookImageUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "chapters", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "message", T: DownloadedChapter} },
    { no: 5, name: "assets", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: DownloadedAsset} },
    { no: 6, name: "is_downloaded", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "userId", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "offline_access_key", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadedSubject {
    return new DownloadedSubject().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadedSubject {
    return new DownloadedSubject().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadedSubject {
    return new DownloadedSubject().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadedSubject | PlainMessage<DownloadedSubject> | undefined, b: DownloadedSubject | PlainMessage<DownloadedSubject> | undefined): boolean {
    return proto3.util.equals(DownloadedSubject, a, b);
  }
}

/**
 * @generated from message geneo.content.db.DownloadedChapter
 */
export class DownloadedChapter extends Message<DownloadedChapter> {
  /**
   * @generated from field: int32 chapterId = 1;
   */
  chapterId = 0;

  /**
   * @generated from field: string chapterTitle = 2;
   */
  chapterTitle = "";

  /**
   * URL or path to the image
   *
   * @generated from field: string posterImagesUrl = 3;
   */
  posterImagesUrl = "";

  /**
   * @generated from field: int32 order = 4;
   */
  order = 0;

  /**
   * @generated from field: google.protobuf.Timestamp lastUpdatedAt = 5;
   */
  lastUpdatedAt?: Timestamp;

  /**
   * @generated from field: map<int32, geneo.content.db.DownloadedTopic> downloadedTopics = 6;
   */
  downloadedTopics: { [key: number]: DownloadedTopic } = {};

  /**
   * @generated from field: map<string, geneo.content.db.DownloadedAsset> assets = 7;
   */
  assets: { [key: string]: DownloadedAsset } = {};

  /**
   * @generated from field: optional bool is_downloaded = 8;
   */
  isDownloaded?: boolean;

  constructor(data?: PartialMessage<DownloadedChapter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.DownloadedChapter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapterId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "chapterTitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "posterImagesUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "lastUpdatedAt", kind: "message", T: Timestamp },
    { no: 6, name: "downloadedTopics", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "message", T: DownloadedTopic} },
    { no: 7, name: "assets", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: DownloadedAsset} },
    { no: 8, name: "is_downloaded", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadedChapter {
    return new DownloadedChapter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadedChapter {
    return new DownloadedChapter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadedChapter {
    return new DownloadedChapter().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadedChapter | PlainMessage<DownloadedChapter> | undefined, b: DownloadedChapter | PlainMessage<DownloadedChapter> | undefined): boolean {
    return proto3.util.equals(DownloadedChapter, a, b);
  }
}

/**
 * @generated from message geneo.content.db.DownloadedTopic
 */
export class DownloadedTopic extends Message<DownloadedTopic> {
  /**
   * @generated from field: int32 topicId = 1;
   */
  topicId = 0;

  /**
   * @generated from field: string topicName = 2;
   */
  topicName = "";

  /**
   * @generated from field: int32 order = 3;
   */
  order = 0;

  /**
   * @generated from field: google.protobuf.Timestamp lastUpdatedAt = 4;
   */
  lastUpdatedAt?: Timestamp;

  /**
   * @generated from field: int32 lessonPlanCount = 5;
   */
  lessonPlanCount = 0;

  /**
   * @generated from field: int32 questionCount = 6;
   */
  questionCount = 0;

  /**
   * @generated from field: string posterImageUrl = 7;
   */
  posterImageUrl = "";

  /**
   * @generated from field: map<string, geneo.content.db.DownloadedLessonPlan> downloadedLessonPlans = 8;
   */
  downloadedLessonPlans: { [key: string]: DownloadedLessonPlan } = {};

  /**
   * @generated from field: map<string, geneo.content.db.DownloadedAsset> assets = 9;
   */
  assets: { [key: string]: DownloadedAsset } = {};

  /**
   * @generated from field: optional bool is_downloaded = 10;
   */
  isDownloaded?: boolean;

  constructor(data?: PartialMessage<DownloadedTopic>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.DownloadedTopic";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topicId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "topicName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "lastUpdatedAt", kind: "message", T: Timestamp },
    { no: 5, name: "lessonPlanCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "questionCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "posterImageUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "downloadedLessonPlans", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: DownloadedLessonPlan} },
    { no: 9, name: "assets", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: DownloadedAsset} },
    { no: 10, name: "is_downloaded", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadedTopic {
    return new DownloadedTopic().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadedTopic {
    return new DownloadedTopic().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadedTopic {
    return new DownloadedTopic().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadedTopic | PlainMessage<DownloadedTopic> | undefined, b: DownloadedTopic | PlainMessage<DownloadedTopic> | undefined): boolean {
    return proto3.util.equals(DownloadedTopic, a, b);
  }
}

/**
 * @generated from message geneo.content.db.DownloadedLessonPlan
 */
export class DownloadedLessonPlan extends Message<DownloadedLessonPlan> {
  /**
   * @generated from field: string lessonPlanTitle = 1;
   */
  lessonPlanTitle = "";

  /**
   * @generated from field: string lessonPlanId = 2;
   */
  lessonPlanId = "";

  /**
   * @generated from field: int32 order = 3;
   */
  order = 0;

  /**
   * @generated from field: string image = 4;
   */
  image = "";

  /**
   * @generated from field: google.protobuf.Timestamp lastUpdatedAt = 5;
   */
  lastUpdatedAt?: Timestamp;

  /**
   * @generated from field: int32 estimatedTimeInMin = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: repeated string resourceIds = 7;
   */
  resourceIds: string[] = [];

  /**
   * @generated from field: repeated string learningOutcomes = 8;
   */
  learningOutcomes: string[] = [];

  /**
   * @generated from field: map<string, geneo.content.db.DownloadedResource> downloadedResources = 9;
   */
  downloadedResources: { [key: string]: DownloadedResource } = {};

  /**
   * @generated from field: map<string, geneo.content.db.DownloadedAsset> assets = 10;
   */
  assets: { [key: string]: DownloadedAsset } = {};

  /**
   * @generated from field: optional bool is_downloaded = 11;
   */
  isDownloaded?: boolean;

  /**
   * @generated from field: optional int64 teacher_id = 12;
   */
  teacherId?: bigint;

  /**
   * @generated from field: repeated int32 school_class_section_id = 13;
   */
  schoolClassSectionId: number[] = [];

  /**
   * @generated from field: optional string teacher_name = 14;
   */
  teacherName?: string;

  constructor(data?: PartialMessage<DownloadedLessonPlan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.DownloadedLessonPlan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lessonPlanTitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lessonPlanId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "lastUpdatedAt", kind: "message", T: Timestamp },
    { no: 6, name: "estimatedTimeInMin", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "resourceIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "learningOutcomes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "downloadedResources", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: DownloadedResource} },
    { no: 10, name: "assets", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: DownloadedAsset} },
    { no: 11, name: "is_downloaded", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 12, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 14, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadedLessonPlan {
    return new DownloadedLessonPlan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadedLessonPlan {
    return new DownloadedLessonPlan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadedLessonPlan {
    return new DownloadedLessonPlan().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadedLessonPlan | PlainMessage<DownloadedLessonPlan> | undefined, b: DownloadedLessonPlan | PlainMessage<DownloadedLessonPlan> | undefined): boolean {
    return proto3.util.equals(DownloadedLessonPlan, a, b);
  }
}

/**
 * @generated from message geneo.content.db.DownloadedResource
 */
export class DownloadedResource extends Message<DownloadedResource> {
  /**
   * @generated from field: string resourceId = 1;
   */
  resourceId = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string posterImageUrl = 3;
   */
  posterImageUrl = "";

  /**
   * @generated from field: int32 estimatedTimeInMin = 4;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: geneo.content.db.Resource.ResourceEnum resourceType = 5;
   */
  resourceType = Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED;

  /**
   * @generated from field: geneo.content.db.ResourceCategoryEnum resourceCategoryType = 6;
   */
  resourceCategoryType = ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: int32 order = 7;
   */
  order = 0;

  /**
   * @generated from field: map<string, geneo.content.db.DownloadedAsset> assets = 8;
   */
  assets: { [key: string]: DownloadedAsset } = {};

  /**
   * @generated from field: geneo.content.db.ResourceContent resourceContent = 9;
   */
  resourceContent?: ResourceContent;

  /**
   * @generated from field: optional bool is_downloaded = 10;
   */
  isDownloaded?: boolean;

  constructor(data?: PartialMessage<DownloadedResource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.DownloadedResource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resourceId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "posterImageUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "estimatedTimeInMin", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "resourceType", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum) },
    { no: 6, name: "resourceCategoryType", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum) },
    { no: 7, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "assets", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: DownloadedAsset} },
    { no: 9, name: "resourceContent", kind: "message", T: ResourceContent },
    { no: 10, name: "is_downloaded", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadedResource {
    return new DownloadedResource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadedResource {
    return new DownloadedResource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadedResource {
    return new DownloadedResource().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadedResource | PlainMessage<DownloadedResource> | undefined, b: DownloadedResource | PlainMessage<DownloadedResource> | undefined): boolean {
    return proto3.util.equals(DownloadedResource, a, b);
  }
}

/**
 * @generated from message geneo.content.db.DownloadedResourceCacheInfo
 */
export class DownloadedResourceCacheInfo extends Message<DownloadedResourceCacheInfo> {
  /**
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 chapter_id = 2;
   */
  chapterId = 0;

  /**
   * @generated from field: int32 topic_id = 3;
   */
  topicId = 0;

  /**
   * @generated from field: string lesson_id = 4;
   */
  lessonId = "";

  /**
   * @generated from field: string resource_id = 5;
   */
  resourceId = "";

  /**
   * @generated from field: string url = 6;
   */
  url = "";

  /**
   * @generated from field: string cache_key = 7;
   */
  cacheKey = "";

  /**
   * @generated from field: geneo.content.db.DownloadedAsset.CacheKeyType type = 8;
   */
  type = DownloadedAsset_CacheKeyType.IMAGE;

  /**
   * @generated from field: int64 size = 9;
   */
  size = protoInt64.zero;

  constructor(data?: PartialMessage<DownloadedResourceCacheInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.DownloadedResourceCacheInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "cache_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "type", kind: "enum", T: proto3.getEnumType(DownloadedAsset_CacheKeyType) },
    { no: 9, name: "size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadedResourceCacheInfo {
    return new DownloadedResourceCacheInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadedResourceCacheInfo {
    return new DownloadedResourceCacheInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadedResourceCacheInfo {
    return new DownloadedResourceCacheInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadedResourceCacheInfo | PlainMessage<DownloadedResourceCacheInfo> | undefined, b: DownloadedResourceCacheInfo | PlainMessage<DownloadedResourceCacheInfo> | undefined): boolean {
    return proto3.util.equals(DownloadedResourceCacheInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.PDFConversionCronTask
 */
export class PDFConversionCronTask extends Message<PDFConversionCronTask> {
  /**
   * @generated from field: string original_file_url = 1;
   */
  originalFileUrl = "";

  /**
   * @generated from field: geneo.content.db.FileEnum original_file_type = 2;
   */
  originalFileType = FileEnum.FILE_TYPE_UNKNOWN;

  /**
   * @generated from field: geneo.content.db.FileExtensionEnum original_file_extension = 3;
   */
  originalFileExtension = FileExtensionEnum.FILE_EXTENSION_UNKNOWN;

  /**
   * @generated from field: geneo.content.db.PurposeInfo purpose_info = 4;
   */
  purposeInfo?: PurposeInfo;

  /**
   * @generated from field: geneo.content.db.PDFConversionStatus processing_status = 5;
   */
  processingStatus = PDFConversionStatus.PDF_CONVERSION_STATUS_UNDEFINED;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 6;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: int64 created_by = 7;
   */
  createdBy = protoInt64.zero;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: int64 modified_by = 9;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: optional string processed_file_url = 10;
   */
  processedFileUrl?: string;

  /**
   * @generated from field: optional string nano_id = 11;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 12;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 13;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<PDFConversionCronTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.PDFConversionCronTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "original_file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "original_file_type", kind: "enum", T: proto3.getEnumType(FileEnum) },
    { no: 3, name: "original_file_extension", kind: "enum", T: proto3.getEnumType(FileExtensionEnum) },
    { no: 4, name: "purpose_info", kind: "message", T: PurposeInfo },
    { no: 5, name: "processing_status", kind: "enum", T: proto3.getEnumType(PDFConversionStatus) },
    { no: 6, name: "created_on", kind: "message", T: Timestamp },
    { no: 7, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp },
    { no: 9, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "processed_file_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PDFConversionCronTask {
    return new PDFConversionCronTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PDFConversionCronTask {
    return new PDFConversionCronTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PDFConversionCronTask {
    return new PDFConversionCronTask().fromJsonString(jsonString, options);
  }

  static equals(a: PDFConversionCronTask | PlainMessage<PDFConversionCronTask> | undefined, b: PDFConversionCronTask | PlainMessage<PDFConversionCronTask> | undefined): boolean {
    return proto3.util.equals(PDFConversionCronTask, a, b);
  }
}

/**
 * @generated from message geneo.content.db.PurposeInfo
 */
export class PurposeInfo extends Message<PurposeInfo> {
  /**
   * @generated from field: geneo.content.db.UploadPurpose upload_purpose = 1;
   */
  uploadPurpose = UploadPurpose.UPLOAD_FOR_UNDEFINED;

  /**
   * @generated from oneof geneo.content.db.PurposeInfo.db_target_entity_id
   */
  dbTargetEntityId: {
    /**
     * @generated from field: int32 question_id = 5;
     */
    value: number;
    case: "questionId";
  } | {
    /**
     * @generated from field: int32 content_id = 6;
     */
    value: number;
    case: "contentId";
  } | {
    /**
     * @generated from field: int32 resource_id = 7;
     */
    value: number;
    case: "resourceId";
  } | {
    /**
     * @generated from field: int32 homework_id = 8;
     */
    value: number;
    case: "homeworkId";
  } | {
    /**
     * @generated from field: int32 instruction_id = 9;
     */
    value: number;
    case: "instructionId";
  } | {
    /**
     * @generated from field: int32 task_student_assignment_id = 10;
     */
    value: number;
    case: "taskStudentAssignmentId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<PurposeInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.PurposeInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upload_purpose", kind: "enum", T: proto3.getEnumType(UploadPurpose) },
    { no: 5, name: "question_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "db_target_entity_id" },
    { no: 6, name: "content_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "db_target_entity_id" },
    { no: 7, name: "resource_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "db_target_entity_id" },
    { no: 8, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "db_target_entity_id" },
    { no: 9, name: "instruction_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "db_target_entity_id" },
    { no: 10, name: "task_student_assignment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "db_target_entity_id" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PurposeInfo {
    return new PurposeInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PurposeInfo {
    return new PurposeInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PurposeInfo {
    return new PurposeInfo().fromJsonString(jsonString, options);
  }

  static equals(a: PurposeInfo | PlainMessage<PurposeInfo> | undefined, b: PurposeInfo | PlainMessage<PurposeInfo> | undefined): boolean {
    return proto3.util.equals(PurposeInfo, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ContentModuleNode
 */
export class ContentModuleNode extends Message<ContentModuleNode> {
  /**
   * @generated from field: int32 module_id = 1;
   */
  moduleId = 0;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 2;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: optional string poster_image_url = 8;
   */
  posterImageUrl?: string;

  /**
   * @generated from field: optional int32 module_no = 9;
   */
  moduleNo?: number;

  /**
   * @generated from field: repeated geneo.content.db.LessonNode lessons_info = 4;
   */
  lessonsInfo: LessonNode[] = [];

  /**
   * @generated from field: repeated string resource_ids = 5;
   */
  resourceIds: string[] = [];

  /**
   * @generated from field: repeated int32 homework_ids = 6;
   */
  homeworkIds: number[] = [];

  /**
   * @generated from field: repeated geneo.content.db.ContentModuleNode children = 7;
   */
  children: ContentModuleNode[] = [];

  constructor(data?: PartialMessage<ContentModuleNode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ContentModuleNode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "module_no", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "lessons_info", kind: "message", T: LessonNode, repeated: true },
    { no: 5, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "homework_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 7, name: "children", kind: "message", T: ContentModuleNode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentModuleNode {
    return new ContentModuleNode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentModuleNode {
    return new ContentModuleNode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentModuleNode {
    return new ContentModuleNode().fromJsonString(jsonString, options);
  }

  static equals(a: ContentModuleNode | PlainMessage<ContentModuleNode> | undefined, b: ContentModuleNode | PlainMessage<ContentModuleNode> | undefined): boolean {
    return proto3.util.equals(ContentModuleNode, a, b);
  }
}

/**
 * @generated from message geneo.content.db.LessonNode
 */
export class LessonNode extends Message<LessonNode> {
  /**
   * @generated from field: string lesson_id = 1;
   */
  lessonId = "";

  /**
   * @generated from field: repeated string resource_ids = 2;
   */
  resourceIds: string[] = [];

  constructor(data?: PartialMessage<LessonNode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.LessonNode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonNode {
    return new LessonNode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonNode {
    return new LessonNode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonNode {
    return new LessonNode().fromJsonString(jsonString, options);
  }

  static equals(a: LessonNode | PlainMessage<LessonNode> | undefined, b: LessonNode | PlainMessage<LessonNode> | undefined): boolean {
    return proto3.util.equals(LessonNode, a, b);
  }
}

/**
 * Tables
 *
 * @generated from message geneo.content.db.CourseIndexContent
 */
export class CourseIndexContent extends Message<CourseIndexContent> {
  /**
   * @generated from field: int32 course_index_content_id = 1;
   */
  courseIndexContentId = 0;

  /**
   * @generated from field: int32 course_id = 2;
   */
  courseId = 0;

  /**
   * @generated from field: geneo.content.db.ContentModuleNode course_node = 3;
   */
  courseNode?: ContentModuleNode;

  /**
   * Indicates student clicker activity
   *
   * @generated from field: optional bool is_active = 4;
   */
  isActive?: boolean;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 9;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: int32 version = 10;
   */
  version = 0;

  /**
   * @generated from field: optional string nano_id = 11;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<CourseIndexContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.CourseIndexContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "course_index_content_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "course_node", kind: "message", T: ContentModuleNode },
    { no: 4, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CourseIndexContent {
    return new CourseIndexContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CourseIndexContent {
    return new CourseIndexContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CourseIndexContent {
    return new CourseIndexContent().fromJsonString(jsonString, options);
  }

  static equals(a: CourseIndexContent | PlainMessage<CourseIndexContent> | undefined, b: CourseIndexContent | PlainMessage<CourseIndexContent> | undefined): boolean {
    return proto3.util.equals(CourseIndexContent, a, b);
  }
}

/**
 * @generated from message geneo.content.db.LessonModuleTag
 */
export class LessonModuleTag extends Message<LessonModuleTag> {
  /**
   * @generated from field: int32 lesson_module_tag_id = 1;
   */
  lessonModuleTagId = 0;

  /**
   * @generated from field: string lesson_id = 2;
   */
  lessonId = "";

  /**
   * @generated from field: repeated int32 module_ids = 3;
   */
  moduleIds: number[] = [];

  constructor(data?: PartialMessage<LessonModuleTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.LessonModuleTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_module_tag_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "module_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonModuleTag {
    return new LessonModuleTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonModuleTag {
    return new LessonModuleTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonModuleTag {
    return new LessonModuleTag().fromJsonString(jsonString, options);
  }

  static equals(a: LessonModuleTag | PlainMessage<LessonModuleTag> | undefined, b: LessonModuleTag | PlainMessage<LessonModuleTag> | undefined): boolean {
    return proto3.util.equals(LessonModuleTag, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ResourceModuleTag
 */
export class ResourceModuleTag extends Message<ResourceModuleTag> {
  /**
   * @generated from field: int32 resource_module_tag_id = 1;
   */
  resourceModuleTagId = 0;

  /**
   * @generated from field: string resource_id = 2;
   */
  resourceId = "";

  /**
   * @generated from field: repeated int32 module_ids = 3;
   */
  moduleIds: number[] = [];

  constructor(data?: PartialMessage<ResourceModuleTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ResourceModuleTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_module_tag_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "module_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceModuleTag {
    return new ResourceModuleTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceModuleTag {
    return new ResourceModuleTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceModuleTag {
    return new ResourceModuleTag().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceModuleTag | PlainMessage<ResourceModuleTag> | undefined, b: ResourceModuleTag | PlainMessage<ResourceModuleTag> | undefined): boolean {
    return proto3.util.equals(ResourceModuleTag, a, b);
  }
}

/**
 * @generated from message geneo.content.db.ResourceLessonTag
 */
export class ResourceLessonTag extends Message<ResourceLessonTag> {
  /**
   * @generated from field: int32 resource_lesson_tag_id = 1;
   */
  resourceLessonTagId = 0;

  /**
   * @generated from field: string resource_id = 2;
   */
  resourceId = "";

  /**
   * @generated from field: repeated string lesson_ids = 3;
   */
  lessonIds: string[] = [];

  constructor(data?: PartialMessage<ResourceLessonTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.ResourceLessonTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_lesson_tag_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lesson_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceLessonTag {
    return new ResourceLessonTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceLessonTag {
    return new ResourceLessonTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceLessonTag {
    return new ResourceLessonTag().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceLessonTag | PlainMessage<ResourceLessonTag> | undefined, b: ResourceLessonTag | PlainMessage<ResourceLessonTag> | undefined): boolean {
    return proto3.util.equals(ResourceLessonTag, a, b);
  }
}

/**
 * @generated from message geneo.content.db.CurricullumLO
 */
export class CurricullumLO extends Message<CurricullumLO> {
  /**
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * @generated from field: int32 book_id = 2;
   */
  bookId = 0;

  /**
   * @generated from field: int32 course_id = 3;
   */
  courseId = 0;

  /**
   * @generated from field: int32 module_id = 4;
   */
  moduleId = 0;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 5;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * make connections with learning outcome
   *
   * @generated from field: repeated int32 learning_outcome_ids = 6;
   */
  learningOutcomeIds: number[] = [];

  constructor(data?: PartialMessage<CurricullumLO>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.content.db.CurricullumLO";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 6, name: "learning_outcome_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CurricullumLO {
    return new CurricullumLO().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CurricullumLO {
    return new CurricullumLO().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CurricullumLO {
    return new CurricullumLO().fromJsonString(jsonString, options);
  }

  static equals(a: CurricullumLO | PlainMessage<CurricullumLO> | undefined, b: CurricullumLO | PlainMessage<CurricullumLO> | undefined): boolean {
    return proto3.util.equals(CurricullumLO, a, b);
  }
}

