import { Workbox } from 'workbox-window';
import { MCB_LOGIN } from './routeHandling/RoutesNomenclature';
import { getDeviceType } from '@geneo2-web/shared-ui';
export default function registerServiceWorker() {
  const device = getDeviceType();
  try {
    if (
      process.env.NODE_ENV !== 'development' &&
      'serviceWorker' in navigator
    ) {
      const wb = new Workbox('sw.js');
      if ('serviceWorker' in navigator && device !== 'android') {
        window.addEventListener('load', () => {
          navigator.serviceWorker
            .register('firebase-messaging-sw.js', {
              scope: '/firebase-cloud-messaging-push-scope',
            })
            .then(function (registration) {
              console.log(
                'Service worker for /app registered',
                registration.scope
              );
            });
        });
      } else if (device === 'android') {
        console.log(
          'Android device detected. Skipping Firebase service worker registration'
        );
      } else {
        console.log(
          'Service worker registration failed: Service worker not supported'
        );
      }

      wb.addEventListener('waiting', (event) => {
        event.sw?.postMessage({ type: 'SKIP_WAITING' });
      });

      wb.register()
        .then((registration) => {
          if (registration) {
            console.log(
              'Service Worker registered with scope:',
              registration.scope
            );
          } else {
            console.error(
              'Service worker registration failed: Registration is undefined.'
            );
          }
        })
        .catch((error) => {
          console.error('Service worker registration failed:', error);
        });

      wb.addEventListener('controlling', (event) => {
        const currentUrl = window.location.href;
        const currentPath = window.location.pathname;
        if (event.isUpdate) {
          console.log(
            "Refreshing the page to update the service worker's cache"
          );
          if (currentPath === MCB_LOGIN) {
            // Remove query parameters
            const baseUrl = currentUrl.split('?')[0];
            window.location.replace(baseUrl); // This will change the URL without the query parameters
          } else {
            window.location.reload(); // Regular reload for other routes
          }
        }
      });
    }
  } catch (error) {
    console.log('registerServiceWorker - error:', error);
  }
}
