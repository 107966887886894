import { Timestamp } from '@bufbuild/protobuf';
import {
  HomeworkEventData,
  QuestionData,
} from '@protos/geneo_ai/ai.student.events.data_pb';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { v4 as uuidv4 } from 'uuid';
import { saveMessageSession } from '../../storage';
import {
  IHomeworkCloseArgs,
  IHomeworkEventArgs,
  IHomeworkOpenArgs,
  IQuestionCloseArgs,
  IQuestionOpenArgs,
} from './inputArgs';
import {
  HomeworkCloseArgsValidator,
  HomeworkEventArgsValidator,
  HomeworkOpenArgsValidator,
  QuestionCloseArgsValidator,
  QuestionOpenArgsValidator,
} from './inputArgsValidator';
import { HOMEWORK_KEY, REVIEW_HOMEWORK_KEY } from './storageKeys';
import {
  checkNgetHomeworkEventData,
  clearEventData,
  getHomeworkEventData,
  getHomeworkSessionData,
  getSessionDataWithIncrimentedIndex,
  getnewHomeworkEventData,
  hasEventData,
  timeDifference,
} from './utils';

export function homeworkStorageKey(homeworkId: number) {
  return `${HOMEWORK_KEY}!${homeworkId}`;
}

export function reviewHomeworkStorageKey(homeworkId: number) {
  return `${REVIEW_HOMEWORK_KEY}!${homeworkId}`;
}

export function homeworkOpen(args: IHomeworkOpenArgs): HomeworkEventData {
  const { error } = HomeworkOpenArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { openTimestamp, homeworkId, questionIds, homeworkType } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const homeworkData = getnewHomeworkEventData(storageKey);
  const homeworkSessionId = uuidv4();
  if (homeworkData !== null) {
    throw new Error(
      `Homework Data has to be null but ${JSON.stringify(
        homeworkData
      )} is present"`
    );
  } else {
    const eventData = new HomeworkEventData({
      homeworkId,
      homeworkSessionId: homeworkSessionId,
      questionIds: questionIds,
      openTimestamp: Timestamp.fromDate(openTimestamp),
      actionId: homeworkSessionId,
      homeworkType: homeworkType,
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function homeworkReviewOpen(args: IHomeworkOpenArgs): HomeworkEventData {
  // const { error } = HomeworkOpenArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, homeworkId, questionIds, homeworkType } = args;
  const storageKey = reviewHomeworkStorageKey(homeworkId);
  const homeworkData = getnewHomeworkEventData(storageKey);
  if (homeworkData !== null) {
    throw new Error(
      `Homework Data has to be null but ${JSON.stringify(
        homeworkData
      )} is present"`
    );
  } else {
    const eventData = new HomeworkEventData({
      homeworkId,
      homeworkSessionId: uuidv4(),
      questionIds: questionIds,
      openTimestamp: Timestamp.fromDate(openTimestamp),
      homeworkType: homeworkType,
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getHomeworkOpenEvent(args: IHomeworkEventArgs): StudentEvent {
  // const { error } = HomeworkEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { homeworkId, isReattempted, isResume, isOffline } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  if (eventData === undefined) {
    throw new Error(
      `eventData can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        reattempt: isReattempted,
        resume: isResume,
        homeworkType: eventData.homeworkType,
        HomeworkActionType: {
          case: 'open',
          value: {
            questionIds: eventData.questionIds,
          },
        },
      },
    },
  });
}

export function questionOpen(args: IQuestionOpenArgs): HomeworkEventData {
  // const { error } = QuestionOpenArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, questionId, homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const homeworkData = getHomeworkEventData(storageKey);
  if (!homeworkData) {
    throw new Error('homeworkData can not be undefined');
  }

  // if (homeworkData !== null) {
  //   throw new Error(
  //     `Homework Data has to be null but ${JSON.stringify(
  //       homeworkData
  //     )} is present"`
  //   );
  // } else {
  // const homeworkData = new HomeworkEventData();
  const questionData = new QuestionData();

  questionData.questionId = questionId;
  (questionData.openTimestamp = Timestamp.fromDate(openTimestamp)),
    (homeworkData.questionData = questionData);
  saveMessageSession(storageKey, homeworkData);
  return homeworkData;
}
// }

export function getQuestionOpenEvent(args: IHomeworkEventArgs): StudentEvent {
  // const { error } = HomeworkEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { homeworkId, isOffline } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  if (eventData === undefined) {
    throw new Error(
      `eventData can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  if (eventData.questionData === undefined) {
    throw new Error(
      `questionData can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.questionData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        homeworkType: eventData.homeworkType,
        HomeworkActionType: {
          case: 'questionOpen',
          value: {
            questionId: eventData?.questionData.questionId,
          },
        },
      },
    },
  });
}

export function questionSubmit(args: IQuestionCloseArgs): HomeworkEventData {
  // const { error } = QuestionCloseArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { closeTimestamp, questionId, homeworkId, status, answer } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const homeworkData = getHomeworkEventData(storageKey);
  if (!homeworkData) {
    throw new Error('homeworkData can not be undefined');
  }
  if (homeworkData.questionData === undefined) {
    throw new Error(
      `questionData can not be undefined in ${JSON.stringify(homeworkData)}`
    );
  }
  // if (homeworkData !== null) {
  //   throw new Error(
  //     `Homework Data has to be null but ${JSON.stringify(
  //       homeworkData
  //     )} is present"`
  //   );
  // } else {
  // const homeworkData = new HomeworkEventData();
  // const questionData = new QuestionData();
  homeworkData.questionData.questionId = questionId;
  homeworkData.questionData.status = status;
  homeworkData.questionData.answer = answer;

  (homeworkData.questionData.closeTimestamp =
    Timestamp.fromDate(closeTimestamp)),
    // (homeworkData.questionData = questionData);
    saveMessageSession(storageKey, homeworkData);
  return homeworkData;
}

export function getQuestionSubmitEvent(args: IHomeworkEventArgs): StudentEvent {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId, isOffline } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  // if (eventData.closeTimestamp === undefined) {
  //   throw new Error(
  //     `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
  //   );
  // }
  const sessionData = getSessionDataWithIncrimentedIndex();
  const homeworkSessionData = getHomeworkSessionData();
  // if (homeworkSessionData.homework === undefined) {
  //   throw new Error(
  //     `homework Session id can not be undefined in ${JSON.stringify(
  //       homeworkSessionData
  //     )}`
  //   );
  // }

  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  if (eventData.questionData === undefined) {
    throw new Error(
      `questionData can not be undefined in ${JSON.stringify(eventData)}`
    );
  }

  if (eventData.questionData.openTimestamp === undefined) {
    throw new Error(
      `questionData.openTimestamp can not be undefined in ${JSON.stringify(
        eventData
      )}`
    );
  }
  if (eventData.questionData.closeTimestamp === undefined) {
    throw new Error(
      `questionData.closeTimestamp can not be undefined in ${JSON.stringify(
        eventData
      )}`
    );
  }
  const timespent: number = timeDifference(
    eventData.questionData.openTimestamp,
    eventData.questionData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.questionData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        homeworkType: eventData.homeworkType,
        HomeworkActionType: {
          case: 'questionSubmit',
          value: {
            timespent: timespent,
            questionId: eventData.questionData.questionId,
            status: eventData.questionData.status,
            answer: eventData.questionData.answer,
          },
        },
      },
    },
  });
}

export function questionReview(args: IQuestionCloseArgs): HomeworkEventData {
  const { error } = QuestionCloseArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { closeTimestamp, questionId, homeworkId, status, answer } = args;
  const storageKey = reviewHomeworkStorageKey(homeworkId);
  const homeworkData = getHomeworkEventData(storageKey);
  if (!homeworkData) {
    throw new Error('homeworkData can not be undefined');
  }

  if (homeworkData !== null) {
    throw new Error(
      `Homework Data has to be null but ${JSON.stringify(
        homeworkData
      )} is present"`
    );
  } else {
    const homeworkData = new HomeworkEventData();

    const questionData = new QuestionData();
    questionData.questionId = questionId;
    questionData.status = status;
    questionData.answer = answer;

    (questionData.closeTimestamp = Timestamp.fromDate(closeTimestamp)),
      (homeworkData.questionData = questionData);
    saveMessageSession(storageKey, homeworkData);
    return homeworkData;
  }
}

export function getQuestionReviewEvent(args: IHomeworkEventArgs): StudentEvent {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId, isOffline } = args;
  const storageKey = reviewHomeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  const homeworkSessionData = getHomeworkSessionData();
  if (homeworkSessionData.homework === undefined) {
    throw new Error(
      `homework Session id can not be undefined in ${JSON.stringify(
        homeworkSessionData
      )}`
    );
  }
  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  if (eventData.questionData === undefined) {
    throw new Error(
      `questionData can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        homeworkType: eventData.homeworkType,
        HomeworkActionType: {
          case: 'questionReviewSummary',
          value: {
            timespent: timespent,
            questionId: eventData.questionData.questionId,
          },
        },
      },
    },
  });
}

export function getHomeworkReviewOpenEvent(
  args: IHomeworkEventArgs
): StudentEvent {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId, isOffline } = args;
  const storageKey = reviewHomeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  const homeworkSessionData = getHomeworkSessionData();
  if (!homeworkSessionData) {
    throw new Error('homeworkSessionData can not be undefined');
  }
  if (!eventData) {
    throw new Error('eventData can not be undefined');
  }

  // if (homeworkSessionData.homework === undefined) {
  //   throw new Error(
  //     `homework Session id can not be undefined in ${JSON.stringify(
  //       homeworkSessionData
  //     )}`
  //   );
  // }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        homeworkType: eventData.homeworkType,
        HomeworkActionType: {
          case: 'reviewOpen',
          value: {
            questionIds: eventData.questionIds,
          },
        },
      },
    },
  });
}

export function homeworkClose(args: IHomeworkCloseArgs): HomeworkEventData {
  const { error } = HomeworkCloseArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { closeTimestamp, homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const homeworkData = getHomeworkEventData(storageKey);
  if (!homeworkData) {
    throw new Error('homeworkData can not be undefined');
  }

  if (homeworkData.homeworkId !== homeworkId) {
    throw new Error(
      `Homework Id in ${JSON.stringify(
        homeworkData
      )} can't be different from provided "${homeworkId}"`
    );
  } else {
    homeworkData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, homeworkData);
    return homeworkData;
  }
}

export function getHomeworkCloseEvent(args: IHomeworkEventArgs): StudentEvent {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId, isOffline } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  if (!eventData) {
    throw new Error('eventData can not be undefined');
  }
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const homeworkSessionData = getHomeworkSessionData();
  // if (homeworkSessionData.homework === undefined) {
  //   throw new Error(
  //     `homework Session id can not be undefined in ${JSON.stringify(
  //       homeworkSessionData
  //     )}`
  //   );
  // }

  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        homeworkType: eventData.homeworkType,
        HomeworkActionType: {
          case: 'close',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function homeworkReviewClose(
  args: IHomeworkCloseArgs
): HomeworkEventData {
  const { error } = HomeworkCloseArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { closeTimestamp, homeworkId } = args;
  const storageKey = reviewHomeworkStorageKey(homeworkId);
  const homeworkData = getHomeworkEventData(storageKey);
  if (!homeworkData) {
    throw new Error('homeworkData can not be undefined');
  }

  if (homeworkData.homeworkId !== homeworkId) {
    throw new Error(
      `Homework Id in ${JSON.stringify(
        homeworkData
      )} can't be different from provided "${homeworkId}"`
    );
  } else {
    homeworkData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, homeworkData);
    return homeworkData;
  }
}

export function getHomeworkReviewCloseEvent(
  args: IHomeworkEventArgs
): StudentEvent {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId, isOffline } = args;
  const storageKey = reviewHomeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const homeworkSessionData = getHomeworkSessionData();
  // if (homeworkSessionData.homework === undefined) {
  //   throw new Error(
  //     `homework Session id can not be undefined in ${JSON.stringify(
  //       homeworkSessionData
  //     )}`
  //   );
  // }

  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        homeworkType: eventData.homeworkType,
        HomeworkActionType: {
          case: 'reviewClose',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function clearHomeworkData(args: IHomeworkEventArgs) {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  clearEventData(storageKey);
}

export function isHomeworkOpen(args: IHomeworkEventArgs): boolean {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  return hasEventData(storageKey);
}

export function clearReviewHomeworkData(args: IHomeworkEventArgs) {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId } = args;
  const storageKey = reviewHomeworkStorageKey(homeworkId);
  clearEventData(storageKey);
}

export function isReviewHomeworkOpen(args: IHomeworkEventArgs): boolean {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId } = args;
  const storageKey = reviewHomeworkStorageKey(homeworkId);
  return hasEventData(storageKey);
}
