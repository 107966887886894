import { v4 as uuidv4 } from 'uuid';

import { Timestamp } from '@bufbuild/protobuf';
import {
  ResourcePageContentEventData,
  ResourcePageInstructionEventData,
  ResourcePageQuestionEventData,
  VideoEventRange,
} from '@protos/geneo_ai/ai.student.events.data_pb';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { saveMessageSession } from '../../storage';
import {
  IContentCloseArgs,
  IContentEventArgs,
  IContentOpenArgs,
  IContentQuestionSubmitEventArgs,
  IInstructionCloseArgs,
  IInstructionEventArgs,
  IInstructionOpenArgs,
  IQuestionEventArgs,
  IRPQuestionCloseArgs,
  IRPQuestionEventArgs,
  IRPQuestionOpenArgs,
  IVideoPlayArgs,
  IVideoRange,
  IVideoStopArgs,
  IVideoUpdatePositionArgs,
} from './inputArgs';
import {
  ContentEventArgsValidator,
  InstructionCloseArgsValidator,
  InstructionEventArgsValidator,
  InstructionOpenArgsValidator,
  QuestionEventArgsValidator,
  VideoPlayArgsValidator,
  VideoStopArgsValidator,
  VideoUpdatePositionArgsValidator,
} from './inputArgsValidator';
import { CONTENT_KEY, INSTRUCTION_KEY, RPQUESTION_KEY } from './storageKeys';
import {
  checkNgetResourcePageContentEventData,
  checkNgetResourcePageInstructionEventData,
  checkNgetResourcePageQuestionEventData,
  clearEventData,
  getContentSessionData,
  getResourcePageContentEventData,
  getResourcePageInstructionEventData,
  getResourcePageQuestionEventData,
  getSessionDataWithIncrimentedIndex,
  hasEventData,
  timeDifference,
} from './utils';

export function contentStorageKey(contentId: string) {
  return `${CONTENT_KEY}!${contentId}`;
}

export function questionStorageKey(questionId: string) {
  return `${RPQUESTION_KEY}!${questionId}`;
}

export function instructionStorageKey(instructionId: string) {
  return `${INSTRUCTION_KEY}!${instructionId}`;
}

export function contentOpen(
  args: IContentOpenArgs
): ResourcePageContentEventData {
  // const { error } = ContentOpenArgsValidator.validate(args);
  // console.log('error:', error);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, contentId, resourceId, pageNumber, type } = args;
  const storageKey = contentStorageKey(contentId);
  const contentData = getResourcePageContentEventData(storageKey);
  if (contentData !== null) {
    throw new Error(
      `Content Data has to be null but ${JSON.stringify(
        contentData
      )} is present"`
    );
  } else {
    const eventData = new ResourcePageContentEventData({
      contentId,
      resourceId,
      pageNumber,
      actionId: uuidv4(),
      type,
      openTimestamp: Timestamp.fromDate(openTimestamp),
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getContentOpenEvent(args: IContentEventArgs): StudentEvent {
  // const { error } = ContentEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { contentId, isOffline } = args;
  const storageKey = contentStorageKey(contentId);
  const eventData = checkNgetResourcePageContentEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const contentSessionData = getContentSessionData();
  // if (contentSessionData.content === undefined) {
  //   throw new Error(
  //     `content Session id can not be undefined in ${JSON.stringify(
  //       contentSessionData
  //     )}`
  //   );
  // }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'resourcePageContentAction',
      value: {
        actionId: eventData.actionId,
        resourceId: eventData.resourceId,
        pageNumber: eventData.pageNumber,
        ResourceContentType: {
          case: 'open',
          value: {
            PageContentType: {
              case: 'content',
              value: {
                contentId,
                type: eventData.type,
              },
            },
          },
        },
      },
    },
  });
}

export function resourceQuestionOpen(
  args: IRPQuestionOpenArgs
): ResourcePageQuestionEventData {
  // const { error } = RPQuestionOpenArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, questionId, resourceId, pageNumber, type } = args;
  const storageKey = questionStorageKey(questionId);
  const questionData = getResourcePageQuestionEventData(storageKey);
  if (questionData !== null) {
    throw new Error(
      `question Data has to be null but ${JSON.stringify(
        questionData
      )} is present"`
    );
  } else {
    const eventData = new ResourcePageQuestionEventData({
      questionId,
      resourceId,
      pageNumber,
      actionId: uuidv4(),
      type,
      openTimestamp: Timestamp.fromDate(openTimestamp),
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getResourceQuestionOpenEvent(
  args: IRPQuestionEventArgs
): StudentEvent {
  // const { error } = ContentEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { questionId, isOffline } = args;
  const storageKey = questionStorageKey(questionId);
  const eventData = checkNgetResourcePageQuestionEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const contentSessionData = getContentSessionData();
  // if (contentSessionData.content === undefined) {
  //   throw new Error(
  //     `content Session id can not be undefined in ${JSON.stringify(
  //       contentSessionData
  //     )}`
  //   );
  // }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'resourcePageContentAction',
      value: {
        actionId: eventData.actionId,
        resourceId: eventData.resourceId,
        pageNumber: eventData.pageNumber,
        ResourceContentType: {
          case: 'open',
          value: {
            PageContentType: {
              case: 'question',
              value: {
                questionId,
                type: eventData.type,
              },
            },
          },
        },
      },
    },
  });
}

export function resourceInstructionOpen(
  args: IInstructionOpenArgs
): ResourcePageInstructionEventData {
  // const { error } = InstructionOpenArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, instructionId, resourceId, pageNumber } = args;
  const storageKey = instructionStorageKey(instructionId);
  const instructionData = getResourcePageInstructionEventData(storageKey);
  if (instructionData !== null) {
    throw new Error(
      `instruction Data has to be null but ${JSON.stringify(
        instructionData
      )} is present"`
    );
  } else {
    const eventData = new ResourcePageInstructionEventData({
      instructionId,
      resourceId,
      pageNumber,
      actionId: uuidv4(),
      openTimestamp: Timestamp.fromDate(openTimestamp),
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getResourceInstructionOpenEvent(
  args: IInstructionEventArgs
): StudentEvent {
  // const { error } = InstructionEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { instructionId, isOffline } = args;
  const storageKey = instructionStorageKey(instructionId);
  const eventData = checkNgetResourcePageInstructionEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const contentSessionData = getContentSessionData();
  // if (contentSessionData.content === undefined) {
  //   throw new Error(
  //     `content Session id can not be undefined in ${JSON.stringify(
  //       contentSessionData
  //     )}`
  //   );
  // }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'resourcePageContentAction',
      value: {
        actionId: eventData.actionId,
        resourceId: eventData.resourceId,
        pageNumber: eventData.pageNumber,
        ResourceContentType: {
          case: 'open',
          value: {
            PageContentType: {
              case: 'instruction',
              value: {
                instructionId,
              },
            },
          },
        },
      },
    },
  });
}

export function getResPageQuestionSubmitClose(
  args: IRPQuestionCloseArgs
): ResourcePageQuestionEventData {
  // const { error } = RPQuestionCloseArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { closeTimestamp, questionId } = args;
  const storageKey = questionStorageKey(questionId);
  const questionData = getResourcePageQuestionEventData(storageKey);
  if (!questionData) {
    throw new Error('question Data can not be undefined');
  }
  if (questionData.questionId !== questionId) {
    throw new Error(
      `question Id in ${JSON.stringify(
        questionData
      )} can't be different from provided "${questionId}"`
    );
  } else {
    questionData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, questionData);
    return questionData;
  }
}

export function getResPageQuestionSubmitCloseEvent(
  args: IContentQuestionSubmitEventArgs
): StudentEvent {
  // const { error } = RPQuestionSubmitEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { questionId, answer, status, isOffline } = args;
  const storageKey = questionStorageKey(questionId);
  const eventData = checkNgetResourcePageQuestionEventData(storageKey);
  if (
    eventData.closeTimestamp === undefined ||
    eventData.openTimestamp === undefined
  ) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const contentSessionData = getContentSessionData();
  // if (contentSessionData.content === undefined) {
  //   throw new Error(
  //     `content Session id can not be undefined in ${JSON.stringify(
  //       contentSessionData
  //     )}`
  //   );
  // }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'resourcePageContentAction',
      value: {
        actionId: eventData.actionId,
        resourceId: eventData.resourceId,
        pageNumber: eventData.pageNumber,
        ResourceContentType: {
          case: 'close',
          value: {
            ResourcePageSummaryType: {
              case: 'question',
              value: {
                timespent,
                status,
                answer,
                questionId,
                type: eventData.type,
              },
            },
          },
        },
      },
    },
  });
}

export function getResPageInstructionClose(
  args: IInstructionCloseArgs
): ResourcePageInstructionEventData {
  // const { error } = InstructionCloseArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { closeTimestamp, instructionId } = args;
  const storageKey = instructionStorageKey(instructionId);
  const instructionData = getResourcePageInstructionEventData(storageKey);
  if (!instructionData) {
    throw new Error('Content Data can not be undefined');
  }
  if (instructionData.instructionId !== instructionId) {
    throw new Error(
      `Content Id in ${JSON.stringify(
        instructionData
      )} can't be different from provided "${instructionId}"`
    );
  } else {
    instructionData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, instructionData);
    return instructionData;
  }
}

export function getResPageInstructionCloseEvent(
  args: IInstructionEventArgs
): StudentEvent {
  // const { error } = InstructionEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { instructionId, isOffline } = args;
  const storageKey = instructionStorageKey(instructionId);
  const eventData = checkNgetResourcePageInstructionEventData(storageKey);
  if (
    eventData.closeTimestamp === undefined ||
    eventData.openTimestamp === undefined
  ) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  const contentSessionData = getContentSessionData();
  if (contentSessionData === undefined) {
    throw new Error(
      `content Session id can not be undefined in ${JSON.stringify(
        contentSessionData
      )}`
    );
  }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'resourcePageContentAction',
      value: {
        actionId: eventData.actionId,
        resourceId: eventData.resourceId,
        pageNumber: eventData.pageNumber,
        ResourceContentType: {
          case: 'close',
          value: {
            ResourcePageSummaryType: {
              case: 'instruction',
              value: {
                timespent,
                instructionId,
              },
            },
          },
        },
      },
    },
  });
}

export function videoPlay(args: IVideoPlayArgs) {
  // const { error } = VideoPlayArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { contentId, playPosition, speed } = args;
  const storageKey = contentStorageKey(contentId);
  const eventData = checkNgetResourcePageContentEventData(storageKey);
  if (eventData.ranges.length > 0) {
    const lastRange = eventData.ranges[eventData.ranges.length - 1];
    if (lastRange.endPosition === undefined) {
      throw new Error(
        `Video: ${contentId} should not be played before stopping in ${JSON.stringify(
          eventData
        )}`
      );
    }
  }
  const messageInstance = new VideoEventRange();
  messageInstance.startPosition = playPosition;
  messageInstance.speed = speed;
  eventData.ranges.push(messageInstance);
  saveMessageSession(storageKey, eventData);
  return eventData;
}

export function videoStop(args: IVideoStopArgs) {
  // const { error } = VideoStopArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { contentId, stopPosition } = args;
  const storageKey = contentStorageKey(contentId);
  const eventData = checkNgetResourcePageContentEventData(storageKey);
  console.log('eventData:', eventData);
  if (eventData.ranges.length === 0) {
    throw new Error(
      `Video: ${contentId} can not be stopped before playing in ${JSON.stringify(
        eventData
      )}`
    );
  } else {
    const lastRange = eventData.ranges[eventData.ranges.length - 1];
    console.log('lastRange:', lastRange);

    // if (lastRange.endPosition !== undefined) {
    //   throw new Error(
    //     `Video: ${contentId} can not be stopped before playing in ${JSON.stringify(
    //       eventData
    //     )}`
    //   );
    // } else {
    //   lastRange.endPosition = stopPosition;
    // }

    if (lastRange.endPosition === undefined) {
      lastRange.endPosition = stopPosition;
    }
  }
  saveMessageSession(storageKey, eventData);
  return eventData;
}

export function videoUpdatePosition(args: IVideoUpdatePositionArgs) {
  // const { error } = VideoUpdatePositionArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { contentId, videoPosition } = args;
  const storageKey = contentStorageKey(contentId);
  const videoData = checkNgetResourcePageContentEventData(storageKey);
  videoData.videoPosition = videoPosition;
  saveMessageSession(storageKey, videoData);
  return videoData;
}

export function contentClose(
  args: IContentCloseArgs
): ResourcePageContentEventData {
  // const { error } = ContentCloseArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { closeTimestamp, contentId } = args;
  const storageKey = contentStorageKey(contentId);
  const contentData = getResourcePageContentEventData(storageKey);
  if (!contentData) {
    throw new Error('Content Data can not be undefined');
  }
  if (contentData.contentId !== contentId) {
    throw new Error(
      `Content Id in ${JSON.stringify(
        contentData
      )} can't be different from provided "${contentId}"`
    );
  } else {
    contentData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, contentData);
    return contentData;
  }
}

function getResultRanges(
  eventData: ResourcePageContentEventData,
  videoId: string
) {
  if (!eventData.ranges || eventData.ranges.length === 0) {
    return [];
  }

  return eventData.ranges.map((videoRange) => {
    if (videoRange.endPosition === undefined) {
      throw new Error(
        `End position for video ${videoId} cannot be undefined in ${JSON.stringify(
          eventData
        )}`
      );
    }

    return {
      start_position: videoRange.startPosition,
      speed: videoRange.speed,
      end_position: videoRange.endPosition,
    };
  });
}

function getVideoPlaytime(ranges: IVideoRange[]) {
  return ranges.reduce((timespent: number, range: IVideoRange) => {
    return (
      timespent + (range.end_position - range.start_position) / range.speed
    );
  }, 0);
}

export function getContentVideoCloseEvent(
  args: IContentEventArgs
): StudentEvent {
  // const { error } = ContentEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { contentId, isOffline } = args;
  const storageKey = contentStorageKey(contentId);
  const eventData = checkNgetResourcePageContentEventData(storageKey);
  if (
    eventData.closeTimestamp === undefined ||
    eventData.openTimestamp === undefined
  ) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const contentSessionData = getContentSessionData();
  // if (contentSessionData.content === undefined) {
  //   throw new Error(
  //     `content Session id can not be undefined in ${JSON.stringify(
  //       contentSessionData
  //     )}`
  //   );
  // }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );
  const ranges = getResultRanges(eventData, contentId);
  const playtime = getVideoPlaytime(ranges);

  // const repeatedRangeMessages = ranges.map((range) => {
  //   const messageInstance = new VideoEventRange();
  //   messageInstance.startPosition = range.start_position;
  //   messageInstance.endPosition = range.end_position;
  //   messageInstance.speed = range.speed;
  //   return messageInstance;
  // });

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'resourcePageContentAction',
      value: {
        actionId: eventData.actionId,
        resourceId: eventData.resourceId,
        pageNumber: eventData.pageNumber,
        ResourceContentType: {
          case: 'close',
          value: {
            ResourcePageSummaryType: {
              case: 'content',
              value: {
                timespent,
                contentId,
                playtime: Math.ceil(playtime),
                type: eventData.type,
                ranges,
              },
            },
          },
        },
      },
    },
  });
}

export function getContentCloseEvent(args: IContentEventArgs): StudentEvent {
  // const { error } = ContentEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { contentId, isOffline } = args;
  const storageKey = contentStorageKey(contentId);
  const eventData = checkNgetResourcePageContentEventData(storageKey);
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  const contentSessionData = getContentSessionData();
  if (
    eventData.closeTimestamp === undefined ||
    eventData.openTimestamp === undefined
  ) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'resourcePageContentAction',
      value: {
        actionId: eventData.actionId,
        resourceId: eventData.resourceId,
        pageNumber: eventData.pageNumber,
        ResourceContentType: {
          case: 'close',
          value: {
            ResourcePageSummaryType: {
              case: 'content',
              value: {
                timespent,
                contentId,
                type: eventData.type,
              },
            },
          },
        },
      },
    },
  });
}

export function clearResourcePageContentData(args: IContentEventArgs) {
  // const { error } = ContentEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { contentId } = args;
  const storageKey = contentStorageKey(contentId);
  clearEventData(storageKey);
}

export function isResourcePageContentOpen(args: IContentEventArgs): boolean {
  // const { error } = ContentEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { contentId } = args;
  const storageKey = contentStorageKey(contentId);
  return hasEventData(storageKey);
}

export function clearQuestionData(args: IQuestionEventArgs) {
  const { error } = QuestionEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { questionId } = args;
  const storageKey = questionStorageKey(questionId);
  clearEventData(storageKey);
}

export function isQuestionOpen(args: IQuestionEventArgs): boolean {
  const { error } = QuestionEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { questionId } = args;
  const storageKey = questionStorageKey(questionId);
  return hasEventData(storageKey);
}

export function clearInstructionData(args: IInstructionEventArgs) {
  const { error } = InstructionEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { instructionId } = args;
  const storageKey = instructionStorageKey(instructionId);
  clearEventData(storageKey);
}

export function isInstructionOpen(args: IInstructionEventArgs): boolean {
  const { error } = InstructionEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { instructionId } = args;
  const storageKey = instructionStorageKey(instructionId);
  return hasEventData(storageKey);
}
