// AUTH
export const LOGIN = '/login';
export const ENTER_UID = '/enter-uid';
export const LOGIN_PASSWORD = '/login_password';
export const RESET_PASSWORD_OTP = '/reset-password-otp';
export const CHOOSE_PROFILE = '/choose-profile';
export const SWITCH_PROFILE = '/switch-profile';
export const RESET_PASSWORD = '/reset-password';
export const REGISTRATION = '/registration';
export const VERIFY_DETAILS = '/verify-details';
export const VERIFY_PHONE = '/verify-phone';
export const VERIFY_EMAIL = '/verify-email';
export const SET_PASSWORD = '/set-password';
export const OTP_SCREEN = '/otp-screen';
export const PROFILE_OTP_SCREEN = '/profile-otp-screen';
export const LOGIN_UID_PASSWORD = '/login-uid-password';

export const ENTER_UID_VK12 = '/vk12';
export const VK12_LOGIN_PASSWORD = '/vk12_login_password';

// DEMO
export const DOCS = '/docs';
export const DEMO_RESOURCE = '/demo-resource';
export const DEMO_QUESTION = '/demo-question';
export const DEMO_RESOURCE_CARD = '/demo-resource-card';

// HOME
export const HOME = '/home';

// HOMEWORK
export const HOMEWORK_STUDENT_DASHBOARD = '/student-homework-dash';
export const HOMEWORK_ACTIVE = '/active-homework';
export const HOMEWORK_COMPLETED = '/completed-homework';
export const HOMEWORK_SUBMITTED = '/submitted-homework';
export const HOMEWORK_VIEW_RECOMMENDATION =
  '/student-homework-view-recommendation';
export const HOMEWORK_START_COVER = '/homework-start-cover';

// PERFORMANCE
export const PERFORMANCE_STUDENT_DASHBOARD = '/student-performance-dash';
export const PERFORMANCE_STUDENT_CHAPTERWISE =
  '/student-performance-chapterwise';

// LEARN
export const LEARN_DASHBOARD = '/learn-dashboard';
export const LEARN_TOPIC_SELECTION = '/learn-topic-selection';
export const TOPIC_LESSON = '/topic-lesson';
export const LESSON_VIEW = '/lesson-view';
export const RESOURCE_VIEW = '/resource-view';
export const SEE_ALL_LESSON_PLAN = '/see-all-lesson-plan';

// SIDEBAR
export const PROFILE = '/profile';
export const NOTIFICATION = '/notifications';
export const HELP = '/help';
export const FAQ = '/faq';
export const DISCLAIMER = '/disclaimer';
export const CHANGE_PASSWORD = '/change-password';
//OFFLINE
export const OFFLINE_LEARN_DASHBOARD = '/offline/learn-dashboard';
export const OFFLINE_LEARN_TOPIC_SELECTION = '/offline/learn-topic-selection';
export const OFFLINE_TOPIC_LESSON = '/offline/topic-lesson';
export const OFFLINE_LESSON_VIEW = '/offline/lesson-view';
export const OFFLINE_RESOURCE_VIEW = '/offline/resource-lesson';
export const DOWNLOAD_SETTINGS = '/download-settings';

// MCB Login
export const MCB_LOGIN = '/mcb-login';
export const MCB_APP_LOGIN = '/mcb-app-login';
