import { Timestamp } from '@bufbuild/protobuf';
import { ChapterEventData } from '@protos/geneo_ai/ai.student.events.data_pb';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { v4 as uuidv4 } from 'uuid';
import { saveMessageSession } from '../../storage';
import {
  IChapterCloseArgs,
  IChapterEventArgs,
  IChapterOpenArgs,
} from './inputArgs';
import {
  ChapterCloseArgsValidator,
  ChapterEventArgsValidator,
  ChapterOpenArgsValidator,
} from './inputArgsValidator';
import { CHAPTER_KEY } from './storageKeys';
import {
  checkNgetChapterEventData,
  clearEventData,
  getBookSessionData,
  getChapterEventData,
  getSessionDataWithIncrimentedIndex,
  hasEventData,
  initialiseChapterSession,
  timeDifference,
} from './utils';

export function chapterStorageKey(chapterId: number) {
  return `${CHAPTER_KEY}!${chapterId}`;
}

export function chapterOpen(args: IChapterOpenArgs): ChapterEventData {
  // const { error } = ChapterOpenArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, chapterId } = args;
  const storageKey = chapterStorageKey(chapterId);
  const chapterData = getChapterEventData(storageKey);
  const chapterSessionId = uuidv4();
  initialiseChapterSession({
    chapterSessionId,
    chapterId,
  });
  if (chapterData !== null) {
    throw new Error(
      `Chapter Data has to be null but ${JSON.stringify(
        chapterData
      )} is present"`
    );
  } else {
    const eventData = new ChapterEventData({
      chapterId,
      chapterSessionId: chapterSessionId,
      openTimestamp: Timestamp.fromDate(openTimestamp),
      actionId: chapterSessionId,
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getChapterOpenEvent(args: IChapterEventArgs): StudentEvent {
  // const { error } = ChapterEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { chapterId, isOffline } = args;
  const storageKey = chapterStorageKey(chapterId);
  const eventData = checkNgetChapterEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  const bookSessionData = getBookSessionData();
  if (
    bookSessionData.chapter === undefined ||
    bookSessionData.book === undefined
  ) {
    throw new Error(
      `chapter Session id can not be undefined in ${JSON.stringify(
        bookSessionData
      )}`
    );
  }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    bookSessionId: bookSessionData.book.sessionId,
    chapterSessionId: bookSessionData.chapter.sessionId,
    isOffline: isOffline,
    EventType: {
      case: 'chapterAction',
      value: {
        actionId: eventData.actionId,
        chapterId,
        ChapterActionType: {
          case: 'open',
          value: true,
        },
      },
    },
  });
}

export function chapterClose(args: IChapterCloseArgs): ChapterEventData {
  // const { error } = ChapterCloseArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { closeTimestamp, chapterId } = args;
  const storageKey = chapterStorageKey(chapterId);
  const chapterData = getChapterEventData(storageKey);

  if (chapterData === null) {
    throw new Error(`Chapter data is null for chapterId: ${chapterId}`);
  }

  if (chapterData.chapterId !== chapterId) {
    throw new Error(
      `Chapter Id in ${JSON.stringify(
        chapterData
      )} can't be different from provided "${chapterId}"`
    );
  } else {
    chapterData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, chapterData);
    return chapterData;
  }
}

export function getChapterCloseEvent(args: IChapterEventArgs): StudentEvent {
  // const { error } = ChapterEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { chapterId, isOffline } = args;
  const storageKey = chapterStorageKey(chapterId);
  const eventData = checkNgetChapterEventData(storageKey);
  if (
    eventData.closeTimestamp === undefined ||
    eventData.openTimestamp === undefined
  ) {
    throw new Error(
      `closeTimestamp/openTimestamp can not be undefined in ${JSON.stringify(
        eventData
      )}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  const bookSessionData = getBookSessionData();
  if (
    bookSessionData.chapter === undefined ||
    bookSessionData.book === undefined
  ) {
    throw new Error(
      `chapter Session id can not be undefined in ${JSON.stringify(
        bookSessionData
      )}`
    );
  }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    bookSessionId: bookSessionData.book.sessionId,
    chapterSessionId: bookSessionData.chapter.sessionId,
    isOffline: isOffline,
    EventType: {
      case: 'chapterAction',
      value: {
        actionId: eventData.actionId,
        chapterId,
        ChapterActionType: {
          case: 'close',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function clearChapterData(args: IChapterEventArgs) {
  const { error } = ChapterEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { chapterId } = args;
  const storageKey = chapterStorageKey(chapterId);
  clearEventData(storageKey);
}

export function isChapterOpen(args: IChapterEventArgs): boolean {
  const { error } = ChapterEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { chapterId } = args;
  const storageKey = chapterStorageKey(chapterId);
  return hasEventData(storageKey);
}
