// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.admin.portal.apis.proto (package geneo.lms.admin.portal.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AdminContentLockUpdateRequest, AdminContentLockUpdateResponse, AdminModuleLockInfoFetchRequest, AdminModuleLockInfoFetchResponse, FetchAdminSchoolsInfoRequest, FetchAdminSchoolsInfoResponse, FetchAssessmentReportsRequest, FetchAssessmentReportsResponse, FetchSchoolClassSectionCoursesInfoResponse, FetchSchoolClassSectionCoursesInfoResquest, FetchSchoolContentApprovalRequestListRequest, FetchSchoolContentApprovalRequestListResponse, SendAssessmentReportEmailRequest, SendAssessmentReportEmailResponse, UpdateContentApprovalByAdminRequest, UpdateContentApprovalByAdminResponse } from "./lms.admin.portal.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1
 */
export const LmsAdminPortalAPIServiceV1 = {
  typeName: "geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1.adminContentLockUpdate
     */
    adminContentLockUpdate: {
      name: "adminContentLockUpdate",
      I: AdminContentLockUpdateRequest,
      O: AdminContentLockUpdateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1.adminModuleLockInfoFetch
     */
    adminModuleLockInfoFetch: {
      name: "adminModuleLockInfoFetch",
      I: AdminModuleLockInfoFetchRequest,
      O: AdminModuleLockInfoFetchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1.fetchAdminSchoolsInfo
     */
    fetchAdminSchoolsInfo: {
      name: "fetchAdminSchoolsInfo",
      I: FetchAdminSchoolsInfoRequest,
      O: FetchAdminSchoolsInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1.fetchSchoolClassSectionCoursesInfo
     */
    fetchSchoolClassSectionCoursesInfo: {
      name: "fetchSchoolClassSectionCoursesInfo",
      I: FetchSchoolClassSectionCoursesInfoResquest,
      O: FetchSchoolClassSectionCoursesInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1.fetchSchoolContentApprovalRequestList
     */
    fetchSchoolContentApprovalRequestList: {
      name: "fetchSchoolContentApprovalRequestList",
      I: FetchSchoolContentApprovalRequestListRequest,
      O: FetchSchoolContentApprovalRequestListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1.updateContentApprovalByAdmin
     */
    updateContentApprovalByAdmin: {
      name: "updateContentApprovalByAdmin",
      I: UpdateContentApprovalByAdminRequest,
      O: UpdateContentApprovalByAdminResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1.fetchAssessmentReports
     */
    fetchAssessmentReports: {
      name: "fetchAssessmentReports",
      I: FetchAssessmentReportsRequest,
      O: FetchAssessmentReportsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.admin.portal.apis.LmsAdminPortalAPIServiceV1.emailAssessmentReport
     */
    emailAssessmentReport: {
      name: "emailAssessmentReport",
      I: SendAssessmentReportEmailRequest,
      O: SendAssessmentReportEmailResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

