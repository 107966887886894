import { Timestamp } from '@bufbuild/protobuf';
import { ResourcePageEventData } from '@protos/geneo_ai/ai.student.events.data_pb';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { v4 as uuidv4 } from 'uuid';
import { saveMessageSession } from '../../storage';
import {
  IResourcePageCloseArgs,
  IResourcePageEventArgs,
  IResourcePageOpenArgs,
} from './inputArgs';
import {
  ResourcePageCloseArgsValidator,
  ResourcePageEventArgsValidator,
  ResourcePageOpenArgsValidator,
} from './inputArgsValidator';
import { RESOURCE_PAGE_CONTENT_KEY } from './storageKeys';
import {
  checkNgetResourcePageEventData,
  clearEventData,
  getResourcePageEventData,
  getSessionDataWithIncrimentedIndex,
  hasEventData,
  timeDifference,
} from './utils';

export function resourcePageStorageKey(resourceId: string, pageNumber: number) {
  return `${RESOURCE_PAGE_CONTENT_KEY}!${resourceId}!${pageNumber}`;
}

export function resourcePageOpen(
  args: IResourcePageOpenArgs
): ResourcePageEventData {
  // const { error } = ResourcePageOpenArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, resourceId, pageNumber } = args;
  const storageKey = resourcePageStorageKey(resourceId, pageNumber);
  const resourceData = getResourcePageEventData(storageKey);
  const resourceSessionId = uuidv4();
  if (resourceData !== null) {
    throw new Error(
      `Resource Data has to be null but ${JSON.stringify(
        resourceData
      )} is present"`
    );
  } else {
    const eventData = new ResourcePageEventData({
      resourceId,
      pageNumber,
      resourceSessionId: resourceSessionId,
      openTimestamp: Timestamp.fromDate(openTimestamp),
      actionId: resourceSessionId,
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getResourcePageOpenEvent(
  args: IResourcePageEventArgs
): StudentEvent {
  // const { error } = ResourcePageEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { resourceId, pageNumber, isOffline } = args;
  const storageKey = resourcePageStorageKey(resourceId, pageNumber);
  const eventData = checkNgetResourcePageEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const resourceSessionData = getResourcePageSessionData();
  // if (resourceSessionData.resourcePage === undefined) {
  //   throw new Error(
  //     `resource Session id can not be undefined in ${JSON.stringify(
  //       resourceSessionData
  //     )}`
  //   );
  // }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'resourcePageAction',
      value: {
        actionId: eventData.actionId,
        resourceId,
        pageNumber,
        ResourcePageActionType: {
          case: 'open',
          value: true,
        },
      },
    },
  });
}

export function resourcePageClose(
  args: IResourcePageCloseArgs
): ResourcePageEventData {
  // const { error } = ResourcePageCloseArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { closeTimestamp, resourceId, pageNumber } = args;
  const storageKey = resourcePageStorageKey(resourceId, pageNumber);
  const resourceData = getResourcePageEventData(storageKey);

  if (resourceData === null) {
    throw new Error(`Resource data is null for resourceId: ${resourceId}`);
  }

  if (resourceData.resourceId !== resourceId) {
    throw new Error(
      `Resource Id in ${JSON.stringify(
        resourceData
      )} can't be different from provided "${resourceId}"`
    );
  } else {
    resourceData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, resourceData);
    return resourceData;
  }
}

export function getResourcePageCloseEvent(
  args: IResourcePageEventArgs
): StudentEvent {
  // const { error } = ResourcePageEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { resourceId, pageNumber, isOffline } = args;
  const storageKey = resourcePageStorageKey(resourceId, pageNumber);
  const eventData = checkNgetResourcePageEventData(storageKey);
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const resourceSessionData = getResourcePageSessionData();
  // if (resourceSessionData.resourcePage === undefined) {
  //   throw new Error(
  //     `resource Session id can not be undefined in ${JSON.stringify(
  //       resourceSessionData
  //     )}`
  //   );
  // }

  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }

  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'resourcePageAction',
      value: {
        actionId: eventData.actionId,
        resourceId,
        pageNumber,
        ResourcePageActionType: {
          case: 'close',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function clearResourcePageData(args: IResourcePageEventArgs) {
  // const { error } = ResourcePageEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { resourceId, pageNumber } = args;
  const storageKey = resourcePageStorageKey(resourceId, pageNumber);
  clearEventData(storageKey);
}

export function isResourcePageOpen(args: IResourcePageEventArgs): boolean {
  // const { error } = ResourcePageEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { resourceId, pageNumber } = args;
  const storageKey = resourcePageStorageKey(resourceId, pageNumber);
  return hasEventData(storageKey);
}
