import {
  ActionPopUpSingleButton,
  ChapterInfoCard,
  IStyles,
  deserify,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import { LessonInfo } from '@protos/learning_management/lms.lesson.common.apis_pb';
import {
  ContentLockStatusType,
  Feature,
} from '@protos/school_management/school.db_pb';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../app/Context/GlobalContextProvider';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../reduxStore/reduxHooks';
import { onLessonCardClick } from '../../../../../utils/learn';
import { interactionEvent } from '../../../../Auth/Login/login_logout.events';
import { useDownloadContext } from '../../../../../app/Context/DownloadContextProviderV2';

interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}
const styles: IStyles = {};

interface IOngoingLessonCard {
  loading: boolean;
  error: boolean;
  lockedData?: ContentLockModuleData;
  showLockPopup: () => void;
}

export const OngoingLessonCard = (props: IOngoingLessonCard) => {
  const { loading, error, lockedData, showLockPopup } = props;
  const { subject_id, chapter_id } = useParams();
  const subjectId = Number(subject_id);
  const { isIpadOnly } = useGlobalContext();
  const { isOffline } = useDownloadContext();
  const chapterId = Number(chapter_id);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { selected_module_lessons } = deserify(
    useAppSelector((state) => state.learn)
  );
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });
  const [actionPopupState, setActionPopupState] = useState(false);

  const lessons: LessonInfo[] = selected_module_lessons?.lessons || [];

  const ongoinglesson =
    lessons.find((lesson) => !!lesson.resourceIds.length) || lessons[0];
  // const ongoinglesson =
  //   lessons
  //     .filter((lesson) => lesson.resourceIds.length > 0) // Filter out lessons with no resourceIds
  //     .sort(
  //       (a, b) =>
  //         (b.lastSessionTime?.toDate().getTime() ?? 0) -
  //         (a.lastSessionTime?.toDate().getTime() ?? 0)
  //     )[0] || lessons[0];
  const location = useLocation();
  const dispatch = useAppDispatch();
  const lessonId = ongoinglesson?.lessonId;
  const topicId = ongoinglesson?.moduleId;

  const lockStatus = lockedData?.moduleUpdatedLockInfo?.lessonsLockInfo?.find(
    (val) => val.lessonId === ongoinglesson?.lessonId
  )?.lockStatus;

  if (loading || error || !ongoinglesson) {
    return null;
  }

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{ marginBottom: { xs: pxToRem(15), md: pxTovW(15) } }}
      >
        Ongoing Lesson
      </Typography>
      <ChapterInfoCard
        // isContentLockFeature={isContentLockFeature}
        // isLocked={lockStatus}
        variant={isIpadOnly ? 'large' : 'medium'}
        mainHeading={ongoinglesson.title}
        image={getMediaBasePath(
          ongoinglesson.posterImageUrl,
          'processedMediaBucket'
        )}
        iconDetails={[
          {
            iconName: 'clock',
            text: ongoinglesson.estimatedTimeInMin + ' Min',
          },
          {
            iconName: 'questions',
            text: ongoinglesson.resourceIds.length + ' Resources',
          },
        ]}
        withArrow={!isMobile}
        rootStyle={{
          // backgroundColor: 'red',
          width: { xs: '100%', md: pxToRem(360), lg: pxTovW(692) },
          cursor: 'pointer',
        }}
        tagName={isMobile ? undefined : 'Ongoing'}
        cardClickHandler={async () => {
          // if (
          //   isContentLockFeature &&
          //   lockStatus === ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
          // ) {
          //   showLockPopup();
          // } else {
          //   if (lessonId) {
          //     await interactionEvent({
          //       url: 'Student_topic_lesson',
          //       context: 'ongoing_lesson',
          //       name: 'LESSON_OPEN',
          //     });

          //     onLessonCardClick(
          //       dispatch,
          //       navigate,
          //       {
          //         lessonId,
          //         subjectId,
          //         topicId,
          //         chapterId,
          //       },
          //       location.pathname
          //     );
          //   }
          // }

          if (lessonId) {
            await interactionEvent({
              url: 'Student_topic_lesson',
              context: 'ongoing_lesson',
              name: 'LESSON_OPEN',
              isOffline,
            });

            onLessonCardClick(
              dispatch,
              navigate,
              {
                lessonId,
                subjectId,
                topicId,
                chapterId,
              },
              location.pathname
            );
          }
        }}
      />
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
    </Box>
  );
};
