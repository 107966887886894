// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.assessment.teacher.apis.proto (package geneo.lms.assessment.teacher.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AssessmentAssignmentSubmitRequest, AssessmentAssignmentSubmitResponse, AssessmentCreationSubmitRequest, AssessmentCreationSubmitResponse, GetModuleFilteredQuestionsV2Request, GetModuleFilteredQuestionsV2Response, GetModuleQuestionStatsV2Request, GetModuleQuestionStatsV2Response, GetTeacherAssessmentsListRequest, GetTeacherAssessmentsListResponse, TeacherAssessmentClassStatsRequest, TeacherAssessmentClassStatsResponse } from "./lms.assessment.teacher.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.assessment.teacher.apis.LmsAssessmentTeacherAPIServiceV1
 */
export const LmsAssessmentTeacherAPIServiceV1 = {
  typeName: "geneo.lms.assessment.teacher.apis.LmsAssessmentTeacherAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.assessment.teacher.apis.LmsAssessmentTeacherAPIServiceV1.getTeacherAssessmentsList
     */
    getTeacherAssessmentsList: {
      name: "getTeacherAssessmentsList",
      I: GetTeacherAssessmentsListRequest,
      O: GetTeacherAssessmentsListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.teacher.apis.LmsAssessmentTeacherAPIServiceV1.assessmentCreationSubmit
     */
    assessmentCreationSubmit: {
      name: "assessmentCreationSubmit",
      I: AssessmentCreationSubmitRequest,
      O: AssessmentCreationSubmitResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.teacher.apis.LmsAssessmentTeacherAPIServiceV1.assessmentAssignmentSubmit
     */
    assessmentAssignmentSubmit: {
      name: "assessmentAssignmentSubmit",
      I: AssessmentAssignmentSubmitRequest,
      O: AssessmentAssignmentSubmitResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.teacher.apis.LmsAssessmentTeacherAPIServiceV1.getModuleQuestionsWithFilters
     */
    getModuleQuestionsWithFilters: {
      name: "getModuleQuestionsWithFilters",
      I: GetModuleFilteredQuestionsV2Request,
      O: GetModuleFilteredQuestionsV2Response,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.teacher.apis.LmsAssessmentTeacherAPIServiceV1.getModuleQuestionStats
     */
    getModuleQuestionStats: {
      name: "getModuleQuestionStats",
      I: GetModuleQuestionStatsV2Request,
      O: GetModuleQuestionStatsV2Response,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.teacher.apis.LmsAssessmentTeacherAPIServiceV1.getAssessmentClassStats
     */
    getAssessmentClassStats: {
      name: "getAssessmentClassStats",
      I: TeacherAssessmentClassStatsRequest,
      O: TeacherAssessmentClassStatsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

