import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import { LmsAssessmentCommonAPIServiceV1 } from '@protos/learning_management/lms.assessment.common.apis_connect';
import {
  ResponseStatusCodeFunction,
  lmsTransport,
  lmsTransportWithLogoutHandler,
} from './transports';

export const LmsAssessmentCommonAPIServiceV1Client = createPromiseClient(
  LmsAssessmentCommonAPIServiceV1,
  lmsTransport
);

export const useLmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    LmsAssessmentCommonAPIServiceV1,
    lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
