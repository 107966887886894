declare global {
  interface Navigator {
    standalone?: boolean;
  }
}

function getDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Check for Android devices
  if (/Android/i.test(userAgent)) {
    // Check for WebView on Android
    if (
      /wv|WebView/i.test(userAgent) ||
      /Version\/.*Chrome\/[.0-9]* Mobile/i.test(userAgent)
    ) {
      return 'android'; // Keep the string as 'android_app'
    }
    return 'android_browser'; // Standard Android Browser
  }

  // Check for iOS devices
  else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    // Check for WebView or standalone mode
    if (navigator.standalone || /WebView/i.test(userAgent)) {
      return 'ios'; // iOS App using WebView
    }
    return 'ios_browser'; // Standard iOS Browser
  }

  // Default to web for other devices
  return 'web';
}

export default getDeviceType;
