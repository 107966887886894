import { useEffect, useState } from 'react';

import { PlainMessage, Timestamp } from '@bufbuild/protobuf';
import {
  ContentIdAttempt,
  IStyles,
  IconWrapper,
  ImageWrapper,
  InPageHeader,
  Loader,
  NoContentCard,
  PaginationButton,
  ResourcePageContent,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  resourceTypeName,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import {
  Content,
  DownloadedSubject,
  Question,
  ResourceContent,
  ResourceTeacherInstruction,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import {
  ContentAttempt,
  SessionStatusEnum,
  TeacherContentResponse,
} from '@protos/learning_management/lms.db_pb';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import {
  OFFLINE_LESSON_VIEW,
  OFFLINE_RESOURCE_VIEW,
} from '../../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';
import { ResourceContent as RawResourceContent } from '@protos/content_management/content.common.apis_pb';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';

const styles: IStyles = {
  paginationButtonBox: {
    position: 'fixed',
    bottom: { md: pxTovW(30), xs: pxToRem(10) },
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  iconTextBoxContainer: {
    width: '100%',
    display: 'flex',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    alignItems: 'center',
    mt: { xs: pxToRem(10), md: pxTovW(10) },
  },
  headingImage: {
    width: { xs: pxToRem(60), md: pxToRem(105) },
    height: { xs: pxToRem(60), md: pxToRem(105) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    objectFit: 'cover',
  },
  resourcePage: {
    paddingBottom: {
      xs: pxToRem(75),
      md: pxTovW(110),
    },
  },
};

interface IProps {
  headerButtonText?: string;
  buttonClickHandler?: () => void;
}
export const ResourceViewOffline = (props: IProps) => {
  const navigate = useNavigate();
  const { subject_id, chapter_id, topic_id, lesson_plan_id, resource_id } =
    useParams();
  const subjectId = Number(subject_id);
  const chapterId = Number(chapter_id);
  const topicId = Number(topic_id);
  const [downloadedSubject, setDownloadedSubject] = useState<
    PlainMessage<DownloadedSubject>
  >(new DownloadedSubject());
  const userInfo = deserify(useAppSelector((state) => state.auth.userInfo));
  const chaps = downloadedSubject?.chapters || {};
  const selectedChapter = chaps[chapterId];
  const topics = selectedChapter?.downloadedTopics || {};
  const selectedTopic = topics[topicId];
  const lessonPlans = selectedTopic?.downloadedLessonPlans || {};
  const selectedLessonPlan = lesson_plan_id
    ? lessonPlans[lesson_plan_id]
    : undefined;
  const resources = selectedLessonPlan?.downloadedResources || {};
  const selectedResource = resource_id ? resources[resource_id] : undefined;
  const selectedResourceContent = new ResourceContent(
    selectedResource?.resourceContent
  );

  const [completedResourceIds, setCompletedResourceIds] = useState<string[]>(
    []
  );

  const dispatch = useDispatch();

  const location = useLocation();

  const pageContents = (
    new ResourceContent(selectedResourceContent)?.resourceContentData
      ?.pageContent || []
  ).sort((a, b) => a.pageNumber - b.pageNumber);
  const [pageNumber, setPageNumber] = useState(0);
  const [contentIdAttempt, updateContentIdAttempt] = useState<ContentIdAttempt>(
    {}
  );

  const pageContent = pageContents[pageNumber];
  const [startTime, setStartTime] = useState(Timestamp.fromDate(new Date()));
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'loading'
  );
  const { setSelectedFunction } = useGlobalContext();
  const { isOffline } = useDownloadContext();
  const [createLoader, setCreateLoader] = useState(false);
  useEffect(() => {
    fetchResourceData();
    updateContentIdAttempt({});
  }, [resource_id]);

  useEffect(() => {
    setSelectedFunction(() => () => {
      handleResourceCompleteOrEnd(SessionStatusEnum.SESSION_STATUS_EXITED);
    });
    return () => {
      setSelectedFunction(null);
    };
  }, [resource_id, contentIdAttempt]);

  useEffect(() => {
    setStartTime(Timestamp.fromDate(new Date()));
  }, [pageNumber, resource_id]);

  const fetchResourceData = async () => {
    setPageNumber(0);
    setLoading('loading');
    const indexDbData = await findDownloadedSubjectByParams({
      subjectId: subjectId,
    });
    if (indexDbData.length > 0) {
      const data = indexDbData[0];
      setDownloadedSubject(data);
    }
    setLoading('completed');
  };

  const handleAnswerSubmit = (contentId: string, answer: string[]) => {
    const contentIdAttemptCopy = deepClone(contentIdAttempt);
    if (!contentIdAttemptCopy[contentId]) {
      contentIdAttemptCopy[contentId] = new TeacherContentResponse({
        contentId: contentId,
        responses: [],
      });
    }
    contentIdAttemptCopy[contentId].responses.push(
      new ContentAttempt({
        startTime: startTime,
        endTime: Timestamp.fromDate(new Date()),
        answer,
      })
    );
    updateContentIdAttempt(contentIdAttemptCopy);
  };

  const getUpdatedContentAttempt = () => {
    const contentIdAttemptCopy = deepClone(contentIdAttempt);
    pageContent?.contents.forEach((con) => {
      const contentValue = con.model.value;
      const contentId =
        contentValue instanceof Content
          ? contentValue.contentId
          : contentValue instanceof Question
          ? contentValue.questionId
          : contentValue instanceof ResourceTeacherInstruction
          ? contentValue.resourceTeacherInstructionId
          : undefined;
      if (contentId) {
        if (!contentIdAttemptCopy[contentId]) {
          contentIdAttemptCopy[contentId] = new TeacherContentResponse({
            contentId: contentId,
          });
        }
        if (
          !(contentValue instanceof Question) ||
          contentIdAttemptCopy[contentId].responses.length === 0
        ) {
          contentIdAttemptCopy[contentId].responses.push(
            new ContentAttempt({
              startTime: startTime,
              endTime: Timestamp.fromDate(new Date()),
              answer: undefined,
            })
          );
        }
      }
    });
    return contentIdAttemptCopy;
  };

  const handleResourceCompleteOrEnd = async (
    sessionStatus: SessionStatusEnum
  ) => {
    if (sessionStatus == SessionStatusEnum.SESSION_STATUS_EXITED) {
      navigate(
        `${OFFLINE_LESSON_VIEW}/${subject_id}/${chapter_id}/${topic_id}/${lesson_plan_id}`
      );
      return;
    } else {
      const allResources = selectedLessonPlan?.downloadedResources;
      if (allResources) {
        const resourceArray =
          Object.values(allResources).sort((a, b) => a.order - b.order) || [];
        const currentIndex = resourceArray.findIndex(
          (val) => val.resourceId == resource_id
        );
        if (currentIndex >= 0 && resourceArray[currentIndex + 1]?.resourceId) {
          navigate(
            `${OFFLINE_RESOURCE_VIEW}/${subject_id}/${chapter_id}/${topic_id}/${lesson_plan_id}/${
              resourceArray[currentIndex + 1].resourceId
            }`
          );
          return;
        }
      }
    }
    navigate(-1);
  };

  const onNextClick = async () => {
    if (pageContents[pageNumber + 1]) {
      const contentIdAttemptCopy = getUpdatedContentAttempt();
      updateContentIdAttempt(contentIdAttemptCopy);
      setPageNumber(pageNumber + 1);
    } else {
      handleResourceCompleteOrEnd(SessionStatusEnum.SESSION_STATUS_COMPLETED);
    }

    await interactionEvent({
      url: 'Student_resource_view',
      context: 'navigation_bar',
      name: 'NEXT',
      isOffline: isOffline,
    });
  };

  const onPreviousClick = async () => {
    if (pageNumber > 0 && pageContents[pageNumber - 1]) {
      setPageNumber(pageNumber - 1);
      await interactionEvent({
        url: 'Student_resource_view',
        context: 'navigation_bar',
        name: 'PREVIOUS',
        isOffline: isOffline,
      });
    }
  };

  const renderHeading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          flexGrow: 1,
          alignItems: 'center',
          padding: { xs: pxToRem(15), md: pxTovW(0) },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: { xs: pxToRem(10), md: pxTovW(20) },
            // flexGrow: '1',
          }}
        >
          <ImageWrapper
            name="chapterImage"
            type="png"
            styles={styles.headingImage}
            parentFolder="tempAssets"
            path={getMediaBasePath(
              selectedResource?.posterImageUrl,
              'processedMediaBucket'
            )}
          />
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: pxToRem(10) }}
          >
            <Typography variant="elementH1">
              {selectedResource?.title}
            </Typography>
            <Box sx={styles.iconTextBoxContainer}>
              {[
                {
                  iconName: 'clock',
                  text: (selectedResource?.estimatedTimeInMin || 0) + 'Mins',
                },
                {
                  iconName: resourceTypeName(
                    selectedResource?.resourceType ||
                      Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED
                  )?.icon,
                  text: resourceTypeName(
                    selectedResource?.resourceType ||
                      Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED
                  )?.name,
                },
              ].map((elem, index) => (
                <IconTextBox
                  key={index}
                  iconName={elem.iconName}
                  text={elem.text}
                  index={index}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  if (loading === 'loading') {
    return <Loader />;
  }

  if (loading === 'error') {
    return (
      <Box
        width={{ xs: undefined, md: '95%' }}
        margin={{ xs: pxToRem(20), md: pxTovW(40) }}
        marginRight={{ xs: pxToRem(20), md: pxTovW(40) }}
      >
        <NoContentCard variant="error" icon="error" text="Error Occured" />
      </Box>
    );
  }
  if (createLoader === true) {
    return <Loader />;
  }
  if (!resource_id || !selectedResourceContent) {
    return (
      <Box>
        <NoContentCard variant="info" icon="cards" text="No Cards available" />
      </Box>
    );
  }

  return (
    <Box sx={styles.resourcePage}>
      {pageNumber === 0 && <InPageHeader title={renderHeading()} />}

      <ResourcePageContent
        pageContents={pageContents}
        pageNumber={pageNumber}
        contentIdAttempt={contentIdAttempt}
        handleAnswerSubmit={handleAnswerSubmit}
        showSubmitButton={true}
        resource_raw_data={
          new RawResourceContent({
            resourceCategory: selectedResource?.resourceCategoryType,
            resourceType: selectedResource?.resourceType,
            resourceId: selectedResource?.resourceId,
            title: selectedResource?.title,
            posterImageUrl: selectedResource?.posterImageUrl,
            estimatedTimeInMin: selectedResource?.estimatedTimeInMin,
          })
        }
      />

      <Box sx={styles.paginationButtonBox}>
        <PaginationButton
          currentPage={pageNumber + 1}
          totalPages={pageContents.length}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      </Box>
      {/* {createLoader === true && <Loader />} */}
    </Box>
  );
};

interface IBox {
  iconName: string;
  text?: string;
  index: number;
}

const IconTextBox = ({ iconName, text, index }: IBox) => {
  return (
    <Box sx={{ display: 'flex', gap: { xs: pxToRem(4), md: pxTovW(4) } }}>
      <IconWrapper name={iconName} size="md" parentFolder="icons" type="png" />
      <Typography
        variant="bodyText"
        fontWeight={'bold'}
        color={index % 2 === 0 ? 'primary' : 'secondary'}
      >
        {text}
      </Typography>
    </Box>
  );
};
