import {
  ActionPopUpSingleButton,
  IStyles,
  deserify,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { LEARN_TOPIC_SELECTION } from '../../../routeHandling/RoutesNomenclature';
import { getSubjectsMap } from '../../../utils/icons';
import { OngoingLessonCard } from './components/OngoingLessonCard';

import { Module_ModuleCategoryEnum } from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import { Feature } from '@protos/school_management/school.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useDispatch } from 'react-redux';
import {
  setSelectedModuleLessons,
  setSelectedTopicResourceList,
} from '../reducer/learn.slice';
import {
  aiTopicCloseEvent,
  aiTopicOpenEvent,
} from '../utils_learn/learn.events';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { TopicLessonList } from './components/TopicLessonsList';
import { TopicResources } from './components/TopicResources';

const styles: IStyles = {
  root: {
    width: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    paddingTop: { xs: pxToRem(20), md: pxTovW(40) },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
    boxSizing: 'border-box',
    overflowX: 'hidden',
    paddingX: { md: pxToRem(20), lg: pxTovW(240) },
  },
  header: {
    maxWidth: {
      xs: '100%',
      md: '100%',
    },
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
    gap: { xs: pxToRem(24), md: pxTovW(48) },
    margin: '0 auto',
    paddingX: { xs: pxToRem(20), md: 0 },
  },
  bodySection: {
    display: 'flex',
    gap: { xs: pxToRem(48), md: pxTovW(48) },
    flexDirection: { xs: 'column', md: 'row' },

    justifyContent: 'space-between',
    // maxWidth: {
    //   xs: '100%',
    //   md: '80%',
    // },
    // margin: '0 auto',
  },

  twoLineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    // width: '90%',
  },
};

interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}

export const TopicLesson = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [lockedData, setLockedData] = useState<ContentLockModuleData>();
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });
  const [actionPopupState, setActionPopupState] = useState(false);
  const { subject_id, topic_id, chapter_id } = useParams();
  const { isOffline } = useDownloadContext();
  const selectedChapterId = Number(chapter_id);
  const selectedTopicId = Number(topic_id);
  const subjectId = Number(subject_id);
  const moduleLessons = deserify(
    useAppSelector((state) => state.learn.selected_module_lessons)
  );
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  const learnSubjects = user?.learnSubjects || [];
  const subMap = getSubjectsMap(learnSubjects);
  const navigate = useNavigate();
  const { setSelectedFunction } = useGlobalContext();
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    ContentCommonAPIServiceV2ClientWithStatusCodeHandler,
    LessonCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );

  const { selected_module_lessons } = deserify(
    useAppSelector((state) => state.learn)
  );

  useEffect(() => {
    const redirectToLearnDashboard = async () => {
      await aiTopicCloseEvent({
        topicId: Number(topic_id),
        isOffline: isOffline,
      });
      navigate(`${LEARN_TOPIC_SELECTION}/${subjectId}/${chapter_id}`);
    };
    setSelectedFunction(() => redirectToLearnDashboard);

    (async () => {
      // const searchedBookId = getBookId(userInfo, subjectChapterInfo?.subjectId);
      // if (!selected_book_id) dispatch(setHomeSelectedBookId(searchedBookId));
      // await aiBookOpenEvent(
      //   selected_book_id ? selected_book_id : searchedBookId
      // );
      // await aiChapterOpenEvent(Number(chapter_id));
      await aiTopicOpenEvent({
        topicId: Number(topic_id),
        isOffline: isOffline,
      });
      await fetchTopicLessonInfo();
    })();

    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const fetchTopicResources = async () => {
    try {
      const response =
        await ContentCommonAPIServiceV2ClientWithStatusCodeHandler.fetchTopicResources(
          {
            personId: user?.studentProfileId,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            subjectId: subjectId,
            chapterId: selectedChapterId,
            topicId: selectedTopicId,
          }
        );
      if (response) {
        if (response?.data) {
          response.data.categoryResourceMap.forEach((elem) => {
            elem.categoryResources.sort((a, b) => a.rank - b.rank);
          });
          const categoryResourceMap = response.data?.categoryResourceMap;
          dispatch(setSelectedTopicResourceList(categoryResourceMap));
          return;
        }
      }
      dispatch(setSelectedTopicResourceList(undefined));
    } catch (err) {
      dispatch(setSelectedTopicResourceList(undefined));
      throw err;
    }
  };

  const fetchLessonsByModule = async () => {
    try {
      const response =
        await LessonCommonAPIServiceV1ClientWithStatusCodeHandler.fetchLessonsByModule(
          {
            personId: user?.studentProfileId,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            moduleId: selectedTopicId,
            sectionId: user?.classSectionDetails?.sectionId,
          }
        );
      if (response) {
        if (response?.data) {
          // const data = response.data;
          response.data?.lessons.sort(
            (a, b) =>
              (b.lastSessionTime?.toDate().getTime() ?? 0) -
              (a.lastSessionTime?.toDate().getTime() ?? 0)
          );
          // response.data.lessons = sortedData;
          dispatch(setSelectedModuleLessons(response.data));
          return;
        }
      }
      dispatch(setSelectedModuleLessons(undefined));
    } catch (err) {
      dispatch(setSelectedModuleLessons(undefined));
      throw err;
    }
  };

  const fetchTopicLessonInfo = async () => {
    try {
      setLoading(true);
      await fetchLessonsByModule();
      await fetchTopicResources();
      if (isContentLockFeature) {
        await fetchLockContent();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  const fetchLockContent = async () => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.studentModuleLockInfoFetch(
          {
            subjectId: subjectId,
            studentId: user?.studentProfileId,
            moduleId: selectedTopicId,
            category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
          }
        );
      if (response?.data) {
        const data = response.data;
        setLockedData(data);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const showLockPopup = () => {
    setPopupDetails({
      iconName: 'locked@2x',
      buttontext: 'ok',
      popupText:
        'Your school has locked this resource. You will be able to access it once they unlock it',
      ctaHandler: () => setActionPopupState(false),
    });
    setActionPopupState(true);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '100%', md: '45%', lg: '50%' },
            gap: pxToRem(10),
          }}
        >
          <Typography variant="h1" sx={styles.twoLineClamp}>
            {moduleLessons?.topicInfo?.topicTitle}
          </Typography>
          <Typography variant="cardText" sx={{ color: '#007CDC' }}>
            {subjectId ? subMap[subjectId].subjectName || '' : ''}
          </Typography>
        </Box>
        {selected_module_lessons && (
          <OngoingLessonCard
            loading={loading}
            error={error}
            showLockPopup={showLockPopup}
            lockedData={lockedData}
          />
        )}
      </Box>

      <Box sx={styles.bodySection}>
        <Box sx={{ width: { xs: '100%', md: '45%', lg: pxTovW(771) } }}>
          <TopicLessonList
            loading={loading}
            error={error}
            showLockPopup={showLockPopup}
            lockedData={lockedData}
          />
        </Box>
        <Box sx={{ width: { xs: '100%', md: '55%', lg: pxTovW(620) } }}>
          <TopicResources
            loading={loading}
            error={error}
            showLockPopup={showLockPopup}
            lockedData={lockedData}
          />
        </Box>
      </Box>
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
    </Box>
  );
};
