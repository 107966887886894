import { ConnectError } from '@bufbuild/connect';
import { getSessionData } from '@events/student/eventsCreator/utils';
import {
  Loader,
  callStudentEventsApi,
  clearLocalStorageKeys,
  deleteOfflineAccessKeyFromCache,
  deserify,
  getDeviceType,
  getLocalStorage,
  setLocalStorage,
  storeOfflineAccessKeyInCache,
  useCommonServiceClientContext,
  useNotificationPermission,
} from '@geneo2-web/shared-ui';
import {
  DeviceEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { HOME } from '../../../../routeHandling/RoutesNomenclature';
import { resetHomeState } from '../../../Home/reducer/homeDashboard.slice';
import { resetHomeworkState } from '../../../Homework/reducer/homework.slice';
import {
  aiHomeworkCloseEvent,
  aiHomeworkReviewCloseEvent,
} from '../../../Homework/utils_homework/homework.events';
import { resetLearnState } from '../../../Learn/reducer/learn.slice';
import {
  aiBookCloseEvent,
  aiChapterCloseEvent,
  aiContentCloseEvent,
  aiLessonCloseEvent,
  aiResourceCloseEvent,
  aiResourcePageCloseEvent,
  aiTopicCloseEvent,
} from '../../../Learn/utils_learn/learn.events';
import { resetPerformanceState } from '../../../Performance/reducer/performance.slice';
import { aiPerformanceCloseEvent } from '../../../Performance/utils_performance/performance.events';
import {
  aiLoginEvent,
  aiLogoutEvent,
  interactionEvent,
} from '../../Login/login_logout.events';
import {
  resetAuthState,
  setIsMCBUser,
  setUserInfo,
} from '../../reducer/auth.slice';
import { MCBErrorScreen } from './MCBErrorScreen';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';

interface IBackendLogin {
  code?: string;
}

export const BackendLogin = (props: IBackendLogin) => {
  const { code } = props;

  const navigate = useNavigate();
  let auth = getLocalStorage('auth');
  if (auth === 'true' || auth === 'false') {
    auth = JSON.parse(auth);
  }
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    UmsSsoAPIServiceV1ClientWithStatusCodeHandler,
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  useEffect(() => {
    geneoLoginUsingMCBCode();
  }, []);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const android = window.Android;
  const dispatch = useDispatch();
  const deviceType = getDeviceType();
  const userInfo = deserify(useAppSelector((state) => state.auth.userInfo));
  const userId = getLocalStorage('userId');
  const fcmToken = getLocalStorage('fcmToken'); // Convert fcmToken to string
  const deviceId = getLocalStorage('deviceID');
  const { selected_book_id } = deserify(useAppSelector((state) => state.home));
  const {
    selected_chapter_resources,
    selected_module_lessons,
    selected_lesson_content,
    selected_resource_content,
    selected_resource_info,
  } = deserify(useAppSelector((state) => state.learn));
  const { isOffline } = useDownloadContext();
  const { performance_id } = deserify(
    useAppSelector((state) => state.performance)
  );

  const { selected_homework_data } = deserify(
    useAppSelector((state) => state.homework)
  );

  const { permissionGranted, requestPermission } =
    useNotificationPermission('student');

  const notificationAPI = async () => {
    if (!userId) {
      console.log('User ID not found.');
      return;
    }

    try {
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        deviceId: deviceId ? BigInt(deviceId) : undefined,
        profileId: BigInt(userId),
        role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
        deviceType: DeviceEnum.DEVICE_TYPE_WEB,
        deviceToken: fcmToken?.toString(),
        isActive: false,
      });
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        deviceToken: fcmToken?.toString(),
        topic: `${String(userId)}_${ProfileRolesEnum.PROFILE_ROLE_STUDENT}`,
        subscribeTopic: false,
      });
    } catch (err) {
      console.error('Error in Notification API:', err);
    }
  };

  const commonAiCloseEvents = async () => {
    await aiContentCloseEvent({
      pageNumber: selected_resource_info?.pageNumber,
      pageContent: selected_resource_info?.pageContent || [],
      isOffline: isOffline,
    });
    await aiResourcePageCloseEvent({
      resourceId: selected_resource_content?.parsedData.resourceId,
      pageNumber: selected_resource_info?.pageNumber,
      isOffline: isOffline,
    });
    await aiResourceCloseEvent({
      resourceId: selected_resource_content?.parsedData.resourceId,
      isOffline: isOffline,
    });
    await aiLessonCloseEvent({
      lessonId: selected_lesson_content?.lessonId,
      isOffline: isOffline,
    });
    await aiTopicCloseEvent({
      topicId: selected_module_lessons?.moduleId,
      isOffline: isOffline,
    });
    await aiChapterCloseEvent({
      chapterId: selected_chapter_resources?.chapterId,
      isOffline: isOffline,
    });

    await aiPerformanceCloseEvent({
      performanceId: performance_id,
      isOffline: isOffline,
    });

    await aiHomeworkCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
      isOffline: isOffline,
    });
    await aiHomeworkReviewCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
      isOffline: isOffline,
    });
    await aiBookCloseEvent({ bookId: selected_book_id, isOffline: isOffline });
    await aiLogoutEvent({ isOffline: isOffline });
    !isOffline && (await callStudentEventsApi(commonServiceClientContext));
  };

  // Function to handle logout and state reset
  const handleLogout = async () => {
    try {
      await commonAiCloseEvents();
      if (fcmToken) {
        await notificationAPI();
      }
      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.logout({
          profileId: getLocalStorage('userId'),
          role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
        });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(resetAuthState());
      dispatch(resetHomeState());
      dispatch(resetLearnState());
      dispatch(resetPerformanceState());
      dispatch(resetHomeworkState());
      // localStorage.clear();
      clearLocalStorageKeys();
      deleteOfflineAccessKeyFromCache();
    }
  };

  function pushUserDetailsToAndroid(uToken: string, userID: string): void {
    try {
      if (deviceType === 'android') {
        android.pushUserDetails(uToken, userID);
      }
    } catch (error) {
      console.log('Error pushing user details to Android', error);
    }
  }

  const geneoLoginUsingMCBCode = async () => {
    try {
      if (code) {
        const res =
          await UmsSsoAPIServiceV1ClientWithStatusCodeHandler.validateUserSsoLogin(
            {
              code: code,
              role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
              requestOrigin: 'MCB',
            }
          );
        const userId = res.userInfo?.profileId;
        if (auth && userId) {
          if (userId !== userInfo?.studentProfileId) {
            await handleLogout();
          } else {
            navigate(HOME);
            return;
          }
        }
        const resSession =
          await UmsSsoAPIServiceV1ClientWithStatusCodeHandler.createSsoLoginSession(
            {
              profileId: userId,
            }
          );
        if (resSession.activeSessionId) {
          localStorage.setItem(
            'activeSession',
            resSession.activeSessionId.toString()
          );
        }
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.fetchStudentProfile(
            {
              studentProfileId: userId,
            }
          );
        const user_info = response.data;
        if (!user_info) {
          console.log('User info not present');
          return;
        }
        dispatch(setUserInfo(user_info));
        dispatch(setIsMCBUser(true));
        const accessKey = user_info.studentProfileId
          ? 'student-' + user_info.studentProfileId.toString()
          : 'geneo';
        await storeOfflineAccessKeyInCache(accessKey);
        setLocalStorage('userId', user_info.studentProfileId.toString());
        setLocalStorage('role', 'student');
        setLocalStorage('auth', 'true');
        setTimeout(async () => {
          await interactionEvent({
            url: 'student_login',
            context: 'mcb_sso',
            name: 'Login',
            isOffline: isOffline,
          });
        }, 3000);
        await aiLoginEvent({
          studentId: Number(user_info.studentProfileId),
          sessionId: getSessionData().sessionId,
          classId: user_info.classSectionDetails?.classId,
          sectionId: user_info.classSectionDetails?.sectionId,
          schoolId: user_info.schoolDetails?.schoolId,
          boardId: user_info.boardMediumDetails?.boardId,
          regionId: user_info.regionId,
          mediumId: user_info.boardMediumDetails?.mediumId,
          isOffline: isOffline,
        });
        pushUserDetailsToAndroid(
          user_info.token,
          user_info.studentProfileId.toString()
        );
        navigate(HOME);

        if (deviceType !== 'android') {
          await requestPermission();
        }
      }
    } catch (err: any) {
      if (err instanceof ConnectError) {
        setErrorMessage(err.message.replace(/.*\]\s*/, '').trim());
        return;
      }
      setErrorMessage('Internal Error');
    }
  };
  if (errorMessage) {
    return <MCBErrorScreen errorMessage={errorMessage} />;
  }
  return <Loader />;
};
