// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.db.proto (package geneo.lms.db, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { DifficultyLevelEnum, FileEnum, FileExtensionEnum, Question_QuestionEnum } from "@protos/content_management/content.db_pb";
import { Class_ClassEnum, Subject_SubjectEnum } from "@protos/common/common.db_pb";

/**
 * Enum for taskEnum
 *
 * @generated from enum geneo.lms.db.TaskEnum
 */
export enum TaskEnum {
  /**
   * Represents an undefined task
   *
   * @generated from enum value: TASK_UNDEFINED = 0;
   */
  TASK_UNDEFINED = 0,

  /**
   * Represents an automated homework task
   *
   * @generated from enum value: TASK_HOMEWORK_AUTOMATED = 1;
   */
  TASK_HOMEWORK_AUTOMATED = 1,

  /**
   * Represents a custom homework task
   *
   * @generated from enum value: TASK_HOMEWORK_CUSTOM = 2;
   */
  TASK_HOMEWORK_CUSTOM = 2,

  /**
   * Represents a custom homework task
   *
   * @generated from enum value: TASK_HOMEWORK_TEACHER_CUSTOM = 5;
   */
  TASK_HOMEWORK_TEACHER_CUSTOM = 5,

  /**
   * Represents an automated assessment task
   *
   * @generated from enum value: TASK_ASSESSMENT_AUTOMATED = 3;
   */
  TASK_ASSESSMENT_AUTOMATED = 3,

  /**
   * Represents a custom assessment task
   *
   * @generated from enum value: TASK_ASSESSMENT_CUSTOM = 4;
   */
  TASK_ASSESSMENT_CUSTOM = 4,

  /**
   * Represents an automated assessment task
   *
   * @generated from enum value: TASK_FORMATIVE_ASSESSMENT_AUTOMATED = 6;
   */
  TASK_FORMATIVE_ASSESSMENT_AUTOMATED = 6,

  /**
   * Represents a custom assessment task
   *
   * @generated from enum value: TASK_FORMATIVE_ASSESSMENT_CUSTOM = 7;
   */
  TASK_FORMATIVE_ASSESSMENT_CUSTOM = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskEnum)
proto3.util.setEnumType(TaskEnum, "geneo.lms.db.TaskEnum", [
  { no: 0, name: "TASK_UNDEFINED" },
  { no: 1, name: "TASK_HOMEWORK_AUTOMATED" },
  { no: 2, name: "TASK_HOMEWORK_CUSTOM" },
  { no: 5, name: "TASK_HOMEWORK_TEACHER_CUSTOM" },
  { no: 3, name: "TASK_ASSESSMENT_AUTOMATED" },
  { no: 4, name: "TASK_ASSESSMENT_CUSTOM" },
  { no: 6, name: "TASK_FORMATIVE_ASSESSMENT_AUTOMATED" },
  { no: 7, name: "TASK_FORMATIVE_ASSESSMENT_CUSTOM" },
]);

/**
 * Enum for taskEnum
 *
 * @generated from enum geneo.lms.db.AssignmentConfigEnum
 */
export enum AssignmentConfigEnum {
  /**
   * @generated from enum value: ASSIGNMENT_CONFIG_UNDEFINED = 0;
   */
  ASSIGNMENT_CONFIG_UNDEFINED = 0,

  /**
   * @generated from enum value: ASSIGNMENT_CONFIG_SHUFFLING = 1;
   */
  ASSIGNMENT_CONFIG_SHUFFLING = 1,

  /**
   * @generated from enum value: ASSIGNMENT_CONFIG_REATTEMPT = 2;
   */
  ASSIGNMENT_CONFIG_REATTEMPT = 2,

  /**
   * @generated from enum value: ASSIGNMENT_CONFIG_TIMED = 3;
   */
  ASSIGNMENT_CONFIG_TIMED = 3,

  /**
   * @generated from enum value: ASSIGNMENT_CONFIG_ALLOW_LATE_SUBMISSION = 4;
   */
  ASSIGNMENT_CONFIG_ALLOW_LATE_SUBMISSION = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(AssignmentConfigEnum)
proto3.util.setEnumType(AssignmentConfigEnum, "geneo.lms.db.AssignmentConfigEnum", [
  { no: 0, name: "ASSIGNMENT_CONFIG_UNDEFINED" },
  { no: 1, name: "ASSIGNMENT_CONFIG_SHUFFLING" },
  { no: 2, name: "ASSIGNMENT_CONFIG_REATTEMPT" },
  { no: 3, name: "ASSIGNMENT_CONFIG_TIMED" },
  { no: 4, name: "ASSIGNMENT_CONFIG_ALLOW_LATE_SUBMISSION" },
]);

/**
 * Enum for taskActionEnum
 *
 * @generated from enum geneo.lms.db.TaskActionEnum
 */
export enum TaskActionEnum {
  /**
   * Represents an undefined action
   *
   * @generated from enum value: TASK_ACTION_UNDEFINED = 0;
   */
  TASK_ACTION_UNDEFINED = 0,

  /**
   * Represents a read action
   *
   * @generated from enum value: TASK_ACTION_READ = 1;
   */
  TASK_ACTION_READ = 1,

  /**
   * Represents a write action
   *
   * @generated from enum value: TASK_ACTION_WRITE = 2;
   */
  TASK_ACTION_WRITE = 2,

  /**
   * Represents a watch video action
   *
   * @generated from enum value: TASK_ACTION_WATCH_VIDEO = 3;
   */
  TASK_ACTION_WATCH_VIDEO = 3,

  /**
   * Represents a fill in the blank action
   *
   * @generated from enum value: TASK_ACTION_FIB = 4;
   */
  TASK_ACTION_FIB = 4,

  /**
   * Represents a match the following action
   *
   * @generated from enum value: TASK_ACTION_MTF = 5;
   */
  TASK_ACTION_MTF = 5,

  /**
   * Represents an activity action
   *
   * @generated from enum value: TASK_ACTION_ACTIVITY = 6;
   */
  TASK_ACTION_ACTIVITY = 6,

  /**
   * Represents an activity question action
   *
   * @generated from enum value: TASK_ACTION_ACTIVITY_QUESTION = 7;
   */
  TASK_ACTION_ACTIVITY_QUESTION = 7,

  /**
   * Represents a single-choice multiple-choice question action
   *
   * @generated from enum value: TASK_ACTION_MCQ_S = 8;
   */
  TASK_ACTION_MCQ_S = 8,

  /**
   * Represents a multiple-choice multiple-choice question action
   *
   * @generated from enum value: TASK_ACTION_MCQ_M = 9;
   */
  TASK_ACTION_MCQ_M = 9,

  /**
   * Represents a true or false question action
   *
   * @generated from enum value: TASK_ACTION_TF = 10;
   */
  TASK_ACTION_TF = 10,

  /**
   * Represents a short answer question action
   *
   * @generated from enum value: TASK_ACTION_SHORT = 11;
   */
  TASK_ACTION_SHORT = 11,

  /**
   * Represents a long answer question action
   *
   * @generated from enum value: TASK_ACTION_LONG = 12;
   */
  TASK_ACTION_LONG = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskActionEnum)
proto3.util.setEnumType(TaskActionEnum, "geneo.lms.db.TaskActionEnum", [
  { no: 0, name: "TASK_ACTION_UNDEFINED" },
  { no: 1, name: "TASK_ACTION_READ" },
  { no: 2, name: "TASK_ACTION_WRITE" },
  { no: 3, name: "TASK_ACTION_WATCH_VIDEO" },
  { no: 4, name: "TASK_ACTION_FIB" },
  { no: 5, name: "TASK_ACTION_MTF" },
  { no: 6, name: "TASK_ACTION_ACTIVITY" },
  { no: 7, name: "TASK_ACTION_ACTIVITY_QUESTION" },
  { no: 8, name: "TASK_ACTION_MCQ_S" },
  { no: 9, name: "TASK_ACTION_MCQ_M" },
  { no: 10, name: "TASK_ACTION_TF" },
  { no: 11, name: "TASK_ACTION_SHORT" },
  { no: 12, name: "TASK_ACTION_LONG" },
]);

/**
 * Enum for taskCreationStatusEnum
 *
 * @generated from enum geneo.lms.db.TaskCreationStatusEnum
 */
export enum TaskCreationStatusEnum {
  /**
   * Represents an undefined task creation status
   *
   * @generated from enum value: TASK_CREATION_STATUS_UNDEFINED = 0;
   */
  TASK_CREATION_STATUS_UNDEFINED = 0,

  /**
   * Represents an initialized task
   *
   * @generated from enum value: TASK_CREATION_STATUS_INITIALIZED = 1;
   */
  TASK_CREATION_STATUS_INITIALIZED = 1,

  /**
   * Represents a draft task
   *
   * @generated from enum value: TASK_CREATION_STATUS_DRAFT = 2;
   */
  TASK_CREATION_STATUS_DRAFT = 2,

  /**
   * Represents an approved task
   *
   * @generated from enum value: TASK_CREATION_STATUS_APPROVED = 3;
   */
  TASK_CREATION_STATUS_APPROVED = 3,

  /**
   * Represents a duplicated task
   *
   * @generated from enum value: TASK_CREATION_STATUS_DUPLICATED = 4;
   */
  TASK_CREATION_STATUS_DUPLICATED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskCreationStatusEnum)
proto3.util.setEnumType(TaskCreationStatusEnum, "geneo.lms.db.TaskCreationStatusEnum", [
  { no: 0, name: "TASK_CREATION_STATUS_UNDEFINED" },
  { no: 1, name: "TASK_CREATION_STATUS_INITIALIZED" },
  { no: 2, name: "TASK_CREATION_STATUS_DRAFT" },
  { no: 3, name: "TASK_CREATION_STATUS_APPROVED" },
  { no: 4, name: "TASK_CREATION_STATUS_DUPLICATED" },
]);

/**
 * Enum for taskGroupStatusEnum
 *
 * @generated from enum geneo.lms.db.TaskGroupStatusEnum
 */
export enum TaskGroupStatusEnum {
  /**
   * Represents an undefined task group status
   *
   * @generated from enum value: TASK_GROUP_STATUS_UNDEFINED = 0;
   */
  TASK_GROUP_STATUS_UNDEFINED = 0,

  /**
   * Represents an assigned task group
   *
   * @generated from enum value: TASK_GROUP_STATUS_ASSIGNED = 1;
   */
  TASK_GROUP_STATUS_ASSIGNED = 1,

  /**
   * Represents a cancelled task group
   *
   * @generated from enum value: TASK_GROUP_STATUS_CANCELLED = 2;
   */
  TASK_GROUP_STATUS_CANCELLED = 2,

  /**
   * Represents a completed task group
   *
   * @generated from enum value: TASK_GROUP_STATUS_COMPLETED = 3;
   */
  TASK_GROUP_STATUS_COMPLETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskGroupStatusEnum)
proto3.util.setEnumType(TaskGroupStatusEnum, "geneo.lms.db.TaskGroupStatusEnum", [
  { no: 0, name: "TASK_GROUP_STATUS_UNDEFINED" },
  { no: 1, name: "TASK_GROUP_STATUS_ASSIGNED" },
  { no: 2, name: "TASK_GROUP_STATUS_CANCELLED" },
  { no: 3, name: "TASK_GROUP_STATUS_COMPLETED" },
]);

/**
 * Enum for taskEvaluationEnum
 *
 * @generated from enum geneo.lms.db.TaskEvaluationStatusEnum
 */
export enum TaskEvaluationStatusEnum {
  /**
   * Represents an undefined task evaluation status
   *
   * @generated from enum value: TASK_EVALUATION_STATUS_UNDEFINED = 0;
   */
  TASK_EVALUATION_STATUS_UNDEFINED = 0,

  /**
   * Represents a task evaluation not started
   *
   * @generated from enum value: TASK_EVALUATION_STATUS_NOT_STARTED = 1;
   */
  TASK_EVALUATION_STATUS_NOT_STARTED = 1,

  /**
   * Represents a task evaluation in progress
   *
   * @generated from enum value: TASK_EVALUATION_STATUS_IN_PROGRESS = 2;
   */
  TASK_EVALUATION_STATUS_IN_PROGRESS = 2,

  /**
   * Represents a task evaluation completed
   *
   * @generated from enum value: TASK_EVALUATION_STATUS_COMPLETED = 3;
   */
  TASK_EVALUATION_STATUS_COMPLETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskEvaluationStatusEnum)
proto3.util.setEnumType(TaskEvaluationStatusEnum, "geneo.lms.db.TaskEvaluationStatusEnum", [
  { no: 0, name: "TASK_EVALUATION_STATUS_UNDEFINED" },
  { no: 1, name: "TASK_EVALUATION_STATUS_NOT_STARTED" },
  { no: 2, name: "TASK_EVALUATION_STATUS_IN_PROGRESS" },
  { no: 3, name: "TASK_EVALUATION_STATUS_COMPLETED" },
]);

/**
 * Enum for taskStudentStatusEnum
 *
 * @generated from enum geneo.lms.db.TaskStudentAttemptStatusEnum
 */
export enum TaskStudentAttemptStatusEnum {
  /**
   * Represents an undefined task student status
   *
   * @generated from enum value: TASK_STUDENT_STATUS_UNDEFINED = 0;
   */
  TASK_STUDENT_STATUS_UNDEFINED = 0,

  /**
   * Represents an assigned task student status
   *
   * @generated from enum value: TASK_STUDENT_STATUS_PENDING = 1;
   */
  TASK_STUDENT_STATUS_PENDING = 1,

  /**
   * Represents a task in progress student status
   *
   * @generated from enum value: TASK_STUDENT_STATUS_IN_PROGRESS = 2;
   */
  TASK_STUDENT_STATUS_IN_PROGRESS = 2,

  /**
   * Represents a completed task student status
   *
   * @generated from enum value: TASK_STUDENT_STATUS_COMPLETED = 3;
   */
  TASK_STUDENT_STATUS_COMPLETED = 3,

  /**
   * Represents a exited task student status
   *
   * @generated from enum value: TASK_STUDENT_STATUS_EXITED = 4;
   */
  TASK_STUDENT_STATUS_EXITED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskStudentAttemptStatusEnum)
proto3.util.setEnumType(TaskStudentAttemptStatusEnum, "geneo.lms.db.TaskStudentAttemptStatusEnum", [
  { no: 0, name: "TASK_STUDENT_STATUS_UNDEFINED" },
  { no: 1, name: "TASK_STUDENT_STATUS_PENDING" },
  { no: 2, name: "TASK_STUDENT_STATUS_IN_PROGRESS" },
  { no: 3, name: "TASK_STUDENT_STATUS_COMPLETED" },
  { no: 4, name: "TASK_STUDENT_STATUS_EXITED" },
]);

/**
 * Enum for TaskActiveStatusEnum
 *
 * @generated from enum geneo.lms.db.TaskActiveStatusEnum
 */
export enum TaskActiveStatusEnum {
  /**
   * Represents an undefined task active status
   *
   * @generated from enum value: TASK_ACTIVE_STATUS_UNDEFINED = 0;
   */
  TASK_ACTIVE_STATUS_UNDEFINED = 0,

  /**
   * Represents an active task status
   *
   * @generated from enum value: TASK_ACTIVE_STATUS_ACTIVE = 1;
   */
  TASK_ACTIVE_STATUS_ACTIVE = 1,

  /**
   * Represents an inactive task status
   *
   * @generated from enum value: TASK_ACTIVE_STATUS_INACTIVE = 2;
   */
  TASK_ACTIVE_STATUS_INACTIVE = 2,

  /**
   * Represents a suspended task status
   *
   * @generated from enum value: TASK_ACTIVE_STATUS_SUSPENDED = 3;
   */
  TASK_ACTIVE_STATUS_SUSPENDED = 3,

  /**
   * Represents a cancelled task status
   *
   * @generated from enum value: TASK_ACTIVE_STATUS_CANCELLED = 4;
   */
  TASK_ACTIVE_STATUS_CANCELLED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskActiveStatusEnum)
proto3.util.setEnumType(TaskActiveStatusEnum, "geneo.lms.db.TaskActiveStatusEnum", [
  { no: 0, name: "TASK_ACTIVE_STATUS_UNDEFINED" },
  { no: 1, name: "TASK_ACTIVE_STATUS_ACTIVE" },
  { no: 2, name: "TASK_ACTIVE_STATUS_INACTIVE" },
  { no: 3, name: "TASK_ACTIVE_STATUS_SUSPENDED" },
  { no: 4, name: "TASK_ACTIVE_STATUS_CANCELLED" },
]);

/**
 * @generated from enum geneo.lms.db.AssessmentEnum
 */
export enum AssessmentEnum {
  /**
   * Represent a undefined assesment
   *
   * @generated from enum value: ASSESSMENT_UNDEFINED = 0;
   */
  ASSESSMENT_UNDEFINED = 0,

  /**
   * Represents a formative assessment_type
   *
   * @generated from enum value: ASSESSMENT_FORMATIVE_ASSESSMENT = 1;
   */
  ASSESSMENT_FORMATIVE_ASSESSMENT = 1,

  /**
   * Represents a homework assessment_type
   *
   * @generated from enum value: ASSESSMENT_HOMEWORK = 2;
   */
  ASSESSMENT_HOMEWORK = 2,

  /**
   * Represents a diagnostics assessment_type
   *
   * @generated from enum value: ASSESSMENT_DIAGNOSTICS = 3;
   */
  ASSESSMENT_DIAGNOSTICS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AssessmentEnum)
proto3.util.setEnumType(AssessmentEnum, "geneo.lms.db.AssessmentEnum", [
  { no: 0, name: "ASSESSMENT_UNDEFINED" },
  { no: 1, name: "ASSESSMENT_FORMATIVE_ASSESSMENT" },
  { no: 2, name: "ASSESSMENT_HOMEWORK" },
  { no: 3, name: "ASSESSMENT_DIAGNOSTICS" },
]);

/**
 * Enum for SessionModeEnum
 *
 * @generated from enum geneo.lms.db.SessionModeEnum
 */
export enum SessionModeEnum {
  /**
   * Represents an undefined session mode
   *
   * @generated from enum value: SESSION_MODE_UNDEFINED = 0;
   */
  SESSION_MODE_UNDEFINED = 0,

  /**
   * Represents a session mode of learn
   *
   * @generated from enum value: SESSION_MODE_LEARN = 1;
   */
  SESSION_MODE_LEARN = 1,

  /**
   * Represents a session mode of teach
   *
   * @generated from enum value: SESSION_MODE_TEACH = 2;
   */
  SESSION_MODE_TEACH = 2,

  /**
   * Represents a session mode of prepare
   *
   * @generated from enum value: SESSION_MODE_PREPARE_LESSON = 3;
   */
  SESSION_MODE_PREPARE_LESSON = 3,

  /**
   * Represents a session mode of assessment
   *
   * @generated from enum value: SESSION_MODE_RESOURCE = 4;
   */
  SESSION_MODE_RESOURCE = 4,

  /**
   * Represents a session mode of revise
   *
   * @generated from enum value: SESSION_MODE_LESSON_RESOURCE = 5;
   */
  SESSION_MODE_LESSON_RESOURCE = 5,

  /**
   * Represents a session mode of prepare
   *
   * @generated from enum value: SESSION_MODE_PREPARE_RESOURCE = 6;
   */
  SESSION_MODE_PREPARE_RESOURCE = 6,

  /**
   * Represents a session mode of prepare
   *
   * @generated from enum value: SESSION_MODE_REVIEW = 7;
   */
  SESSION_MODE_REVIEW = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(SessionModeEnum)
proto3.util.setEnumType(SessionModeEnum, "geneo.lms.db.SessionModeEnum", [
  { no: 0, name: "SESSION_MODE_UNDEFINED" },
  { no: 1, name: "SESSION_MODE_LEARN" },
  { no: 2, name: "SESSION_MODE_TEACH" },
  { no: 3, name: "SESSION_MODE_PREPARE_LESSON" },
  { no: 4, name: "SESSION_MODE_RESOURCE" },
  { no: 5, name: "SESSION_MODE_LESSON_RESOURCE" },
  { no: 6, name: "SESSION_MODE_PREPARE_RESOURCE" },
  { no: 7, name: "SESSION_MODE_REVIEW" },
]);

/**
 * Enum for SessionStatusEnum
 *
 * @generated from enum geneo.lms.db.SessionStatusEnum
 */
export enum SessionStatusEnum {
  /**
   * Represents an undefined session status
   *
   * @generated from enum value: SESSION_STATUS_UNDEFINED = 0;
   */
  SESSION_STATUS_UNDEFINED = 0,

  /**
   * Represents an ongoing session status
   *
   * @generated from enum value: SESSION_STATUS_STARTED = 1;
   */
  SESSION_STATUS_STARTED = 1,

  /**
   * Represents an ongoing session status
   *
   * @generated from enum value: SESSION_STATUS_ONGOING = 2;
   */
  SESSION_STATUS_ONGOING = 2,

  /**
   * Represents an exited session status
   *
   * @generated from enum value: SESSION_STATUS_EXITED = 3;
   */
  SESSION_STATUS_EXITED = 3,

  /**
   * Represents a completed session status
   *
   * @generated from enum value: SESSION_STATUS_COMPLETED = 4;
   */
  SESSION_STATUS_COMPLETED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(SessionStatusEnum)
proto3.util.setEnumType(SessionStatusEnum, "geneo.lms.db.SessionStatusEnum", [
  { no: 0, name: "SESSION_STATUS_UNDEFINED" },
  { no: 1, name: "SESSION_STATUS_STARTED" },
  { no: 2, name: "SESSION_STATUS_ONGOING" },
  { no: 3, name: "SESSION_STATUS_EXITED" },
  { no: 4, name: "SESSION_STATUS_COMPLETED" },
]);

/**
 * Enum for PersonEnum
 *
 * @generated from enum geneo.lms.db.PersonEnum
 */
export enum PersonEnum {
  /**
   * Represents an undefined person type
   *
   * @generated from enum value: PERSON_TYPE_UNDEFINED = 0;
   */
  PERSON_TYPE_UNDEFINED = 0,

  /**
   * Represents a person type of student
   *
   * @generated from enum value: PERSON_TYPE_STUDENT = 1;
   */
  PERSON_TYPE_STUDENT = 1,

  /**
   * Represents a person type of teacher
   *
   * @generated from enum value: PERSON_TYPE_TEACHER = 2;
   */
  PERSON_TYPE_TEACHER = 2,

  /**
   * Represents a person type of parent
   *
   * @generated from enum value: PERSON_TYPE_PARENT = 3;
   */
  PERSON_TYPE_PARENT = 3,

  /**
   * Represents a person type of admin
   *
   * @generated from enum value: PERSON_TYPE_ADMIN = 4;
   */
  PERSON_TYPE_ADMIN = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(PersonEnum)
proto3.util.setEnumType(PersonEnum, "geneo.lms.db.PersonEnum", [
  { no: 0, name: "PERSON_TYPE_UNDEFINED" },
  { no: 1, name: "PERSON_TYPE_STUDENT" },
  { no: 2, name: "PERSON_TYPE_TEACHER" },
  { no: 3, name: "PERSON_TYPE_PARENT" },
  { no: 4, name: "PERSON_TYPE_ADMIN" },
]);

/**
 * Enum for SessionModeEnum
 *
 * @generated from enum geneo.lms.db.HomeworkSessionModeEnum
 */
export enum HomeworkSessionModeEnum {
  /**
   * Represents an undefined session mode
   *
   * @generated from enum value: HOMEWORK_SESSION_MODE_UNDEFINED = 0;
   */
  HOMEWORK_SESSION_MODE_UNDEFINED = 0,

  /**
   * Represents a session mode of lesson
   *
   * @generated from enum value: HOMEWORK_SESSION_MODE_FIRST_ATTEMPT = 1;
   */
  HOMEWORK_SESSION_MODE_FIRST_ATTEMPT = 1,

  /**
   * Represents a session mode of learn
   *
   * @generated from enum value: HOMEWORK_SESSION_MODE_REATTEMPT = 2;
   */
  HOMEWORK_SESSION_MODE_REATTEMPT = 2,

  /**
   * Represents a session mode of assessment
   *
   * @generated from enum value: HOMEWORK_SESSION_MODE_RESUME = 3;
   */
  HOMEWORK_SESSION_MODE_RESUME = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(HomeworkSessionModeEnum)
proto3.util.setEnumType(HomeworkSessionModeEnum, "geneo.lms.db.HomeworkSessionModeEnum", [
  { no: 0, name: "HOMEWORK_SESSION_MODE_UNDEFINED" },
  { no: 1, name: "HOMEWORK_SESSION_MODE_FIRST_ATTEMPT" },
  { no: 2, name: "HOMEWORK_SESSION_MODE_REATTEMPT" },
  { no: 3, name: "HOMEWORK_SESSION_MODE_RESUME" },
]);

/**
 * @generated from enum geneo.lms.db.SubmissionType
 */
export enum SubmissionType {
  /**
   * @generated from enum value: SUBMISSION_UNDEFINED = 0;
   */
  SUBMISSION_UNDEFINED = 0,

  /**
   * @generated from enum value: SUBMISSION_ONLINE = 1;
   */
  SUBMISSION_ONLINE = 1,

  /**
   * @generated from enum value: SUBMISSION_OFFLINE = 2;
   */
  SUBMISSION_OFFLINE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SubmissionType)
proto3.util.setEnumType(SubmissionType, "geneo.lms.db.SubmissionType", [
  { no: 0, name: "SUBMISSION_UNDEFINED" },
  { no: 1, name: "SUBMISSION_ONLINE" },
  { no: 2, name: "SUBMISSION_OFFLINE" },
]);

/**
 * @generated from enum geneo.lms.db.ResponseCronEvaluationStatus
 */
export enum ResponseCronEvaluationStatus {
  /**
   * @generated from enum value: REESPONSE_CRON_EVALUATION_STATUS_UNDEFINED = 0;
   */
  REESPONSE_CRON_EVALUATION_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: REESPONSE_CRON_EVALUATION_STATUS_PENDING = 1;
   */
  REESPONSE_CRON_EVALUATION_STATUS_PENDING = 1,

  /**
   * @generated from enum value: REESPONSE_CRON_EVALUATION_STATUS_INPROGRESS = 2;
   */
  REESPONSE_CRON_EVALUATION_STATUS_INPROGRESS = 2,

  /**
   * @generated from enum value: REESPONSE_CRON_EVALUATION_STATUS_COMPLETED = 3;
   */
  REESPONSE_CRON_EVALUATION_STATUS_COMPLETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ResponseCronEvaluationStatus)
proto3.util.setEnumType(ResponseCronEvaluationStatus, "geneo.lms.db.ResponseCronEvaluationStatus", [
  { no: 0, name: "REESPONSE_CRON_EVALUATION_STATUS_UNDEFINED" },
  { no: 1, name: "REESPONSE_CRON_EVALUATION_STATUS_PENDING" },
  { no: 2, name: "REESPONSE_CRON_EVALUATION_STATUS_INPROGRESS" },
  { no: 3, name: "REESPONSE_CRON_EVALUATION_STATUS_COMPLETED" },
]);

/**
 * @generated from enum geneo.lms.db.QAttemptResultEnum
 */
export enum QAttemptResultEnum {
  /**
   * @generated from enum value: RESPONSE_UNDEFINED = 0;
   */
  RESPONSE_UNDEFINED = 0,

  /**
   * @generated from enum value: RESPONSE_CORRECT = 1;
   */
  RESPONSE_CORRECT = 1,

  /**
   * @generated from enum value: RESPONSE_INCORRECT = 2;
   */
  RESPONSE_INCORRECT = 2,

  /**
   * @generated from enum value: RESPONSE_PARTIALLY_CORRECT = 3;
   */
  RESPONSE_PARTIALLY_CORRECT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(QAttemptResultEnum)
proto3.util.setEnumType(QAttemptResultEnum, "geneo.lms.db.QAttemptResultEnum", [
  { no: 0, name: "RESPONSE_UNDEFINED" },
  { no: 1, name: "RESPONSE_CORRECT" },
  { no: 2, name: "RESPONSE_INCORRECT" },
  { no: 3, name: "RESPONSE_PARTIALLY_CORRECT" },
]);

/**
 * @generated from enum geneo.lms.db.PDFGenStatus
 */
export enum PDFGenStatus {
  /**
   * @generated from enum value: PDF_GENERATION_STATUS_UNDEFINED = 0;
   */
  PDF_GENERATION_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_PENDING = 1;
   */
  PDF_GENERATION_STATUS_PENDING = 1,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_STARTED = 2;
   */
  PDF_GENERATION_STATUS_STARTED = 2,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_IN_PROGRESS = 3;
   */
  PDF_GENERATION_STATUS_IN_PROGRESS = 3,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_FAILED = 4;
   */
  PDF_GENERATION_STATUS_FAILED = 4,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_COMPLETED = 5;
   */
  PDF_GENERATION_STATUS_COMPLETED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(PDFGenStatus)
proto3.util.setEnumType(PDFGenStatus, "geneo.lms.db.PDFGenStatus", [
  { no: 0, name: "PDF_GENERATION_STATUS_UNDEFINED" },
  { no: 1, name: "PDF_GENERATION_STATUS_PENDING" },
  { no: 2, name: "PDF_GENERATION_STATUS_STARTED" },
  { no: 3, name: "PDF_GENERATION_STATUS_IN_PROGRESS" },
  { no: 4, name: "PDF_GENERATION_STATUS_FAILED" },
  { no: 5, name: "PDF_GENERATION_STATUS_COMPLETED" },
]);

/**
 * Enum for SessionModeEnum
 *
 * @generated from enum geneo.lms.db.AssessmentSessionModeEnum
 */
export enum AssessmentSessionModeEnum {
  /**
   * Represents an undefined session mode
   *
   * @generated from enum value: ASSESSMENT_SESSION_MODE_UNDEFINED = 0;
   */
  ASSESSMENT_SESSION_MODE_UNDEFINED = 0,

  /**
   * Represents a session mode of lesson
   *
   * @generated from enum value: ASSESSMENT_SESSION_MODE_FIRST_ATTEMPT = 1;
   */
  ASSESSMENT_SESSION_MODE_FIRST_ATTEMPT = 1,

  /**
   * Represents a session mode of learn
   *
   * @generated from enum value: ASSESSMENT_SESSION_MODE_REATTEMPT = 2;
   */
  ASSESSMENT_SESSION_MODE_REATTEMPT = 2,

  /**
   * Represents a session mode of assessment
   *
   * @generated from enum value: ASSESSMENT_SESSION_MODE_RESUME = 3;
   */
  ASSESSMENT_SESSION_MODE_RESUME = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AssessmentSessionModeEnum)
proto3.util.setEnumType(AssessmentSessionModeEnum, "geneo.lms.db.AssessmentSessionModeEnum", [
  { no: 0, name: "ASSESSMENT_SESSION_MODE_UNDEFINED" },
  { no: 1, name: "ASSESSMENT_SESSION_MODE_FIRST_ATTEMPT" },
  { no: 2, name: "ASSESSMENT_SESSION_MODE_REATTEMPT" },
  { no: 3, name: "ASSESSMENT_SESSION_MODE_RESUME" },
]);

/**
 * @generated from message geneo.lms.db.TaskQuestionInfo
 */
export class TaskQuestionInfo extends Message<TaskQuestionInfo> {
  /**
   * Parent Task ID
   *
   * @generated from field: optional string parent_task_id = 1;
   */
  parentTaskId?: string;

  /**
   * Question Task ID
   *
   * @generated from field: optional string question_task_id = 2;
   */
  questionTaskId?: string;

  /**
   * Question ID
   *
   * @generated from field: optional string question_id = 3;
   */
  questionId?: string;

  /**
   * @generated from field: geneo.content.db.Question.QuestionEnum question_type = 4;
   */
  questionType = Question_QuestionEnum.QUESTION_TYPE_UNDEFINED;

  /**
   * Sequence number
   *
   * @generated from field: int32 sequence = 5;
   */
  sequence = 0;

  /**
   * @generated from field: float estimated_time_in_min = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * @generated from field: optional float max_marks = 7;
   */
  maxMarks?: number;

  constructor(data?: PartialMessage<TaskQuestionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TaskQuestionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "question_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "question_type", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum) },
    { no: 5, name: "sequence", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "estimated_time_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskQuestionInfo {
    return new TaskQuestionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskQuestionInfo {
    return new TaskQuestionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskQuestionInfo {
    return new TaskQuestionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: TaskQuestionInfo | PlainMessage<TaskQuestionInfo> | undefined, b: TaskQuestionInfo | PlainMessage<TaskQuestionInfo> | undefined): boolean {
    return proto3.util.equals(TaskQuestionInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.TaskInfo
 */
export class TaskInfo extends Message<TaskInfo> {
  /**
   * Homework ID
   *
   * @generated from field: optional int32 homework_id = 1;
   */
  homeworkId?: number;

  /**
   * @generated from field: repeated string question_ids = 2;
   */
  questionIds: string[] = [];

  /**
   * @generated from field: repeated geneo.lms.db.TaskQuestionInfo task_question_info = 3;
   */
  taskQuestionInfo: TaskQuestionInfo[] = [];

  constructor(data?: PartialMessage<TaskInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TaskInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "question_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "task_question_info", kind: "message", T: TaskQuestionInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskInfo {
    return new TaskInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskInfo {
    return new TaskInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskInfo {
    return new TaskInfo().fromJsonString(jsonString, options);
  }

  static equals(a: TaskInfo | PlainMessage<TaskInfo> | undefined, b: TaskInfo | PlainMessage<TaskInfo> | undefined): boolean {
    return proto3.util.equals(TaskInfo, a, b);
  }
}

/**
 * Message for student_group table
 *
 * @generated from message geneo.lms.db.StudentGroup
 */
export class StudentGroup extends Message<StudentGroup> {
  /**
   * Primary key for student_group
   *
   * @generated from field: optional int32 student_group_id = 1;
   */
  studentGroupId?: number;

  /**
   * Group name
   *
   * @generated from field: string group_name = 2;
   */
  groupName = "";

  /**
   * Foreign key referencing school_management.school_teacher_class_course (school_teacher_class_course_id)
   *
   * @generated from field: optional int32 school_class_section_id = 3;
   */
  schoolClassSectionId?: number;

  /**
   * Array of student user IDs
   *
   * @generated from field: repeated int64 student_ids = 4;
   */
  studentIds: bigint[] = [];

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string nano_id = 9;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 10;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 11;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<StudentGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "student_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentGroup {
    return new StudentGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentGroup {
    return new StudentGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentGroup {
    return new StudentGroup().fromJsonString(jsonString, options);
  }

  static equals(a: StudentGroup | PlainMessage<StudentGroup> | undefined, b: StudentGroup | PlainMessage<StudentGroup> | undefined): boolean {
    return proto3.util.equals(StudentGroup, a, b);
  }
}

/**
 * Message for task_default_instructions table
 *
 * @generated from message geneo.lms.db.TaskDefaultInstructions
 */
export class TaskDefaultInstructions extends Message<TaskDefaultInstructions> {
  /**
   * Primary key for task_default_instructions
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Referencing key for task_default_instructions
   *
   * @generated from field: optional string task_instruction_id = 2;
   */
  taskInstructionId?: string;

  /**
   * Type of task (enum: task_type_enum)
   *
   * @generated from field: string task_type = 3;
   */
  taskType = "";

  /**
   * Default instructions for the task
   *
   * @generated from field: repeated string instructions = 4;
   */
  instructions: string[] = [];

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string nano_id = 9;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<TaskDefaultInstructions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TaskDefaultInstructions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "task_instruction_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "task_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskDefaultInstructions {
    return new TaskDefaultInstructions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskDefaultInstructions {
    return new TaskDefaultInstructions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskDefaultInstructions {
    return new TaskDefaultInstructions().fromJsonString(jsonString, options);
  }

  static equals(a: TaskDefaultInstructions | PlainMessage<TaskDefaultInstructions> | undefined, b: TaskDefaultInstructions | PlainMessage<TaskDefaultInstructions> | undefined): boolean {
    return proto3.util.equals(TaskDefaultInstructions, a, b);
  }
}

/**
 * Message for task table
 *
 * @generated from message geneo.lms.db.Task
 */
export class Task extends Message<Task> {
  /**
   * Primary key for task
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * @generated from field: optional string task_id = 2;
   */
  taskId?: string;

  /**
   * @generated from field: optional string generic_homework_id = 3;
   */
  genericHomeworkId?: string;

  /**
   * Teacher's user ID
   *
   * @generated from field: optional int64 teacher_id = 4;
   */
  teacherId?: bigint;

  /**
   * Name of the task
   *
   * @generated from field: string task_title = 5;
   */
  taskTitle = "";

  /**
   * Type of task (enum: task_type_enum)
   *
   * @generated from field: optional geneo.lms.db.TaskEnum task_type = 6;
   */
  taskType?: TaskEnum;

  /**
   * Action type of the task (enum: task_action_type_enum)
   *
   * @generated from field: optional geneo.lms.db.TaskActionEnum task_action_type = 7;
   */
  taskActionType?: TaskActionEnum;

  /**
   * Parent task ID (foreign key referencing task.task_id)
   *
   * @generated from field: optional string parent_task_id = 8;
   */
  parentTaskId?: string;

  /**
   * Root task ID
   *
   * @generated from field: optional string root_task_id = 9;
   */
  rootTaskId?: string;

  /**
   * Course ID
   *
   * @generated from field: optional int32 course_id = 10;
   */
  courseId?: number;

  /**
   * Course module ID
   *
   * @generated from field: optional int32 module_id = 11;
   */
  moduleId?: number;

  /**
   * Task level
   *
   * @generated from field: optional int32 level = 12;
   */
  level?: number;

  /**
   * Position of the task
   *
   * @generated from field: int32 position = 13;
   */
  position = 0;

  /**
   * Task description
   *
   * @generated from field: string description = 14;
   */
  description = "";

  /**
   * Task instructions
   *
   * @generated from field: repeated string instructions = 15;
   */
  instructions: string[] = [];

  /**
   * Task difficulty level
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 16;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * Source ID for duplicated tasks
   *
   * @generated from field: optional int32 duplicate_task_source_id = 17;
   */
  duplicateTaskSourceId?: number;

  /**
   * Number of times task assigned
   *
   * @generated from field: int32 num_assigned = 18;
   */
  numAssigned = 0;

  /**
   * Task data in JSONB format
   *
   * @generated from field: geneo.lms.db.TaskInfo data = 19;
   */
  data?: TaskInfo;

  /**
   * Task creation status (enum: task_creation_status_enum)
   *
   * @generated from field: geneo.lms.db.TaskCreationStatusEnum task_creation_status = 20;
   */
  taskCreationStatus = TaskCreationStatusEnum.TASK_CREATION_STATUS_UNDEFINED;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 21;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 22;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 23;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 24;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: string poster_image_url = 25;
   */
  posterImageUrl = "";

  /**
   * @generated from field: geneo.lms.db.TaskActiveStatusEnum task_active_status = 26;
   */
  taskActiveStatus = TaskActiveStatusEnum.TASK_ACTIVE_STATUS_UNDEFINED;

  /**
   * @generated from field: optional int32 school_class_section_id = 27;
   */
  schoolClassSectionId?: number;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 28;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload task_uploads = 29;
   */
  taskUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional float max_marks = 30;
   */
  maxMarks?: number;

  /**
   * Only for teacher created CUSTOM hws
   *
   * @generated from field: optional geneo.lms.db.SubmissionType submission_type = 31;
   */
  submissionType?: SubmissionType;

  /**
   * New modifications start here
   *
   * List of associated chapter IDs
   *
   * @generated from field: repeated int32 chapter_ids = 32;
   */
  chapterIds: number[] = [];

  /**
   * List of associated topic IDs
   *
   * @generated from field: repeated int32 topic_ids = 33;
   */
  topicIds: number[] = [];

  /**
   * List of associated topic IDs
   *
   * @generated from field: repeated int32 learning_outcome_ids = 34;
   */
  learningOutcomeIds: number[] = [];

  /**
   * @generated from field: optional string nano_id = 35;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 36;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 37;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<Task>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.Task";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "generic_homework_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 5, name: "task_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum), opt: true },
    { no: 7, name: "task_action_type", kind: "enum", T: proto3.getEnumType(TaskActionEnum), opt: true },
    { no: 8, name: "parent_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "root_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "level", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "position", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 17, name: "duplicate_task_source_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 18, name: "num_assigned", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 19, name: "data", kind: "message", T: TaskInfo },
    { no: 20, name: "task_creation_status", kind: "enum", T: proto3.getEnumType(TaskCreationStatusEnum) },
    { no: 21, name: "created_on", kind: "message", T: Timestamp },
    { no: 22, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 23, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 24, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 25, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "task_active_status", kind: "enum", T: proto3.getEnumType(TaskActiveStatusEnum) },
    { no: 27, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 28, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 29, name: "task_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 30, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 31, name: "submission_type", kind: "enum", T: proto3.getEnumType(SubmissionType), opt: true },
    { no: 32, name: "chapter_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 33, name: "topic_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 34, name: "learning_outcome_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 35, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 36, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 37, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Task {
    return new Task().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJsonString(jsonString, options);
  }

  static equals(a: Task | PlainMessage<Task> | undefined, b: Task | PlainMessage<Task> | undefined): boolean {
    return proto3.util.equals(Task, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.CloudUpload
 */
export class CloudUpload extends Message<CloudUpload> {
  /**
   * @generated from field: string file_name = 1;
   */
  fileName = "";

  /**
   * @generated from field: int64 person_id = 2;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.content.db.FileEnum file_type = 3;
   */
  fileType = FileEnum.FILE_TYPE_UNKNOWN;

  /**
   * @generated from field: optional float file_size_in_mb = 4;
   */
  fileSizeInMb?: number;

  /**
   * @generated from field: optional string gcp_file_url = 5;
   */
  gcpFileUrl?: string;

  /**
   * @generated from field: optional string external_web_resource_url = 6;
   */
  externalWebResourceUrl?: string;

  /**
   * @generated from field: geneo.content.db.FileExtensionEnum file_extension_type = 7;
   */
  fileExtensionType = FileExtensionEnum.FILE_EXTENSION_UNKNOWN;

  /**
   * @generated from field: optional bool is_uploaded = 8;
   */
  isUploaded?: boolean;

  /**
   * @generated from field: optional bool is_deleted = 9;
   */
  isDeleted?: boolean;

  /**
   * @generated from field: optional string signed_url = 10;
   */
  signedUrl?: string;

  /**
   * @generated from field: optional string download_url = 11;
   */
  downloadUrl?: string;

  /**
   * @generated from field: optional string preview_url = 12;
   */
  previewUrl?: string;

  constructor(data?: PartialMessage<CloudUpload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.CloudUpload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "file_type", kind: "enum", T: proto3.getEnumType(FileEnum) },
    { no: 4, name: "file_size_in_mb", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "gcp_file_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "external_web_resource_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "file_extension_type", kind: "enum", T: proto3.getEnumType(FileExtensionEnum) },
    { no: 8, name: "is_uploaded", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 10, name: "signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "download_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CloudUpload {
    return new CloudUpload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CloudUpload {
    return new CloudUpload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CloudUpload {
    return new CloudUpload().fromJsonString(jsonString, options);
  }

  static equals(a: CloudUpload | PlainMessage<CloudUpload> | undefined, b: CloudUpload | PlainMessage<CloudUpload> | undefined): boolean {
    return proto3.util.equals(CloudUpload, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.AverageMetricsModel
 */
export class AverageMetricsModel extends Message<AverageMetricsModel> {
  /**
   * @generated from field: int32 no_of_submissions = 1;
   */
  noOfSubmissions = 0;

  /**
   * @generated from field: float avg_score = 2;
   */
  avgScore = 0;

  constructor(data?: PartialMessage<AverageMetricsModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.AverageMetricsModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "no_of_submissions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "avg_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AverageMetricsModel {
    return new AverageMetricsModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AverageMetricsModel {
    return new AverageMetricsModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AverageMetricsModel {
    return new AverageMetricsModel().fromJsonString(jsonString, options);
  }

  static equals(a: AverageMetricsModel | PlainMessage<AverageMetricsModel> | undefined, b: AverageMetricsModel | PlainMessage<AverageMetricsModel> | undefined): boolean {
    return proto3.util.equals(AverageMetricsModel, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.AssignmentConfigInfo
 */
export class AssignmentConfigInfo extends Message<AssignmentConfigInfo> {
  /**
   * @generated from field: repeated geneo.lms.db.AssignmentInfo assignment_info = 1;
   */
  assignmentInfo: AssignmentInfo[] = [];

  constructor(data?: PartialMessage<AssignmentConfigInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.AssignmentConfigInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assignment_info", kind: "message", T: AssignmentInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssignmentConfigInfo {
    return new AssignmentConfigInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssignmentConfigInfo {
    return new AssignmentConfigInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssignmentConfigInfo {
    return new AssignmentConfigInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssignmentConfigInfo | PlainMessage<AssignmentConfigInfo> | undefined, b: AssignmentConfigInfo | PlainMessage<AssignmentConfigInfo> | undefined): boolean {
    return proto3.util.equals(AssignmentConfigInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.AssignmentInfo
 */
export class AssignmentInfo extends Message<AssignmentInfo> {
  /**
   * @generated from field: geneo.lms.db.AssignmentConfigEnum config_name = 1;
   */
  configName = AssignmentConfigEnum.ASSIGNMENT_CONFIG_UNDEFINED;

  /**
   * @generated from field: bool is_enabled = 2;
   */
  isEnabled = false;

  constructor(data?: PartialMessage<AssignmentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.AssignmentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "config_name", kind: "enum", T: proto3.getEnumType(AssignmentConfigEnum) },
    { no: 2, name: "is_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssignmentInfo {
    return new AssignmentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssignmentInfo {
    return new AssignmentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssignmentInfo {
    return new AssignmentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssignmentInfo | PlainMessage<AssignmentInfo> | undefined, b: AssignmentInfo | PlainMessage<AssignmentInfo> | undefined): boolean {
    return proto3.util.equals(AssignmentInfo, a, b);
  }
}

/**
 * Message for task_group_assignment table
 *
 * @generated from message geneo.lms.db.TaskGroupAssignment
 */
export class TaskGroupAssignment extends Message<TaskGroupAssignment> {
  /**
   * Primary key for task_group_assignment
   *
   * @generated from field: optional int32 task_group_assignment_id = 1;
   */
  taskGroupAssignmentId?: number;

  /**
   * Foreign key referencing task.task_id
   *
   * @generated from field: optional string task_id = 2;
   */
  taskId?: string;

  /**
   * @generated from field: optional string parent_task_id = 19;
   */
  parentTaskId?: string;

  /**
   * Student group ID (foreign key referencing student_group.student_group_id)
   *
   * @generated from field: optional int32 student_group_id = 3;
   */
  studentGroupId?: number;

  /**
   * Number of students in the group
   *
   * @generated from field: int32 num_students = 4;
   */
  numStudents = 0;

  /**
   * Date when the task was assigned
   *
   * @generated from field: google.protobuf.Timestamp task_assigned_date = 5;
   */
  taskAssignedDate?: Timestamp;

  /**
   * Date when the task commenced
   *
   * @generated from field: google.protobuf.Timestamp task_commence_date = 6;
   */
  taskCommenceDate?: Timestamp;

  /**
   * Target date for task completion
   *
   * @generated from field: google.protobuf.Timestamp task_target_date = 7;
   */
  taskTargetDate?: Timestamp;

  /**
   * Date when the task was completed
   *
   * @generated from field: google.protobuf.Timestamp task_completed_date = 8;
   */
  taskCompletedDate?: Timestamp;

  /**
   * Task cancellation date in JSONB format
   *
   * @generated from field: google.protobuf.Timestamp task_cancellation_date = 9;
   */
  taskCancellationDate?: Timestamp;

  /**
   * Task status (enum: task_group_status_enum)
   *
   * @generated from field: geneo.lms.db.TaskGroupStatusEnum task_group_status = 10;
   */
  taskGroupStatus = TaskGroupStatusEnum.TASK_GROUP_STATUS_UNDEFINED;

  /**
   * Average metrics in JSONB format
   *
   * @generated from field: geneo.lms.db.AverageMetricsModel avg_metrics = 11;
   */
  avgMetrics?: AverageMetricsModel;

  /**
   * Number of completions for the task
   *
   * @generated from field: int32 num_completions = 12;
   */
  numCompletions = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 13;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 14;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 15;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 16;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp daily_reminder_time = 17;
   */
  dailyReminderTime?: Timestamp;

  /**
   * @generated from field: optional bool allow_late_submission = 18;
   */
  allowLateSubmission?: boolean;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 20;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional int32 school_class_section_id = 21;
   */
  schoolClassSectionId?: number;

  /**
   * @generated from field: optional geneo.lms.db.AssignmentConfigInfo assignment_config_info = 22;
   */
  assignmentConfigInfo?: AssignmentConfigInfo;

  /**
   * @generated from field: optional string nano_id = 23;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 24;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 25;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<TaskGroupAssignment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TaskGroupAssignment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_group_assignment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "parent_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "student_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "num_students", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "task_assigned_date", kind: "message", T: Timestamp },
    { no: 6, name: "task_commence_date", kind: "message", T: Timestamp },
    { no: 7, name: "task_target_date", kind: "message", T: Timestamp },
    { no: 8, name: "task_completed_date", kind: "message", T: Timestamp },
    { no: 9, name: "task_cancellation_date", kind: "message", T: Timestamp },
    { no: 10, name: "task_group_status", kind: "enum", T: proto3.getEnumType(TaskGroupStatusEnum) },
    { no: 11, name: "avg_metrics", kind: "message", T: AverageMetricsModel },
    { no: 12, name: "num_completions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "created_on", kind: "message", T: Timestamp },
    { no: 14, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 15, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 16, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 17, name: "daily_reminder_time", kind: "message", T: Timestamp, opt: true },
    { no: 18, name: "allow_late_submission", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 20, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 21, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 22, name: "assignment_config_info", kind: "message", T: AssignmentConfigInfo, opt: true },
    { no: 23, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 25, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskGroupAssignment {
    return new TaskGroupAssignment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskGroupAssignment {
    return new TaskGroupAssignment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskGroupAssignment {
    return new TaskGroupAssignment().fromJsonString(jsonString, options);
  }

  static equals(a: TaskGroupAssignment | PlainMessage<TaskGroupAssignment> | undefined, b: TaskGroupAssignment | PlainMessage<TaskGroupAssignment> | undefined): boolean {
    return proto3.util.equals(TaskGroupAssignment, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.MetricsModel
 */
export class MetricsModel extends Message<MetricsModel> {
  /**
   * @generated from field: float response_score = 1;
   */
  responseScore = 0;

  /**
   * @generated from field: float max_score = 2;
   */
  maxScore = 0;

  /**
   * @generated from field: float time_spent_in_sec = 3;
   */
  timeSpentInSec = 0;

  /**
   * @generated from field: int32 total_questions = 4;
   */
  totalQuestions = 0;

  /**
   * @generated from field: int32 correct_count = 5;
   */
  correctCount = 0;

  constructor(data?: PartialMessage<MetricsModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.MetricsModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "time_spent_in_sec", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "total_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "correct_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MetricsModel {
    return new MetricsModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MetricsModel {
    return new MetricsModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MetricsModel {
    return new MetricsModel().fromJsonString(jsonString, options);
  }

  static equals(a: MetricsModel | PlainMessage<MetricsModel> | undefined, b: MetricsModel | PlainMessage<MetricsModel> | undefined): boolean {
    return proto3.util.equals(MetricsModel, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.TaskTeacherReview
 */
export class TaskTeacherReview extends Message<TaskTeacherReview> {
  /**
   * @generated from field: repeated string remarks = 1;
   */
  remarks: string[] = [];

  constructor(data?: PartialMessage<TaskTeacherReview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TaskTeacherReview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "remarks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskTeacherReview {
    return new TaskTeacherReview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskTeacherReview {
    return new TaskTeacherReview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskTeacherReview {
    return new TaskTeacherReview().fromJsonString(jsonString, options);
  }

  static equals(a: TaskTeacherReview | PlainMessage<TaskTeacherReview> | undefined, b: TaskTeacherReview | PlainMessage<TaskTeacherReview> | undefined): boolean {
    return proto3.util.equals(TaskTeacherReview, a, b);
  }
}

/**
 * Message for task_student_assignment table
 *
 * @generated from message geneo.lms.db.TaskStudentAssignment
 */
export class TaskStudentAssignment extends Message<TaskStudentAssignment> {
  /**
   * Primary key for task_student_assignment
   *
   * @generated from field: optional int32 task_student_assignment_id = 1;
   */
  taskStudentAssignmentId?: number;

  /**
   * Foreign key referencing task.task_id
   *
   * @generated from field: optional string task_id = 2;
   */
  taskId?: string;

  /**
   * @generated from field: optional string parent_task_id = 20;
   */
  parentTaskId?: string;

  /**
   * Student's user ID
   *
   * @generated from field: optional int64 student_id = 3;
   */
  studentId?: bigint;

  /**
   * Student group ID (foreign key referencing student_group.student_group_id)
   *
   * @generated from field: optional int32 student_group_id = 4;
   */
  studentGroupId?: number;

  /**
   * Date when the task was assigned to the student
   *
   * @generated from field: google.protobuf.Timestamp task_assigned_date = 5;
   */
  taskAssignedDate?: Timestamp;

  /**
   * Target date for task completion by the student
   *
   * @generated from field: google.protobuf.Timestamp task_target_date = 6;
   */
  taskTargetDate?: Timestamp;

  /**
   * Date when the student commenced the task
   *
   * @generated from field: google.protobuf.Timestamp task_commence_date = 7;
   */
  taskCommenceDate?: Timestamp;

  /**
   * Date when the student completed the task
   *
   * @generated from field: google.protobuf.Timestamp task_completed_date = 8;
   */
  taskCompletedDate?: Timestamp;

  /**
   * Task status for the student (enum: task_student_status_enum)
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum task_student_status = 9;
   */
  taskStudentStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * Task evaluation status for the student (enum: task_evaluation_enum)
   *
   * @generated from field: geneo.lms.db.TaskEvaluationStatusEnum task_evaluation_status = 10;
   */
  taskEvaluationStatus = TaskEvaluationStatusEnum.TASK_EVALUATION_STATUS_UNDEFINED;

  /**
   * Metrics related to the student's task performance in JSONB format
   *
   * @generated from field: geneo.lms.db.MetricsModel metrics = 11;
   */
  metrics?: MetricsModel;

  /**
   * Student's response to the task in JSONB format
   *
   * @generated from field: repeated geneo.lms.db.StudentTaskResponse response = 12;
   */
  response: StudentTaskResponse[] = [];

  /**
   * Teacher's review of the student's task in JSONB format
   *
   * @generated from field: geneo.lms.db.TaskTeacherReview teacher_review = 13;
   */
  teacherReview?: TaskTeacherReview;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 14;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 15;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 16;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 17;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp daily_reminder_time = 18;
   */
  dailyReminderTime?: Timestamp;

  /**
   * @generated from field: optional bool allow_late_submission = 19;
   */
  allowLateSubmission?: boolean;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 21;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional int32 metrics_evaluation_version = 22;
   */
  metricsEvaluationVersion?: number;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload user_uploads = 23;
   */
  userUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional geneo.lms.db.ResponseCronEvaluationStatus response_cron_evaluation_status = 24;
   */
  responseCronEvaluationStatus?: ResponseCronEvaluationStatus;

  /**
   * @generated from field: optional string nano_id = 25;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 26;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 27;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<TaskStudentAssignment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TaskStudentAssignment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_student_assignment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "parent_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 4, name: "student_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "task_assigned_date", kind: "message", T: Timestamp },
    { no: 6, name: "task_target_date", kind: "message", T: Timestamp },
    { no: 7, name: "task_commence_date", kind: "message", T: Timestamp },
    { no: 8, name: "task_completed_date", kind: "message", T: Timestamp },
    { no: 9, name: "task_student_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 10, name: "task_evaluation_status", kind: "enum", T: proto3.getEnumType(TaskEvaluationStatusEnum) },
    { no: 11, name: "metrics", kind: "message", T: MetricsModel },
    { no: 12, name: "response", kind: "message", T: StudentTaskResponse, repeated: true },
    { no: 13, name: "teacher_review", kind: "message", T: TaskTeacherReview },
    { no: 14, name: "created_on", kind: "message", T: Timestamp },
    { no: 15, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 16, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 17, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 18, name: "daily_reminder_time", kind: "message", T: Timestamp, opt: true },
    { no: 19, name: "allow_late_submission", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 21, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 22, name: "metrics_evaluation_version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 23, name: "user_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 24, name: "response_cron_evaluation_status", kind: "enum", T: proto3.getEnumType(ResponseCronEvaluationStatus), opt: true },
    { no: 25, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 27, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskStudentAssignment {
    return new TaskStudentAssignment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskStudentAssignment {
    return new TaskStudentAssignment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskStudentAssignment {
    return new TaskStudentAssignment().fromJsonString(jsonString, options);
  }

  static equals(a: TaskStudentAssignment | PlainMessage<TaskStudentAssignment> | undefined, b: TaskStudentAssignment | PlainMessage<TaskStudentAssignment> | undefined): boolean {
    return proto3.util.equals(TaskStudentAssignment, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.StudentHomeworkSession
 */
export class StudentHomeworkSession extends Message<StudentHomeworkSession> {
  /**
   * @generated from field: int32 student_homework_session_id = 1;
   */
  studentHomeworkSessionId = 0;

  /**
   * @generated from field: int32 task_student_assignment_id = 12;
   */
  taskStudentAssignmentId = 0;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: int64 student_id = 3;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp start_time = 4;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 5;
   */
  endTime?: Timestamp;

  /**
   * Task status for the student (enum: task_student_status_enum)
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum session_status = 6;
   */
  sessionStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.lms.db.HomeworkSessionModeEnum session_mode = 7;
   */
  sessionMode = HomeworkSessionModeEnum.HOMEWORK_SESSION_MODE_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.db.StudentTaskResponse responses = 8;
   */
  responses: StudentTaskResponse[] = [];

  /**
   * Metrics associated with the lesson session. Nullable field.
   *
   * @generated from field: geneo.lms.db.MetricsModel metrics = 9;
   */
  metrics?: MetricsModel;

  /**
   * @generated from field: int32 session_number = 10;
   */
  sessionNumber = 0;

  /**
   * @generated from field: optional int32 previous_student_homework_session_id = 11;
   */
  previousStudentHomeworkSessionId?: number;

  /**
   * @generated from field: optional int32 active_session_id = 13;
   */
  activeSessionId?: number;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload user_uploads = 14;
   */
  userUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional bool is_evaluation_completed = 15;
   */
  isEvaluationCompleted?: boolean;

  /**
   * @generated from field: optional string nano_id = 16;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 17;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 18;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<StudentHomeworkSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentHomeworkSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_homework_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "task_student_assignment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "start_time", kind: "message", T: Timestamp },
    { no: 5, name: "end_time", kind: "message", T: Timestamp },
    { no: 6, name: "session_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 7, name: "session_mode", kind: "enum", T: proto3.getEnumType(HomeworkSessionModeEnum) },
    { no: 8, name: "responses", kind: "message", T: StudentTaskResponse, repeated: true },
    { no: 9, name: "metrics", kind: "message", T: MetricsModel },
    { no: 10, name: "session_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "previous_student_homework_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "user_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 15, name: "is_evaluation_completed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 16, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 18, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHomeworkSession {
    return new StudentHomeworkSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHomeworkSession {
    return new StudentHomeworkSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHomeworkSession {
    return new StudentHomeworkSession().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHomeworkSession | PlainMessage<StudentHomeworkSession> | undefined, b: StudentHomeworkSession | PlainMessage<StudentHomeworkSession> | undefined): boolean {
    return proto3.util.equals(StudentHomeworkSession, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.ContentAttempt
 */
export class ContentAttempt extends Message<ContentAttempt> {
  /**
   * @generated from field: google.protobuf.Timestamp start_time = 1;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: repeated string answer = 3;
   */
  answer: string[] = [];

  /**
   * @generated from field: repeated string uploaded_image_urls = 4;
   */
  uploadedImageUrls: string[] = [];

  constructor(data?: PartialMessage<ContentAttempt>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.ContentAttempt";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
    { no: 2, name: "end_time", kind: "message", T: Timestamp, opt: true },
    { no: 3, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "uploaded_image_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentAttempt {
    return new ContentAttempt().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentAttempt {
    return new ContentAttempt().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentAttempt {
    return new ContentAttempt().fromJsonString(jsonString, options);
  }

  static equals(a: ContentAttempt | PlainMessage<ContentAttempt> | undefined, b: ContentAttempt | PlainMessage<ContentAttempt> | undefined): boolean {
    return proto3.util.equals(ContentAttempt, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.StudentTaskResponse
 */
export class StudentTaskResponse extends Message<StudentTaskResponse> {
  /**
   * @generated from field: optional int32 homework_id = 1;
   */
  homeworkId?: number;

  /**
   * @generated from field: optional string question_id = 2;
   */
  questionId?: string;

  /**
   * @generated from field: optional string question_task_id = 3;
   */
  questionTaskId?: string;

  /**
   * @generated from field: repeated geneo.lms.db.ContentAttempt responses = 4;
   */
  responses: ContentAttempt[] = [];

  /**
   * @generated from field: optional geneo.lms.db.QAttemptResultEnum is_correct = 5;
   */
  isCorrect?: QAttemptResultEnum;

  /**
   * @generated from field: float max_score = 6;
   */
  maxScore = 0;

  /**
   * @generated from field: float response_score = 7;
   */
  responseScore = 0;

  /**
   * @generated from field: repeated string remarks = 11;
   */
  remarks: string[] = [];

  /**
   * @generated from field: float expectedTimeInSec = 8;
   */
  expectedTimeInSec = 0;

  /**
   * @generated from field: float timeTakenInSec = 9;
   */
  timeTakenInSec = 0;

  /**
   * @generated from field: int32 session_id = 10;
   */
  sessionId = 0;

  /**
   * @generated from field: optional geneo.lms.db.TaskEvaluationStatusEnum task_evaluation_status = 12;
   */
  taskEvaluationStatus?: TaskEvaluationStatusEnum;

  /**
   * @generated from field: optional bool is_attempted = 13;
   */
  isAttempted?: boolean;

  /**
   * @generated from field: optional int32 evaluationMetricsVersion = 14;
   */
  evaluationMetricsVersion?: number;

  /**
   * @generated from field: optional geneo.content.db.Question.QuestionEnum question_type = 15;
   */
  questionType?: Question_QuestionEnum;

  constructor(data?: PartialMessage<StudentTaskResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentTaskResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "question_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "responses", kind: "message", T: ContentAttempt, repeated: true },
    { no: 5, name: "is_correct", kind: "enum", T: proto3.getEnumType(QAttemptResultEnum), opt: true },
    { no: 6, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "remarks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "expectedTimeInSec", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "timeTakenInSec", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "task_evaluation_status", kind: "enum", T: proto3.getEnumType(TaskEvaluationStatusEnum), opt: true },
    { no: 13, name: "is_attempted", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 14, name: "evaluationMetricsVersion", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 15, name: "question_type", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentTaskResponse {
    return new StudentTaskResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentTaskResponse {
    return new StudentTaskResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentTaskResponse {
    return new StudentTaskResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentTaskResponse | PlainMessage<StudentTaskResponse> | undefined, b: StudentTaskResponse | PlainMessage<StudentTaskResponse> | undefined): boolean {
    return proto3.util.equals(StudentTaskResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.StudentAssessmentResponse
 */
export class StudentAssessmentResponse extends Message<StudentAssessmentResponse> {
  /**
   * @generated from field: optional int32 assessment_id = 1;
   */
  assessmentId?: number;

  /**
   * @generated from field: optional string question_id = 2;
   */
  questionId?: string;

  /**
   * @generated from field: optional string question_task_id = 3;
   */
  questionTaskId?: string;

  /**
   * @generated from field: repeated geneo.lms.db.ContentAttempt responses = 4;
   */
  responses: ContentAttempt[] = [];

  /**
   * @generated from field: optional geneo.lms.db.QAttemptResultEnum is_correct = 5;
   */
  isCorrect?: QAttemptResultEnum;

  /**
   * @generated from field: float max_score = 6;
   */
  maxScore = 0;

  /**
   * @generated from field: float response_score = 7;
   */
  responseScore = 0;

  /**
   * @generated from field: repeated string remarks = 11;
   */
  remarks: string[] = [];

  /**
   * @generated from field: float expectedTimeInSec = 8;
   */
  expectedTimeInSec = 0;

  /**
   * @generated from field: float timeTakenInSec = 9;
   */
  timeTakenInSec = 0;

  /**
   * @generated from field: int32 session_id = 10;
   */
  sessionId = 0;

  /**
   * @generated from field: optional geneo.lms.db.TaskEvaluationStatusEnum task_evaluation_status = 12;
   */
  taskEvaluationStatus?: TaskEvaluationStatusEnum;

  /**
   * @generated from field: optional bool is_attempted = 13;
   */
  isAttempted?: boolean;

  /**
   * @generated from field: optional int32 evaluationMetricsVersion = 14;
   */
  evaluationMetricsVersion?: number;

  /**
   * @generated from field: optional geneo.content.db.Question.QuestionEnum question_type = 15;
   */
  questionType?: Question_QuestionEnum;

  constructor(data?: PartialMessage<StudentAssessmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentAssessmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "question_task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "responses", kind: "message", T: ContentAttempt, repeated: true },
    { no: 5, name: "is_correct", kind: "enum", T: proto3.getEnumType(QAttemptResultEnum), opt: true },
    { no: 6, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "remarks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "expectedTimeInSec", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "timeTakenInSec", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "task_evaluation_status", kind: "enum", T: proto3.getEnumType(TaskEvaluationStatusEnum), opt: true },
    { no: 13, name: "is_attempted", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 14, name: "evaluationMetricsVersion", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 15, name: "question_type", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAssessmentResponse {
    return new StudentAssessmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAssessmentResponse {
    return new StudentAssessmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAssessmentResponse {
    return new StudentAssessmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAssessmentResponse | PlainMessage<StudentAssessmentResponse> | undefined, b: StudentAssessmentResponse | PlainMessage<StudentAssessmentResponse> | undefined): boolean {
    return proto3.util.equals(StudentAssessmentResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.StudentContentResponse
 */
export class StudentContentResponse extends Message<StudentContentResponse> {
  /**
   * @generated from field: optional string content_id = 1;
   */
  contentId?: string;

  /**
   * @generated from field: repeated geneo.lms.db.ContentAttempt responses = 2;
   */
  responses: ContentAttempt[] = [];

  constructor(data?: PartialMessage<StudentContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "responses", kind: "message", T: ContentAttempt, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentContentResponse {
    return new StudentContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentContentResponse {
    return new StudentContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentContentResponse {
    return new StudentContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentContentResponse | PlainMessage<StudentContentResponse> | undefined, b: StudentContentResponse | PlainMessage<StudentContentResponse> | undefined): boolean {
    return proto3.util.equals(StudentContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.TeacherContentResponse
 */
export class TeacherContentResponse extends Message<TeacherContentResponse> {
  /**
   * @generated from field: optional string content_id = 1;
   */
  contentId?: string;

  /**
   * @generated from field: repeated geneo.lms.db.ContentAttempt responses = 2;
   */
  responses: ContentAttempt[] = [];

  constructor(data?: PartialMessage<TeacherContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TeacherContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "responses", kind: "message", T: ContentAttempt, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherContentResponse {
    return new TeacherContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherContentResponse {
    return new TeacherContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherContentResponse {
    return new TeacherContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherContentResponse | PlainMessage<TeacherContentResponse> | undefined, b: TeacherContentResponse | PlainMessage<TeacherContentResponse> | undefined): boolean {
    return proto3.util.equals(TeacherContentResponse, a, b);
  }
}

/**
 * StudentLessonSessionMetrics represents the metrics for a student's lesson session.
 *
 * @generated from message geneo.lms.db.StudentLessonSessionMetrics
 */
export class StudentLessonSessionMetrics extends Message<StudentLessonSessionMetrics> {
  /**
   * Unique identifier for the student's lesson session.
   *
   * @generated from field: optional int32 student_lesson_session_id = 1;
   */
  studentLessonSessionId?: number;

  /**
   * Indicates if the lesson session is complete or not. Optional field.
   *
   * @generated from field: bool is_complete = 2;
   */
  isComplete = false;

  /**
   * @generated from field: float response_score = 3;
   */
  responseScore = 0;

  /**
   * @generated from field: float max_score = 4;
   */
  maxScore = 0;

  /**
   * List of resource IDs that have been completed by the student.
   *
   * @generated from field: repeated string completed_resource_ids = 5;
   */
  completedResourceIds: string[] = [];

  /**
   * List of visited resource content information.
   *
   * @generated from field: repeated geneo.lms.db.VisitedResourceContentInfoList visited_resource_content_info_list = 6;
   */
  visitedResourceContentInfoList: VisitedResourceContentInfoList[] = [];

  /**
   * @generated from field: optional string nano_id = 7;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<StudentLessonSessionMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentLessonSessionMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "is_complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "completed_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "visited_resource_content_info_list", kind: "message", T: VisitedResourceContentInfoList, repeated: true },
    { no: 7, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentLessonSessionMetrics {
    return new StudentLessonSessionMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentLessonSessionMetrics {
    return new StudentLessonSessionMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentLessonSessionMetrics {
    return new StudentLessonSessionMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: StudentLessonSessionMetrics | PlainMessage<StudentLessonSessionMetrics> | undefined, b: StudentLessonSessionMetrics | PlainMessage<StudentLessonSessionMetrics> | undefined): boolean {
    return proto3.util.equals(StudentLessonSessionMetrics, a, b);
  }
}

/**
 * StudentLessonSessionMetrics represents the metrics for a student's lesson session.
 *
 * @generated from message geneo.lms.db.TeacherLessonSessionMetrics
 */
export class TeacherLessonSessionMetrics extends Message<TeacherLessonSessionMetrics> {
  /**
   * Unique identifier for the student's lesson session.
   *
   * @generated from field: optional int32 teacher_lesson_session_id = 1;
   */
  teacherLessonSessionId?: number;

  /**
   * Indicates if the lesson session is complete or not. Optional field.
   *
   * @generated from field: bool is_complete = 2;
   */
  isComplete = false;

  /**
   * List of resource IDs that have been completed by the student.
   *
   * @generated from field: repeated string completed_resource_ids = 3;
   */
  completedResourceIds: string[] = [];

  /**
   * List of visited resource content information.
   *
   * @generated from field: repeated geneo.lms.db.VisitedResourceContentInfoList visited_resource_content_info_list = 4;
   */
  visitedResourceContentInfoList: VisitedResourceContentInfoList[] = [];

  /**
   * @generated from field: float response_score = 5;
   */
  responseScore = 0;

  /**
   * @generated from field: float max_score = 6;
   */
  maxScore = 0;

  /**
   * @generated from field: optional string nano_id = 7;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<TeacherLessonSessionMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TeacherLessonSessionMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "is_complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "completed_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "visited_resource_content_info_list", kind: "message", T: VisitedResourceContentInfoList, repeated: true },
    { no: 5, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherLessonSessionMetrics {
    return new TeacherLessonSessionMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherLessonSessionMetrics {
    return new TeacherLessonSessionMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherLessonSessionMetrics {
    return new TeacherLessonSessionMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherLessonSessionMetrics | PlainMessage<TeacherLessonSessionMetrics> | undefined, b: TeacherLessonSessionMetrics | PlainMessage<TeacherLessonSessionMetrics> | undefined): boolean {
    return proto3.util.equals(TeacherLessonSessionMetrics, a, b);
  }
}

/**
 * VisitedResourceContentInfoList represents the information about visited resource content.
 *
 * @generated from message geneo.lms.db.VisitedResourceContentInfoList
 */
export class VisitedResourceContentInfoList extends Message<VisitedResourceContentInfoList> {
  /**
   * Identifier for the visited resource.
   *
   * @generated from field: optional string resource_id = 1;
   */
  resourceId?: string;

  /**
   * Optional field indicating the page number of the visited resource.
   *
   * @generated from field: int32 page_no = 2;
   */
  pageNo = 0;

  /**
   * @generated from field: repeated string page_content_ids = 3;
   */
  pageContentIds: string[] = [];

  constructor(data?: PartialMessage<VisitedResourceContentInfoList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.VisitedResourceContentInfoList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "page_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_content_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VisitedResourceContentInfoList {
    return new VisitedResourceContentInfoList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VisitedResourceContentInfoList {
    return new VisitedResourceContentInfoList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VisitedResourceContentInfoList {
    return new VisitedResourceContentInfoList().fromJsonString(jsonString, options);
  }

  static equals(a: VisitedResourceContentInfoList | PlainMessage<VisitedResourceContentInfoList> | undefined, b: VisitedResourceContentInfoList | PlainMessage<VisitedResourceContentInfoList> | undefined): boolean {
    return proto3.util.equals(VisitedResourceContentInfoList, a, b);
  }
}

/**
 * StudentLessonSession represents a lesson session for a student.
 *
 * @generated from message geneo.lms.db.StudentLessonSession
 */
export class StudentLessonSession extends Message<StudentLessonSession> {
  /**
   * Unique identifier for the student's lesson session.
   *
   * @generated from field: optional int32 student_lesson_session_id = 1;
   */
  studentLessonSessionId?: number;

  /**
   * ID of the student associated with the lesson session.
   *
   * @generated from field: optional int64 student_id = 2;
   */
  studentId?: bigint;

  /**
   * ID of the lesson associated with the session.
   *
   * @generated from field: optional string lesson_id = 3;
   */
  lessonId?: string;

  /**
   * ID of the school where the lesson session takes place.
   *
   * @generated from field: optional int32 school_id = 4;
   */
  schoolId?: number;

  /**
   * Academic year associated with the lesson session.
   *
   * @generated from field: int32 academic_year = 5;
   */
  academicYear = 0;

  /**
   * Grade of the student.
   *
   * @generated from field: geneo.common.db.Class.ClassEnum class = 6;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * Section of the student.
   *
   * @generated from field: string section = 7;
   */
  section = "";

  /**
   * Subject of the lesson session.
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 8;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * Mode of the lesson session (e.g., online, offline, etc.).
   *
   * @generated from field: geneo.lms.db.SessionModeEnum session_mode = 9;
   */
  sessionMode = SessionModeEnum.SESSION_MODE_UNDEFINED;

  /**
   * Status of the lesson session (e.g., in progress, completed, etc.).
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 10;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * ID of the teacher's lesson session. Nullable field.
   *
   * @generated from field: optional int32 teacher_lesson_session_id = 11;
   */
  teacherLessonSessionId?: number;

  /**
   * List of resource IDs associated with the session.
   *
   * @generated from field: repeated string session_resource_ids = 12;
   */
  sessionResourceIds: string[] = [];

  /**
   * Start time of the lesson session.
   *
   * @generated from field: google.protobuf.Timestamp start_time = 13;
   */
  startTime?: Timestamp;

  /**
   * End time of the lesson session. Nullable field.
   *
   * @generated from field: google.protobuf.Timestamp end_time = 14;
   */
  endTime?: Timestamp;

  /**
   * Metrics associated with the lesson session. Nullable field.
   *
   * @generated from field: geneo.lms.db.StudentLessonSessionMetrics metrics = 15;
   */
  metrics?: StudentLessonSessionMetrics;

  /**
   * Timestamp when the session was created. Nullable field.
   *
   * @generated from field: google.protobuf.Timestamp created_on = 16;
   */
  createdOn?: Timestamp;

  /**
   * ID of the user who created the session. Nullable field.
   *
   * @generated from field: optional int64 created_by = 17;
   */
  createdBy?: bigint;

  /**
   * Timestamp when the session was last modified. Nullable field.
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 18;
   */
  modifiedOn?: Timestamp;

  /**
   * ID of the user who last modified the session. Nullable field.
   *
   * @generated from field: optional int64 modified_by = 19;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional int32 active_session_id = 20;
   */
  activeSessionId?: number;

  /**
   * @generated from field: optional string nano_id = 21;
   */
  nanoId?: string;

  /**
   * optional int32 school_id = 22 ;
   *
   * @generated from field: optional int32 school_device_id = 22;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<StudentLessonSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentLessonSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "academic_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 7, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 9, name: "session_mode", kind: "enum", T: proto3.getEnumType(SessionModeEnum) },
    { no: 10, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 11, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "session_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "start_time", kind: "message", T: Timestamp },
    { no: 14, name: "end_time", kind: "message", T: Timestamp },
    { no: 15, name: "metrics", kind: "message", T: StudentLessonSessionMetrics },
    { no: 16, name: "created_on", kind: "message", T: Timestamp },
    { no: 17, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 18, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 19, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 20, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 21, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentLessonSession {
    return new StudentLessonSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentLessonSession {
    return new StudentLessonSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentLessonSession {
    return new StudentLessonSession().fromJsonString(jsonString, options);
  }

  static equals(a: StudentLessonSession | PlainMessage<StudentLessonSession> | undefined, b: StudentLessonSession | PlainMessage<StudentLessonSession> | undefined): boolean {
    return proto3.util.equals(StudentLessonSession, a, b);
  }
}

/**
 * TeacherLessonSession represents a lesson session for a teacher.
 *
 * @generated from message geneo.lms.db.TeacherLessonSession
 */
export class TeacherLessonSession extends Message<TeacherLessonSession> {
  /**
   * Unique identifier for the teacher's lesson session.
   *
   * @generated from field: optional int32 teacher_lesson_session_id = 1;
   */
  teacherLessonSessionId?: number;

  /**
   * ID of the teacher associated with the lesson session.
   *
   * @generated from field: optional int64 teacher_id = 2;
   */
  teacherId?: bigint;

  /**
   * ID of the lesson associated with the session.
   *
   * @generated from field: optional string lesson_id = 3;
   */
  lessonId?: string;

  /**
   * ID of the school where the lesson session takes place.
   *
   * @generated from field: optional int32 school_id = 4;
   */
  schoolId?: number;

  /**
   * Academic year associated with the lesson session.
   *
   * @generated from field: int32 academic_year = 5;
   */
  academicYear = 0;

  /**
   * Grade associated with the lesson session.
   *
   * @generated from field: geneo.common.db.Class.ClassEnum class = 6;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * Section associated with the lesson session.
   *
   * @generated from field: string section = 7;
   */
  section = "";

  /**
   * Subject associated with the lesson session.
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 8;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * Mode of the lesson session (e.g., online, offline, etc.).
   *
   * @generated from field: geneo.lms.db.SessionModeEnum session_mode = 9;
   */
  sessionMode = SessionModeEnum.SESSION_MODE_UNDEFINED;

  /**
   * Status of the lesson session (e.g., in progress, completed, etc.).
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 10;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * List of resource IDs associated with the session.
   *
   * @generated from field: repeated string session_resource_ids = 11;
   */
  sessionResourceIds: string[] = [];

  /**
   * Start time of the lesson session.
   *
   * @generated from field: google.protobuf.Timestamp start_time = 12;
   */
  startTime?: Timestamp;

  /**
   * End time of the lesson session. Nullable field.
   *
   * @generated from field: google.protobuf.Timestamp end_time = 13;
   */
  endTime?: Timestamp;

  /**
   * Metrics associated with the lesson session. Nullable field.
   *
   * @generated from field: geneo.lms.db.TeacherLessonSessionMetrics metrics = 14;
   */
  metrics?: TeacherLessonSessionMetrics;

  /**
   * Timestamp when the session was created. Nullable field.
   *
   * @generated from field: google.protobuf.Timestamp created_on = 15;
   */
  createdOn?: Timestamp;

  /**
   * ID of the user who created the session. Nullable field.
   *
   * @generated from field: optional int64 created_by = 16;
   */
  createdBy?: bigint;

  /**
   * Timestamp when the session was last modified. Nullable field.
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 17;
   */
  modifiedOn?: Timestamp;

  /**
   * ID of the user who last modified the session. Nullable field.
   *
   * @generated from field: optional int64 modified_by = 18;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional int32 active_session_id = 19;
   */
  activeSessionId?: number;

  /**
   * @generated from field: optional string nano_id = 20;
   */
  nanoId?: string;

  /**
   * optional int32 school_id = 12 ;
   *
   * @generated from field: optional int32 school_device_id = 21;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<TeacherLessonSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TeacherLessonSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "academic_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 7, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 9, name: "session_mode", kind: "enum", T: proto3.getEnumType(SessionModeEnum) },
    { no: 10, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 11, name: "session_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "start_time", kind: "message", T: Timestamp },
    { no: 13, name: "end_time", kind: "message", T: Timestamp },
    { no: 14, name: "metrics", kind: "message", T: TeacherLessonSessionMetrics },
    { no: 15, name: "created_on", kind: "message", T: Timestamp },
    { no: 16, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 17, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 18, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 19, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 20, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherLessonSession {
    return new TeacherLessonSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherLessonSession {
    return new TeacherLessonSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherLessonSession {
    return new TeacherLessonSession().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherLessonSession | PlainMessage<TeacherLessonSession> | undefined, b: TeacherLessonSession | PlainMessage<TeacherLessonSession> | undefined): boolean {
    return proto3.util.equals(TeacherLessonSession, a, b);
  }
}

/**
 * StudentLessonSessionMetrics represents the metrics for a student's lesson session.
 *
 * @generated from message geneo.lms.db.StudentResourceSessionMetrics
 */
export class StudentResourceSessionMetrics extends Message<StudentResourceSessionMetrics> {
  /**
   * Unique identifier for the student's lesson session.
   *
   * @generated from field: optional int32 student_resource_session_id = 1;
   */
  studentResourceSessionId?: number;

  /**
   * Indicates if the lesson session is complete or not. Optional field.
   *
   * @generated from field: bool is_complete = 2;
   */
  isComplete = false;

  /**
   * @generated from field: float response_score = 3;
   */
  responseScore = 0;

  /**
   * @generated from field: float max_score = 4;
   */
  maxScore = 0;

  /**
   * List of resource IDs that have been completed by the student.
   *
   * @generated from field: repeated int32 completed_pages = 5;
   */
  completedPages: number[] = [];

  /**
   * List of visited resource content information.
   *
   * @generated from field: repeated geneo.lms.db.VisitedResourceContentInfoList visited_resource_content_info_list = 6;
   */
  visitedResourceContentInfoList: VisitedResourceContentInfoList[] = [];

  constructor(data?: PartialMessage<StudentResourceSessionMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentResourceSessionMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "is_complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "completed_pages", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 6, name: "visited_resource_content_info_list", kind: "message", T: VisitedResourceContentInfoList, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentResourceSessionMetrics {
    return new StudentResourceSessionMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentResourceSessionMetrics {
    return new StudentResourceSessionMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentResourceSessionMetrics {
    return new StudentResourceSessionMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: StudentResourceSessionMetrics | PlainMessage<StudentResourceSessionMetrics> | undefined, b: StudentResourceSessionMetrics | PlainMessage<StudentResourceSessionMetrics> | undefined): boolean {
    return proto3.util.equals(StudentResourceSessionMetrics, a, b);
  }
}

/**
 * StudentResourceSessionMetrics represents the metrics for a student's lesson session.
 *
 * @generated from message geneo.lms.db.TeacherResourceSessionMetrics
 */
export class TeacherResourceSessionMetrics extends Message<TeacherResourceSessionMetrics> {
  /**
   * Unique identifier for the student's lesson session.
   *
   * @generated from field: optional int32 teacher_resource_session_id = 1;
   */
  teacherResourceSessionId?: number;

  /**
   * Indicates if the lesson session is complete or not. Optional field.
   *
   * @generated from field: bool is_complete = 2;
   */
  isComplete = false;

  /**
   * @generated from field: float response_score = 3;
   */
  responseScore = 0;

  /**
   * @generated from field: float max_score = 4;
   */
  maxScore = 0;

  /**
   * List of resource IDs that have been completed by the student.
   *
   * @generated from field: repeated int32 completed_pages = 5;
   */
  completedPages: number[] = [];

  /**
   * List of visited resource content information.
   *
   * @generated from field: repeated geneo.lms.db.VisitedResourceContentInfoList visited_resource_content_info_list = 6;
   */
  visitedResourceContentInfoList: VisitedResourceContentInfoList[] = [];

  constructor(data?: PartialMessage<TeacherResourceSessionMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TeacherResourceSessionMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "is_complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "completed_pages", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 6, name: "visited_resource_content_info_list", kind: "message", T: VisitedResourceContentInfoList, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherResourceSessionMetrics {
    return new TeacherResourceSessionMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherResourceSessionMetrics {
    return new TeacherResourceSessionMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherResourceSessionMetrics {
    return new TeacherResourceSessionMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherResourceSessionMetrics | PlainMessage<TeacherResourceSessionMetrics> | undefined, b: TeacherResourceSessionMetrics | PlainMessage<TeacherResourceSessionMetrics> | undefined): boolean {
    return proto3.util.equals(TeacherResourceSessionMetrics, a, b);
  }
}

/**
 * StudentResourceSession represents a resource session for a student.
 *
 * @generated from message geneo.lms.db.StudentResourceSession
 */
export class StudentResourceSession extends Message<StudentResourceSession> {
  /**
   * Unique identifier for the student's resource session.
   *
   * @generated from field: optional int32 student_resource_session_id = 1;
   */
  studentResourceSessionId?: number;

  /**
   * ID of the student associated with the resource session.
   *
   * @generated from field: optional int64 student_id = 2;
   */
  studentId?: bigint;

  /**
   * ID of the resource associated with the session.
   *
   * @generated from field: optional string resource_id = 3;
   */
  resourceId?: string;

  /**
   * ID of the school where the lesson session takes place.
   *
   * @generated from field: optional int32 school_id = 4;
   */
  schoolId?: number;

  /**
   * Academic year associated with the lesson session.
   *
   * @generated from field: int32 academic_year = 5;
   */
  academicYear = 0;

  /**
   * Grade associated with the lesson session.
   *
   * @generated from field: geneo.common.db.Class.ClassEnum class = 6;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * Section associated with the lesson session.
   *
   * @generated from field: string section = 7;
   */
  section = "";

  /**
   * Subject associated with the lesson session.
   *
   * @generated from field: optional int32 module_id = 8;
   */
  moduleId?: number;

  /**
   * Subject associated with the lesson session.
   *
   * @generated from field: optional geneo.common.db.Subject.SubjectEnum subject = 9;
   */
  subject?: Subject_SubjectEnum;

  /**
   * Resource version at the time of the session.
   *
   * @generated from field: int32 resource_content_version = 10;
   */
  resourceContentVersion = 0;

  /**
   * ID of the user's lesson session. Nullable field.
   *
   * @generated from field: optional int32 student_lesson_session_id = 11;
   */
  studentLessonSessionId?: number;

  /**
   * Mode of the resource session (e.g., online, offline, etc.).
   *
   * @generated from field: geneo.lms.db.SessionModeEnum session_mode = 12;
   */
  sessionMode = SessionModeEnum.SESSION_MODE_UNDEFINED;

  /**
   * Status of the resource session (e.g., in progress, completed, etc.).
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 13;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * Start time of the resource session.
   *
   * @generated from field: google.protobuf.Timestamp start_time = 14;
   */
  startTime?: Timestamp;

  /**
   * End time of the resource session. Nullable field.
   *
   * @generated from field: google.protobuf.Timestamp end_time = 15;
   */
  endTime?: Timestamp;

  /**
   * Response associated with the resource session (e.g., ResourceResponseModel).
   *
   * @generated from field: repeated geneo.lms.db.StudentContentResponse response = 16;
   */
  response: StudentContentResponse[] = [];

  /**
   * Metrics associated with the lesson session. Nullable field.
   *
   * @generated from field: geneo.lms.db.StudentResourceSessionMetrics metrics = 17;
   */
  metrics?: StudentResourceSessionMetrics;

  /**
   * @generated from field: optional int32 active_session_id = 18;
   */
  activeSessionId?: number;

  /**
   * @generated from field: optional string nano_id = 19;
   */
  nanoId?: string;

  /**
   * optional int32 school_id = 12 ;
   *
   * @generated from field: optional int32 school_device_id = 20;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<StudentResourceSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentResourceSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "academic_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 7, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum), opt: true },
    { no: 10, name: "resource_content_version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "student_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "session_mode", kind: "enum", T: proto3.getEnumType(SessionModeEnum) },
    { no: 13, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 14, name: "start_time", kind: "message", T: Timestamp },
    { no: 15, name: "end_time", kind: "message", T: Timestamp },
    { no: 16, name: "response", kind: "message", T: StudentContentResponse, repeated: true },
    { no: 17, name: "metrics", kind: "message", T: StudentResourceSessionMetrics },
    { no: 18, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 19, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentResourceSession {
    return new StudentResourceSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentResourceSession {
    return new StudentResourceSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentResourceSession {
    return new StudentResourceSession().fromJsonString(jsonString, options);
  }

  static equals(a: StudentResourceSession | PlainMessage<StudentResourceSession> | undefined, b: StudentResourceSession | PlainMessage<StudentResourceSession> | undefined): boolean {
    return proto3.util.equals(StudentResourceSession, a, b);
  }
}

/**
 * TeacherResourceSession represents a resource session for a teacher.
 *
 * @generated from message geneo.lms.db.TeacherResourceSession
 */
export class TeacherResourceSession extends Message<TeacherResourceSession> {
  /**
   * Unique identifier for the teacher's resource session.
   *
   * @generated from field: optional int32 teacher_resource_session_id = 1;
   */
  teacherResourceSessionId?: number;

  /**
   * ID of the teacher associated with the resource session.
   *
   * @generated from field: optional int64 teacher_id = 2;
   */
  teacherId?: bigint;

  /**
   * ID of the resource associated with the session.
   *
   * @generated from field: optional string resource_id = 3;
   */
  resourceId?: string;

  /**
   * ID of the school where the lesson session takes place.
   *
   * @generated from field: optional int32 school_id = 4;
   */
  schoolId?: number;

  /**
   * Academic year associated with the lesson session.
   *
   * @generated from field: int32 academic_year = 5;
   */
  academicYear = 0;

  /**
   * Grade associated with the lesson session.
   *
   * @generated from field: geneo.common.db.Class.ClassEnum class = 6;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * Section associated with the lesson session.
   *
   * @generated from field: string section = 7;
   */
  section = "";

  /**
   * Subject associated with the lesson session.
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 8;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * Subject associated with the lesson session.
   *
   * @generated from field: optional int32 module_id = 9;
   */
  moduleId?: number;

  /**
   * Resource version at the time of the session.
   *
   * @generated from field: int32 resource_content_version = 10;
   */
  resourceContentVersion = 0;

  /**
   * ID of the user's lesson session. Nullable field.
   *
   * @generated from field: optional int32 teacher_lesson_session_id = 11;
   */
  teacherLessonSessionId?: number;

  /**
   * Mode of the resource session (e.g., online, offline, etc.).
   *
   * @generated from field: geneo.lms.db.SessionModeEnum session_mode = 12;
   */
  sessionMode = SessionModeEnum.SESSION_MODE_UNDEFINED;

  /**
   * Status of the resource session (e.g., in progress, completed, etc.).
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 13;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * Start time of the resource session.
   *
   * @generated from field: google.protobuf.Timestamp start_time = 14;
   */
  startTime?: Timestamp;

  /**
   * End time of the resource session. Nullable field.
   *
   * @generated from field: google.protobuf.Timestamp end_time = 15;
   */
  endTime?: Timestamp;

  /**
   * Response associated with the resource session (e.g., ResourceResponseModel).
   *
   * @generated from field: repeated geneo.lms.db.TeacherContentResponse response = 16;
   */
  response: TeacherContentResponse[] = [];

  /**
   * Metrics associated with the lesson session. Nullable field.
   *
   * @generated from field: geneo.lms.db.TeacherResourceSessionMetrics metrics = 17;
   */
  metrics?: TeacherResourceSessionMetrics;

  /**
   * @generated from field: optional int32 active_session_id = 18;
   */
  activeSessionId?: number;

  /**
   * @generated from field: optional string nano_id = 19;
   */
  nanoId?: string;

  /**
   * optional int32 school_id = 12 ;
   *
   * @generated from field: optional int32 school_device_id = 20;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<TeacherResourceSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TeacherResourceSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "academic_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 7, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 9, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "resource_content_version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "session_mode", kind: "enum", T: proto3.getEnumType(SessionModeEnum) },
    { no: 13, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 14, name: "start_time", kind: "message", T: Timestamp },
    { no: 15, name: "end_time", kind: "message", T: Timestamp },
    { no: 16, name: "response", kind: "message", T: TeacherContentResponse, repeated: true },
    { no: 17, name: "metrics", kind: "message", T: TeacherResourceSessionMetrics },
    { no: 18, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 19, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherResourceSession {
    return new TeacherResourceSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherResourceSession {
    return new TeacherResourceSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherResourceSession {
    return new TeacherResourceSession().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherResourceSession | PlainMessage<TeacherResourceSession> | undefined, b: TeacherResourceSession | PlainMessage<TeacherResourceSession> | undefined): boolean {
    return proto3.util.equals(TeacherResourceSession, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.StudentModuleAnalytics
 */
export class StudentModuleAnalytics extends Message<StudentModuleAnalytics> {
  /**
   * @generated from field: int32 student_module_analytics_id = 1;
   */
  studentModuleAnalyticsId = 0;

  /**
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 course_id = 3;
   */
  courseId = 0;

  /**
   * @generated from field: int32 module_id = 4;
   */
  moduleId = 0;

  /**
   * @generated from field: float score = 5;
   */
  score = 0;

  /**
   * @generated from field: int32 no_of_hw_completed = 6;
   */
  noOfHwCompleted = 0;

  /**
   * @generated from field: float time_spent_in_min = 7;
   */
  timeSpentInMin = 0;

  /**
   * @generated from field: google.protobuf.Timestamp expiry_timestamp = 8;
   */
  expiryTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp last_updated_time = 9;
   */
  lastUpdatedTime?: Timestamp;

  /**
   * Timestamp when the session was created. Nullable field.
   *
   * @generated from field: google.protobuf.Timestamp created_on = 10;
   */
  createdOn?: Timestamp;

  /**
   * ID of the user who created the session. Nullable field.
   *
   * @generated from field: optional int64 created_by = 11;
   */
  createdBy?: bigint;

  /**
   * Timestamp when the session was last modified. Nullable field.
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 12;
   */
  modifiedOn?: Timestamp;

  /**
   * ID of the user who last modified the session. Nullable field.
   *
   * @generated from field: optional int64 modified_by = 13;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: int32 version = 14;
   */
  version = 0;

  /**
   * 0 -> book, 1 -> chapter, 2 -> topic
   *
   * @generated from field: int32 level = 15;
   */
  level = 0;

  /**
   * @generated from field: optional int64 teacher_id = 16;
   */
  teacherId?: bigint;

  /**
   * @generated from field: optional string nano_id = 17;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 18;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 19;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<StudentModuleAnalytics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentModuleAnalytics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_module_analytics_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "no_of_hw_completed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "time_spent_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "expiry_timestamp", kind: "message", T: Timestamp },
    { no: 9, name: "last_updated_time", kind: "message", T: Timestamp },
    { no: 10, name: "created_on", kind: "message", T: Timestamp },
    { no: 11, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 13, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 14, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "level", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 17, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 19, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentModuleAnalytics {
    return new StudentModuleAnalytics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentModuleAnalytics {
    return new StudentModuleAnalytics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentModuleAnalytics {
    return new StudentModuleAnalytics().fromJsonString(jsonString, options);
  }

  static equals(a: StudentModuleAnalytics | PlainMessage<StudentModuleAnalytics> | undefined, b: StudentModuleAnalytics | PlainMessage<StudentModuleAnalytics> | undefined): boolean {
    return proto3.util.equals(StudentModuleAnalytics, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.TeacherModuleAnalytics
 */
export class TeacherModuleAnalytics extends Message<TeacherModuleAnalytics> {
  /**
   * @generated from field: int32 teacher_module_analytics_id = 1;
   */
  teacherModuleAnalyticsId = 0;

  /**
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 school_class_section_id = 3;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 course_id = 5;
   */
  courseId = 0;

  /**
   * @generated from field: int32 module_id = 4;
   */
  moduleId = 0;

  /**
   * @generated from field: float score = 6;
   */
  score = 0;

  /**
   * @generated from field: int32 no_of_hw_assigned = 7;
   */
  noOfHwAssigned = 0;

  /**
   * @generated from field: int32 no_of_lesson_taughts = 8;
   */
  noOfLessonTaughts = 0;

  /**
   * @generated from field: float time_spent_in_min = 9;
   */
  timeSpentInMin = 0;

  /**
   * @generated from field: google.protobuf.Timestamp expiry_timestamp = 10;
   */
  expiryTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp last_updated_time = 11;
   */
  lastUpdatedTime?: Timestamp;

  /**
   * Timestamp when the session was created. Nullable field.
   *
   * @generated from field: google.protobuf.Timestamp created_on = 12;
   */
  createdOn?: Timestamp;

  /**
   * ID of the user who created the session. Nullable field.
   *
   * @generated from field: optional int64 created_by = 13;
   */
  createdBy?: bigint;

  /**
   * Timestamp when the session was last modified. Nullable field.
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 14;
   */
  modifiedOn?: Timestamp;

  /**
   * ID of the user who last modified the session. Nullable field.
   *
   * @generated from field: optional int64 modified_by = 15;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: int32 version = 16;
   */
  version = 0;

  /**
   * 0 -> book, 1 -> chapter, 2 -> topic
   *
   * @generated from field: int32 level = 17;
   */
  level = 0;

  /**
   * @generated from field: optional string nano_id = 18;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 19;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 20;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<TeacherModuleAnalytics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.TeacherModuleAnalytics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_module_analytics_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "no_of_hw_assigned", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "no_of_lesson_taughts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "time_spent_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "expiry_timestamp", kind: "message", T: Timestamp },
    { no: 11, name: "last_updated_time", kind: "message", T: Timestamp },
    { no: 12, name: "created_on", kind: "message", T: Timestamp },
    { no: 13, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 14, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 15, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 16, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "level", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 20, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherModuleAnalytics {
    return new TeacherModuleAnalytics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherModuleAnalytics {
    return new TeacherModuleAnalytics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherModuleAnalytics {
    return new TeacherModuleAnalytics().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherModuleAnalytics | PlainMessage<TeacherModuleAnalytics> | undefined, b: TeacherModuleAnalytics | PlainMessage<TeacherModuleAnalytics> | undefined): boolean {
    return proto3.util.equals(TeacherModuleAnalytics, a, b);
  }
}

/**
 * Message for connected_class session table
 *
 * @generated from message geneo.lms.db.ConnectedClassSession
 */
export class ConnectedClassSession extends Message<ConnectedClassSession> {
  /**
   * Primary key for connected_class_session
   *
   * @generated from field: int64 connected_class_session_id = 1;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * referencing school id
   *
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * referencing class
   *
   * @generated from field: int32 classId = 3;
   */
  classId = 0;

  /**
   * referencing section
   *
   * @generated from field: int32 sectionId = 4;
   */
  sectionId = 0;

  /**
   * referencing subject
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 5;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * referencing base receiver id
   *
   * @generated from field: int32 base_receiver_id = 6;
   */
  baseReceiverId = 0;

  /**
   * referencing teacher profile id
   *
   * @generated from field: int64 teacher_id = 7;
   */
  teacherId = protoInt64.zero;

  /**
   * referencing lesson id
   *
   * @generated from field: string lesson_id = 8;
   */
  lessonId = "";

  /**
   * referencing lesson session id
   *
   * @generated from field: int32 teacher_lesson_session_id = 9;
   */
  teacherLessonSessionId = 0;

  /**
   * session start time
   *
   * @generated from field: google.protobuf.Timestamp session_start_time = 10;
   */
  sessionStartTime?: Timestamp;

  /**
   * session end time
   *
   * @generated from field: google.protobuf.Timestamp session_end_time = 11;
   */
  sessionEndTime?: Timestamp;

  /**
   * session status
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 12;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 13;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 14;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 15;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 16;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 17;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 18;
   */
  nanoId?: string;

  /**
   * optional int32 school_id = 19 ;
   *
   * @generated from field: optional int32 school_device_id = 19;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<ConnectedClassSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.ConnectedClassSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "classId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 6, name: "base_receiver_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "session_start_time", kind: "message", T: Timestamp },
    { no: 11, name: "session_end_time", kind: "message", T: Timestamp },
    { no: 12, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 13, name: "created_on", kind: "message", T: Timestamp },
    { no: 14, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 15, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 16, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 17, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 18, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassSession {
    return new ConnectedClassSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassSession {
    return new ConnectedClassSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassSession {
    return new ConnectedClassSession().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassSession | PlainMessage<ConnectedClassSession> | undefined, b: ConnectedClassSession | PlainMessage<ConnectedClassSession> | undefined): boolean {
    return proto3.util.equals(ConnectedClassSession, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.ConnectedClassResourceSession
 */
export class ConnectedClassResourceSession extends Message<ConnectedClassResourceSession> {
  /**
   * Primary key for connected_class_question_session
   *
   * @generated from field: int64 connected_class_resource_session_id = 1;
   */
  connectedClassResourceSessionId = protoInt64.zero;

  /**
   * referencing connected_class_session_id
   *
   * @generated from field: int64 connected_class_session_id = 2;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * referencing question id
   *
   * @generated from field: string resource_id = 3;
   */
  resourceId = "";

  /**
   * question start time
   *
   * @generated from field: google.protobuf.Timestamp session_start_time = 4;
   */
  sessionStartTime?: Timestamp;

  /**
   * question end time
   *
   * @generated from field: optional google.protobuf.Timestamp session_end_time = 5;
   */
  sessionEndTime?: Timestamp;

  /**
   * session status
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 6;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 7;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 8;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 9;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 10;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 11;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 12;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 13;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 14;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<ConnectedClassResourceSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.ConnectedClassResourceSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_resource_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_start_time", kind: "message", T: Timestamp },
    { no: 5, name: "session_end_time", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 7, name: "created_on", kind: "message", T: Timestamp },
    { no: 8, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "modified_on", kind: "message", T: Timestamp },
    { no: 10, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassResourceSession {
    return new ConnectedClassResourceSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassResourceSession {
    return new ConnectedClassResourceSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassResourceSession {
    return new ConnectedClassResourceSession().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassResourceSession | PlainMessage<ConnectedClassResourceSession> | undefined, b: ConnectedClassResourceSession | PlainMessage<ConnectedClassResourceSession> | undefined): boolean {
    return proto3.util.equals(ConnectedClassResourceSession, a, b);
  }
}

/**
 * message for connected_class_question_session table
 *
 * @generated from message geneo.lms.db.ConnectedClassQuestionSession
 */
export class ConnectedClassQuestionSession extends Message<ConnectedClassQuestionSession> {
  /**
   * Primary key for connected_class_question_session
   *
   * @generated from field: int64 connected_class_question_session_id = 1;
   */
  connectedClassQuestionSessionId = protoInt64.zero;

  /**
   * referencing connected_class_session_id
   *
   * @generated from field: int64 connected_class_resource_session_id = 2;
   */
  connectedClassResourceSessionId = protoInt64.zero;

  /**
   * referencing question id
   *
   * @generated from field: string question_id = 3;
   */
  questionId = "";

  /**
   * sequence number
   *
   * @generated from field: optional int32 sequence = 4;
   */
  sequence?: number;

  /**
   * question start time
   *
   * @generated from field: google.protobuf.Timestamp start_time = 5;
   */
  startTime?: Timestamp;

  /**
   * question end time
   *
   * @generated from field: optional google.protobuf.Timestamp end_time = 6;
   */
  endTime?: Timestamp;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 7;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 8;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 9;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 10;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 11;
   */
  deletedAt?: Timestamp;

  /**
   * session status
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 12;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * @generated from field: optional string nano_id = 13;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 14;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 15;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<ConnectedClassQuestionSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.ConnectedClassQuestionSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_question_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "connected_class_resource_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sequence", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "start_time", kind: "message", T: Timestamp },
    { no: 6, name: "end_time", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "created_on", kind: "message", T: Timestamp },
    { no: 8, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 13, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 15, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassQuestionSession {
    return new ConnectedClassQuestionSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassQuestionSession {
    return new ConnectedClassQuestionSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassQuestionSession {
    return new ConnectedClassQuestionSession().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassQuestionSession | PlainMessage<ConnectedClassQuestionSession> | undefined, b: ConnectedClassQuestionSession | PlainMessage<ConnectedClassQuestionSession> | undefined): boolean {
    return proto3.util.equals(ConnectedClassQuestionSession, a, b);
  }
}

/**
 * Message for connected_class_student_session table
 * TODO: do we need to support multiple sessions for a student in a class? and shall we consider it as 1 session even if it's not continuous? -- individual session
 * TODO: check for the user active status and need to maintain that?
 *
 * @generated from message geneo.lms.db.ConnectedClassStudentSession
 */
export class ConnectedClassStudentSession extends Message<ConnectedClassStudentSession> {
  /**
   * Primary key for connected_class_student_session
   *
   * @generated from field: int64 connected_class_student_session_id = 1;
   */
  connectedClassStudentSessionId = protoInt64.zero;

  /**
   * referencing connected_class_session_id
   *
   * @generated from field: int64 connected_class_session_id = 2;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * referencing student profile id
   *
   * @generated from field: int64 student_id = 3;
   */
  studentId = protoInt64.zero;

  /**
   * referencing clicker id
   *
   * @generated from field: string clicker_id = 4;
   */
  clickerId = "";

  /**
   * Response associated with the resource session (e.g., ResourceResponseModel).
   *
   * @generated from field: repeated geneo.lms.db.StudentContentedClassResponse response = 5;
   */
  response: StudentContentedClassResponse[] = [];

  /**
   * session start time
   *
   * @generated from field: google.protobuf.Timestamp session_start_time = 6;
   */
  sessionStartTime?: Timestamp;

  /**
   * session end time
   *
   * @generated from field: optional google.protobuf.Timestamp session_end_time = 7;
   */
  sessionEndTime?: Timestamp;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 8;
   */
  createdOn?: Timestamp;

  /**
   * session status
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 9;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 10;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 11;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 12;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 13;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: repeated geneo.lms.db.ClickerConnectionAttempt clicker_connection_attempts = 14;
   */
  clickerConnectionAttempts: ClickerConnectionAttempt[] = [];

  /**
   * @generated from field: optional string nano_id = 15;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 16;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 17;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<ConnectedClassStudentSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.ConnectedClassStudentSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_student_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "clicker_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "response", kind: "message", T: StudentContentedClassResponse, repeated: true },
    { no: 6, name: "session_start_time", kind: "message", T: Timestamp },
    { no: 7, name: "session_end_time", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "created_on", kind: "message", T: Timestamp },
    { no: 9, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 10, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "clicker_connection_attempts", kind: "message", T: ClickerConnectionAttempt, repeated: true },
    { no: 15, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 17, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassStudentSession {
    return new ConnectedClassStudentSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassStudentSession {
    return new ConnectedClassStudentSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassStudentSession {
    return new ConnectedClassStudentSession().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassStudentSession | PlainMessage<ConnectedClassStudentSession> | undefined, b: ConnectedClassStudentSession | PlainMessage<ConnectedClassStudentSession> | undefined): boolean {
    return proto3.util.equals(ConnectedClassStudentSession, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.ClickerConnectionAttempt
 */
export class ClickerConnectionAttempt extends Message<ClickerConnectionAttempt> {
  /**
   * @generated from field: google.protobuf.Timestamp connected_at = 1;
   */
  connectedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp disconnected_at = 2;
   */
  disconnectedAt?: Timestamp;

  constructor(data?: PartialMessage<ClickerConnectionAttempt>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.ClickerConnectionAttempt";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_at", kind: "message", T: Timestamp },
    { no: 2, name: "disconnected_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClickerConnectionAttempt {
    return new ClickerConnectionAttempt().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClickerConnectionAttempt {
    return new ClickerConnectionAttempt().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClickerConnectionAttempt {
    return new ClickerConnectionAttempt().fromJsonString(jsonString, options);
  }

  static equals(a: ClickerConnectionAttempt | PlainMessage<ClickerConnectionAttempt> | undefined, b: ClickerConnectionAttempt | PlainMessage<ClickerConnectionAttempt> | undefined): boolean {
    return proto3.util.equals(ClickerConnectionAttempt, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.StudentContentedClassResponse
 */
export class StudentContentedClassResponse extends Message<StudentContentedClassResponse> {
  /**
   * @generated from field: int64 connected_class_resource_session_id = 1;
   */
  connectedClassResourceSessionId = protoInt64.zero;

  /**
   * @generated from field: int64 connected_class_question_session_id = 2;
   */
  connectedClassQuestionSessionId = protoInt64.zero;

  /**
   * ID of the question
   *
   * @generated from field: string question_id = 3;
   */
  questionId = "";

  /**
   * Answer given by the student
   *
   * @generated from field: string answer = 4;
   */
  answer = "";

  /**
   * Indicates whether the answer is correct or not
   *
   * @generated from field: optional geneo.lms.db.QAttemptResultEnum is_correct = 5;
   */
  isCorrect?: QAttemptResultEnum;

  /**
   * @generated from field: optional float response_score = 8;
   */
  responseScore?: number;

  /**
   * Timestamp when the response was submitted
   *
   * @generated from field: google.protobuf.Timestamp response_submission_time = 6;
   */
  responseSubmissionTime?: Timestamp;

  /**
   * Duration of the response in minutes
   *
   * @generated from field: float duration_in_mins = 7;
   */
  durationInMins = 0;

  constructor(data?: PartialMessage<StudentContentedClassResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentContentedClassResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_resource_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "connected_class_question_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "is_correct", kind: "enum", T: proto3.getEnumType(QAttemptResultEnum), opt: true },
    { no: 8, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 6, name: "response_submission_time", kind: "message", T: Timestamp },
    { no: 7, name: "duration_in_mins", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentContentedClassResponse {
    return new StudentContentedClassResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentContentedClassResponse {
    return new StudentContentedClassResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentContentedClassResponse {
    return new StudentContentedClassResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentContentedClassResponse | PlainMessage<StudentContentedClassResponse> | undefined, b: StudentContentedClassResponse | PlainMessage<StudentContentedClassResponse> | undefined): boolean {
    return proto3.util.equals(StudentContentedClassResponse, a, b);
  }
}

/**
 * TODO: metrics tables to be added - refine the tables
 * message for the connected class metrics
 *
 * @generated from message geneo.lms.db.ConnectedClassSessionAnalytics
 */
export class ConnectedClassSessionAnalytics extends Message<ConnectedClassSessionAnalytics> {
  /**
   * Primary key for connected_class_session_metrics
   *
   * @generated from field: int64 connected_class_session_analytics_id = 1;
   */
  connectedClassSessionAnalyticsId = protoInt64.zero;

  /**
   * referencing connected_class_session_id
   *
   * @generated from field: int64 connected_class_session_id = 2;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * number of students in the session
   *
   * @generated from field: int32 no_of_students = 3;
   */
  noOfStudents = 0;

  /**
   * number of questions in the session
   *
   * @generated from field: int32 no_of_questions = 4;
   */
  noOfQuestions = 0;

  /**
   * number of correct answers in the session
   *
   * @generated from field: int32 no_of_correct_answers = 5;
   */
  noOfCorrectAnswers = 0;

  /**
   * number of incorrect answers in the session
   *
   * @generated from field: int32 no_of_incorrect_answers = 6;
   */
  noOfIncorrectAnswers = 0;

  /**
   * @generated from field: int32 no_of_unanswered_questions = 7;
   */
  noOfUnansweredQuestions = 0;

  /**
   * maximum score for all the questions in the session
   *
   * @generated from field: int32 max_score = 8;
   */
  maxScore = 0;

  /**
   * number of marks gained by the students
   *
   * @generated from field: float score = 9;
   */
  score = 0;

  /**
   * time spent by the students in the session
   *
   * @generated from field: int32 time_spent_in_min = 10;
   */
  timeSpentInMin = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 11;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 12;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 13;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 14;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 15;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: repeated geneo.lms.db.StudentAnalytics student_analytics = 16;
   */
  studentAnalytics: StudentAnalytics[] = [];

  /**
   * @generated from field: optional string nano_id = 17;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 18;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 19;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<ConnectedClassSessionAnalytics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.ConnectedClassSessionAnalytics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_session_analytics_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "no_of_students", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "no_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "no_of_correct_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "no_of_incorrect_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "no_of_unanswered_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "max_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "time_spent_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "created_on", kind: "message", T: Timestamp },
    { no: 12, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 15, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 16, name: "student_analytics", kind: "message", T: StudentAnalytics, repeated: true },
    { no: 17, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 19, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassSessionAnalytics {
    return new ConnectedClassSessionAnalytics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassSessionAnalytics {
    return new ConnectedClassSessionAnalytics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassSessionAnalytics {
    return new ConnectedClassSessionAnalytics().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassSessionAnalytics | PlainMessage<ConnectedClassSessionAnalytics> | undefined, b: ConnectedClassSessionAnalytics | PlainMessage<ConnectedClassSessionAnalytics> | undefined): boolean {
    return proto3.util.equals(ConnectedClassSessionAnalytics, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.StudentAnalytics
 */
export class StudentAnalytics extends Message<StudentAnalytics> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: float score = 2;
   */
  score = 0;

  /**
   * @generated from field: optional float response_submission_time_in_mins = 3;
   */
  responseSubmissionTimeInMins?: number;

  constructor(data?: PartialMessage<StudentAnalytics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentAnalytics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "response_submission_time_in_mins", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAnalytics {
    return new StudentAnalytics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAnalytics {
    return new StudentAnalytics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAnalytics {
    return new StudentAnalytics().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAnalytics | PlainMessage<StudentAnalytics> | undefined, b: StudentAnalytics | PlainMessage<StudentAnalytics> | undefined): boolean {
    return proto3.util.equals(StudentAnalytics, a, b);
  }
}

/**
 * message for the connected class student wise sessions metrics
 *
 * @generated from message geneo.lms.db.ConnectedClassStudentSessionAnalytics
 */
export class ConnectedClassStudentSessionAnalytics extends Message<ConnectedClassStudentSessionAnalytics> {
  /**
   * Primary key for connected_class_student_session_metrics
   *
   * @generated from field: int64 connected_class_student_session_analytics_id = 1;
   */
  connectedClassStudentSessionAnalyticsId = protoInt64.zero;

  /**
   * referencing connected_class_student_session_id
   *
   * @generated from field: int64 connected_class_student_session_id = 2;
   */
  connectedClassStudentSessionId = protoInt64.zero;

  /**
   * number of questions in the session
   *
   * @generated from field: int32 no_of_questions = 3;
   */
  noOfQuestions = 0;

  /**
   * number of correct answers in the session
   *
   * @generated from field: int32 no_of_correct_answers = 4;
   */
  noOfCorrectAnswers = 0;

  /**
   * number of incorrect answers in the session
   *
   * @generated from field: int32 no_of_incorrect_answers = 5;
   */
  noOfIncorrectAnswers = 0;

  /**
   * @generated from field: int32 no_of_unanswered_questions = 6;
   */
  noOfUnansweredQuestions = 0;

  /**
   * maximum score for all the questions in the session
   *
   * @generated from field: int32 max_score = 7;
   */
  maxScore = 0;

  /**
   * number of marks gained by the student
   *
   * @generated from field: float score = 8;
   */
  score = 0;

  /**
   * time spent by the students in the session
   *
   * @generated from field: int32 time_spent_in_min = 9;
   */
  timeSpentInMin = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 10;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 11;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 12;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 13;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 14;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 15;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 16;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 17;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<ConnectedClassStudentSessionAnalytics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.ConnectedClassStudentSessionAnalytics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_student_session_analytics_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "connected_class_student_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "no_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "no_of_correct_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "no_of_incorrect_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "no_of_unanswered_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "max_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "time_spent_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "created_on", kind: "message", T: Timestamp },
    { no: 11, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 13, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 14, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 15, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 17, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassStudentSessionAnalytics {
    return new ConnectedClassStudentSessionAnalytics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassStudentSessionAnalytics {
    return new ConnectedClassStudentSessionAnalytics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassStudentSessionAnalytics {
    return new ConnectedClassStudentSessionAnalytics().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassStudentSessionAnalytics | PlainMessage<ConnectedClassStudentSessionAnalytics> | undefined, b: ConnectedClassStudentSessionAnalytics | PlainMessage<ConnectedClassStudentSessionAnalytics> | undefined): boolean {
    return proto3.util.equals(ConnectedClassStudentSessionAnalytics, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.HomeworkAssessmentReport
 */
export class HomeworkAssessmentReport extends Message<HomeworkAssessmentReport> {
  /**
   * @generated from field: int32 assessment_report_id = 1;
   */
  assessmentReportId = 0;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: string gcp_report_url = 3;
   */
  gcpReportUrl = "";

  /**
   * optional geneo.analysis.school.assessment.apis.AssessmentReport response = 4;
   *
   * @generated from field: optional int32 teacher_id = 5;
   */
  teacherId?: number;

  /**
   * @generated from field: geneo.lms.db.PDFGenStatus processing_status = 6;
   */
  processingStatus = PDFGenStatus.PDF_GENERATION_STATUS_UNDEFINED;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 7;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 8;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 9;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 10;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 11;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 12;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 13;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 14;
   */
  schoolDeviceId?: number;

  constructor(data?: PartialMessage<HomeworkAssessmentReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.HomeworkAssessmentReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_report_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "gcp_report_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "teacher_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "processing_status", kind: "enum", T: proto3.getEnumType(PDFGenStatus) },
    { no: 7, name: "created_on", kind: "message", T: Timestamp },
    { no: 8, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkAssessmentReport {
    return new HomeworkAssessmentReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkAssessmentReport {
    return new HomeworkAssessmentReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkAssessmentReport {
    return new HomeworkAssessmentReport().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkAssessmentReport | PlainMessage<HomeworkAssessmentReport> | undefined, b: HomeworkAssessmentReport | PlainMessage<HomeworkAssessmentReport> | undefined): boolean {
    return proto3.util.equals(HomeworkAssessmentReport, a, b);
  }
}

/**
 * @generated from message geneo.lms.db.StudentAssessmentSession
 */
export class StudentAssessmentSession extends Message<StudentAssessmentSession> {
  /**
   * @generated from field: int32 student_assessment_session_id = 1;
   */
  studentAssessmentSessionId = 0;

  /**
   * @generated from field: int32 task_student_assignment_id = 12;
   */
  taskStudentAssignmentId = 0;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: int64 student_id = 3;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp start_time = 4;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 5;
   */
  endTime?: Timestamp;

  /**
   * Task status for the student (enum: task_student_status_enum)
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum session_status = 6;
   */
  sessionStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.lms.db.HomeworkSessionModeEnum session_mode = 7;
   */
  sessionMode = HomeworkSessionModeEnum.HOMEWORK_SESSION_MODE_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.db.StudentTaskResponse responses = 8;
   */
  responses: StudentTaskResponse[] = [];

  /**
   * Metrics associated with the lesson session. Nullable field.
   *
   * @generated from field: geneo.lms.db.MetricsModel metrics = 9;
   */
  metrics?: MetricsModel;

  /**
   * @generated from field: int32 session_number = 10;
   */
  sessionNumber = 0;

  /**
   * @generated from field: optional int32 previous_student_assessment_session_id = 11;
   */
  previousStudentAssessmentSessionId?: number;

  /**
   * @generated from field: optional int32 active_session_id = 13;
   */
  activeSessionId?: number;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload user_uploads = 14;
   */
  userUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional bool is_evaluation_completed = 15;
   */
  isEvaluationCompleted?: boolean;

  constructor(data?: PartialMessage<StudentAssessmentSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.db.StudentAssessmentSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_assessment_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "task_student_assignment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "start_time", kind: "message", T: Timestamp },
    { no: 5, name: "end_time", kind: "message", T: Timestamp },
    { no: 6, name: "session_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 7, name: "session_mode", kind: "enum", T: proto3.getEnumType(HomeworkSessionModeEnum) },
    { no: 8, name: "responses", kind: "message", T: StudentTaskResponse, repeated: true },
    { no: 9, name: "metrics", kind: "message", T: MetricsModel },
    { no: 10, name: "session_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "previous_student_assessment_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "user_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 15, name: "is_evaluation_completed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAssessmentSession {
    return new StudentAssessmentSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAssessmentSession {
    return new StudentAssessmentSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAssessmentSession {
    return new StudentAssessmentSession().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAssessmentSession | PlainMessage<StudentAssessmentSession> | undefined, b: StudentAssessmentSession | PlainMessage<StudentAssessmentSession> | undefined): boolean {
    return proto3.util.equals(StudentAssessmentSession, a, b);
  }
}

