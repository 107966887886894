// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/analysis_management/analysis.assessment.apis.proto (package geneo.analysis.school.assessment.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { DifficultyLevelEnum, Question, Resource_ResourceEnum, ResourceCategoryEnum } from "@protos/content_management/content.db_pb";

/**
 * @generated from enum geneo.analysis.school.assessment.apis.PDFGenStatus
 */
export enum PDFGenStatus {
  /**
   * @generated from enum value: PDF_GENERATION_STATUS_UNDEFINED = 0;
   */
  PDF_GENERATION_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_PENDING = 1;
   */
  PDF_GENERATION_STATUS_PENDING = 1,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_STARTED = 2;
   */
  PDF_GENERATION_STATUS_STARTED = 2,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_IN_PROGRESS = 3;
   */
  PDF_GENERATION_STATUS_IN_PROGRESS = 3,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_FAILED = 4;
   */
  PDF_GENERATION_STATUS_FAILED = 4,

  /**
   * @generated from enum value: PDF_GENERATION_STATUS_COMPLETED = 5;
   */
  PDF_GENERATION_STATUS_COMPLETED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(PDFGenStatus)
proto3.util.setEnumType(PDFGenStatus, "geneo.analysis.school.assessment.apis.PDFGenStatus", [
  { no: 0, name: "PDF_GENERATION_STATUS_UNDEFINED" },
  { no: 1, name: "PDF_GENERATION_STATUS_PENDING" },
  { no: 2, name: "PDF_GENERATION_STATUS_STARTED" },
  { no: 3, name: "PDF_GENERATION_STATUS_IN_PROGRESS" },
  { no: 4, name: "PDF_GENERATION_STATUS_FAILED" },
  { no: 5, name: "PDF_GENERATION_STATUS_COMPLETED" },
]);

/**
 * @generated from enum geneo.analysis.school.assessment.apis.KdtAssessmentStatus
 */
export enum KdtAssessmentStatus {
  /**
   * @generated from enum value: KDT_ASSESSMENT_STATUS_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: KDT_ASSESSMENT_STATUS_APPROVED = 1;
   */
  APPROVED = 1,

  /**
   * @generated from enum value: KDT_ASSESSMENT_STATUS_REJECTED = 2;
   */
  REJECTED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(KdtAssessmentStatus)
proto3.util.setEnumType(KdtAssessmentStatus, "geneo.analysis.school.assessment.apis.KdtAssessmentStatus", [
  { no: 0, name: "KDT_ASSESSMENT_STATUS_UNDEFINED" },
  { no: 1, name: "KDT_ASSESSMENT_STATUS_APPROVED" },
  { no: 2, name: "KDT_ASSESSMENT_STATUS_REJECTED" },
]);

/**
 * @generated from enum geneo.analysis.school.assessment.apis.BloomLevel
 */
export enum BloomLevel {
  /**
   * Retention level
   *
   * @generated from enum value: RETENTION = 0;
   */
  RETENTION = 0,

  /**
   * Comprehension level
   *
   * @generated from enum value: COMPREHENSION = 1;
   */
  COMPREHENSION = 1,

  /**
   * Application level
   *
   * @generated from enum value: APPLICATION = 2;
   */
  APPLICATION = 2,

  /**
   * Analysis level
   *
   * @generated from enum value: ANALYSIS = 3;
   */
  ANALYSIS = 3,

  /**
   * Evaluation level
   *
   * @generated from enum value: EVALUATION = 4;
   */
  EVALUATION = 4,

  /**
   * Creation level
   *
   * @generated from enum value: CREATION = 5;
   */
  CREATION = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(BloomLevel)
proto3.util.setEnumType(BloomLevel, "geneo.analysis.school.assessment.apis.BloomLevel", [
  { no: 0, name: "RETENTION" },
  { no: 1, name: "COMPREHENSION" },
  { no: 2, name: "APPLICATION" },
  { no: 3, name: "ANALYSIS" },
  { no: 4, name: "EVALUATION" },
  { no: 5, name: "CREATION" },
]);

/**
 * @generated from enum geneo.analysis.school.assessment.apis.AnswerStatusEnum
 */
export enum AnswerStatusEnum {
  /**
   * @generated from enum value: ANSWER_UNDEFINED = 0;
   */
  ANSWER_UNDEFINED = 0,

  /**
   * @generated from enum value: CORRECT = 1;
   */
  CORRECT = 1,

  /**
   * @generated from enum value: WRONG = 2;
   */
  WRONG = 2,

  /**
   * @generated from enum value: PARTIALLY_CORRECT = 3;
   */
  PARTIALLY_CORRECT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AnswerStatusEnum)
proto3.util.setEnumType(AnswerStatusEnum, "geneo.analysis.school.assessment.apis.AnswerStatusEnum", [
  { no: 0, name: "ANSWER_UNDEFINED" },
  { no: 1, name: "CORRECT" },
  { no: 2, name: "WRONG" },
  { no: 3, name: "PARTIALLY_CORRECT" },
]);

/**
 * @generated from message geneo.analysis.school.assessment.apis.fetchKdtAssessmentStudentsDetailsRequest
 */
export class fetchKdtAssessmentStudentsDetailsRequest extends Message<fetchKdtAssessmentStudentsDetailsRequest> {
  /**
   * ID for the teacher
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  constructor(data?: PartialMessage<fetchKdtAssessmentStudentsDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.fetchKdtAssessmentStudentsDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): fetchKdtAssessmentStudentsDetailsRequest {
    return new fetchKdtAssessmentStudentsDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): fetchKdtAssessmentStudentsDetailsRequest {
    return new fetchKdtAssessmentStudentsDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): fetchKdtAssessmentStudentsDetailsRequest {
    return new fetchKdtAssessmentStudentsDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: fetchKdtAssessmentStudentsDetailsRequest | PlainMessage<fetchKdtAssessmentStudentsDetailsRequest> | undefined, b: fetchKdtAssessmentStudentsDetailsRequest | PlainMessage<fetchKdtAssessmentStudentsDetailsRequest> | undefined): boolean {
    return proto3.util.equals(fetchKdtAssessmentStudentsDetailsRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.fetchKdtAssessmentStudentsDetailsResponse
 */
export class fetchKdtAssessmentStudentsDetailsResponse extends Message<fetchKdtAssessmentStudentsDetailsResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.KdtAssessmentStudentDetails data = 4;
   */
  data: KdtAssessmentStudentDetails[] = [];

  constructor(data?: PartialMessage<fetchKdtAssessmentStudentsDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.fetchKdtAssessmentStudentsDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: KdtAssessmentStudentDetails, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): fetchKdtAssessmentStudentsDetailsResponse {
    return new fetchKdtAssessmentStudentsDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): fetchKdtAssessmentStudentsDetailsResponse {
    return new fetchKdtAssessmentStudentsDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): fetchKdtAssessmentStudentsDetailsResponse {
    return new fetchKdtAssessmentStudentsDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: fetchKdtAssessmentStudentsDetailsResponse | PlainMessage<fetchKdtAssessmentStudentsDetailsResponse> | undefined, b: fetchKdtAssessmentStudentsDetailsResponse | PlainMessage<fetchKdtAssessmentStudentsDetailsResponse> | undefined): boolean {
    return proto3.util.equals(fetchKdtAssessmentStudentsDetailsResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.KdtAssessmentStudentDetails
 */
export class KdtAssessmentStudentDetails extends Message<KdtAssessmentStudentDetails> {
  /**
   * ID for the student
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * Name of the student
   *
   * @generated from field: string student_name = 2;
   */
  studentName = "";

  /**
   * @generated from field: string class = 3;
   */
  class = "";

  /**
   * @generated from field: optional string section = 4;
   */
  section?: string;

  /**
   * @generated from field: optional string subject = 5;
   */
  subject?: string;

  /**
   * KdtAssessmentStatus status = 6;
   *
   * @generated from field: google.protobuf.Timestamp completion_date = 7;
   */
  completionDate?: Timestamp;

  /**
   * @generated from field: optional string gcp_report_url = 8;
   */
  gcpReportUrl?: string;

  /**
   * @generated from field: optional int32 student_kdt_report_id = 9;
   */
  studentKdtReportId?: number;

  constructor(data?: PartialMessage<KdtAssessmentStudentDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.KdtAssessmentStudentDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "student_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "completion_date", kind: "message", T: Timestamp },
    { no: 8, name: "gcp_report_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "student_kdt_report_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KdtAssessmentStudentDetails {
    return new KdtAssessmentStudentDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KdtAssessmentStudentDetails {
    return new KdtAssessmentStudentDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KdtAssessmentStudentDetails {
    return new KdtAssessmentStudentDetails().fromJsonString(jsonString, options);
  }

  static equals(a: KdtAssessmentStudentDetails | PlainMessage<KdtAssessmentStudentDetails> | undefined, b: KdtAssessmentStudentDetails | PlainMessage<KdtAssessmentStudentDetails> | undefined): boolean {
    return proto3.util.equals(KdtAssessmentStudentDetails, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.KdtAssessmentSendMailRequest
 */
export class KdtAssessmentSendMailRequest extends Message<KdtAssessmentSendMailRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: optional int32 kdt_report_id = 3;
   */
  kdtReportId?: number;

  constructor(data?: PartialMessage<KdtAssessmentSendMailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.KdtAssessmentSendMailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "kdt_report_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KdtAssessmentSendMailRequest {
    return new KdtAssessmentSendMailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KdtAssessmentSendMailRequest {
    return new KdtAssessmentSendMailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KdtAssessmentSendMailRequest {
    return new KdtAssessmentSendMailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: KdtAssessmentSendMailRequest | PlainMessage<KdtAssessmentSendMailRequest> | undefined, b: KdtAssessmentSendMailRequest | PlainMessage<KdtAssessmentSendMailRequest> | undefined): boolean {
    return proto3.util.equals(KdtAssessmentSendMailRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.KdtAssessmentSendMailResponse
 */
export class KdtAssessmentSendMailResponse extends Message<KdtAssessmentSendMailResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<KdtAssessmentSendMailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.KdtAssessmentSendMailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KdtAssessmentSendMailResponse {
    return new KdtAssessmentSendMailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KdtAssessmentSendMailResponse {
    return new KdtAssessmentSendMailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KdtAssessmentSendMailResponse {
    return new KdtAssessmentSendMailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: KdtAssessmentSendMailResponse | PlainMessage<KdtAssessmentSendMailResponse> | undefined, b: KdtAssessmentSendMailResponse | PlainMessage<KdtAssessmentSendMailResponse> | undefined): boolean {
    return proto3.util.equals(KdtAssessmentSendMailResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.updateStudentHomeworkPdfRequest
 */
export class updateStudentHomeworkPdfRequest extends Message<updateStudentHomeworkPdfRequest> {
  /**
   * @generated from field: int32 homeworkId = 1;
   */
  homeworkId = 0;

  /**
   * @generated from field: int64 studentId = 2;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string gcpReportUrl = 3;
   */
  gcpReportUrl = "";

  /**
   * @generated from field: geneo.analysis.school.assessment.apis.PDFGenStatus status = 4;
   */
  status = PDFGenStatus.PDF_GENERATION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<updateStudentHomeworkPdfRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.updateStudentHomeworkPdfRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homeworkId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "studentId", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "gcpReportUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(PDFGenStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): updateStudentHomeworkPdfRequest {
    return new updateStudentHomeworkPdfRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): updateStudentHomeworkPdfRequest {
    return new updateStudentHomeworkPdfRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): updateStudentHomeworkPdfRequest {
    return new updateStudentHomeworkPdfRequest().fromJsonString(jsonString, options);
  }

  static equals(a: updateStudentHomeworkPdfRequest | PlainMessage<updateStudentHomeworkPdfRequest> | undefined, b: updateStudentHomeworkPdfRequest | PlainMessage<updateStudentHomeworkPdfRequest> | undefined): boolean {
    return proto3.util.equals(updateStudentHomeworkPdfRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.updateHwPdfRequest
 */
export class updateHwPdfRequest extends Message<updateHwPdfRequest> {
  /**
   * @generated from field: int32 homeworkId = 1;
   */
  homeworkId = 0;

  /**
   * @generated from field: string gcpReportUrl = 2;
   */
  gcpReportUrl = "";

  /**
   * @generated from field: geneo.analysis.school.assessment.apis.PDFGenStatus status = 3;
   */
  status = PDFGenStatus.PDF_GENERATION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<updateHwPdfRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.updateHwPdfRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homeworkId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "gcpReportUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(PDFGenStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): updateHwPdfRequest {
    return new updateHwPdfRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): updateHwPdfRequest {
    return new updateHwPdfRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): updateHwPdfRequest {
    return new updateHwPdfRequest().fromJsonString(jsonString, options);
  }

  static equals(a: updateHwPdfRequest | PlainMessage<updateHwPdfRequest> | undefined, b: updateHwPdfRequest | PlainMessage<updateHwPdfRequest> | undefined): boolean {
    return proto3.util.equals(updateHwPdfRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.updateHwPdfResponse
 */
export class updateHwPdfResponse extends Message<updateHwPdfResponse> {
  constructor(data?: PartialMessage<updateHwPdfResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.updateHwPdfResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): updateHwPdfResponse {
    return new updateHwPdfResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): updateHwPdfResponse {
    return new updateHwPdfResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): updateHwPdfResponse {
    return new updateHwPdfResponse().fromJsonString(jsonString, options);
  }

  static equals(a: updateHwPdfResponse | PlainMessage<updateHwPdfResponse> | undefined, b: updateHwPdfResponse | PlainMessage<updateHwPdfResponse> | undefined): boolean {
    return proto3.util.equals(updateHwPdfResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.updateStudentHwPdfRequest
 */
export class updateStudentHwPdfRequest extends Message<updateStudentHwPdfRequest> {
  /**
   * @generated from field: repeated int32 homeworkIds = 1;
   */
  homeworkIds: number[] = [];

  /**
   * @generated from field: string gcpReportUrl = 2;
   */
  gcpReportUrl = "";

  /**
   * @generated from field: geneo.analysis.school.assessment.apis.PDFGenStatus status = 3;
   */
  status = PDFGenStatus.PDF_GENERATION_STATUS_UNDEFINED;

  /**
   * ID for the studentId
   *
   * @generated from field: int64 studentId = 4;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<updateStudentHwPdfRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.updateStudentHwPdfRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homeworkIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 2, name: "gcpReportUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(PDFGenStatus) },
    { no: 4, name: "studentId", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): updateStudentHwPdfRequest {
    return new updateStudentHwPdfRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): updateStudentHwPdfRequest {
    return new updateStudentHwPdfRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): updateStudentHwPdfRequest {
    return new updateStudentHwPdfRequest().fromJsonString(jsonString, options);
  }

  static equals(a: updateStudentHwPdfRequest | PlainMessage<updateStudentHwPdfRequest> | undefined, b: updateStudentHwPdfRequest | PlainMessage<updateStudentHwPdfRequest> | undefined): boolean {
    return proto3.util.equals(updateStudentHwPdfRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.updateStudentHwPdfResponse
 */
export class updateStudentHwPdfResponse extends Message<updateStudentHwPdfResponse> {
  constructor(data?: PartialMessage<updateStudentHwPdfResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.updateStudentHwPdfResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): updateStudentHwPdfResponse {
    return new updateStudentHwPdfResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): updateStudentHwPdfResponse {
    return new updateStudentHwPdfResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): updateStudentHwPdfResponse {
    return new updateStudentHwPdfResponse().fromJsonString(jsonString, options);
  }

  static equals(a: updateStudentHwPdfResponse | PlainMessage<updateStudentHwPdfResponse> | undefined, b: updateStudentHwPdfResponse | PlainMessage<updateStudentHwPdfResponse> | undefined): boolean {
    return proto3.util.equals(updateStudentHwPdfResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.FetchAssessmentReportRequest
 */
export class FetchAssessmentReportRequest extends Message<FetchAssessmentReportRequest> {
  /**
   * ID for the homework
   *
   * @generated from field: int32 homework_id = 1;
   */
  homeworkId = 0;

  /**
   * ID for the teacher
   *
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchAssessmentReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.FetchAssessmentReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentReportRequest {
    return new FetchAssessmentReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentReportRequest {
    return new FetchAssessmentReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentReportRequest {
    return new FetchAssessmentReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentReportRequest | PlainMessage<FetchAssessmentReportRequest> | undefined, b: FetchAssessmentReportRequest | PlainMessage<FetchAssessmentReportRequest> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentReportRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.FetchCCAssessmentReportRequest
 */
export class FetchCCAssessmentReportRequest extends Message<FetchCCAssessmentReportRequest> {
  /**
   * ID for the homework
   *
   * @generated from field: int32 ccSession_id = 1;
   */
  ccSessionId = 0;

  /**
   * ID for the teacher
   *
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchCCAssessmentReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.FetchCCAssessmentReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ccSession_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchCCAssessmentReportRequest {
    return new FetchCCAssessmentReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchCCAssessmentReportRequest {
    return new FetchCCAssessmentReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchCCAssessmentReportRequest {
    return new FetchCCAssessmentReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchCCAssessmentReportRequest | PlainMessage<FetchCCAssessmentReportRequest> | undefined, b: FetchCCAssessmentReportRequest | PlainMessage<FetchCCAssessmentReportRequest> | undefined): boolean {
    return proto3.util.equals(FetchCCAssessmentReportRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.FetchAssessmentReportResponse
 */
export class FetchAssessmentReportResponse extends Message<FetchAssessmentReportResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * data (required)
   *
   * @generated from field: geneo.analysis.school.assessment.apis.AssessmentReport data = 4;
   */
  data?: AssessmentReport;

  constructor(data?: PartialMessage<FetchAssessmentReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.FetchAssessmentReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: AssessmentReport },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentReportResponse {
    return new FetchAssessmentReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentReportResponse {
    return new FetchAssessmentReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentReportResponse {
    return new FetchAssessmentReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentReportResponse | PlainMessage<FetchAssessmentReportResponse> | undefined, b: FetchAssessmentReportResponse | PlainMessage<FetchAssessmentReportResponse> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentReportResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.FetchCCAssessmentReportResponse
 */
export class FetchCCAssessmentReportResponse extends Message<FetchCCAssessmentReportResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * data (required)
   *
   * @generated from field: geneo.analysis.school.assessment.apis.CCAssessmentReport data = 4;
   */
  data?: CCAssessmentReport;

  constructor(data?: PartialMessage<FetchCCAssessmentReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.FetchCCAssessmentReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: CCAssessmentReport },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchCCAssessmentReportResponse {
    return new FetchCCAssessmentReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchCCAssessmentReportResponse {
    return new FetchCCAssessmentReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchCCAssessmentReportResponse {
    return new FetchCCAssessmentReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchCCAssessmentReportResponse | PlainMessage<FetchCCAssessmentReportResponse> | undefined, b: FetchCCAssessmentReportResponse | PlainMessage<FetchCCAssessmentReportResponse> | undefined): boolean {
    return proto3.util.equals(FetchCCAssessmentReportResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.CCAssessmentReport
 */
export class CCAssessmentReport extends Message<CCAssessmentReport> {
  /**
   * ID for the homework
   *
   * @generated from field: int32 ccSession_id = 1;
   */
  ccSessionId = 0;

  /**
   * School name
   *
   * @generated from field: string school = 2;
   */
  school = "";

  /**
   * Class name
   *
   * @generated from field: string class = 3;
   */
  class = "";

  /**
   * Section name
   *
   * @generated from field: string section = 4;
   */
  section = "";

  /**
   * Subject name
   *
   * @generated from field: string subject = 5;
   */
  subject = "";

  /**
   * Chapter name
   *
   * @generated from field: repeated string chapters = 6;
   */
  chapters: string[] = [];

  /**
   * Overview of the test
   *
   * @generated from field: optional geneo.analysis.school.assessment.apis.TestOverview test_overview = 7;
   */
  testOverview?: TestOverview;

  /**
   * Performance of questions
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.QuestionPerformance question_performances = 8;
   */
  questionPerformances: QuestionPerformance[] = [];

  /**
   * Performance of students
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.StudentPerformance student_performances = 9;
   */
  studentPerformances: StudentPerformance[] = [];

  /**
   * Achievement of learning outcomes
   *
   * @generated from field: optional geneo.analysis.school.assessment.apis.LearningOutcomeAchievement learning_outcome_achievement = 10;
   */
  learningOutcomeAchievement?: LearningOutcomeAchievement;

  /**
   * Common mistakes and remedies
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.CommonMistakeAndRemedy common_mistakes_remedy = 11;
   */
  commonMistakesRemedy: CommonMistakeAndRemedy[] = [];

  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.ChapterWiseOverview chapter_wise_overview = 12;
   */
  chapterWiseOverview: ChapterWiseOverview[] = [];

  /**
   * @generated from field: optional string gcp_report_url = 13;
   */
  gcpReportUrl?: string;

  /**
   * @generated from field: google.protobuf.Timestamp created_on = 14;
   */
  createdOn?: Timestamp;

  constructor(data?: PartialMessage<CCAssessmentReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.CCAssessmentReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ccSession_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "chapters", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "test_overview", kind: "message", T: TestOverview, opt: true },
    { no: 8, name: "question_performances", kind: "message", T: QuestionPerformance, repeated: true },
    { no: 9, name: "student_performances", kind: "message", T: StudentPerformance, repeated: true },
    { no: 10, name: "learning_outcome_achievement", kind: "message", T: LearningOutcomeAchievement, opt: true },
    { no: 11, name: "common_mistakes_remedy", kind: "message", T: CommonMistakeAndRemedy, repeated: true },
    { no: 12, name: "chapter_wise_overview", kind: "message", T: ChapterWiseOverview, repeated: true },
    { no: 13, name: "gcp_report_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "created_on", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CCAssessmentReport {
    return new CCAssessmentReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CCAssessmentReport {
    return new CCAssessmentReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CCAssessmentReport {
    return new CCAssessmentReport().fromJsonString(jsonString, options);
  }

  static equals(a: CCAssessmentReport | PlainMessage<CCAssessmentReport> | undefined, b: CCAssessmentReport | PlainMessage<CCAssessmentReport> | undefined): boolean {
    return proto3.util.equals(CCAssessmentReport, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.AssessmentReport
 */
export class AssessmentReport extends Message<AssessmentReport> {
  /**
   * ID for the homework
   *
   * @generated from field: int32 homework_id = 1;
   */
  homeworkId = 0;

  /**
   * School name
   *
   * @generated from field: string school = 2;
   */
  school = "";

  /**
   * Class name
   *
   * @generated from field: string class = 3;
   */
  class = "";

  /**
   * Section name
   *
   * @generated from field: string section = 4;
   */
  section = "";

  /**
   * Subject name
   *
   * @generated from field: string subject = 5;
   */
  subject = "";

  /**
   * Chapter name
   *
   * @generated from field: repeated string chapters = 6;
   */
  chapters: string[] = [];

  /**
   * Overview of the test
   *
   * @generated from field: optional geneo.analysis.school.assessment.apis.TestOverview test_overview = 7;
   */
  testOverview?: TestOverview;

  /**
   * Performance of questions
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.QuestionPerformance question_performances = 8;
   */
  questionPerformances: QuestionPerformance[] = [];

  /**
   * Performance of students
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.StudentPerformance student_performances = 9;
   */
  studentPerformances: StudentPerformance[] = [];

  /**
   * Achievement of learning outcomes
   *
   * @generated from field: optional geneo.analysis.school.assessment.apis.LearningOutcomeAchievement learning_outcome_achievement = 10;
   */
  learningOutcomeAchievement?: LearningOutcomeAchievement;

  /**
   * Common mistakes and remedies
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.CommonMistakeAndRemedy common_mistakes_remedy = 11;
   */
  commonMistakesRemedy: CommonMistakeAndRemedy[] = [];

  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.ChapterWiseOverview chapter_wise_overview = 12;
   */
  chapterWiseOverview: ChapterWiseOverview[] = [];

  /**
   * @generated from field: optional string gcp_report_url = 13;
   */
  gcpReportUrl?: string;

  /**
   * @generated from field: google.protobuf.Timestamp created_on = 14;
   */
  createdOn?: Timestamp;

  constructor(data?: PartialMessage<AssessmentReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.AssessmentReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "chapters", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "test_overview", kind: "message", T: TestOverview, opt: true },
    { no: 8, name: "question_performances", kind: "message", T: QuestionPerformance, repeated: true },
    { no: 9, name: "student_performances", kind: "message", T: StudentPerformance, repeated: true },
    { no: 10, name: "learning_outcome_achievement", kind: "message", T: LearningOutcomeAchievement, opt: true },
    { no: 11, name: "common_mistakes_remedy", kind: "message", T: CommonMistakeAndRemedy, repeated: true },
    { no: 12, name: "chapter_wise_overview", kind: "message", T: ChapterWiseOverview, repeated: true },
    { no: 13, name: "gcp_report_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "created_on", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentReport {
    return new AssessmentReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentReport {
    return new AssessmentReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentReport {
    return new AssessmentReport().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentReport | PlainMessage<AssessmentReport> | undefined, b: AssessmentReport | PlainMessage<AssessmentReport> | undefined): boolean {
    return proto3.util.equals(AssessmentReport, a, b);
  }
}

/**
 * Overview of the test performance
 *
 * @generated from message geneo.analysis.school.assessment.apis.TestOverview
 */
export class TestOverview extends Message<TestOverview> {
  /**
   * Overall class score percentage
   *
   * @generated from field: float class_score = 1;
   */
  classScore = 0;

  /**
   * Total number of questions
   *
   * @generated from field: int32 number_of_questions = 2;
   */
  numberOfQuestions = 0;

  /**
   * Difficulty level of the test
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum overall_difficulty_level = 3;
   */
  overallDifficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * Total possible marks
   *
   * @generated from field: int32 total_marks = 4;
   */
  totalMarks = 0;

  /**
   * Number of students assessed
   *
   * @generated from field: int32 students_assessed = 5;
   */
  studentsAssessed = 0;

  /**
   * Summary of performance
   *
   * @generated from field: string assessment_verdict = 6;
   */
  assessmentVerdict = "";

  /**
   * List of learning outcomes
   *
   * @generated from field: repeated string learning_outcomes = 7;
   */
  learningOutcomes: string[] = [];

  /**
   * Question difficulty distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.QuestionDifficultyDistribution question_difficulty_distribution = 8;
   */
  questionDifficultyDistribution?: QuestionDifficultyDistribution;

  /**
   * Question Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.QuestionBloomLevelDistribution question_bloom_level_distribution = 9;
   */
  questionBloomLevelDistribution?: QuestionBloomLevelDistribution;

  /**
   * Question Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.QuestionBloomLevelDistributionV2 question_bloom_level_distribution_v2 = 10;
   */
  questionBloomLevelDistributionV2?: QuestionBloomLevelDistributionV2;

  /**
   * @generated from field: string difficulty = 11;
   */
  difficulty = "";

  constructor(data?: PartialMessage<TestOverview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.TestOverview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "number_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "overall_difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 4, name: "total_marks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "students_assessed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "assessment_verdict", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "learning_outcomes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "question_difficulty_distribution", kind: "message", T: QuestionDifficultyDistribution },
    { no: 9, name: "question_bloom_level_distribution", kind: "message", T: QuestionBloomLevelDistribution },
    { no: 10, name: "question_bloom_level_distribution_v2", kind: "message", T: QuestionBloomLevelDistributionV2 },
    { no: 11, name: "difficulty", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TestOverview {
    return new TestOverview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TestOverview {
    return new TestOverview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TestOverview {
    return new TestOverview().fromJsonString(jsonString, options);
  }

  static equals(a: TestOverview | PlainMessage<TestOverview> | undefined, b: TestOverview | PlainMessage<TestOverview> | undefined): boolean {
    return proto3.util.equals(TestOverview, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.QuestionPerformance
 */
export class QuestionPerformance extends Message<QuestionPerformance> {
  /**
   * Question number (e.g., Q1, Q2)
   *
   * @generated from field: int32 question_number = 1;
   */
  questionNumber = 0;

  /**
   * Marks for the question
   *
   * @generated from field: int32 marks = 2;
   */
  marks = 0;

  /**
   * Number of correct answers
   *
   * @generated from field: int32 correct_answers = 3;
   */
  correctAnswers = 0;

  /**
   * @generated from field: int32 partially_correct_answers = 4;
   */
  partiallyCorrectAnswers = 0;

  /**
   * Average score percentage
   *
   * @generated from field: float average_score = 5;
   */
  averageScore = 0;

  /**
   * Difficulty level of the question
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 6;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * @generated from field: string difficulty = 7;
   */
  difficulty = "";

  constructor(data?: PartialMessage<QuestionPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.QuestionPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "marks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "correct_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "partially_correct_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "average_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 7, name: "difficulty", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionPerformance {
    return new QuestionPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionPerformance {
    return new QuestionPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionPerformance {
    return new QuestionPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionPerformance | PlainMessage<QuestionPerformance> | undefined, b: QuestionPerformance | PlainMessage<QuestionPerformance> | undefined): boolean {
    return proto3.util.equals(QuestionPerformance, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.QuestionDifficultyDistribution
 */
export class QuestionDifficultyDistribution extends Message<QuestionDifficultyDistribution> {
  /**
   * @generated from field: int32 low_difficulty_percentage = 1;
   */
  lowDifficultyPercentage = 0;

  /**
   * @generated from field: int32 medium_difficulty_percentage = 2;
   */
  mediumDifficultyPercentage = 0;

  /**
   * @generated from field: int32 high_difficulty_percentage = 3;
   */
  highDifficultyPercentage = 0;

  constructor(data?: PartialMessage<QuestionDifficultyDistribution>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.QuestionDifficultyDistribution";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "low_difficulty_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "medium_difficulty_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "high_difficulty_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionDifficultyDistribution {
    return new QuestionDifficultyDistribution().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionDifficultyDistribution {
    return new QuestionDifficultyDistribution().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionDifficultyDistribution {
    return new QuestionDifficultyDistribution().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionDifficultyDistribution | PlainMessage<QuestionDifficultyDistribution> | undefined, b: QuestionDifficultyDistribution | PlainMessage<QuestionDifficultyDistribution> | undefined): boolean {
    return proto3.util.equals(QuestionDifficultyDistribution, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.QuestionBloomLevelDistribution
 */
export class QuestionBloomLevelDistribution extends Message<QuestionBloomLevelDistribution> {
  /**
   * percentage of questions at the Remember level
   *
   * @generated from field: int32 remember_percentage = 1;
   */
  rememberPercentage = 0;

  /**
   * percentage of questions at the Understand level
   *
   * @generated from field: int32 understand_percentage = 2;
   */
  understandPercentage = 0;

  /**
   * Coupercentagent of questions at the Apply level
   *
   * @generated from field: int32 apply_percentage = 3;
   */
  applyPercentage = 0;

  /**
   * percentage of questions at the Analyze level
   *
   * @generated from field: int32 analyze_percentage = 4;
   */
  analyzePercentage = 0;

  /**
   * percentage of questions assessing Retention
   *
   * @generated from field: int32 retention_percentage = 5;
   */
  retentionPercentage = 0;

  /**
   * percentage of questions assessing Comprehension
   *
   * @generated from field: int32 comprehension_percentage = 6;
   */
  comprehensionPercentage = 0;

  constructor(data?: PartialMessage<QuestionBloomLevelDistribution>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.QuestionBloomLevelDistribution";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "remember_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "understand_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "apply_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "analyze_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "retention_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "comprehension_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionBloomLevelDistribution {
    return new QuestionBloomLevelDistribution().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionBloomLevelDistribution {
    return new QuestionBloomLevelDistribution().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionBloomLevelDistribution {
    return new QuestionBloomLevelDistribution().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionBloomLevelDistribution | PlainMessage<QuestionBloomLevelDistribution> | undefined, b: QuestionBloomLevelDistribution | PlainMessage<QuestionBloomLevelDistribution> | undefined): boolean {
    return proto3.util.equals(QuestionBloomLevelDistribution, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.QuestionBloomLevelDistributionV2
 */
export class QuestionBloomLevelDistributionV2 extends Message<QuestionBloomLevelDistributionV2> {
  /**
   * percentage of questions at the Remember level
   *
   * @generated from field: int32 creation_percentage = 1;
   */
  creationPercentage = 0;

  /**
   * percentage of questions at the Understand level
   *
   * @generated from field: int32 evaluation_percentage = 2;
   */
  evaluationPercentage = 0;

  /**
   * Coupercentagent of questions at the Apply level
   *
   * @generated from field: int32 application_percentage = 3;
   */
  applicationPercentage = 0;

  /**
   * percentage of questions at the Analyze level
   *
   * @generated from field: int32 analysis_percentage = 4;
   */
  analysisPercentage = 0;

  /**
   * percentage of questions assessing Retention
   *
   * @generated from field: int32 retention_percentage = 5;
   */
  retentionPercentage = 0;

  /**
   * percentage of questions assessing Comprehension
   *
   * @generated from field: int32 comprehension_percentage = 6;
   */
  comprehensionPercentage = 0;

  constructor(data?: PartialMessage<QuestionBloomLevelDistributionV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.QuestionBloomLevelDistributionV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creation_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "evaluation_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "application_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "analysis_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "retention_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "comprehension_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionBloomLevelDistributionV2 {
    return new QuestionBloomLevelDistributionV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionBloomLevelDistributionV2 {
    return new QuestionBloomLevelDistributionV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionBloomLevelDistributionV2 {
    return new QuestionBloomLevelDistributionV2().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionBloomLevelDistributionV2 | PlainMessage<QuestionBloomLevelDistributionV2> | undefined, b: QuestionBloomLevelDistributionV2 | PlainMessage<QuestionBloomLevelDistributionV2> | undefined): boolean {
    return proto3.util.equals(QuestionBloomLevelDistributionV2, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.BloomLevelBreakDown
 */
export class BloomLevelBreakDown extends Message<BloomLevelBreakDown> {
  /**
   * Score for Remember level questions
   *
   * @generated from field: optional float remember_score = 1;
   */
  rememberScore?: number;

  /**
   * Score for Understand level questions
   *
   * @generated from field: optional float understand_score = 2;
   */
  understandScore?: number;

  /**
   * Score for Apply level questions
   *
   * @generated from field: optional float apply_score = 3;
   */
  applyScore?: number;

  /**
   * Score for Analyze level questions
   *
   * @generated from field: optional float analyze_score = 4;
   */
  analyzeScore?: number;

  /**
   * Percentage for Retention level performance
   *
   * @generated from field: optional float retention_score = 5;
   */
  retentionScore?: number;

  /**
   * Percentage for Comprehension level performance
   *
   * @generated from field: optional float comprehension_score = 6;
   */
  comprehensionScore?: number;

  /**
   * Overall statement regarding Bloom's taxonomy performance
   *
   * @generated from field: string overall_statement = 7;
   */
  overallStatement = "";

  constructor(data?: PartialMessage<BloomLevelBreakDown>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.BloomLevelBreakDown";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "remember_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 2, name: "understand_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 3, name: "apply_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 4, name: "analyze_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "retention_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 6, name: "comprehension_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 7, name: "overall_statement", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BloomLevelBreakDown {
    return new BloomLevelBreakDown().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BloomLevelBreakDown {
    return new BloomLevelBreakDown().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BloomLevelBreakDown {
    return new BloomLevelBreakDown().fromJsonString(jsonString, options);
  }

  static equals(a: BloomLevelBreakDown | PlainMessage<BloomLevelBreakDown> | undefined, b: BloomLevelBreakDown | PlainMessage<BloomLevelBreakDown> | undefined): boolean {
    return proto3.util.equals(BloomLevelBreakDown, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.BloomLevelBreakDownV2
 */
export class BloomLevelBreakDownV2 extends Message<BloomLevelBreakDownV2> {
  /**
   * Score for Remember level questions
   *
   * @generated from field: optional float creation_score = 1;
   */
  creationScore?: number;

  /**
   * Score for Understand level questions
   *
   * @generated from field: optional float evaluation_score = 2;
   */
  evaluationScore?: number;

  /**
   * Score for Apply level questions
   *
   * @generated from field: optional float application_score = 3;
   */
  applicationScore?: number;

  /**
   * Score for Analyze level questions
   *
   * @generated from field: optional float analysis_score = 4;
   */
  analysisScore?: number;

  /**
   * Percentage for Retention level performance
   *
   * @generated from field: optional float retention_score = 5;
   */
  retentionScore?: number;

  /**
   * Percentage for Comprehension level performance
   *
   * @generated from field: optional float comprehension_score = 6;
   */
  comprehensionScore?: number;

  /**
   * Overall statement regarding Bloom's taxonomy performance
   *
   * @generated from field: string overall_statement = 7;
   */
  overallStatement = "";

  constructor(data?: PartialMessage<BloomLevelBreakDownV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.BloomLevelBreakDownV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creation_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 2, name: "evaluation_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 3, name: "application_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 4, name: "analysis_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "retention_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 6, name: "comprehension_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 7, name: "overall_statement", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BloomLevelBreakDownV2 {
    return new BloomLevelBreakDownV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BloomLevelBreakDownV2 {
    return new BloomLevelBreakDownV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BloomLevelBreakDownV2 {
    return new BloomLevelBreakDownV2().fromJsonString(jsonString, options);
  }

  static equals(a: BloomLevelBreakDownV2 | PlainMessage<BloomLevelBreakDownV2> | undefined, b: BloomLevelBreakDownV2 | PlainMessage<BloomLevelBreakDownV2> | undefined): boolean {
    return proto3.util.equals(BloomLevelBreakDownV2, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.BloomLevelClassBreakDown
 */
export class BloomLevelClassBreakDown extends Message<BloomLevelClassBreakDown> {
  /**
   * Score for Remember level questions
   *
   * @generated from field: optional float class_creation_score = 1;
   */
  classCreationScore?: number;

  /**
   * Score for Understand level questions
   *
   * @generated from field: optional float class_evaluation_score = 2;
   */
  classEvaluationScore?: number;

  /**
   * Score for Apply level questions
   *
   * @generated from field: optional float class_application_score = 3;
   */
  classApplicationScore?: number;

  /**
   * Score for Analyze level questions
   *
   * @generated from field: optional float class_analysis_score = 4;
   */
  classAnalysisScore?: number;

  /**
   * Percentage for Retention level performance
   *
   * @generated from field: optional float class_retention_score = 5;
   */
  classRetentionScore?: number;

  /**
   * Percentage for Comprehension level performance
   *
   * @generated from field: optional float class_comprehension_score = 6;
   */
  classComprehensionScore?: number;

  constructor(data?: PartialMessage<BloomLevelClassBreakDown>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.BloomLevelClassBreakDown";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_creation_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 2, name: "class_evaluation_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 3, name: "class_application_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 4, name: "class_analysis_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "class_retention_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 6, name: "class_comprehension_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BloomLevelClassBreakDown {
    return new BloomLevelClassBreakDown().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BloomLevelClassBreakDown {
    return new BloomLevelClassBreakDown().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BloomLevelClassBreakDown {
    return new BloomLevelClassBreakDown().fromJsonString(jsonString, options);
  }

  static equals(a: BloomLevelClassBreakDown | PlainMessage<BloomLevelClassBreakDown> | undefined, b: BloomLevelClassBreakDown | PlainMessage<BloomLevelClassBreakDown> | undefined): boolean {
    return proto3.util.equals(BloomLevelClassBreakDown, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.StudentPerformance
 */
export class StudentPerformance extends Message<StudentPerformance> {
  /**
   * @generated from field: string student_name = 1;
   */
  studentName = "";

  /**
   * Student's overall score percentage
   *
   * @generated from field: float overall_score = 2;
   */
  overallScore = 0;

  /**
   * List of question statuses for the student
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.QuestionStatus question_statuses = 3;
   */
  questionStatuses: QuestionStatus[] = [];

  constructor(data?: PartialMessage<StudentPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.StudentPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "overall_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "question_statuses", kind: "message", T: QuestionStatus, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentPerformance {
    return new StudentPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentPerformance {
    return new StudentPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentPerformance {
    return new StudentPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: StudentPerformance | PlainMessage<StudentPerformance> | undefined, b: StudentPerformance | PlainMessage<StudentPerformance> | undefined): boolean {
    return proto3.util.equals(StudentPerformance, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.QuestionStatus
 */
export class QuestionStatus extends Message<QuestionStatus> {
  /**
   * @generated from field: int32 question_number = 1;
   */
  questionNumber = 0;

  /**
   * Correct, wrong, or partially correct
   *
   * @generated from field: geneo.analysis.school.assessment.apis.AnswerStatusEnum answer_status = 2;
   */
  answerStatus = AnswerStatusEnum.ANSWER_UNDEFINED;

  constructor(data?: PartialMessage<QuestionStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.QuestionStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "answer_status", kind: "enum", T: proto3.getEnumType(AnswerStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionStatus {
    return new QuestionStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionStatus {
    return new QuestionStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionStatus {
    return new QuestionStatus().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionStatus | PlainMessage<QuestionStatus> | undefined, b: QuestionStatus | PlainMessage<QuestionStatus> | undefined): boolean {
    return proto3.util.equals(QuestionStatus, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.LearningOutcomeAchievement
 */
export class LearningOutcomeAchievement extends Message<LearningOutcomeAchievement> {
  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.LearningOutcomeAchievement.LoDetails lo_details = 1;
   */
  loDetails: LearningOutcomeAchievement_LoDetails[] = [];

  /**
   * LOS
   *
   * @generated from field: repeated string strengths = 2;
   */
  strengths: string[] = [];

  /**
   * Los
   *
   * @generated from field: repeated string weaknesses = 3;
   */
  weaknesses: string[] = [];

  /**
   * Score Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.BloomLevelBreakDown bloom_level_breakdown = 4;
   */
  bloomLevelBreakdown?: BloomLevelBreakDown;

  /**
   * Score Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.BloomLevelBreakDownV2 bloom_level_breakdown_v2 = 5;
   */
  bloomLevelBreakdownV2?: BloomLevelBreakDownV2;

  constructor(data?: PartialMessage<LearningOutcomeAchievement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.LearningOutcomeAchievement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lo_details", kind: "message", T: LearningOutcomeAchievement_LoDetails, repeated: true },
    { no: 2, name: "strengths", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "weaknesses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "bloom_level_breakdown", kind: "message", T: BloomLevelBreakDown },
    { no: 5, name: "bloom_level_breakdown_v2", kind: "message", T: BloomLevelBreakDownV2 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LearningOutcomeAchievement {
    return new LearningOutcomeAchievement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LearningOutcomeAchievement {
    return new LearningOutcomeAchievement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LearningOutcomeAchievement {
    return new LearningOutcomeAchievement().fromJsonString(jsonString, options);
  }

  static equals(a: LearningOutcomeAchievement | PlainMessage<LearningOutcomeAchievement> | undefined, b: LearningOutcomeAchievement | PlainMessage<LearningOutcomeAchievement> | undefined): boolean {
    return proto3.util.equals(LearningOutcomeAchievement, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.LearningOutcomeAchievement.LoDetails
 */
export class LearningOutcomeAchievement_LoDetails extends Message<LearningOutcomeAchievement_LoDetails> {
  /**
   * Description of the learning outcome
   *
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * Score achieved in percentage
   *
   * @generated from field: float score_percentage = 2;
   */
  scorePercentage = 0;

  /**
   * Suggested action (e.g., "Revise", "Reteach")
   *
   * @generated from field: string action_required = 3;
   */
  actionRequired = "";

  constructor(data?: PartialMessage<LearningOutcomeAchievement_LoDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.LearningOutcomeAchievement.LoDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "score_percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "action_required", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LearningOutcomeAchievement_LoDetails {
    return new LearningOutcomeAchievement_LoDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LearningOutcomeAchievement_LoDetails {
    return new LearningOutcomeAchievement_LoDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LearningOutcomeAchievement_LoDetails {
    return new LearningOutcomeAchievement_LoDetails().fromJsonString(jsonString, options);
  }

  static equals(a: LearningOutcomeAchievement_LoDetails | PlainMessage<LearningOutcomeAchievement_LoDetails> | undefined, b: LearningOutcomeAchievement_LoDetails | PlainMessage<LearningOutcomeAchievement_LoDetails> | undefined): boolean {
    return proto3.util.equals(LearningOutcomeAchievement_LoDetails, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.LearningOutcome
 */
export class LearningOutcome extends Message<LearningOutcome> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * Score achieved in percentage
   *
   * @generated from field: float score_percentage = 2;
   */
  scorePercentage = 0;

  /**
   * Suggested action (e.g., "Revise", "Reteach")
   *
   * @generated from field: string action_required = 3;
   */
  actionRequired = "";

  constructor(data?: PartialMessage<LearningOutcome>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.LearningOutcome";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "score_percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "action_required", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LearningOutcome {
    return new LearningOutcome().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LearningOutcome {
    return new LearningOutcome().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LearningOutcome {
    return new LearningOutcome().fromJsonString(jsonString, options);
  }

  static equals(a: LearningOutcome | PlainMessage<LearningOutcome> | undefined, b: LearningOutcome | PlainMessage<LearningOutcome> | undefined): boolean {
    return proto3.util.equals(LearningOutcome, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.ChapterWiseOverview
 */
export class ChapterWiseOverview extends Message<ChapterWiseOverview> {
  /**
   * @generated from field: string chapter_name = 1;
   */
  chapterName = "";

  /**
   * Score achieved in percentage
   *
   * @generated from field: float overall_class_score = 2;
   */
  overallClassScore = 0;

  /**
   * @generated from field: int32 no_of_questions = 3;
   */
  noOfQuestions = 0;

  constructor(data?: PartialMessage<ChapterWiseOverview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.ChapterWiseOverview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapter_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "overall_class_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "no_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterWiseOverview {
    return new ChapterWiseOverview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterWiseOverview {
    return new ChapterWiseOverview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterWiseOverview {
    return new ChapterWiseOverview().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterWiseOverview | PlainMessage<ChapterWiseOverview> | undefined, b: ChapterWiseOverview | PlainMessage<ChapterWiseOverview> | undefined): boolean {
    return proto3.util.equals(ChapterWiseOverview, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.CommonMistakeAndRemedy
 */
export class CommonMistakeAndRemedy extends Message<CommonMistakeAndRemedy> {
  /**
   * @generated from field: string question_heading = 1;
   */
  questionHeading = "";

  /**
   * @generated from field: geneo.content.db.Question question_details = 2;
   */
  questionDetails?: Question;

  /**
   * Question number where mistake occurred
   *
   * @generated from field: optional int32 question_number = 3;
   */
  questionNumber?: number;

  /**
   * Difficulty level of the question
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 4;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * Average time taken to answer the question in seconds
   *
   * @generated from field: int32 avg_time_to_answer = 5;
   */
  avgTimeToAnswer = 0;

  /**
   * Most common wrong answer given by students
   *
   * @generated from field: string most_common_wrong_answer = 6;
   */
  mostCommonWrongAnswer = "";

  /**
   * Class score percentage for that question
   *
   * @generated from field: float class_score_percentage = 7;
   */
  classScorePercentage = 0;

  /**
   * Suggested remedy content for improvement
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.RemedyContent remedy_content = 8;
   */
  remedyContent: RemedyContent[] = [];

  /**
   * Explanation of the correct answer or concept
   *
   * @generated from field: string explanation = 9;
   */
  explanation = "";

  constructor(data?: PartialMessage<CommonMistakeAndRemedy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.CommonMistakeAndRemedy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_heading", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_details", kind: "message", T: Question },
    { no: 3, name: "question_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 5, name: "avg_time_to_answer", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "most_common_wrong_answer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "class_score_percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "remedy_content", kind: "message", T: RemedyContent, repeated: true },
    { no: 9, name: "explanation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommonMistakeAndRemedy {
    return new CommonMistakeAndRemedy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommonMistakeAndRemedy {
    return new CommonMistakeAndRemedy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommonMistakeAndRemedy {
    return new CommonMistakeAndRemedy().fromJsonString(jsonString, options);
  }

  static equals(a: CommonMistakeAndRemedy | PlainMessage<CommonMistakeAndRemedy> | undefined, b: CommonMistakeAndRemedy | PlainMessage<CommonMistakeAndRemedy> | undefined): boolean {
    return proto3.util.equals(CommonMistakeAndRemedy, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.CommonMistakeAndRemedyKdt
 */
export class CommonMistakeAndRemedyKdt extends Message<CommonMistakeAndRemedyKdt> {
  /**
   * @generated from field: geneo.content.db.Question question_details = 1;
   */
  questionDetails?: Question;

  /**
   * Question number where mistake occurred
   *
   * @generated from field: optional int32 question_number = 2;
   */
  questionNumber?: number;

  /**
   *  time taken to answer the question in seconds
   *
   * @generated from field: string time_to_answer = 3;
   */
  timeToAnswer = "";

  /**
   *   wrong answer given by student
   *
   * @generated from field: string your_answer = 4;
   */
  yourAnswer = "";

  /**
   * Class score percentage for that question
   *
   * @generated from field: float class_score_percentage = 5;
   */
  classScorePercentage = 0;

  /**
   * Suggested remedy content for improvement
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.RemedyContent remedy_content = 6;
   */
  remedyContent: RemedyContent[] = [];

  /**
   * Explanation of the correct answer or concept
   *
   * @generated from field: string explanation = 7;
   */
  explanation = "";

  constructor(data?: PartialMessage<CommonMistakeAndRemedyKdt>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.CommonMistakeAndRemedyKdt";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_details", kind: "message", T: Question },
    { no: 2, name: "question_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "time_to_answer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "your_answer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "class_score_percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "remedy_content", kind: "message", T: RemedyContent, repeated: true },
    { no: 7, name: "explanation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommonMistakeAndRemedyKdt {
    return new CommonMistakeAndRemedyKdt().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommonMistakeAndRemedyKdt {
    return new CommonMistakeAndRemedyKdt().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommonMistakeAndRemedyKdt {
    return new CommonMistakeAndRemedyKdt().fromJsonString(jsonString, options);
  }

  static equals(a: CommonMistakeAndRemedyKdt | PlainMessage<CommonMistakeAndRemedyKdt> | undefined, b: CommonMistakeAndRemedyKdt | PlainMessage<CommonMistakeAndRemedyKdt> | undefined): boolean {
    return proto3.util.equals(CommonMistakeAndRemedyKdt, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.ResourceContent
 */
export class ResourceContent extends Message<ResourceContent> {
  /**
   * Identifier for the resource
   *
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * Title of the resource.
   *
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * URL of the resource's poster image.
   *
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * Estimated time to complete the resource in minutes.
   *
   * @generated from field: int32 estimated_time_in_min = 4;
   */
  estimatedTimeInMin = 0;

  /**
   * Type of the resource (e.g., video, document).
   *
   * @generated from field: geneo.content.db.Resource.ResourceEnum resource_type = 5;
   */
  resourceType = Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED;

  /**
   * Category type of the resource (e.g., science, history).
   *
   * @generated from field: geneo.content.db.ResourceCategoryEnum resource_category = 6;
   */
  resourceCategory = ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: string category = 7;
   */
  category = "";

  /**
   * @generated from field: string type = 8;
   */
  type = "";

  constructor(data?: PartialMessage<ResourceContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.ResourceContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "estimated_time_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "resource_type", kind: "enum", T: proto3.getEnumType(Resource_ResourceEnum) },
    { no: 6, name: "resource_category", kind: "enum", T: proto3.getEnumType(ResourceCategoryEnum) },
    { no: 7, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContent {
    return new ResourceContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContent {
    return new ResourceContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContent {
    return new ResourceContent().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContent | PlainMessage<ResourceContent> | undefined, b: ResourceContent | PlainMessage<ResourceContent> | undefined): boolean {
    return proto3.util.equals(ResourceContent, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.RemedyContent
 */
export class RemedyContent extends Message<RemedyContent> {
  /**
   * Chapter related to the remedy content
   *
   * @generated from field: string chapter = 1;
   */
  chapter = "";

  /**
   * Topic related to the remedy content
   *
   * @generated from field: string topic = 2;
   */
  topic = "";

  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.ResourceContent resources = 3;
   */
  resources: ResourceContent[] = [];

  constructor(data?: PartialMessage<RemedyContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.RemedyContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapter", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "topic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "resources", kind: "message", T: ResourceContent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemedyContent {
    return new RemedyContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemedyContent {
    return new RemedyContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemedyContent {
    return new RemedyContent().fromJsonString(jsonString, options);
  }

  static equals(a: RemedyContent | PlainMessage<RemedyContent> | undefined, b: RemedyContent | PlainMessage<RemedyContent> | undefined): boolean {
    return proto3.util.equals(RemedyContent, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.FetchStudnetKDAssessmentReportRequest
 */
export class FetchStudnetKDAssessmentReportRequest extends Message<FetchStudnetKDAssessmentReportRequest> {
  /**
   * IDs for the homework
   *
   * @generated from field: repeated int32 homework_ids = 1;
   */
  homeworkIds: number[] = [];

  /**
   * ID for the student
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchStudnetKDAssessmentReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.FetchStudnetKDAssessmentReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchStudnetKDAssessmentReportRequest {
    return new FetchStudnetKDAssessmentReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchStudnetKDAssessmentReportRequest {
    return new FetchStudnetKDAssessmentReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchStudnetKDAssessmentReportRequest {
    return new FetchStudnetKDAssessmentReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchStudnetKDAssessmentReportRequest | PlainMessage<FetchStudnetKDAssessmentReportRequest> | undefined, b: FetchStudnetKDAssessmentReportRequest | PlainMessage<FetchStudnetKDAssessmentReportRequest> | undefined): boolean {
    return proto3.util.equals(FetchStudnetKDAssessmentReportRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.FetchStudentHwReportRequest
 */
export class FetchStudentHwReportRequest extends Message<FetchStudentHwReportRequest> {
  /**
   * ID for the homework
   *
   * @generated from field: int32 homework_id = 1;
   */
  homeworkId = 0;

  /**
   * ID for the student
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchStudentHwReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.FetchStudentHwReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchStudentHwReportRequest {
    return new FetchStudentHwReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchStudentHwReportRequest {
    return new FetchStudentHwReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchStudentHwReportRequest {
    return new FetchStudentHwReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchStudentHwReportRequest | PlainMessage<FetchStudentHwReportRequest> | undefined, b: FetchStudentHwReportRequest | PlainMessage<FetchStudentHwReportRequest> | undefined): boolean {
    return proto3.util.equals(FetchStudentHwReportRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.FetchStudentHwReportResponse
 */
export class FetchStudentHwReportResponse extends Message<FetchStudentHwReportResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * data (required)
   *
   * @generated from field: geneo.analysis.school.assessment.apis.StudentHwReport data = 4;
   */
  data?: StudentHwReport;

  constructor(data?: PartialMessage<FetchStudentHwReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.FetchStudentHwReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: StudentHwReport },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchStudentHwReportResponse {
    return new FetchStudentHwReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchStudentHwReportResponse {
    return new FetchStudentHwReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchStudentHwReportResponse {
    return new FetchStudentHwReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchStudentHwReportResponse | PlainMessage<FetchStudentHwReportResponse> | undefined, b: FetchStudentHwReportResponse | PlainMessage<FetchStudentHwReportResponse> | undefined): boolean {
    return proto3.util.equals(FetchStudentHwReportResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.StudentHwReport
 */
export class StudentHwReport extends Message<StudentHwReport> {
  /**
   * @generated from field: string student_name = 1;
   */
  studentName = "";

  /**
   * School name
   *
   * @generated from field: string school = 2;
   */
  school = "";

  /**
   * Class name
   *
   * @generated from field: string class = 3;
   */
  class = "";

  /**
   * Section name
   *
   * @generated from field: string section = 4;
   */
  section = "";

  /**
   * @generated from field: string assessment_type = 5;
   */
  assessmentType = "";

  /**
   * @generated from field: geneo.analysis.school.assessment.apis.HomeworkOverview test_overview = 6;
   */
  testOverview?: HomeworkOverview;

  /**
   * Report creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 8;
   */
  createdOn?: Timestamp;

  /**
   * URL for the report stored in GCP
   *
   * @generated from field: optional string gcp_report_url = 9;
   */
  gcpReportUrl?: string;

  /**
   * Chapter name
   *
   * @generated from field: repeated string chapters = 10;
   */
  chapters: string[] = [];

  /**
   * Common mistakes and remedies
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.CommonMistakeAndRemedyKdt common_mistakes_remedy = 11;
   */
  commonMistakesRemedy: CommonMistakeAndRemedyKdt[] = [];

  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.studentChapterWiseOverview chapter_wise_overview = 12;
   */
  chapterWiseOverview: studentChapterWiseOverview[] = [];

  /**
   * Performance of questions
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.StudentHwQuestionOverview question_performances = 13;
   */
  questionPerformances: StudentHwQuestionOverview[] = [];

  /**
   * LOS
   *
   * @generated from field: repeated string strengths = 14;
   */
  strengths: string[] = [];

  /**
   * Los
   *
   * @generated from field: repeated string growth_areas = 15;
   */
  growthAreas: string[] = [];

  /**
   * Score Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.BloomLevelBreakDownV2 bloom_level_breakdown_v2 = 16;
   */
  bloomLevelBreakdownV2?: BloomLevelBreakDownV2;

  /**
   * Score Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.BloomLevelClassBreakDown bloom_level_class_breakdown = 17;
   */
  bloomLevelClassBreakdown?: BloomLevelClassBreakDown;

  /**
   * Name of the chapter
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.KnowledgeGraph knowledge_graph = 18;
   */
  knowledgeGraph: KnowledgeGraph[] = [];

  /**
   * subject name
   *
   * @generated from field: string subject = 19;
   */
  subject = "";

  /**
   * ID for the homework
   *
   * @generated from field: int32 homework_id = 20;
   */
  homeworkId = 0;

  constructor(data?: PartialMessage<StudentHwReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.StudentHwReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "school", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "assessment_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "test_overview", kind: "message", T: HomeworkOverview },
    { no: 8, name: "created_on", kind: "message", T: Timestamp },
    { no: 9, name: "gcp_report_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "chapters", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "common_mistakes_remedy", kind: "message", T: CommonMistakeAndRemedyKdt, repeated: true },
    { no: 12, name: "chapter_wise_overview", kind: "message", T: studentChapterWiseOverview, repeated: true },
    { no: 13, name: "question_performances", kind: "message", T: StudentHwQuestionOverview, repeated: true },
    { no: 14, name: "strengths", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "growth_areas", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "bloom_level_breakdown_v2", kind: "message", T: BloomLevelBreakDownV2 },
    { no: 17, name: "bloom_level_class_breakdown", kind: "message", T: BloomLevelClassBreakDown },
    { no: 18, name: "knowledge_graph", kind: "message", T: KnowledgeGraph, repeated: true },
    { no: 19, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHwReport {
    return new StudentHwReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHwReport {
    return new StudentHwReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHwReport {
    return new StudentHwReport().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHwReport | PlainMessage<StudentHwReport> | undefined, b: StudentHwReport | PlainMessage<StudentHwReport> | undefined): boolean {
    return proto3.util.equals(StudentHwReport, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.studentChapterWiseOverview
 */
export class studentChapterWiseOverview extends Message<studentChapterWiseOverview> {
  /**
   * @generated from field: string chapter_name = 1;
   */
  chapterName = "";

  /**
   * Score achieved in percentage
   *
   * @generated from field: float overall_class_score = 2;
   */
  overallClassScore = 0;

  /**
   * @generated from field: int32 no_of_questions = 3;
   */
  noOfQuestions = 0;

  /**
   * Score achieved in percentage
   *
   * @generated from field: float student_score = 4;
   */
  studentScore = 0;

  constructor(data?: PartialMessage<studentChapterWiseOverview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.studentChapterWiseOverview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapter_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "overall_class_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "no_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "student_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): studentChapterWiseOverview {
    return new studentChapterWiseOverview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): studentChapterWiseOverview {
    return new studentChapterWiseOverview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): studentChapterWiseOverview {
    return new studentChapterWiseOverview().fromJsonString(jsonString, options);
  }

  static equals(a: studentChapterWiseOverview | PlainMessage<studentChapterWiseOverview> | undefined, b: studentChapterWiseOverview | PlainMessage<studentChapterWiseOverview> | undefined): boolean {
    return proto3.util.equals(studentChapterWiseOverview, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.HomeworkOverview
 */
export class HomeworkOverview extends Message<HomeworkOverview> {
  /**
   * student score percentage
   *
   * @generated from field: float student_score = 1;
   */
  studentScore = 0;

  /**
   * Total number of questions
   *
   * @generated from field: int32 number_of_questions = 2;
   */
  numberOfQuestions = 0;

  /**
   * Difficulty level of the test
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum overall_difficulty_level = 3;
   */
  overallDifficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * Total possible marks
   *
   * @generated from field: int32 total_marks = 4;
   */
  totalMarks = 0;

  /**
   * Number of students assessed
   *
   * @generated from field: int32 chapters_assessed = 5;
   */
  chaptersAssessed = 0;

  /**
   * Summary of performance
   *
   * @generated from field: string assessment_verdict = 6;
   */
  assessmentVerdict = "";

  /**
   * List of learning outcomes
   *
   * @generated from field: float overall_class_score = 7;
   */
  overallClassScore = 0;

  /**
   * Question difficulty distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.QuestionDifficultyDistribution question_difficulty_distribution = 8;
   */
  questionDifficultyDistribution?: QuestionDifficultyDistribution;

  /**
   * Question Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.QuestionBloomLevelDistribution question_bloom_level_distribution = 9;
   */
  questionBloomLevelDistribution?: QuestionBloomLevelDistribution;

  /**
   * Question Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.QuestionBloomLevelDistributionV2 question_bloom_level_distribution_v2 = 10;
   */
  questionBloomLevelDistributionV2?: QuestionBloomLevelDistributionV2;

  /**
   * @generated from field: string difficulty = 11;
   */
  difficulty = "";

  constructor(data?: PartialMessage<HomeworkOverview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.HomeworkOverview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "number_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "overall_difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 4, name: "total_marks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "chapters_assessed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "assessment_verdict", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "overall_class_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "question_difficulty_distribution", kind: "message", T: QuestionDifficultyDistribution },
    { no: 9, name: "question_bloom_level_distribution", kind: "message", T: QuestionBloomLevelDistribution },
    { no: 10, name: "question_bloom_level_distribution_v2", kind: "message", T: QuestionBloomLevelDistributionV2 },
    { no: 11, name: "difficulty", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkOverview {
    return new HomeworkOverview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkOverview {
    return new HomeworkOverview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkOverview {
    return new HomeworkOverview().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkOverview | PlainMessage<HomeworkOverview> | undefined, b: HomeworkOverview | PlainMessage<HomeworkOverview> | undefined): boolean {
    return proto3.util.equals(HomeworkOverview, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.FetchStudnetKDAssessmentReportResonse
 */
export class FetchStudnetKDAssessmentReportResonse extends Message<FetchStudnetKDAssessmentReportResonse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * data (required)
   *
   * @generated from field: geneo.analysis.school.assessment.apis.StudentKnowledgeDiagnosticAssessmentReport data = 4;
   */
  data?: StudentKnowledgeDiagnosticAssessmentReport;

  constructor(data?: PartialMessage<FetchStudnetKDAssessmentReportResonse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.FetchStudnetKDAssessmentReportResonse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: StudentKnowledgeDiagnosticAssessmentReport },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchStudnetKDAssessmentReportResonse {
    return new FetchStudnetKDAssessmentReportResonse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchStudnetKDAssessmentReportResonse {
    return new FetchStudnetKDAssessmentReportResonse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchStudnetKDAssessmentReportResonse {
    return new FetchStudnetKDAssessmentReportResonse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchStudnetKDAssessmentReportResonse | PlainMessage<FetchStudnetKDAssessmentReportResonse> | undefined, b: FetchStudnetKDAssessmentReportResonse | PlainMessage<FetchStudnetKDAssessmentReportResonse> | undefined): boolean {
    return proto3.util.equals(FetchStudnetKDAssessmentReportResonse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.StudentKnowledgeDiagnosticAssessmentReport
 */
export class StudentKnowledgeDiagnosticAssessmentReport extends Message<StudentKnowledgeDiagnosticAssessmentReport> {
  /**
   * @generated from field: string student_name = 1;
   */
  studentName = "";

  /**
   * School name
   *
   * @generated from field: string school = 2;
   */
  school = "";

  /**
   * Class name
   *
   * @generated from field: string class = 3;
   */
  class = "";

  /**
   * Section name
   *
   * @generated from field: string section = 4;
   */
  section = "";

  /**
   * @generated from field: string assessment_type = 5;
   */
  assessmentType = "";

  /**
   * @generated from field: geneo.analysis.school.assessment.apis.SubjectTestOverview test_overview = 6;
   */
  testOverview?: SubjectTestOverview;

  /**
   * List of subject reports
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.SubjectReport subjects = 7;
   */
  subjects: SubjectReport[] = [];

  /**
   * Report creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 8;
   */
  createdOn?: Timestamp;

  /**
   * URL for the report stored in GCP
   *
   * @generated from field: optional string gcp_report_url = 9;
   */
  gcpReportUrl?: string;

  constructor(data?: PartialMessage<StudentKnowledgeDiagnosticAssessmentReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.StudentKnowledgeDiagnosticAssessmentReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "school", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "assessment_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "test_overview", kind: "message", T: SubjectTestOverview },
    { no: 7, name: "subjects", kind: "message", T: SubjectReport, repeated: true },
    { no: 8, name: "created_on", kind: "message", T: Timestamp },
    { no: 9, name: "gcp_report_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentKnowledgeDiagnosticAssessmentReport {
    return new StudentKnowledgeDiagnosticAssessmentReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentKnowledgeDiagnosticAssessmentReport {
    return new StudentKnowledgeDiagnosticAssessmentReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentKnowledgeDiagnosticAssessmentReport {
    return new StudentKnowledgeDiagnosticAssessmentReport().fromJsonString(jsonString, options);
  }

  static equals(a: StudentKnowledgeDiagnosticAssessmentReport | PlainMessage<StudentKnowledgeDiagnosticAssessmentReport> | undefined, b: StudentKnowledgeDiagnosticAssessmentReport | PlainMessage<StudentKnowledgeDiagnosticAssessmentReport> | undefined): boolean {
    return proto3.util.equals(StudentKnowledgeDiagnosticAssessmentReport, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.SubjectTestOverview
 */
export class SubjectTestOverview extends Message<SubjectTestOverview> {
  /**
   * Overall class score percentage
   *
   * @generated from field: float overall_class_score = 1;
   */
  overallClassScore = 0;

  /**
   * Total number of questions
   *
   * @generated from field: int32 number_of_questions = 2;
   */
  numberOfQuestions = 0;

  /**
   * Difficulty level of the test
   *
   * @generated from field: string overall_difficulty_level = 3;
   */
  overallDifficultyLevel = "";

  /**
   * Total possible marks
   *
   * @generated from field: int32 total_marks = 4;
   */
  totalMarks = 0;

  /**
   * Summary of performance
   *
   * @generated from field: string assessment_verdict = 6;
   */
  assessmentVerdict = "";

  /**
   * @generated from field: float student_score = 7;
   */
  studentScore = 0;

  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.SubjectWiseOverview subject_overview = 8;
   */
  subjectOverview: SubjectWiseOverview[] = [];

  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.QuestionDistribution question_distribution = 9;
   */
  questionDistribution: QuestionDistribution[] = [];

  constructor(data?: PartialMessage<SubjectTestOverview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.SubjectTestOverview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "overall_class_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "number_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "overall_difficulty_level", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_marks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "assessment_verdict", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "student_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "subject_overview", kind: "message", T: SubjectWiseOverview, repeated: true },
    { no: 9, name: "question_distribution", kind: "message", T: QuestionDistribution, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubjectTestOverview {
    return new SubjectTestOverview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubjectTestOverview {
    return new SubjectTestOverview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubjectTestOverview {
    return new SubjectTestOverview().fromJsonString(jsonString, options);
  }

  static equals(a: SubjectTestOverview | PlainMessage<SubjectTestOverview> | undefined, b: SubjectTestOverview | PlainMessage<SubjectTestOverview> | undefined): boolean {
    return proto3.util.equals(SubjectTestOverview, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.SubjectWiseOverview
 */
export class SubjectWiseOverview extends Message<SubjectWiseOverview> {
  /**
   * Name of the subject (e.g., Maths, Science, English)
   *
   * @generated from field: string subject_name = 1;
   */
  subjectName = "";

  /**
   * Total number of questions
   *
   * @generated from field: int32 number_of_questions = 2;
   */
  numberOfQuestions = 0;

  /**
   * Total possible marks
   *
   * @generated from field: int32 total_marks = 3;
   */
  totalMarks = 0;

  /**
   * Total possible marks
   *
   * @generated from field: int32 marks_achieved = 4;
   */
  marksAchieved = 0;

  /**
   * @generated from field: float percentage_score = 5;
   */
  percentageScore = 0;

  /**
   * @generated from field: float class_coverage = 6;
   */
  classCoverage = 0;

  constructor(data?: PartialMessage<SubjectWiseOverview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.SubjectWiseOverview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "number_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "total_marks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "marks_achieved", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "percentage_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "class_coverage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubjectWiseOverview {
    return new SubjectWiseOverview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubjectWiseOverview {
    return new SubjectWiseOverview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubjectWiseOverview {
    return new SubjectWiseOverview().fromJsonString(jsonString, options);
  }

  static equals(a: SubjectWiseOverview | PlainMessage<SubjectWiseOverview> | undefined, b: SubjectWiseOverview | PlainMessage<SubjectWiseOverview> | undefined): boolean {
    return proto3.util.equals(SubjectWiseOverview, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.QuestionDistribution
 */
export class QuestionDistribution extends Message<QuestionDistribution> {
  /**
   * Name of the subject (e.g., Maths, Science, English)
   *
   * @generated from field: string subject_name = 1;
   */
  subjectName = "";

  /**
   * Question difficulty distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.QuestionDifficultyDistribution question_difficulty_distribution = 2;
   */
  questionDifficultyDistribution?: QuestionDifficultyDistribution;

  /**
   * Question Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.QuestionBloomLevelDistribution question_bloom_level_distribution = 3;
   */
  questionBloomLevelDistribution?: QuestionBloomLevelDistribution;

  /**
   * Question Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.QuestionBloomLevelDistributionV2 question_bloom_level_distribution_v2 = 4;
   */
  questionBloomLevelDistributionV2?: QuestionBloomLevelDistributionV2;

  /**
   * @generated from field: repeated string concepts = 5;
   */
  concepts: string[] = [];

  constructor(data?: PartialMessage<QuestionDistribution>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.QuestionDistribution";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_difficulty_distribution", kind: "message", T: QuestionDifficultyDistribution },
    { no: 3, name: "question_bloom_level_distribution", kind: "message", T: QuestionBloomLevelDistribution },
    { no: 4, name: "question_bloom_level_distribution_v2", kind: "message", T: QuestionBloomLevelDistributionV2 },
    { no: 5, name: "concepts", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionDistribution {
    return new QuestionDistribution().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionDistribution {
    return new QuestionDistribution().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionDistribution {
    return new QuestionDistribution().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionDistribution | PlainMessage<QuestionDistribution> | undefined, b: QuestionDistribution | PlainMessage<QuestionDistribution> | undefined): boolean {
    return proto3.util.equals(QuestionDistribution, a, b);
  }
}

/**
 * Subject-specific performance report
 *
 * @generated from message geneo.analysis.school.assessment.apis.SubjectReport
 */
export class SubjectReport extends Message<SubjectReport> {
  /**
   * Name of the subject (e.g., Maths, Science, English)
   *
   * @generated from field: string subject_name = 2;
   */
  subjectName = "";

  /**
   * Name of the chapter
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.KnowledgeGraph knowledge_graph = 3;
   */
  knowledgeGraph: KnowledgeGraph[] = [];

  /**
   * Performance of questions
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.SubjectQuestionOverview question_overview = 11;
   */
  questionOverview: SubjectQuestionOverview[] = [];

  /**
   * Score Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.BloomLevelBreakDown bloom_level_breakdown = 4;
   */
  bloomLevelBreakdown?: BloomLevelBreakDown;

  /**
   * Score Bloom level distribution
   *
   * @generated from field: geneo.analysis.school.assessment.apis.BloomLevelBreakDownV2 bloom_level_breakdown_v2 = 5;
   */
  bloomLevelBreakdownV2?: BloomLevelBreakDownV2;

  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.LearningGapsAndRemedy learning_gaps_remedy = 6;
   */
  learningGapsRemedy: LearningGapsAndRemedy[] = [];

  /**
   * Common mistakes and remedies
   *
   * @generated from field: repeated geneo.analysis.school.assessment.apis.CommonMistakeAndRemedyKdt common_mistakes_remedy = 7;
   */
  commonMistakesRemedy: CommonMistakeAndRemedyKdt[] = [];

  constructor(data?: PartialMessage<SubjectReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.SubjectReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "knowledge_graph", kind: "message", T: KnowledgeGraph, repeated: true },
    { no: 11, name: "question_overview", kind: "message", T: SubjectQuestionOverview, repeated: true },
    { no: 4, name: "bloom_level_breakdown", kind: "message", T: BloomLevelBreakDown },
    { no: 5, name: "bloom_level_breakdown_v2", kind: "message", T: BloomLevelBreakDownV2 },
    { no: 6, name: "learning_gaps_remedy", kind: "message", T: LearningGapsAndRemedy, repeated: true },
    { no: 7, name: "common_mistakes_remedy", kind: "message", T: CommonMistakeAndRemedyKdt, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubjectReport {
    return new SubjectReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubjectReport {
    return new SubjectReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubjectReport {
    return new SubjectReport().fromJsonString(jsonString, options);
  }

  static equals(a: SubjectReport | PlainMessage<SubjectReport> | undefined, b: SubjectReport | PlainMessage<SubjectReport> | undefined): boolean {
    return proto3.util.equals(SubjectReport, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.KnowledgeGraph
 */
export class KnowledgeGraph extends Message<KnowledgeGraph> {
  /**
   * @generated from field: string concept = 1;
   */
  concept = "";

  /**
   * @generated from field: float score = 2;
   */
  score = 0;

  /**
   * @generated from field: float ideal_score = 3;
   */
  idealScore = 0;

  /**
   * @generated from field: float class_score = 4;
   */
  classScore = 0;

  /**
   * @generated from field: float min_score = 5;
   */
  minScore = 0;

  constructor(data?: PartialMessage<KnowledgeGraph>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.KnowledgeGraph";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "concept", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "ideal_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "class_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "min_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnowledgeGraph {
    return new KnowledgeGraph().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnowledgeGraph {
    return new KnowledgeGraph().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnowledgeGraph {
    return new KnowledgeGraph().fromJsonString(jsonString, options);
  }

  static equals(a: KnowledgeGraph | PlainMessage<KnowledgeGraph> | undefined, b: KnowledgeGraph | PlainMessage<KnowledgeGraph> | undefined): boolean {
    return proto3.util.equals(KnowledgeGraph, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.StudentHwQuestionOverview
 */
export class StudentHwQuestionOverview extends Message<StudentHwQuestionOverview> {
  /**
   * Question number (e.g., Q1, Q2)
   *
   * @generated from field: int32 question_number = 1;
   */
  questionNumber = 0;

  /**
   * Marks for the question
   *
   * @generated from field: int32 marks = 2;
   */
  marks = 0;

  /**
   * Number of correct answers
   *
   * @generated from field: string chapter = 3;
   */
  chapter = "";

  /**
   * @generated from field: string bloom = 4;
   */
  bloom = "";

  /**
   * Average score percentage
   *
   * @generated from field: float average_score = 5;
   */
  averageScore = 0;

  /**
   * geneo.content.db.DifficultyLevelEnum difficulty_level = 6; // Difficulty level of the question
   *
   * @generated from field: string difficulty = 7;
   */
  difficulty = "";

  /**
   * @generated from field: string learning_outcome = 8;
   */
  learningOutcome = "";

  /**
   * @generated from field: float student_score = 9;
   */
  studentScore = 0;

  constructor(data?: PartialMessage<StudentHwQuestionOverview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.StudentHwQuestionOverview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "marks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "chapter", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "bloom", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "average_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "difficulty", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "learning_outcome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "student_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHwQuestionOverview {
    return new StudentHwQuestionOverview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHwQuestionOverview {
    return new StudentHwQuestionOverview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHwQuestionOverview {
    return new StudentHwQuestionOverview().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHwQuestionOverview | PlainMessage<StudentHwQuestionOverview> | undefined, b: StudentHwQuestionOverview | PlainMessage<StudentHwQuestionOverview> | undefined): boolean {
    return proto3.util.equals(StudentHwQuestionOverview, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.SubjectQuestionOverview
 */
export class SubjectQuestionOverview extends Message<SubjectQuestionOverview> {
  /**
   * Question number (e.g., Q1, Q2)
   *
   * @generated from field: int32 question_number = 1;
   */
  questionNumber = 0;

  /**
   * Marks for the question
   *
   * @generated from field: int32 marks = 2;
   */
  marks = 0;

  /**
   * Number of correct answers
   *
   * @generated from field: string concept = 3;
   */
  concept = "";

  /**
   * @generated from field: string bloom = 4;
   */
  bloom = "";

  /**
   * Average score percentage
   *
   * @generated from field: float average_score = 5;
   */
  averageScore = 0;

  /**
   * geneo.content.db.DifficultyLevelEnum difficulty_level = 6; // Difficulty level of the question
   *
   * @generated from field: string difficulty = 7;
   */
  difficulty = "";

  /**
   * @generated from field: string learning_outcome = 8;
   */
  learningOutcome = "";

  /**
   * @generated from field: float student_score = 9;
   */
  studentScore = 0;

  constructor(data?: PartialMessage<SubjectQuestionOverview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.SubjectQuestionOverview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "marks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "concept", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "bloom", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "average_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "difficulty", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "learning_outcome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "student_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubjectQuestionOverview {
    return new SubjectQuestionOverview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubjectQuestionOverview {
    return new SubjectQuestionOverview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubjectQuestionOverview {
    return new SubjectQuestionOverview().fromJsonString(jsonString, options);
  }

  static equals(a: SubjectQuestionOverview | PlainMessage<SubjectQuestionOverview> | undefined, b: SubjectQuestionOverview | PlainMessage<SubjectQuestionOverview> | undefined): boolean {
    return proto3.util.equals(SubjectQuestionOverview, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.LearningGapsAndRemedy
 */
export class LearningGapsAndRemedy extends Message<LearningGapsAndRemedy> {
  /**
   * @generated from field: repeated string major_gaps = 1;
   */
  majorGaps: string[] = [];

  /**
   * @generated from field: repeated string minor_gaps = 2;
   */
  minorGaps: string[] = [];

  /**
   * @generated from field: repeated geneo.analysis.school.assessment.apis.ChapterResource chapter_resources = 3;
   */
  chapterResources: ChapterResource[] = [];

  constructor(data?: PartialMessage<LearningGapsAndRemedy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.LearningGapsAndRemedy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "major_gaps", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "minor_gaps", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "chapter_resources", kind: "message", T: ChapterResource, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LearningGapsAndRemedy {
    return new LearningGapsAndRemedy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LearningGapsAndRemedy {
    return new LearningGapsAndRemedy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LearningGapsAndRemedy {
    return new LearningGapsAndRemedy().fromJsonString(jsonString, options);
  }

  static equals(a: LearningGapsAndRemedy | PlainMessage<LearningGapsAndRemedy> | undefined, b: LearningGapsAndRemedy | PlainMessage<LearningGapsAndRemedy> | undefined): boolean {
    return proto3.util.equals(LearningGapsAndRemedy, a, b);
  }
}

/**
 * @generated from message geneo.analysis.school.assessment.apis.ChapterResource
 */
export class ChapterResource extends Message<ChapterResource> {
  /**
   * @generated from field: string chapter_name = 1;
   */
  chapterName = "";

  /**
   * @generated from field: geneo.analysis.school.assessment.apis.ResourceContent resource = 2;
   */
  resource?: ResourceContent;

  constructor(data?: PartialMessage<ChapterResource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.school.assessment.apis.ChapterResource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapter_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource", kind: "message", T: ResourceContent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterResource {
    return new ChapterResource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterResource {
    return new ChapterResource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterResource {
    return new ChapterResource().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterResource | PlainMessage<ChapterResource> | undefined, b: ChapterResource | PlainMessage<ChapterResource> | undefined): boolean {
    return proto3.util.equals(ChapterResource, a, b);
  }
}

