export const EnvironmentValues = ['local', 'dev', 'stage', 'prod'] as const;
export type Environment = typeof EnvironmentValues[number];

export const isEnvironmentValue = (value: string): value is Environment => {
  return EnvironmentValues.includes(value as Environment);
};
export const getEnvConfig = () => {
  return {
    environment: process.env.NX_GENEO_ENV || 'dev',
    userDataBucket:
      process.env.NX_USER_DATA_BUCKET || 'geneo-staging-geneo-user-data',
    resourceContentBucket:
      process.env.NX_RESOURCE_CONTENT_BUCKET || 'geneo-content-resource-files',
    processedMediaBucket:
      process.env.NX_PROCESSED_MEDIA_BUCKET || 'geneo-content-processed-media',
    schoolnetCdnImagesBucket:
      process.env.NX_SCHOOLNET_CDN_IMAGES_BUCKET || 'schoolnet-cdn-images',
    schoolnetStaticAssetBucket:
      process.env.NX_ASSETS_MEDIA_BUCKET || 'schoolnet_app_assets',
    firebaseConfig: {
      apiKey: process.env.NX_FIREBASE_API_KEY,
      authDomain: process.env.NX_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NX_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NX_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NX_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NX_FIREBASE_APP_ID,
      measurementId: process.env.NX_FIREBASE_MEASUREMENT_ID,
    },
    firebaseVapidKey: process.env.NX_VAPID_KEY,
    mcbSsoClientId: process.env.NX_MCB_SSO_CLIENT_ID,
    mcbRedirectUrl: process.env.NX_MCB_REDIRECT_URL,
    sentry: {
      dsn: process.env.NX_SENTRY_DSN,
      enabled: process.env.NX_SENTRY_ENABLED === 'true',
    },
    homeExpirationTime: process.env.NX_HOMEPAGE_API_EXPIRATION_TIME || '60000',
  };
};

export const isAiDebuggingEnabled = () => {
  const nxGeneoEnv = process.env.NX_GENEO_ENV || 'local';
  const environment = (
    isEnvironmentValue(nxGeneoEnv) ? nxGeneoEnv : 'local'
  ) as Environment;
  // if (environment === 'local' || environment === 'dev') {
  //   return true;
  // } else {
  //   return false;
  // }
  return false;
};
