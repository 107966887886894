import { Timestamp } from '@bufbuild/protobuf';
import { TopicEventData } from '@protos/geneo_ai/ai.student.events.data_pb';
import { StudentEvent } from '@protos/geneo_ai/ai.student.events_pb';
import { v4 as uuidv4 } from 'uuid';
import { saveMessageSession } from '../../storage';
import { ITopicCloseArgs, ITopicEventArgs, ITopicOpenArgs } from './inputArgs';
import {
  TopicCloseArgsValidator,
  TopicEventArgsValidator,
  TopicOpenArgsValidator,
} from './inputArgsValidator';
import { TOPIC_KEY } from './storageKeys';
import {
  checkNgetTopicEventData,
  clearEventData,
  getBookSessionData,
  getSessionDataWithIncrimentedIndex,
  getTopicEventData,
  hasEventData,
  initialiseTopicSession,
  timeDifference,
} from './utils';

export function topicStorageKey(topicId: number) {
  return `${TOPIC_KEY}!${topicId}`;
}

export function topicOpen(args: ITopicOpenArgs): TopicEventData {
  // const { error } = TopicOpenArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, topicId } = args;
  const storageKey = topicStorageKey(topicId);
  const topicData = getTopicEventData(storageKey);
  const topicSessionId = uuidv4();
  initialiseTopicSession({
    topicSessionId,
    topicId,
  });
  if (topicData !== null) {
    throw new Error(
      `Topic Data has to be null but ${JSON.stringify(topicData)} is present"`
    );
  } else {
    const eventData = new TopicEventData({
      topicId,
      topicSessionId: topicSessionId,
      openTimestamp: Timestamp.fromDate(openTimestamp),
      actionId: topicSessionId,
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getTopicOpenEvent(args: ITopicEventArgs): StudentEvent {
  // const { error } = TopicEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { topicId, isOffline } = args;
  const storageKey = topicStorageKey(topicId);
  const eventData = checkNgetTopicEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const topicSessionData = getTopicSessionData();
  const bookSessionData = getBookSessionData();
  if (
    bookSessionData.topic === undefined ||
    bookSessionData.chapter === undefined ||
    bookSessionData.book === undefined
  ) {
    throw new Error(
      `BookSessionData can not be undefined in ${JSON.stringify(
        bookSessionData
      )}`
    );
  }
  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    bookSessionId: bookSessionData.book.sessionId,
    chapterSessionId: bookSessionData.chapter.sessionId,
    isOffline: isOffline,
    EventType: {
      case: 'topicAction',
      value: {
        actionId: eventData.topicSessionId,
        topicId,
        TopicActionType: {
          case: 'open',
          value: true,
        },
      },
    },
  });
}

export function topicClose(args: ITopicCloseArgs): TopicEventData {
  // const { error } = TopicCloseArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { closeTimestamp, topicId } = args;
  const storageKey = topicStorageKey(topicId);
  const topicData = getTopicEventData(storageKey);
  if (topicData?.topicId !== topicId) {
    throw new Error(
      `Topic Id in ${JSON.stringify(
        topicData
      )} can't be different from provided "${topicId}"`
    );
  } else {
    topicData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, topicData);
    return topicData;
  }
}

export function getTopicCloseEvent(args: ITopicEventArgs): StudentEvent {
  // const { error } = TopicEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { topicId, isOffline } = args;
  const storageKey = topicStorageKey(topicId);
  const eventData = checkNgetTopicEventData(storageKey);
  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const topicSessionData = getTopicSessionData();
  const bookSessionData = getBookSessionData();

  if (
    bookSessionData.topic === undefined ||
    bookSessionData.chapter === undefined ||
    bookSessionData.book === undefined
  ) {
    throw new Error(
      `topic Session id can not be undefined in ${JSON.stringify(
        bookSessionData
      )}`
    );
  }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new StudentEvent({
    studentId: sessionData.studentId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    bookSessionId: bookSessionData.book.sessionId,
    chapterSessionId: bookSessionData.chapter.sessionId,
    isOffline: isOffline,
    EventType: {
      case: 'topicAction',
      value: {
        actionId: eventData.topicSessionId,
        topicId,
        TopicActionType: {
          case: 'close',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function clearTopicData(args: ITopicEventArgs) {
  // const { error } = TopicEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { topicId } = args;
  const storageKey = topicStorageKey(topicId);
  clearEventData(storageKey);
}

export function isTopicOpen(args: ITopicEventArgs): boolean {
  // const { error } = TopicEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { topicId } = args;
  const storageKey = topicStorageKey(topicId);
  return hasEventData(storageKey);
}
