import {
  callStudentEventsApi,
  clearLocalStorageKeys,
  deleteOfflineAccessKeyFromCache,
  deserify,
  getEnvConfig,
  getLocalStorage,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  DeviceEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { resetAuthState } from '../../pages/Auth/reducer/auth.slice';
import { resetHomeState } from '../../pages/Home/reducer/homeDashboard.slice';
import { resetHomeworkState } from '../../pages/Homework/reducer/homework.slice';
import { resetLearnState } from '../../pages/Learn/reducer/learn.slice';
import { resetPerformanceState } from '../../pages/Performance/reducer/performance.slice';
import { useAppDispatch, useAppSelector } from '../../reduxStore/reduxHooks';
import { useAuthentication } from '../../utils/customHooks';

import { aiLogoutEvent } from '../../pages/Auth/Login/login_logout.events';
import {
  aiHomeworkCloseEvent,
  aiHomeworkReviewCloseEvent,
} from '../../pages/Homework/utils_homework/homework.events';
import {
  aiBookCloseEvent,
  aiChapterCloseEvent,
  aiContentCloseEvent,
  aiLessonCloseEvent,
  aiResourceCloseEvent,
  aiResourcePageCloseEvent,
  aiTopicCloseEvent,
} from '../../pages/Learn/utils_learn/learn.events';
import { aiPerformanceCloseEvent } from '../../pages/Performance/utils_performance/performance.events';
import { useDownloadContext } from '../../app/Context/DownloadContextProviderV2';

// const PrivateRouterWrapper = () => {
//   const isAuthenticated = useAuthentication();
//   console.log('isAuthenticated', isAuthenticated);
//   return isAuthenticated === true ? <Outlet /> : <Navigate to={LOGIN} />;
// };

// export default PrivateRouterWrapper;

const PrivateRouterWrapper = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAuthentication();
  const isMCBUser = deserify(useAppSelector((state) => state.auth.is_mcb_user));
  const ssoRedirectionUrl = deserify(
    useAppSelector((state) => state.auth.ssoRedirectionUrl)
  );
  const config = getEnvConfig();
  // console.log('isAuthenticated', isAuthenticated);
  const userId = getLocalStorage('userId');
  const fcmToken = getLocalStorage('fcmToken'); // Convert fcmToken to string
  const deviceId = getLocalStorage('deviceID');
  const { isOffline } = useDownloadContext();

  const { selected_book_id } = deserify(useAppSelector((state) => state.home));
  const {
    selected_chapter_resources,
    selected_module_lessons,
    selected_lesson_content,
    selected_resource_content,
    selected_resource_info,
  } = deserify(useAppSelector((state) => state.learn));
  const { performance_id } = deserify(
    useAppSelector((state) => state.performance)
  );

  const { selected_homework_data } = deserify(
    useAppSelector((state) => state.homework)
  );

  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  const commonAiCloseEvents = async () => {
    await aiContentCloseEvent({
      pageNumber: selected_resource_info?.pageNumber,
      pageContent: selected_resource_info?.pageContent || [],
      isOffline: isOffline,
    });
    await aiResourcePageCloseEvent({
      resourceId: selected_resource_content?.parsedData.resourceId,
      pageNumber: selected_resource_info?.pageNumber,
      isOffline: isOffline,
    });
    await aiResourceCloseEvent({
      resourceId: selected_resource_content?.parsedData.resourceId,
      isOffline: isOffline,
    });
    await aiLessonCloseEvent({
      lessonId: selected_lesson_content?.lessonId,
      isOffline: isOffline,
    });
    await aiTopicCloseEvent({
      topicId: selected_module_lessons?.moduleId,
      isOffline: isOffline,
    });
    await aiChapterCloseEvent({
      chapterId: selected_chapter_resources?.chapterId,
      isOffline: isOffline,
    });

    await aiPerformanceCloseEvent({
      performanceId: performance_id,
      isOffline: isOffline,
    });

    await aiHomeworkCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
      isOffline: isOffline,
    });
    await aiHomeworkReviewCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
      isOffline: isOffline,
    });
    await aiBookCloseEvent({
      bookId: selected_book_id,
      isOffline: isOffline,
    });
    await aiLogoutEvent({ isOffline: isOffline });
    if (isAuthenticated) {
      !isOffline && (await callStudentEventsApi(commonServiceClientContext));
    }
  };

  const notificationAPI = async () => {
    if (!userId) {
      console.log('User ID not found.');
      return;
    }

    try {
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        deviceId: deviceId ? BigInt(deviceId) : undefined,
        profileId: BigInt(userId),
        role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
        deviceType: DeviceEnum.DEVICE_TYPE_WEB,
        deviceToken: fcmToken?.toString(),
        isActive: false,
      });
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        deviceToken: fcmToken?.toString(),
        topic: `${String(userId)}_${ProfileRolesEnum.PROFILE_ROLE_STUDENT}`,
        subscribeTopic: false,
      });
    } catch (err) {
      console.error('Error in Notification API:', err);
    }
  };

  // Function to handle logout and state reset
  const handleLogout = async () => {
    try {
      await commonAiCloseEvents();
      if (fcmToken) {
        await notificationAPI();
      }
      if (isAuthenticated) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.logout({
            profileId: getLocalStorage('userId'),
            role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
          });
      }
    } catch (err) {
      console.log(err);
    } finally {
      let redirectUrl = undefined;
      if (isMCBUser && ssoRedirectionUrl) {
        redirectUrl = ssoRedirectionUrl;
      }
      dispatch(resetAuthState());
      dispatch(resetHomeState());
      dispatch(resetLearnState());
      dispatch(resetPerformanceState());
      dispatch(resetHomeworkState());
      // localStorage.clear();
      clearLocalStorageKeys();
      deleteOfflineAccessKeyFromCache();
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  };
  // Check if the user is authenticated, and render accordingly
  if (isAuthenticated) {
    return <Outlet />;
  } else {
    // If not authenticated, call handleLogout function and then navigate to login
    handleLogout();
    return <Navigate to="/login" />;
  }
};

export default PrivateRouterWrapper;
