import React from 'react';
import { Box, Divider, Typography } from '@mui/material'; // Adjust the import based on your file structure

import ImageWrapper from '../../elements/ImageWrapper';
import { pxToRem, pxTovW } from 'libs/shared-ui/src/commonUtils/resizeUtils';

interface InfoItem {
  iconName: string;
  value: string | number;
  label: string;
}

interface InfoPanelProps {
  items: InfoItem[];
}

export const InfoBarWithDivider: React.FC<InfoPanelProps> = ({ items }) => {
  return (
    <Box
      sx={{
        width: {
          xs: pxToRem(300),
          md: pxTovW(800),
          lg: pxTovW(500),
        }, // Responsive width
        margin: '0 auto', // Center the Box horizontally
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around', // Equal spacing between items
        bgcolor: '#FFFFFF', // Background color
        p: 2,
        borderRadius: 2,
        boxShadow: 1,
        gap: { xs: pxToRem(10), md: pxTovW(8) },
        mt: { xs: pxToRem(10), md: pxTovW(15) },
      }}
    >
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Info Box */}
          <Box
            textAlign="center"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1, // Spacing between icon and number
              }}
            >
              <ImageWrapper
                name={item.iconName}
                type="png"
                parentFolder="icons"
                styles={{
                  fontSize: {
                    xs: pxToRem(20),
                    md: pxTovW(30),
                    lg: pxTovW(25),
                  },
                }}
              />
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{
                  fontSize: {
                    xs: pxToRem(20),
                    md: pxTovW(30),
                    lg: pxTovW(25),
                  },
                }}
              >
                {item.value}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                mt: 1, // Add spacing from the row above
                fontSize: {
                  xs: pxToRem(10),
                },
              }}
            >
              {item.label}
            </Typography>
          </Box>

          {/* Divider (Exclude for the last item) */}
          {index < items.length - 1 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: 4, borderRightWidth: 2 }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};
