import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
// import { LmsBookAPIServiceV1 } from '@protos/learning_management/lms.book.apis_connect';
import { LmsAssessmentTeacherAPIServiceV1 } from '@protos/learning_management/lms.assessment.teacher.apis_connect';
import {
  ResponseStatusCodeFunction,
  lmsTransport,
  lmsTransportWithLogoutHandler,
} from './transports';

// export const LmsBookAPIServiceV1Client = createPromiseClient(
//   LmsAssessmentTeacherAPIServiceV1,
//   lmsTransport
// );

export const useLmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    LmsAssessmentTeacherAPIServiceV1,
    lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
