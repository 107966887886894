// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/content_management/cms.content.apis.proto (package geneo.cms.content.parser.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Content, CreationStatusEnum, LearningOutcome, Module, Module_ModuleCategoryEnum, ModuleBookContent, ModuleLessonTag, ModuleQuestionBankTag, ModuleResourceTag, PDFConversionStatus, Question, QuestionBank, Resource, ResourceContent, ResourceTeacherInstruction, VideoResolutionConversion } from "@protos/content_management/content.db_pb";
import { Board_BoardEnum, Book as Book$1, Class_ClassEnum, Subject_SubjectEnum } from "@protos/common/common.db_pb";
import { Task } from "@protos/learning_management/lms.db_pb";

/**
 * @generated from message geneo.cms.content.parser.apis.BookResourceRequest
 */
export class BookResourceRequest extends Message<BookResourceRequest> {
  /**
   * @generated from field: int32 bookId = 1;
   */
  bookId = 0;

  /**
   * @generated from field: int32 startPage = 2;
   */
  startPage = 0;

  /**
   * @generated from field: int32 endPage = 3;
   */
  endPage = 0;

  /**
   * @generated from field: string title = 4;
   */
  title = "";

  /**
   * @generated from field: int32 moduleId = 5;
   */
  moduleId = 0;

  constructor(data?: PartialMessage<BookResourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.BookResourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bookId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "startPage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "endPage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "moduleId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookResourceRequest {
    return new BookResourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookResourceRequest {
    return new BookResourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookResourceRequest {
    return new BookResourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BookResourceRequest | PlainMessage<BookResourceRequest> | undefined, b: BookResourceRequest | PlainMessage<BookResourceRequest> | undefined): boolean {
    return proto3.util.equals(BookResourceRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.BookResourceResponse
 */
export class BookResourceResponse extends Message<BookResourceResponse> {
  /**
   * @generated from field: repeated string resourceIds = 1;
   */
  resourceIds: string[] = [];

  constructor(data?: PartialMessage<BookResourceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.BookResourceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resourceIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookResourceResponse {
    return new BookResourceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookResourceResponse {
    return new BookResourceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookResourceResponse {
    return new BookResourceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BookResourceResponse | PlainMessage<BookResourceResponse> | undefined, b: BookResourceResponse | PlainMessage<BookResourceResponse> | undefined): boolean {
    return proto3.util.equals(BookResourceResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.updatePdfRequest
 */
export class updatePdfRequest extends Message<updatePdfRequest> {
  /**
   * @generated from field: int32 pdfConversionId = 1;
   */
  pdfConversionId = 0;

  /**
   * @generated from field: string processedFileUrl = 2;
   */
  processedFileUrl = "";

  /**
   * @generated from field: geneo.content.db.PDFConversionStatus status = 3;
   */
  status = PDFConversionStatus.PDF_CONVERSION_STATUS_UNDEFINED;

  /**
   * @generated from field: optional string resourceId = 4;
   */
  resourceId?: string;

  constructor(data?: PartialMessage<updatePdfRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.updatePdfRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pdfConversionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "processedFileUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(PDFConversionStatus) },
    { no: 4, name: "resourceId", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): updatePdfRequest {
    return new updatePdfRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): updatePdfRequest {
    return new updatePdfRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): updatePdfRequest {
    return new updatePdfRequest().fromJsonString(jsonString, options);
  }

  static equals(a: updatePdfRequest | PlainMessage<updatePdfRequest> | undefined, b: updatePdfRequest | PlainMessage<updatePdfRequest> | undefined): boolean {
    return proto3.util.equals(updatePdfRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleBookResourceRequest
 */
export class ModuleBookResourceRequest extends Message<ModuleBookResourceRequest> {
  constructor(data?: PartialMessage<ModuleBookResourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleBookResourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleBookResourceRequest {
    return new ModuleBookResourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleBookResourceRequest {
    return new ModuleBookResourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleBookResourceRequest {
    return new ModuleBookResourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleBookResourceRequest | PlainMessage<ModuleBookResourceRequest> | undefined, b: ModuleBookResourceRequest | PlainMessage<ModuleBookResourceRequest> | undefined): boolean {
    return proto3.util.equals(ModuleBookResourceRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleBookResourceResponse
 */
export class ModuleBookResourceResponse extends Message<ModuleBookResourceResponse> {
  constructor(data?: PartialMessage<ModuleBookResourceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleBookResourceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleBookResourceResponse {
    return new ModuleBookResourceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleBookResourceResponse {
    return new ModuleBookResourceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleBookResourceResponse {
    return new ModuleBookResourceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleBookResourceResponse | PlainMessage<ModuleBookResourceResponse> | undefined, b: ModuleBookResourceResponse | PlainMessage<ModuleBookResourceResponse> | undefined): boolean {
    return proto3.util.equals(ModuleBookResourceResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.updatePdfResponse
 */
export class updatePdfResponse extends Message<updatePdfResponse> {
  constructor(data?: PartialMessage<updatePdfResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.updatePdfResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): updatePdfResponse {
    return new updatePdfResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): updatePdfResponse {
    return new updatePdfResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): updatePdfResponse {
    return new updatePdfResponse().fromJsonString(jsonString, options);
  }

  static equals(a: updatePdfResponse | PlainMessage<updatePdfResponse> | undefined, b: updatePdfResponse | PlainMessage<updatePdfResponse> | undefined): boolean {
    return proto3.util.equals(updatePdfResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.getResourceDataRequest
 */
export class getResourceDataRequest extends Message<getResourceDataRequest> {
  /**
   * @generated from field: int32 startRow = 1;
   */
  startRow = 0;

  /**
   * @generated from field: int32 endRow = 2;
   */
  endRow = 0;

  constructor(data?: PartialMessage<getResourceDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.getResourceDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "startRow", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "endRow", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): getResourceDataRequest {
    return new getResourceDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): getResourceDataRequest {
    return new getResourceDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): getResourceDataRequest {
    return new getResourceDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: getResourceDataRequest | PlainMessage<getResourceDataRequest> | undefined, b: getResourceDataRequest | PlainMessage<getResourceDataRequest> | undefined): boolean {
    return proto3.util.equals(getResourceDataRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.getResourceDataResponse
 */
export class getResourceDataResponse extends Message<getResourceDataResponse> {
  /**
   * @generated from field: repeated geneo.content.db.Resource resources = 1;
   */
  resources: Resource[] = [];

  /**
   * @generated from field: repeated geneo.content.db.LearningOutcome learningOutcomes = 2;
   */
  learningOutcomes: LearningOutcome[] = [];

  constructor(data?: PartialMessage<getResourceDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.getResourceDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resources", kind: "message", T: Resource, repeated: true },
    { no: 2, name: "learningOutcomes", kind: "message", T: LearningOutcome, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): getResourceDataResponse {
    return new getResourceDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): getResourceDataResponse {
    return new getResourceDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): getResourceDataResponse {
    return new getResourceDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: getResourceDataResponse | PlainMessage<getResourceDataResponse> | undefined, b: getResourceDataResponse | PlainMessage<getResourceDataResponse> | undefined): boolean {
    return proto3.util.equals(getResourceDataResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.conversionRequest
 */
export class conversionRequest extends Message<conversionRequest> {
  constructor(data?: PartialMessage<conversionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.conversionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): conversionRequest {
    return new conversionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): conversionRequest {
    return new conversionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): conversionRequest {
    return new conversionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: conversionRequest | PlainMessage<conversionRequest> | undefined, b: conversionRequest | PlainMessage<conversionRequest> | undefined): boolean {
    return proto3.util.equals(conversionRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.conversionResponse
 */
export class conversionResponse extends Message<conversionResponse> {
  constructor(data?: PartialMessage<conversionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.conversionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): conversionResponse {
    return new conversionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): conversionResponse {
    return new conversionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): conversionResponse {
    return new conversionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: conversionResponse | PlainMessage<conversionResponse> | undefined, b: conversionResponse | PlainMessage<conversionResponse> | undefined): boolean {
    return proto3.util.equals(conversionResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.populateOfflineMetaRequest
 */
export class populateOfflineMetaRequest extends Message<populateOfflineMetaRequest> {
  constructor(data?: PartialMessage<populateOfflineMetaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.populateOfflineMetaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): populateOfflineMetaRequest {
    return new populateOfflineMetaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): populateOfflineMetaRequest {
    return new populateOfflineMetaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): populateOfflineMetaRequest {
    return new populateOfflineMetaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: populateOfflineMetaRequest | PlainMessage<populateOfflineMetaRequest> | undefined, b: populateOfflineMetaRequest | PlainMessage<populateOfflineMetaRequest> | undefined): boolean {
    return proto3.util.equals(populateOfflineMetaRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.populateOfflineMetaResponse
 */
export class populateOfflineMetaResponse extends Message<populateOfflineMetaResponse> {
  constructor(data?: PartialMessage<populateOfflineMetaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.populateOfflineMetaResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): populateOfflineMetaResponse {
    return new populateOfflineMetaResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): populateOfflineMetaResponse {
    return new populateOfflineMetaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): populateOfflineMetaResponse {
    return new populateOfflineMetaResponse().fromJsonString(jsonString, options);
  }

  static equals(a: populateOfflineMetaResponse | PlainMessage<populateOfflineMetaResponse> | undefined, b: populateOfflineMetaResponse | PlainMessage<populateOfflineMetaResponse> | undefined): boolean {
    return proto3.util.equals(populateOfflineMetaResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.estimatedTimeRequest
 */
export class estimatedTimeRequest extends Message<estimatedTimeRequest> {
  constructor(data?: PartialMessage<estimatedTimeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.estimatedTimeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): estimatedTimeRequest {
    return new estimatedTimeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): estimatedTimeRequest {
    return new estimatedTimeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): estimatedTimeRequest {
    return new estimatedTimeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: estimatedTimeRequest | PlainMessage<estimatedTimeRequest> | undefined, b: estimatedTimeRequest | PlainMessage<estimatedTimeRequest> | undefined): boolean {
    return proto3.util.equals(estimatedTimeRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.estimatedTimeResponse
 */
export class estimatedTimeResponse extends Message<estimatedTimeResponse> {
  constructor(data?: PartialMessage<estimatedTimeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.estimatedTimeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): estimatedTimeResponse {
    return new estimatedTimeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): estimatedTimeResponse {
    return new estimatedTimeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): estimatedTimeResponse {
    return new estimatedTimeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: estimatedTimeResponse | PlainMessage<estimatedTimeResponse> | undefined, b: estimatedTimeResponse | PlainMessage<estimatedTimeResponse> | undefined): boolean {
    return proto3.util.equals(estimatedTimeResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.upsertVideoRequest
 */
export class upsertVideoRequest extends Message<upsertVideoRequest> {
  /**
   * @generated from field: repeated string videoList = 1;
   */
  videoList: string[] = [];

  constructor(data?: PartialMessage<upsertVideoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.upsertVideoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "videoList", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): upsertVideoRequest {
    return new upsertVideoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): upsertVideoRequest {
    return new upsertVideoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): upsertVideoRequest {
    return new upsertVideoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: upsertVideoRequest | PlainMessage<upsertVideoRequest> | undefined, b: upsertVideoRequest | PlainMessage<upsertVideoRequest> | undefined): boolean {
    return proto3.util.equals(upsertVideoRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.populateVideoRequest
 */
export class populateVideoRequest extends Message<populateVideoRequest> {
  constructor(data?: PartialMessage<populateVideoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.populateVideoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): populateVideoRequest {
    return new populateVideoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): populateVideoRequest {
    return new populateVideoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): populateVideoRequest {
    return new populateVideoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: populateVideoRequest | PlainMessage<populateVideoRequest> | undefined, b: populateVideoRequest | PlainMessage<populateVideoRequest> | undefined): boolean {
    return proto3.util.equals(populateVideoRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.populateVideoResponse
 */
export class populateVideoResponse extends Message<populateVideoResponse> {
  constructor(data?: PartialMessage<populateVideoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.populateVideoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): populateVideoResponse {
    return new populateVideoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): populateVideoResponse {
    return new populateVideoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): populateVideoResponse {
    return new populateVideoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: populateVideoResponse | PlainMessage<populateVideoResponse> | undefined, b: populateVideoResponse | PlainMessage<populateVideoResponse> | undefined): boolean {
    return proto3.util.equals(populateVideoResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.TranscoderRequest
 */
export class TranscoderRequest extends Message<TranscoderRequest> {
  /**
   * @generated from field: optional int32 numberOfVideo = 1;
   */
  numberOfVideo?: number;

  /**
   * @generated from field: optional string resolution = 2;
   */
  resolution?: string;

  /**
   * @generated from field: repeated int32 videoIds = 3;
   */
  videoIds: number[] = [];

  /**
   * @generated from field: optional int32 batchSize = 4;
   */
  batchSize?: number;

  constructor(data?: PartialMessage<TranscoderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.TranscoderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "numberOfVideo", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "resolution", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "videoIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "batchSize", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscoderRequest {
    return new TranscoderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscoderRequest {
    return new TranscoderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscoderRequest {
    return new TranscoderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TranscoderRequest | PlainMessage<TranscoderRequest> | undefined, b: TranscoderRequest | PlainMessage<TranscoderRequest> | undefined): boolean {
    return proto3.util.equals(TranscoderRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.TranscoderResponse
 */
export class TranscoderResponse extends Message<TranscoderResponse> {
  /**
   * @generated from field: repeated geneo.content.db.VideoResolutionConversion transcoderData = 1;
   */
  transcoderData: VideoResolutionConversion[] = [];

  constructor(data?: PartialMessage<TranscoderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.TranscoderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "transcoderData", kind: "message", T: VideoResolutionConversion, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscoderResponse {
    return new TranscoderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscoderResponse {
    return new TranscoderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscoderResponse {
    return new TranscoderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TranscoderResponse | PlainMessage<TranscoderResponse> | undefined, b: TranscoderResponse | PlainMessage<TranscoderResponse> | undefined): boolean {
    return proto3.util.equals(TranscoderResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.TranscoderRequestCommon
 */
export class TranscoderRequestCommon extends Message<TranscoderRequestCommon> {
  constructor(data?: PartialMessage<TranscoderRequestCommon>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.TranscoderRequestCommon";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscoderRequestCommon {
    return new TranscoderRequestCommon().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscoderRequestCommon {
    return new TranscoderRequestCommon().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscoderRequestCommon {
    return new TranscoderRequestCommon().fromJsonString(jsonString, options);
  }

  static equals(a: TranscoderRequestCommon | PlainMessage<TranscoderRequestCommon> | undefined, b: TranscoderRequestCommon | PlainMessage<TranscoderRequestCommon> | undefined): boolean {
    return proto3.util.equals(TranscoderRequestCommon, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.TranscoderResponseCommon
 */
export class TranscoderResponseCommon extends Message<TranscoderResponseCommon> {
  constructor(data?: PartialMessage<TranscoderResponseCommon>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.TranscoderResponseCommon";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscoderResponseCommon {
    return new TranscoderResponseCommon().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscoderResponseCommon {
    return new TranscoderResponseCommon().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscoderResponseCommon {
    return new TranscoderResponseCommon().fromJsonString(jsonString, options);
  }

  static equals(a: TranscoderResponseCommon | PlainMessage<TranscoderResponseCommon> | undefined, b: TranscoderResponseCommon | PlainMessage<TranscoderResponseCommon> | undefined): boolean {
    return proto3.util.equals(TranscoderResponseCommon, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleResourceTagRequest
 */
export class ModuleResourceTagRequest extends Message<ModuleResourceTagRequest> {
  /**
   * @generated from field: repeated geneo.content.db.ModuleResourceTag moduleResourceTag = 1;
   */
  moduleResourceTag: ModuleResourceTag[] = [];

  /**
   * @generated from field: repeated geneo.content.db.ModuleQuestionBankTag moduleQuestionBankTag = 2;
   */
  moduleQuestionBankTag: ModuleQuestionBankTag[] = [];

  /**
   * @generated from field: repeated geneo.content.db.ModuleResourceTag moduleHomeworkTag = 3;
   */
  moduleHomeworkTag: ModuleResourceTag[] = [];

  constructor(data?: PartialMessage<ModuleResourceTagRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleResourceTagRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "moduleResourceTag", kind: "message", T: ModuleResourceTag, repeated: true },
    { no: 2, name: "moduleQuestionBankTag", kind: "message", T: ModuleQuestionBankTag, repeated: true },
    { no: 3, name: "moduleHomeworkTag", kind: "message", T: ModuleResourceTag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleResourceTagRequest {
    return new ModuleResourceTagRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleResourceTagRequest {
    return new ModuleResourceTagRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleResourceTagRequest {
    return new ModuleResourceTagRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleResourceTagRequest | PlainMessage<ModuleResourceTagRequest> | undefined, b: ModuleResourceTagRequest | PlainMessage<ModuleResourceTagRequest> | undefined): boolean {
    return proto3.util.equals(ModuleResourceTagRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleResourceTagResponse
 */
export class ModuleResourceTagResponse extends Message<ModuleResourceTagResponse> {
  /**
   * @generated from field: repeated int32 modResTagIds = 1;
   */
  modResTagIds: number[] = [];

  /**
   * @generated from field: repeated int32 modQBTagIds = 2;
   */
  modQBTagIds: number[] = [];

  /**
   * @generated from field: repeated int32 modHWTagIds = 3;
   */
  modHWTagIds: number[] = [];

  constructor(data?: PartialMessage<ModuleResourceTagResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleResourceTagResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "modResTagIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 2, name: "modQBTagIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: "modHWTagIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleResourceTagResponse {
    return new ModuleResourceTagResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleResourceTagResponse {
    return new ModuleResourceTagResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleResourceTagResponse {
    return new ModuleResourceTagResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleResourceTagResponse | PlainMessage<ModuleResourceTagResponse> | undefined, b: ModuleResourceTagResponse | PlainMessage<ModuleResourceTagResponse> | undefined): boolean {
    return proto3.util.equals(ModuleResourceTagResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.Lesson
 */
export class Lesson extends Message<Lesson> {
  /**
   * Primary key for lesson plan
   *
   * @generated from field: optional int32 id = 1;
   */
  id?: number;

  /**
   * Referencing key for lesson plan
   *
   * @generated from field: optional string lessonId = 2;
   */
  lessonId?: string;

  /**
   * Title of the lesson plan
   *
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * Foreign key referencing content_management.course.course_id
   *
   * @generated from field: optional int32 courseId = 4;
   */
  courseId?: number;

  /**
   * Foreign key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 moduleId = 5;
   */
  moduleId?: number;

  /**
   * Estimated time for the lesson plan
   *
   * @generated from field: int32 estimatedTimeInMin = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * Array of resource IDs
   *
   * @generated from field: repeated string resourceIds = 7;
   */
  resourceIds: string[] = [];

  /**
   * Creation status of the lesson plan
   *
   * @generated from field: geneo.content.db.CreationStatusEnum creationStatus = 8;
   */
  creationStatus = CreationStatusEnum.CREATION_STATUS_UNDEFINED;

  /**
   * ID of the source lesson plan
   *
   * @generated from field: optional string sourceLessonId = 9;
   */
  sourceLessonId?: string;

  /**
   * Array of learning outcome IDs
   *
   * @generated from field: repeated string learningOutcomesIds = 10;
   */
  learningOutcomesIds: string[] = [];

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp createdOn = 11;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: int64 createdBy = 12;
   */
  createdBy = protoInt64.zero;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modifiedOn = 13;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: int64 modifiedBy = 14;
   */
  modifiedBy = protoInt64.zero;

  /**
   * @generated from field: optional string posterImageUrl = 15;
   */
  posterImageUrl?: string;

  /**
   * @generated from field: optional string posterImageName = 16;
   */
  posterImageName?: string;

  constructor(data?: PartialMessage<Lesson>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.Lesson";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "lessonId", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "courseId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "moduleId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "estimatedTimeInMin", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "resourceIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "creationStatus", kind: "enum", T: proto3.getEnumType(CreationStatusEnum) },
    { no: 9, name: "sourceLessonId", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "learningOutcomesIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "createdOn", kind: "message", T: Timestamp },
    { no: 12, name: "createdBy", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "modifiedOn", kind: "message", T: Timestamp },
    { no: 14, name: "modifiedBy", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "posterImageUrl", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "posterImageName", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Lesson {
    return new Lesson().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Lesson {
    return new Lesson().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Lesson {
    return new Lesson().fromJsonString(jsonString, options);
  }

  static equals(a: Lesson | PlainMessage<Lesson> | undefined, b: Lesson | PlainMessage<Lesson> | undefined): boolean {
    return proto3.util.equals(Lesson, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.LessonRequest
 */
export class LessonRequest extends Message<LessonRequest> {
  /**
   * @generated from field: repeated geneo.cms.content.parser.apis.Lesson lesson = 1;
   */
  lesson: Lesson[] = [];

  constructor(data?: PartialMessage<LessonRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.LessonRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson", kind: "message", T: Lesson, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonRequest {
    return new LessonRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonRequest {
    return new LessonRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonRequest {
    return new LessonRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LessonRequest | PlainMessage<LessonRequest> | undefined, b: LessonRequest | PlainMessage<LessonRequest> | undefined): boolean {
    return proto3.util.equals(LessonRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.LessonResponse
 */
export class LessonResponse extends Message<LessonResponse> {
  /**
   * @generated from field: repeated string lessonIds = 1;
   */
  lessonIds: string[] = [];

  constructor(data?: PartialMessage<LessonResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.LessonResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lessonIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonResponse {
    return new LessonResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonResponse {
    return new LessonResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonResponse {
    return new LessonResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LessonResponse | PlainMessage<LessonResponse> | undefined, b: LessonResponse | PlainMessage<LessonResponse> | undefined): boolean {
    return proto3.util.equals(LessonResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleLessonTagRequest
 */
export class ModuleLessonTagRequest extends Message<ModuleLessonTagRequest> {
  /**
   * @generated from field: repeated geneo.content.db.ModuleLessonTag moduleLessonTag = 1;
   */
  moduleLessonTag: ModuleLessonTag[] = [];

  constructor(data?: PartialMessage<ModuleLessonTagRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleLessonTagRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "moduleLessonTag", kind: "message", T: ModuleLessonTag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleLessonTagRequest {
    return new ModuleLessonTagRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleLessonTagRequest {
    return new ModuleLessonTagRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleLessonTagRequest {
    return new ModuleLessonTagRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleLessonTagRequest | PlainMessage<ModuleLessonTagRequest> | undefined, b: ModuleLessonTagRequest | PlainMessage<ModuleLessonTagRequest> | undefined): boolean {
    return proto3.util.equals(ModuleLessonTagRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleLessonTagResponse
 */
export class ModuleLessonTagResponse extends Message<ModuleLessonTagResponse> {
  /**
   * @generated from field: repeated int32 modLessTagIds = 1;
   */
  modLessTagIds: number[] = [];

  constructor(data?: PartialMessage<ModuleLessonTagResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleLessonTagResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "modLessTagIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleLessonTagResponse {
    return new ModuleLessonTagResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleLessonTagResponse {
    return new ModuleLessonTagResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleLessonTagResponse {
    return new ModuleLessonTagResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleLessonTagResponse | PlainMessage<ModuleLessonTagResponse> | undefined, b: ModuleLessonTagResponse | PlainMessage<ModuleLessonTagResponse> | undefined): boolean {
    return proto3.util.equals(ModuleLessonTagResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleBookContentRequest
 */
export class ModuleBookContentRequest extends Message<ModuleBookContentRequest> {
  /**
   * @generated from field: repeated geneo.content.db.ModuleBookContent moduleBookContent = 1;
   */
  moduleBookContent: ModuleBookContent[] = [];

  constructor(data?: PartialMessage<ModuleBookContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleBookContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "moduleBookContent", kind: "message", T: ModuleBookContent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleBookContentRequest {
    return new ModuleBookContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleBookContentRequest {
    return new ModuleBookContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleBookContentRequest {
    return new ModuleBookContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleBookContentRequest | PlainMessage<ModuleBookContentRequest> | undefined, b: ModuleBookContentRequest | PlainMessage<ModuleBookContentRequest> | undefined): boolean {
    return proto3.util.equals(ModuleBookContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleBookContentResponse
 */
export class ModuleBookContentResponse extends Message<ModuleBookContentResponse> {
  /**
   * @generated from field: repeated int32 bookModuleIds = 1;
   */
  bookModuleIds: number[] = [];

  constructor(data?: PartialMessage<ModuleBookContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleBookContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bookModuleIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleBookContentResponse {
    return new ModuleBookContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleBookContentResponse {
    return new ModuleBookContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleBookContentResponse {
    return new ModuleBookContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleBookContentResponse | PlainMessage<ModuleBookContentResponse> | undefined, b: ModuleBookContentResponse | PlainMessage<ModuleBookContentResponse> | undefined): boolean {
    return proto3.util.equals(ModuleBookContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.BMRequest
 */
export class BMRequest extends Message<BMRequest> {
  /**
   * @generated from field: int32 bookId = 2;
   */
  bookId = 0;

  constructor(data?: PartialMessage<BMRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.BMRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "bookId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BMRequest {
    return new BMRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BMRequest {
    return new BMRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BMRequest {
    return new BMRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BMRequest | PlainMessage<BMRequest> | undefined, b: BMRequest | PlainMessage<BMRequest> | undefined): boolean {
    return proto3.util.equals(BMRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.BMResponse
 */
export class BMResponse extends Message<BMResponse> {
  /**
   * @generated from field: repeated geneo.common.db.Book book = 1;
   */
  book: Book$1[] = [];

  /**
   * @generated from field: repeated geneo.content.db.Module module = 2;
   */
  module: Module[] = [];

  constructor(data?: PartialMessage<BMResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.BMResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book", kind: "message", T: Book$1, repeated: true },
    { no: 2, name: "module", kind: "message", T: Module, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BMResponse {
    return new BMResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BMResponse {
    return new BMResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BMResponse {
    return new BMResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BMResponse | PlainMessage<BMResponse> | undefined, b: BMResponse | PlainMessage<BMResponse> | undefined): boolean {
    return proto3.util.equals(BMResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.BookUrlRequest
 */
export class BookUrlRequest extends Message<BookUrlRequest> {
  /**
   * @generated from field: string parserVersion = 1;
   */
  parserVersion = "";

  constructor(data?: PartialMessage<BookUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.BookUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parserVersion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookUrlRequest {
    return new BookUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookUrlRequest {
    return new BookUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookUrlRequest {
    return new BookUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BookUrlRequest | PlainMessage<BookUrlRequest> | undefined, b: BookUrlRequest | PlainMessage<BookUrlRequest> | undefined): boolean {
    return proto3.util.equals(BookUrlRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.BookUrlResponse
 */
export class BookUrlResponse extends Message<BookUrlResponse> {
  /**
   * @generated from field: repeated geneo.common.db.Book book = 1;
   */
  book: Book$1[] = [];

  /**
   * @generated from field: string parserVersion = 2;
   */
  parserVersion = "";

  constructor(data?: PartialMessage<BookUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.BookUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book", kind: "message", T: Book$1, repeated: true },
    { no: 2, name: "parserVersion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookUrlResponse {
    return new BookUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookUrlResponse {
    return new BookUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookUrlResponse {
    return new BookUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BookUrlResponse | PlainMessage<BookUrlResponse> | undefined, b: BookUrlResponse | PlainMessage<BookUrlResponse> | undefined): boolean {
    return proto3.util.equals(BookUrlResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ContentRequest
 */
export class ContentRequest extends Message<ContentRequest> {
  /**
   * @generated from field: repeated geneo.content.db.Content content = 2;
   */
  content: Content[] = [];

  constructor(data?: PartialMessage<ContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "content", kind: "message", T: Content, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentRequest {
    return new ContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentRequest {
    return new ContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentRequest {
    return new ContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ContentRequest | PlainMessage<ContentRequest> | undefined, b: ContentRequest | PlainMessage<ContentRequest> | undefined): boolean {
    return proto3.util.equals(ContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.QuestionRequest
 */
export class QuestionRequest extends Message<QuestionRequest> {
  /**
   * @generated from field: repeated geneo.content.db.Question question = 1;
   */
  question: Question[] = [];

  constructor(data?: PartialMessage<QuestionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.QuestionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question", kind: "message", T: Question, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionRequest {
    return new QuestionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionRequest {
    return new QuestionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionRequest {
    return new QuestionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionRequest | PlainMessage<QuestionRequest> | undefined, b: QuestionRequest | PlainMessage<QuestionRequest> | undefined): boolean {
    return proto3.util.equals(QuestionRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ResourceContentRequest
 */
export class ResourceContentRequest extends Message<ResourceContentRequest> {
  /**
   * @generated from field: repeated geneo.content.db.ResourceContent resource_content = 3;
   */
  resourceContent: ResourceContent[] = [];

  constructor(data?: PartialMessage<ResourceContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ResourceContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "resource_content", kind: "message", T: ResourceContent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContentRequest {
    return new ResourceContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContentRequest {
    return new ResourceContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContentRequest {
    return new ResourceContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContentRequest | PlainMessage<ResourceContentRequest> | undefined, b: ResourceContentRequest | PlainMessage<ResourceContentRequest> | undefined): boolean {
    return proto3.util.equals(ResourceContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ContentResponse
 */
export class ContentResponse extends Message<ContentResponse> {
  /**
   * @generated from field: repeated string contentIds = 1;
   */
  contentIds: string[] = [];

  constructor(data?: PartialMessage<ContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contentIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentResponse {
    return new ContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentResponse {
    return new ContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentResponse {
    return new ContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ContentResponse | PlainMessage<ContentResponse> | undefined, b: ContentResponse | PlainMessage<ContentResponse> | undefined): boolean {
    return proto3.util.equals(ContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.QuestionResponse
 */
export class QuestionResponse extends Message<QuestionResponse> {
  /**
   * @generated from field: repeated string questionIds = 2;
   */
  questionIds: string[] = [];

  constructor(data?: PartialMessage<QuestionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.QuestionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "questionIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionResponse {
    return new QuestionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionResponse {
    return new QuestionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionResponse {
    return new QuestionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionResponse | PlainMessage<QuestionResponse> | undefined, b: QuestionResponse | PlainMessage<QuestionResponse> | undefined): boolean {
    return proto3.util.equals(QuestionResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ResourceContentResponseMap
 */
export class ResourceContentResponseMap extends Message<ResourceContentResponseMap> {
  /**
   * @generated from field: string resourceContentId = 1;
   */
  resourceContentId = "";

  /**
   * @generated from field: string resourceContentVersion = 2;
   */
  resourceContentVersion = "";

  constructor(data?: PartialMessage<ResourceContentResponseMap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ResourceContentResponseMap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resourceContentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resourceContentVersion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContentResponseMap {
    return new ResourceContentResponseMap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContentResponseMap {
    return new ResourceContentResponseMap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContentResponseMap {
    return new ResourceContentResponseMap().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContentResponseMap | PlainMessage<ResourceContentResponseMap> | undefined, b: ResourceContentResponseMap | PlainMessage<ResourceContentResponseMap> | undefined): boolean {
    return proto3.util.equals(ResourceContentResponseMap, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ResourceContentResponse
 */
export class ResourceContentResponse extends Message<ResourceContentResponse> {
  /**
   * @generated from field: repeated geneo.cms.content.parser.apis.ResourceContentResponseMap response = 1;
   */
  response: ResourceContentResponseMap[] = [];

  constructor(data?: PartialMessage<ResourceContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ResourceContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response", kind: "message", T: ResourceContentResponseMap, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceContentResponse {
    return new ResourceContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceContentResponse {
    return new ResourceContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceContentResponse {
    return new ResourceContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceContentResponse | PlainMessage<ResourceContentResponse> | undefined, b: ResourceContentResponse | PlainMessage<ResourceContentResponse> | undefined): boolean {
    return proto3.util.equals(ResourceContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ResourceRequest
 */
export class ResourceRequest extends Message<ResourceRequest> {
  /**
   * @generated from field: repeated geneo.content.db.Resource resource = 1;
   */
  resource: Resource[] = [];

  constructor(data?: PartialMessage<ResourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ResourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource", kind: "message", T: Resource, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceRequest {
    return new ResourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceRequest {
    return new ResourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceRequest {
    return new ResourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceRequest | PlainMessage<ResourceRequest> | undefined, b: ResourceRequest | PlainMessage<ResourceRequest> | undefined): boolean {
    return proto3.util.equals(ResourceRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ResourceResponse
 */
export class ResourceResponse extends Message<ResourceResponse> {
  /**
   * @generated from field: repeated string resourceIds = 1;
   */
  resourceIds: string[] = [];

  constructor(data?: PartialMessage<ResourceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ResourceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resourceIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceResponse {
    return new ResourceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceResponse {
    return new ResourceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceResponse {
    return new ResourceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceResponse | PlainMessage<ResourceResponse> | undefined, b: ResourceResponse | PlainMessage<ResourceResponse> | undefined): boolean {
    return proto3.util.equals(ResourceResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.LearningOutcomeRequest
 */
export class LearningOutcomeRequest extends Message<LearningOutcomeRequest> {
  /**
   * @generated from field: repeated geneo.content.db.LearningOutcome learningOutcome = 1;
   */
  learningOutcome: LearningOutcome[] = [];

  constructor(data?: PartialMessage<LearningOutcomeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.LearningOutcomeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "learningOutcome", kind: "message", T: LearningOutcome, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LearningOutcomeRequest {
    return new LearningOutcomeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LearningOutcomeRequest {
    return new LearningOutcomeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LearningOutcomeRequest {
    return new LearningOutcomeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LearningOutcomeRequest | PlainMessage<LearningOutcomeRequest> | undefined, b: LearningOutcomeRequest | PlainMessage<LearningOutcomeRequest> | undefined): boolean {
    return proto3.util.equals(LearningOutcomeRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.LearningOutcomeResponse
 */
export class LearningOutcomeResponse extends Message<LearningOutcomeResponse> {
  /**
   * @generated from field: repeated string learningOutcomeIds = 1;
   */
  learningOutcomeIds: string[] = [];

  constructor(data?: PartialMessage<LearningOutcomeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.LearningOutcomeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "learningOutcomeIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LearningOutcomeResponse {
    return new LearningOutcomeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LearningOutcomeResponse {
    return new LearningOutcomeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LearningOutcomeResponse {
    return new LearningOutcomeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LearningOutcomeResponse | PlainMessage<LearningOutcomeResponse> | undefined, b: LearningOutcomeResponse | PlainMessage<LearningOutcomeResponse> | undefined): boolean {
    return proto3.util.equals(LearningOutcomeResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.HomeworkRequest
 */
export class HomeworkRequest extends Message<HomeworkRequest> {
  /**
   * @generated from field: repeated geneo.lms.db.Task homework = 1;
   */
  homework: Task[] = [];

  constructor(data?: PartialMessage<HomeworkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.HomeworkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework", kind: "message", T: Task, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkRequest {
    return new HomeworkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkRequest {
    return new HomeworkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkRequest {
    return new HomeworkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkRequest | PlainMessage<HomeworkRequest> | undefined, b: HomeworkRequest | PlainMessage<HomeworkRequest> | undefined): boolean {
    return proto3.util.equals(HomeworkRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.HomeworkResponse
 */
export class HomeworkResponse extends Message<HomeworkResponse> {
  /**
   * @generated from field: repeated string homeworkIds = 1;
   */
  homeworkIds: string[] = [];

  constructor(data?: PartialMessage<HomeworkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.HomeworkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homeworkIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkResponse {
    return new HomeworkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkResponse {
    return new HomeworkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkResponse {
    return new HomeworkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkResponse | PlainMessage<HomeworkResponse> | undefined, b: HomeworkResponse | PlainMessage<HomeworkResponse> | undefined): boolean {
    return proto3.util.equals(HomeworkResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.Book
 */
export class Book extends Message<Book> {
  /**
   * @generated from field: optional int32 book_id = 1;
   */
  bookId?: number;

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * Author of the book
   *
   * @generated from field: string author = 3;
   */
  author = "";

  /**
   * Publisher of the book
   *
   * @generated from field: string publisher = 4;
   */
  publisher = "";

  /**
   * ISBN number of the book
   *
   * @generated from field: string isbnNo = 5;
   */
  isbnNo = "";

  /**
   * Year of publication
   *
   * @generated from field: string publishingYear = 6;
   */
  publishingYear = "";

  /**
   * Number of pages in the book
   *
   * @generated from field: int32 noOfPages = 7;
   */
  noOfPages = 0;

  /**
   * URL of the book's PDF
   *
   * @generated from field: string pdfUrl = 8;
   */
  pdfUrl = "";

  /**
   * URL of the book's cover image
   *
   * @generated from field: string coverImageUrl = 9;
   */
  coverImageUrl = "";

  /**
   * Version of the book
   *
   * @generated from field: int32 version = 10;
   */
  version = 0;

  /**
   * Foreign key referencing subject.subject_id
   *
   * @generated from field: optional geneo.common.db.Subject.SubjectEnum subject = 11;
   */
  subject?: Subject_SubjectEnum;

  /**
   * Foreign key referencing class.class_id
   *
   * @generated from field: optional geneo.common.db.Class.ClassEnum grade = 12;
   */
  grade?: Class_ClassEnum;

  /**
   * Foreign key referencing board.board_id
   *
   * @generated from field: optional geneo.common.db.Board.BoardEnum board = 13;
   */
  board?: Board_BoardEnum;

  /**
   * Foreign key referencing teach_medium.teach_medium_id
   *
   * @generated from field: optional string medium = 14;
   */
  medium?: string;

  /**
   * @generated from field: google.protobuf.Timestamp createdOn = 15;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional string gcpUrl = 16;
   */
  gcpUrl?: string;

  constructor(data?: PartialMessage<Book>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.Book";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "author", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "publisher", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "isbnNo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "publishingYear", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "noOfPages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "pdfUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "coverImageUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum), opt: true },
    { no: 12, name: "grade", kind: "enum", T: proto3.getEnumType(Class_ClassEnum), opt: true },
    { no: 13, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum), opt: true },
    { no: 14, name: "medium", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "createdOn", kind: "message", T: Timestamp },
    { no: 16, name: "gcpUrl", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Book {
    return new Book().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Book {
    return new Book().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Book {
    return new Book().fromJsonString(jsonString, options);
  }

  static equals(a: Book | PlainMessage<Book> | undefined, b: Book | PlainMessage<Book> | undefined): boolean {
    return proto3.util.equals(Book, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.BookRequest
 */
export class BookRequest extends Message<BookRequest> {
  /**
   * @generated from field: repeated geneo.cms.content.parser.apis.Book books = 1;
   */
  books: Book[] = [];

  /**
   * @generated from field: repeated geneo.content.db.Module modules = 2;
   */
  modules: Module[] = [];

  /**
   * @generated from field: repeated geneo.content.db.ModuleBookContent moduleBookContents = 3;
   */
  moduleBookContents: ModuleBookContent[] = [];

  constructor(data?: PartialMessage<BookRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.BookRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "books", kind: "message", T: Book, repeated: true },
    { no: 2, name: "modules", kind: "message", T: Module, repeated: true },
    { no: 3, name: "moduleBookContents", kind: "message", T: ModuleBookContent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookRequest {
    return new BookRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookRequest {
    return new BookRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookRequest {
    return new BookRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BookRequest | PlainMessage<BookRequest> | undefined, b: BookRequest | PlainMessage<BookRequest> | undefined): boolean {
    return proto3.util.equals(BookRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.BookResponse
 */
export class BookResponse extends Message<BookResponse> {
  /**
   * @generated from field: repeated int32 bookIds = 1;
   */
  bookIds: number[] = [];

  constructor(data?: PartialMessage<BookResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.BookResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bookIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookResponse {
    return new BookResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookResponse {
    return new BookResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookResponse {
    return new BookResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BookResponse | PlainMessage<BookResponse> | undefined, b: BookResponse | PlainMessage<BookResponse> | undefined): boolean {
    return proto3.util.equals(BookResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.module
 */
export class module extends Message<module> {
  /**
   * @generated from field: optional int32 module_id = 1;
   */
  moduleId?: number;

  /**
   * Name of the module
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string posterImageUrl = 3;
   */
  posterImageUrl = "";

  /**
   * Short description of the module
   *
   * @generated from field: string shortDescription = 4;
   */
  shortDescription = "";

  /**
   * Long description of the module
   *
   * @generated from field: string longDescription = 5;
   */
  longDescription = "";

  /**
   * Foreign key referencing content_management.module.module_id
   *
   * @generated from field: optional int32 parentModuleId = 6;
   */
  parentModuleId?: number;

  /**
   * Foreign key referencing common.book.book_id
   *
   * @generated from field: optional int32 bookId = 7;
   */
  bookId?: number;

  /**
   * Category of the module
   *
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 8;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * Level of the module
   *
   * @generated from field: int32 level = 9;
   */
  level = 0;

  /**
   * @generated from field: int32 rank = 10;
   */
  rank = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp createdOn = 11;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 createdBy = 12;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modifiedOn = 13;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modifiedBy = 14;
   */
  modifiedBy?: bigint;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional string coverImageName = 15;
   */
  coverImageName?: string;

  constructor(data?: PartialMessage<module>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.module";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "posterImageUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "shortDescription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "longDescription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "parentModuleId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "bookId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 9, name: "level", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "createdOn", kind: "message", T: Timestamp },
    { no: 12, name: "createdBy", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "modifiedOn", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "modifiedBy", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 15, name: "coverImageName", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): module {
    return new module().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): module {
    return new module().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): module {
    return new module().fromJsonString(jsonString, options);
  }

  static equals(a: module | PlainMessage<module> | undefined, b: module | PlainMessage<module> | undefined): boolean {
    return proto3.util.equals(module, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleRequest
 */
export class ModuleRequest extends Message<ModuleRequest> {
  /**
   * @generated from field: repeated geneo.cms.content.parser.apis.module modules = 2;
   */
  modules: module[] = [];

  constructor(data?: PartialMessage<ModuleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "modules", kind: "message", T: module, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleRequest {
    return new ModuleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleRequest {
    return new ModuleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleRequest {
    return new ModuleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleRequest | PlainMessage<ModuleRequest> | undefined, b: ModuleRequest | PlainMessage<ModuleRequest> | undefined): boolean {
    return proto3.util.equals(ModuleRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.ModuleResponse
 */
export class ModuleResponse extends Message<ModuleResponse> {
  /**
   * @generated from field: repeated int32 moduleIds = 1;
   */
  moduleIds: number[] = [];

  constructor(data?: PartialMessage<ModuleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.ModuleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "moduleIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleResponse {
    return new ModuleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleResponse {
    return new ModuleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleResponse {
    return new ModuleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleResponse | PlainMessage<ModuleResponse> | undefined, b: ModuleResponse | PlainMessage<ModuleResponse> | undefined): boolean {
    return proto3.util.equals(ModuleResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.QuestionBankRequest
 */
export class QuestionBankRequest extends Message<QuestionBankRequest> {
  /**
   * @generated from field: repeated geneo.content.db.QuestionBank questionBank = 1;
   */
  questionBank: QuestionBank[] = [];

  constructor(data?: PartialMessage<QuestionBankRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.QuestionBankRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionBank", kind: "message", T: QuestionBank, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionBankRequest {
    return new QuestionBankRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionBankRequest {
    return new QuestionBankRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionBankRequest {
    return new QuestionBankRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionBankRequest | PlainMessage<QuestionBankRequest> | undefined, b: QuestionBankRequest | PlainMessage<QuestionBankRequest> | undefined): boolean {
    return proto3.util.equals(QuestionBankRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.QuestionBankResponse
 */
export class QuestionBankResponse extends Message<QuestionBankResponse> {
  /**
   * @generated from field: repeated string questionBankIds = 1;
   */
  questionBankIds: string[] = [];

  constructor(data?: PartialMessage<QuestionBankResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.QuestionBankResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionBankIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionBankResponse {
    return new QuestionBankResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionBankResponse {
    return new QuestionBankResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionBankResponse {
    return new QuestionBankResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionBankResponse | PlainMessage<QuestionBankResponse> | undefined, b: QuestionBankResponse | PlainMessage<QuestionBankResponse> | undefined): boolean {
    return proto3.util.equals(QuestionBankResponse, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.InstructionRequest
 */
export class InstructionRequest extends Message<InstructionRequest> {
  /**
   * @generated from field: repeated geneo.content.db.ResourceTeacherInstruction instruction = 1;
   */
  instruction: ResourceTeacherInstruction[] = [];

  constructor(data?: PartialMessage<InstructionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.InstructionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instruction", kind: "message", T: ResourceTeacherInstruction, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstructionRequest {
    return new InstructionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstructionRequest {
    return new InstructionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstructionRequest {
    return new InstructionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InstructionRequest | PlainMessage<InstructionRequest> | undefined, b: InstructionRequest | PlainMessage<InstructionRequest> | undefined): boolean {
    return proto3.util.equals(InstructionRequest, a, b);
  }
}

/**
 * @generated from message geneo.cms.content.parser.apis.InstructionResponse
 */
export class InstructionResponse extends Message<InstructionResponse> {
  /**
   * @generated from field: repeated string instructionIds = 1;
   */
  instructionIds: string[] = [];

  constructor(data?: PartialMessage<InstructionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.cms.content.parser.apis.InstructionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instructionIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstructionResponse {
    return new InstructionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstructionResponse {
    return new InstructionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstructionResponse {
    return new InstructionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InstructionResponse | PlainMessage<InstructionResponse> | undefined, b: InstructionResponse | PlainMessage<InstructionResponse> | undefined): boolean {
    return proto3.util.equals(InstructionResponse, a, b);
  }
}

