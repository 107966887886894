// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.assessment.teacher.apis.proto (package geneo.lms.assessment.teacher.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { AssessmentEnum, AssignmentInfo, CloudUpload, SubmissionType, TaskCreationStatusEnum, TaskEnum } from "@protos/learning_management/lms.db_pb";
import { AssessmentListEnum } from "@protos/learning_management/lms.assessment.student.apis_pb";
import { HomeworkTask } from "@protos/learning_management/lms.hw.common.apis_pb";
import { Module_ModuleCategoryEnum } from "@protos/content_management/content.db_pb";
import { AssessmentTask } from "@protos/learning_management/lms.assessment.common.apis_pb";

/**
 * @generated from message geneo.lms.assessment.teacher.apis.GetTeacherAssessmentsListRequest
 */
export class GetTeacherAssessmentsListRequest extends Message<GetTeacherAssessmentsListRequest> {
  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the course (optional)
   *
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * @generated from field: optional int32 section_id = 3;
   */
  sectionId?: number;

  /**
   * @generated from field: optional int32 page_number = 5;
   */
  pageNumber?: number;

  /**
   * @generated from field: optional int32 items_per_page = 6;
   */
  itemsPerPage?: number;

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 7;
   */
  assessmentType?: AssessmentEnum;

  /**
   * @generated from field: optional geneo.lms.assessment.student.apis.AssessmentListEnum assessment_list_type = 8;
   */
  assessmentListType?: AssessmentListEnum;

  constructor(data?: PartialMessage<GetTeacherAssessmentsListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetTeacherAssessmentsListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "items_per_page", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 8, name: "assessment_list_type", kind: "enum", T: proto3.getEnumType(AssessmentListEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeacherAssessmentsListRequest {
    return new GetTeacherAssessmentsListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListRequest {
    return new GetTeacherAssessmentsListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListRequest {
    return new GetTeacherAssessmentsListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeacherAssessmentsListRequest | PlainMessage<GetTeacherAssessmentsListRequest> | undefined, b: GetTeacherAssessmentsListRequest | PlainMessage<GetTeacherAssessmentsListRequest> | undefined): boolean {
    return proto3.util.equals(GetTeacherAssessmentsListRequest, a, b);
  }
}

/**
 * Response model for /teacher/homework/list
 *
 * @generated from message geneo.lms.assessment.teacher.apis.GetTeacherAssessmentsListResponse
 */
export class GetTeacherAssessmentsListResponse extends Message<GetTeacherAssessmentsListResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.TeacherAssessmentsList assessment_list = 4;
   */
  assessmentList?: TeacherAssessmentsList;

  constructor(data?: PartialMessage<GetTeacherAssessmentsListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetTeacherAssessmentsListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "assessment_list", kind: "message", T: TeacherAssessmentsList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeacherAssessmentsListResponse {
    return new GetTeacherAssessmentsListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListResponse {
    return new GetTeacherAssessmentsListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListResponse {
    return new GetTeacherAssessmentsListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeacherAssessmentsListResponse | PlainMessage<GetTeacherAssessmentsListResponse> | undefined, b: GetTeacherAssessmentsListResponse | PlainMessage<GetTeacherAssessmentsListResponse> | undefined): boolean {
    return proto3.util.equals(GetTeacherAssessmentsListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentsList
 */
export class TeacherAssessmentsList extends Message<TeacherAssessmentsList> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * @generated from field: optional int32 section_id = 3;
   */
  sectionId?: number;

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.AssessmentList assessment_list = 6;
   */
  assessmentList?: AssessmentList;

  constructor(data?: PartialMessage<TeacherAssessmentsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "assessment_list", kind: "message", T: AssessmentList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentsList {
    return new TeacherAssessmentsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentsList {
    return new TeacherAssessmentsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentsList {
    return new TeacherAssessmentsList().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentsList | PlainMessage<TeacherAssessmentsList> | undefined, b: TeacherAssessmentsList | PlainMessage<TeacherAssessmentsList> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentsList, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentList
 */
export class AssessmentList extends Message<AssessmentList> {
  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask draft = 1;
   */
  draft: HomeworkTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask assigned = 2;
   */
  assigned: HomeworkTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask ended = 3;
   */
  ended: HomeworkTask[] = [];

  /**
   * @generated from field: optional geneo.lms.assessment.teacher.apis.AssessmentListCountInfo assesment_list_info = 4;
   */
  assesmentListInfo?: AssessmentListCountInfo;

  constructor(data?: PartialMessage<AssessmentList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft", kind: "message", T: HomeworkTask, repeated: true },
    { no: 2, name: "assigned", kind: "message", T: HomeworkTask, repeated: true },
    { no: 3, name: "ended", kind: "message", T: HomeworkTask, repeated: true },
    { no: 4, name: "assesment_list_info", kind: "message", T: AssessmentListCountInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentList {
    return new AssessmentList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentList {
    return new AssessmentList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentList {
    return new AssessmentList().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentList | PlainMessage<AssessmentList> | undefined, b: AssessmentList | PlainMessage<AssessmentList> | undefined): boolean {
    return proto3.util.equals(AssessmentList, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentListCountInfo
 */
export class AssessmentListCountInfo extends Message<AssessmentListCountInfo> {
  /**
   * @generated from field: int32 total_count = 1;
   */
  totalCount = 0;

  /**
   * @generated from field: int32 draft_count = 2;
   */
  draftCount = 0;

  /**
   * @generated from field: int32 active_count = 3;
   */
  activeCount = 0;

  /**
   * @generated from field: int32 ended_count = 4;
   */
  endedCount = 0;

  /**
   * @generated from field: int32 count_in_pagination = 5;
   */
  countInPagination = 0;

  constructor(data?: PartialMessage<AssessmentListCountInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentListCountInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "draft_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "active_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "ended_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "count_in_pagination", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentListCountInfo {
    return new AssessmentListCountInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentListCountInfo {
    return new AssessmentListCountInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentListCountInfo {
    return new AssessmentListCountInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentListCountInfo | PlainMessage<AssessmentListCountInfo> | undefined, b: AssessmentListCountInfo | PlainMessage<AssessmentListCountInfo> | undefined): boolean {
    return proto3.util.equals(AssessmentListCountInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentCreationSubmitRequest
 */
export class AssessmentCreationSubmitRequest extends Message<AssessmentCreationSubmitRequest> {
  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the course
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * ID of the school class section
   *
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * Name of the task
   *
   * @generated from field: string task_name = 4;
   */
  taskName = "";

  /**
   * ID of the chapter
   *
   * @generated from field: int32 module_id = 5;
   */
  moduleId = 0;

  /**
   * ID of the topic
   *
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 6;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * Information about selected tasks
   *
   * @generated from field: repeated geneo.lms.assessment.teacher.apis.TaskInfoModel selected_tasks_info = 7;
   */
  selectedTasksInfo: TaskInfoModel[] = [];

  /**
   * ID of the homework (optional)
   *
   * @generated from field: optional int32 assessment_id = 8;
   */
  assessmentId?: number;

  /**
   * Instructions for the homework (optional)
   *
   * @generated from field: repeated string instructions = 9;
   */
  instructions: string[] = [];

  /**
   * Status of the creation process
   *
   * @generated from field: geneo.lms.db.TaskCreationStatusEnum creation_status = 10;
   */
  creationStatus = TaskCreationStatusEnum.TASK_CREATION_STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.lms.db.TaskEnum task_type = 11;
   */
  taskType = TaskEnum.TASK_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload task_uploads = 12;
   */
  taskUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional float max_marks = 13;
   */
  maxMarks?: number;

  /**
   * Only for teacher created CUSTOM hws
   *
   * @generated from field: optional geneo.lms.db.SubmissionType submission_type = 14;
   */
  submissionType?: SubmissionType;

  /**
   * @generated from field: optional int32 source_geneo_assessment_id = 15;
   */
  sourceGeneoAssessmentId?: number;

  /**
   * @generated from field: repeated int32 included_chapter_ids = 16;
   */
  includedChapterIds: number[] = [];

  /**
   * @generated from field: repeated int32 included_topic_ids = 17;
   */
  includedTopicIds: number[] = [];

  constructor(data?: PartialMessage<AssessmentCreationSubmitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentCreationSubmitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "task_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 7, name: "selected_tasks_info", kind: "message", T: TaskInfoModel, repeated: true },
    { no: 8, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "creation_status", kind: "enum", T: proto3.getEnumType(TaskCreationStatusEnum) },
    { no: 11, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum) },
    { no: 12, name: "task_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 13, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 14, name: "submission_type", kind: "enum", T: proto3.getEnumType(SubmissionType), opt: true },
    { no: 15, name: "source_geneo_assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 16, name: "included_chapter_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 17, name: "included_topic_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentCreationSubmitRequest {
    return new AssessmentCreationSubmitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentCreationSubmitRequest {
    return new AssessmentCreationSubmitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentCreationSubmitRequest {
    return new AssessmentCreationSubmitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentCreationSubmitRequest | PlainMessage<AssessmentCreationSubmitRequest> | undefined, b: AssessmentCreationSubmitRequest | PlainMessage<AssessmentCreationSubmitRequest> | undefined): boolean {
    return proto3.util.equals(AssessmentCreationSubmitRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TaskInfoModel
 */
export class TaskInfoModel extends Message<TaskInfoModel> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  /**
   * @generated from field: int32 sequence = 2;
   */
  sequence = 0;

  constructor(data?: PartialMessage<TaskInfoModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TaskInfoModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sequence", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskInfoModel {
    return new TaskInfoModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskInfoModel {
    return new TaskInfoModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskInfoModel {
    return new TaskInfoModel().fromJsonString(jsonString, options);
  }

  static equals(a: TaskInfoModel | PlainMessage<TaskInfoModel> | undefined, b: TaskInfoModel | PlainMessage<TaskInfoModel> | undefined): boolean {
    return proto3.util.equals(TaskInfoModel, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentCreationSubmitResponse
 */
export class AssessmentCreationSubmitResponse extends Message<AssessmentCreationSubmitResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.TeacherAssessmentCreationSubmit data = 4;
   */
  data?: TeacherAssessmentCreationSubmit;

  constructor(data?: PartialMessage<AssessmentCreationSubmitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentCreationSubmitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherAssessmentCreationSubmit },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentCreationSubmitResponse {
    return new AssessmentCreationSubmitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentCreationSubmitResponse {
    return new AssessmentCreationSubmitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentCreationSubmitResponse {
    return new AssessmentCreationSubmitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentCreationSubmitResponse | PlainMessage<AssessmentCreationSubmitResponse> | undefined, b: AssessmentCreationSubmitResponse | PlainMessage<AssessmentCreationSubmitResponse> | undefined): boolean {
    return proto3.util.equals(AssessmentCreationSubmitResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentCreationSubmit
 */
export class TeacherAssessmentCreationSubmit extends Message<TeacherAssessmentCreationSubmit> {
  /**
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentTask assessment = 1;
   */
  assessment?: AssessmentTask;

  constructor(data?: PartialMessage<TeacherAssessmentCreationSubmit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentCreationSubmit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment", kind: "message", T: AssessmentTask },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentCreationSubmit {
    return new TeacherAssessmentCreationSubmit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentCreationSubmit {
    return new TeacherAssessmentCreationSubmit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentCreationSubmit {
    return new TeacherAssessmentCreationSubmit().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentCreationSubmit | PlainMessage<TeacherAssessmentCreationSubmit> | undefined, b: TeacherAssessmentCreationSubmit | PlainMessage<TeacherAssessmentCreationSubmit> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentCreationSubmit, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentAssignmentSubmitRequest
 */
export class AssessmentAssignmentSubmitRequest extends Message<AssessmentAssignmentSubmitRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 assessment_id = 2;
   */
  assessmentId = 0;

  /**
   * @generated from field: google.protobuf.Timestamp start_time = 3;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 4;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp daily_reminder_time = 5;
   */
  dailyReminderTime?: Timestamp;

  /**
   * @generated from field: repeated string assessment_instructions = 6;
   */
  assessmentInstructions: string[] = [];

  /**
   * @generated from field: repeated int32 section_ids = 7;
   */
  sectionIds: number[] = [];

  /**
   * @generated from field: string assessment_name = 8;
   */
  assessmentName = "";

  /**
   * @generated from field: repeated geneo.lms.db.AssignmentInfo assignment_info = 9;
   */
  assignmentInfo: AssignmentInfo[] = [];

  constructor(data?: PartialMessage<AssessmentAssignmentSubmitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentAssignmentSubmitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 4, name: "end_time", kind: "message", T: Timestamp },
    { no: 5, name: "daily_reminder_time", kind: "message", T: Timestamp },
    { no: 6, name: "assessment_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "section_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 8, name: "assessment_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "assignment_info", kind: "message", T: AssignmentInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentAssignmentSubmitRequest {
    return new AssessmentAssignmentSubmitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentAssignmentSubmitRequest {
    return new AssessmentAssignmentSubmitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentAssignmentSubmitRequest {
    return new AssessmentAssignmentSubmitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentAssignmentSubmitRequest | PlainMessage<AssessmentAssignmentSubmitRequest> | undefined, b: AssessmentAssignmentSubmitRequest | PlainMessage<AssessmentAssignmentSubmitRequest> | undefined): boolean {
    return proto3.util.equals(AssessmentAssignmentSubmitRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentAssignmentSubmitResponse
 */
export class AssessmentAssignmentSubmitResponse extends Message<AssessmentAssignmentSubmitResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.TeacherAssessAssignmentSubmit data = 4;
   */
  data?: TeacherAssessAssignmentSubmit;

  constructor(data?: PartialMessage<AssessmentAssignmentSubmitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentAssignmentSubmitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherAssessAssignmentSubmit },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentAssignmentSubmitResponse {
    return new AssessmentAssignmentSubmitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentAssignmentSubmitResponse {
    return new AssessmentAssignmentSubmitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentAssignmentSubmitResponse {
    return new AssessmentAssignmentSubmitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentAssignmentSubmitResponse | PlainMessage<AssessmentAssignmentSubmitResponse> | undefined, b: AssessmentAssignmentSubmitResponse | PlainMessage<AssessmentAssignmentSubmitResponse> | undefined): boolean {
    return proto3.util.equals(AssessmentAssignmentSubmitResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessAssignmentSubmit
 */
export class TeacherAssessAssignmentSubmit extends Message<TeacherAssessAssignmentSubmit> {
  /**
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentTask updated_Assessment = 1;
   */
  updatedAssessment?: AssessmentTask;

  constructor(data?: PartialMessage<TeacherAssessAssignmentSubmit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessAssignmentSubmit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updated_Assessment", kind: "message", T: AssessmentTask },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessAssignmentSubmit {
    return new TeacherAssessAssignmentSubmit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessAssignmentSubmit {
    return new TeacherAssessAssignmentSubmit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessAssignmentSubmit {
    return new TeacherAssessAssignmentSubmit().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessAssignmentSubmit | PlainMessage<TeacherAssessAssignmentSubmit> | undefined, b: TeacherAssessAssignmentSubmit | PlainMessage<TeacherAssessAssignmentSubmit> | undefined): boolean {
    return proto3.util.equals(TeacherAssessAssignmentSubmit, a, b);
  }
}

