// import '@fontsource/lato';
import { getEnvConfig, theme } from '@geneo2-web/shared-ui';
import { ThemeProvider } from '@mui/material';
import * as ReactDOM from 'react-dom/client';

import '@fontsource/lato';
import '@fontsource/poppins';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// import ManageSession from '../../../libs/shared-ui/src/commonUtils/manageSession';
import { useEffect } from 'react';
import { GlobalContextProvider } from './app/Context/GlobalContextProvider';
import { persistor, store } from './reduxStore/store';
import { router } from './routeHandling/Routes/Routes';
import registerServiceWorker from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const envConfig = getEnvConfig();

Sentry.init({
  environment: `student-frontend-${process.env.NX_GENEO_ENV}`,
  release: process.env.NX_GENEO_FRONTEND_VERSION,
  dsn: envConfig.sentry.dsn,
  integrations: [
    Sentry.browserTracingIntegration({
      traceFetch: false,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      mask: [],
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: envConfig.sentry.enabled,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [process.env.NX_GENEO_BACKEND_URL || ''],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'auth' || event.key === 'userId') {
        console.log(
          'Auth / userId key updated in another tab:',
          event.newValue
        );
        // Refresh the page or perform necessary updates
        window.location.reload();
      }
    };

    // Add event listener
    window.addEventListener('storage', handleStorageChange);

    // Cleanup event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalContextProvider>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </GlobalContextProvider>
      </PersistGate>
    </Provider>
  );
};

root.render(<App />);

registerServiceWorker();
