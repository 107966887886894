// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.assessment.common.apis.proto (package geneo.lms.assessment.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";
import { AssessmentEnum, AssignmentInfo, CloudUpload, SubmissionType, TaskActiveStatusEnum, TaskCreationStatusEnum, TaskEnum, TaskInfo } from "@protos/learning_management/lms.db_pb";
import { HomeworkTask } from "@protos/learning_management/lms.hw.common.apis_pb";
import { DifficultyLevelEnum, Module_ModuleCategoryEnum, Question } from "@protos/content_management/content.db_pb";

/**
 * @generated from message geneo.lms.assessment.common.apis.FetchAssessmentsByModuleRequest
 */
export class FetchAssessmentsByModuleRequest extends Message<FetchAssessmentsByModuleRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: optional int32 chapter_id = 4;
   */
  chapterId?: number;

  /**
   * @generated from field: optional int32 topic_id = 5;
   */
  topicId?: number;

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 6;
   */
  assessmentType?: AssessmentEnum;

  /**
   * @generated from field: optional int32 page_number = 7;
   */
  pageNumber?: number;

  constructor(data?: PartialMessage<FetchAssessmentsByModuleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.common.apis.FetchAssessmentsByModuleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 7, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentsByModuleRequest {
    return new FetchAssessmentsByModuleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentsByModuleRequest {
    return new FetchAssessmentsByModuleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentsByModuleRequest {
    return new FetchAssessmentsByModuleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentsByModuleRequest | PlainMessage<FetchAssessmentsByModuleRequest> | undefined, b: FetchAssessmentsByModuleRequest | PlainMessage<FetchAssessmentsByModuleRequest> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentsByModuleRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.common.apis.FetchAssessmentsByModuleResponse
 */
export class FetchAssessmentsByModuleResponse extends Message<FetchAssessmentsByModuleResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentsByType data = 4;
   */
  data?: AssessmentsByType;

  constructor(data?: PartialMessage<FetchAssessmentsByModuleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.common.apis.FetchAssessmentsByModuleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: AssessmentsByType },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentsByModuleResponse {
    return new FetchAssessmentsByModuleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentsByModuleResponse {
    return new FetchAssessmentsByModuleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentsByModuleResponse {
    return new FetchAssessmentsByModuleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentsByModuleResponse | PlainMessage<FetchAssessmentsByModuleResponse> | undefined, b: FetchAssessmentsByModuleResponse | PlainMessage<FetchAssessmentsByModuleResponse> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentsByModuleResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.common.apis.AssessmentsByType
 */
export class AssessmentsByType extends Message<AssessmentsByType> {
  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask assessments = 1;
   */
  assessments: HomeworkTask[] = [];

  /**
   * @generated from field: optional geneo.lms.assessment.common.apis.AssessmentListInfo assessment_list_info = 2;
   */
  assessmentListInfo?: AssessmentListInfo;

  constructor(data?: PartialMessage<AssessmentsByType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.common.apis.AssessmentsByType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessments", kind: "message", T: HomeworkTask, repeated: true },
    { no: 2, name: "assessment_list_info", kind: "message", T: AssessmentListInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentsByType {
    return new AssessmentsByType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentsByType {
    return new AssessmentsByType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentsByType {
    return new AssessmentsByType().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentsByType | PlainMessage<AssessmentsByType> | undefined, b: AssessmentsByType | PlainMessage<AssessmentsByType> | undefined): boolean {
    return proto3.util.equals(AssessmentsByType, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.common.apis.AssessmentListInfo
 */
export class AssessmentListInfo extends Message<AssessmentListInfo> {
  /**
   * @generated from field: int32 total_assessments_count = 1;
   */
  totalAssessmentsCount = 0;

  /**
   * @generated from field: int32 homeworks_count = 2;
   */
  homeworksCount = 0;

  /**
   * @generated from field: int32 formative_assessments_count = 3;
   */
  formativeAssessmentsCount = 0;

  /**
   * @generated from field: int32 diagnostics_assessments = 4;
   */
  diagnosticsAssessments = 0;

  /**
   * @generated from field: int32 count_in_pagination = 5;
   */
  countInPagination = 0;

  constructor(data?: PartialMessage<AssessmentListInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.common.apis.AssessmentListInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_assessments_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "homeworks_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "formative_assessments_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "diagnostics_assessments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "count_in_pagination", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentListInfo {
    return new AssessmentListInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentListInfo {
    return new AssessmentListInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentListInfo {
    return new AssessmentListInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentListInfo | PlainMessage<AssessmentListInfo> | undefined, b: AssessmentListInfo | PlainMessage<AssessmentListInfo> | undefined): boolean {
    return proto3.util.equals(AssessmentListInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.common.apis.FetchAssessmentContentRequest
 */
export class FetchAssessmentContentRequest extends Message<FetchAssessmentContentRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int32 assessment_id = 3;
   */
  assessmentId = 0;

  constructor(data?: PartialMessage<FetchAssessmentContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.common.apis.FetchAssessmentContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentContentRequest {
    return new FetchAssessmentContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentContentRequest {
    return new FetchAssessmentContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentContentRequest {
    return new FetchAssessmentContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentContentRequest | PlainMessage<FetchAssessmentContentRequest> | undefined, b: FetchAssessmentContentRequest | PlainMessage<FetchAssessmentContentRequest> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentContentRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.common.apis.FetchAssessmentContentResponse
 */
export class FetchAssessmentContentResponse extends Message<FetchAssessmentContentResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentContent data = 4;
   */
  data?: AssessmentContent;

  constructor(data?: PartialMessage<FetchAssessmentContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.common.apis.FetchAssessmentContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: AssessmentContent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentContentResponse {
    return new FetchAssessmentContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentContentResponse {
    return new FetchAssessmentContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentContentResponse {
    return new FetchAssessmentContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentContentResponse | PlainMessage<FetchAssessmentContentResponse> | undefined, b: FetchAssessmentContentResponse | PlainMessage<FetchAssessmentContentResponse> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentContentResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.common.apis.AssessmentContent
 */
export class AssessmentContent extends Message<AssessmentContent> {
  /**
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentTask assessment = 1;
   */
  assessment?: AssessmentTask;

  /**
   * @generated from field: geneo.lms.db.TaskInfo questions_sequence_info = 2;
   */
  questionsSequenceInfo?: TaskInfo;

  /**
   * @generated from field: repeated geneo.content.db.Question assessment_content = 3;
   */
  assessmentContent: Question[] = [];

  constructor(data?: PartialMessage<AssessmentContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.common.apis.AssessmentContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment", kind: "message", T: AssessmentTask },
    { no: 2, name: "questions_sequence_info", kind: "message", T: TaskInfo },
    { no: 3, name: "assessment_content", kind: "message", T: Question, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentContent {
    return new AssessmentContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentContent {
    return new AssessmentContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentContent {
    return new AssessmentContent().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentContent | PlainMessage<AssessmentContent> | undefined, b: AssessmentContent | PlainMessage<AssessmentContent> | undefined): boolean {
    return proto3.util.equals(AssessmentContent, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.common.apis.AssessmentTask
 */
export class AssessmentTask extends Message<AssessmentTask> {
  /**
   * ID of the homework
   *
   * @generated from field: int32 assessment_id = 1;
   */
  assessmentId = 0;

  /**
   * Title of the homework
   *
   * @generated from field: string assessment_title = 2;
   */
  assessmentTitle = "";

  /**
   * @generated from field: int32 class_id = 3;
   */
  classId = 0;

  /**
   * Class to which the homework is assigned
   *
   * @generated from field: string class = 4;
   */
  class = "";

  /**
   * @generated from field: optional int32 sectionId = 5;
   */
  sectionId?: number;

  /**
   * Section to which the homework is assigned
   *
   * @generated from field: optional string section = 6;
   */
  section?: string;

  /**
   * ID of the course
   *
   * @generated from field: int32 subject_id = 7;
   */
  subjectId = 0;

  /**
   * Subject of the homework
   *
   * @generated from field: string subject = 8;
   */
  subject = "";

  /**
   * ID of the module
   *
   * @generated from field: int32 module_id = 9;
   */
  moduleId = 0;

  /**
   * @generated from field: string module_name = 10;
   */
  moduleName = "";

  /**
   * @generated from field: optional int32 chapter_id = 31;
   */
  chapterId?: number;

  /**
   * ID of the module
   *
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 11;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * ID of the teacher
   *
   * @generated from field: optional int64 teacher_id = 12;
   */
  teacherId?: bigint;

  /**
   * Name of the teacher
   *
   * @generated from field: optional string teacher_name = 13;
   */
  teacherName?: string;

  /**
   * URL of the teacher's profile image
   *
   * @generated from field: optional string teacher_profile_image_url = 14;
   */
  teacherProfileImageUrl?: string;

  /**
   * Description of the homework
   *
   * @generated from field: string description = 15;
   */
  description = "";

  /**
   * URL of the homework poster image
   *
   * @generated from field: string assessment_poster_img_url = 16;
   */
  assessmentPosterImgUrl = "";

  /**
   * Difficulty level of the homework
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 17;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * Estimated time for which the homework is assigned
   *
   * @generated from field: float estimated_time_in_min = 18;
   */
  estimatedTimeInMin = 0;

  /**
   * Creation status of the task
   *
   * @generated from field: geneo.lms.db.TaskCreationStatusEnum task_creation_status = 19;
   */
  taskCreationStatus = TaskCreationStatusEnum.TASK_CREATION_STATUS_UNDEFINED;

  /**
   * Active status of the task
   *
   * @generated from field: geneo.lms.db.TaskActiveStatusEnum task_active_status = 20;
   */
  taskActiveStatus = TaskActiveStatusEnum.TASK_ACTIVE_STATUS_UNDEFINED;

  /**
   * Instructions for the homework
   *
   * @generated from field: repeated string assessment_instructions = 21;
   */
  assessmentInstructions: string[] = [];

  /**
   * Content info of the homework
   *
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentContentInfo assessment_content_info = 22;
   */
  assessmentContentInfo?: AssessmentContentInfo;

  /**
   * Date when the task was assigned
   *
   * @generated from field: optional google.protobuf.Timestamp assessment_assigned_date = 23;
   */
  assessmentAssignedDate?: Timestamp;

  /**
   * Date when the task commenced
   *
   * @generated from field: optional google.protobuf.Timestamp assessment_commence_date = 24;
   */
  assessmentCommenceDate?: Timestamp;

  /**
   * Target date for task completion
   *
   * @generated from field: optional google.protobuf.Timestamp assessment_target_date = 25;
   */
  assessmentTargetDate?: Timestamp;

  /**
   * Last Modified date for task
   *
   * @generated from field: optional google.protobuf.Timestamp last_modified_time = 26;
   */
  lastModifiedTime?: Timestamp;

  /**
   * Date when the task was assigned
   *
   * @generated from field: optional google.protobuf.Timestamp daily_reminder_time = 27;
   */
  dailyReminderTime?: Timestamp;

  /**
   * @generated from field: optional int32 assigned_students_count = 28;
   */
  assignedStudentsCount?: number;

  /**
   * @generated from field: optional int32 students_submission_count = 29;
   */
  studentsSubmissionCount?: number;

  /**
   * @generated from field: optional float class_score = 30;
   */
  classScore?: number;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload task_uploads = 35;
   */
  taskUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional float max_marks = 32;
   */
  maxMarks?: number;

  /**
   * Only for teacher created CUSTOM hws
   *
   * @generated from field: optional geneo.lms.db.SubmissionType submission_type = 33;
   */
  submissionType?: SubmissionType;

  /**
   * @generated from field: geneo.lms.db.TaskEnum task_type = 34;
   */
  taskType = TaskEnum.TASK_UNDEFINED;

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 36;
   */
  assessmentType?: AssessmentEnum;

  /**
   * ID of the module;
   *
   * @generated from field: optional string chapter_name = 37;
   */
  chapterName?: string;

  /**
   * ID of the module;
   *
   * @generated from field: optional int32 topic_id = 38;
   */
  topicId?: number;

  /**
   * ID of the module;
   *
   * @generated from field: optional string topic_name = 39;
   */
  topicName?: string;

  /**
   * @generated from field: repeated geneo.lms.db.AssignmentInfo assignment_info = 40;
   */
  assignmentInfo: AssignmentInfo[] = [];

  /**
   * @generated from field: repeated int32 chapter_ids = 41;
   */
  chapterIds: number[] = [];

  /**
   * @generated from field: repeated int32 topic_ids = 42;
   */
  topicIds: number[] = [];

  constructor(data?: PartialMessage<AssessmentTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.common.apis.AssessmentTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "assessment_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "module_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 12, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "teacher_profile_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "assessment_poster_img_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 18, name: "estimated_time_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 19, name: "task_creation_status", kind: "enum", T: proto3.getEnumType(TaskCreationStatusEnum) },
    { no: 20, name: "task_active_status", kind: "enum", T: proto3.getEnumType(TaskActiveStatusEnum) },
    { no: 21, name: "assessment_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 22, name: "assessment_content_info", kind: "message", T: AssessmentContentInfo },
    { no: 23, name: "assessment_assigned_date", kind: "message", T: Timestamp, opt: true },
    { no: 24, name: "assessment_commence_date", kind: "message", T: Timestamp, opt: true },
    { no: 25, name: "assessment_target_date", kind: "message", T: Timestamp, opt: true },
    { no: 26, name: "last_modified_time", kind: "message", T: Timestamp, opt: true },
    { no: 27, name: "daily_reminder_time", kind: "message", T: Timestamp, opt: true },
    { no: 28, name: "assigned_students_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 29, name: "students_submission_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 30, name: "class_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 35, name: "task_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 32, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 33, name: "submission_type", kind: "enum", T: proto3.getEnumType(SubmissionType), opt: true },
    { no: 34, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum) },
    { no: 36, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 37, name: "chapter_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 38, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 39, name: "topic_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 40, name: "assignment_info", kind: "message", T: AssignmentInfo, repeated: true },
    { no: 41, name: "chapter_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 42, name: "topic_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentTask {
    return new AssessmentTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentTask {
    return new AssessmentTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentTask {
    return new AssessmentTask().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentTask | PlainMessage<AssessmentTask> | undefined, b: AssessmentTask | PlainMessage<AssessmentTask> | undefined): boolean {
    return proto3.util.equals(AssessmentTask, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.common.apis.AssessmentContentInfo
 */
export class AssessmentContentInfo extends Message<AssessmentContentInfo> {
  /**
   * @generated from field: int32 time_duration_in_min = 1;
   */
  timeDurationInMin = 0;

  /**
   * @generated from field: int32 number_of_questions = 2;
   */
  numberOfQuestions = 0;

  constructor(data?: PartialMessage<AssessmentContentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.common.apis.AssessmentContentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_duration_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "number_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentContentInfo {
    return new AssessmentContentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentContentInfo {
    return new AssessmentContentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentContentInfo {
    return new AssessmentContentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentContentInfo | PlainMessage<AssessmentContentInfo> | undefined, b: AssessmentContentInfo | PlainMessage<AssessmentContentInfo> | undefined): boolean {
    return proto3.util.equals(AssessmentContentInfo, a, b);
  }
}

