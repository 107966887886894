// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.hw.teacher.apis.proto (package geneo.lms.hw.teacher.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetModuleFilteredQuestionsRequest, GetModuleFilteredQuestionsResponse, GetModuleFilteredQuestionsV2Request, GetModuleFilteredQuestionsV2Response, GetModuleQuestionResponse, GetModuleQuestionStatsRequest, GetModuleQuestionStatsV2Request, GetModuleQuestionStatsV2Response, ScheduledMsgsRequest, ScheduledMsgsResponse, SendHWReminderRequest, SendHWReminderResponse, StudentHWPhysicalSubmissionUpdateRequest, StudentHWPhysicalSubmissionUpdateResponse, TeacherHWAssignmentSubmitRequest, TeacherHWAssignmentSubmitResponse, TeacherHWClassStatsRequest, TeacherHWClassStatsResponse, TeacherHWCreationSubmitRequest, TeacherHWCreationSubmitRequestV2, TeacherHWCreationSubmitResponse, TeacherHWListRequest, TeacherHWListResponse, TeacherStudentResponseFetchRequest, TeacherStudentResponseFetchResponse, UpdateHWTeacherReviewRequest, UpdateHWTeacherReviewResponse } from "./lms.hw.teacher.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1
 */
export const LmsHomewokTeacherAPIServiceV1 = {
  typeName: "geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.getTeacherHomeworkList
     */
    getTeacherHomeworkList: {
      name: "getTeacherHomeworkList",
      I: TeacherHWListRequest,
      O: TeacherHWListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.homeworkCreationSubmit
     */
    homeworkCreationSubmit: {
      name: "homeworkCreationSubmit",
      I: TeacherHWCreationSubmitRequest,
      O: TeacherHWCreationSubmitResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.homeworkAssignmentSubmit
     */
    homeworkAssignmentSubmit: {
      name: "homeworkAssignmentSubmit",
      I: TeacherHWAssignmentSubmitRequest,
      O: TeacherHWAssignmentSubmitResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.getModuleQuestionsWithFilters
     */
    getModuleQuestionsWithFilters: {
      name: "getModuleQuestionsWithFilters",
      I: GetModuleFilteredQuestionsRequest,
      O: GetModuleFilteredQuestionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.getHWClassStats
     */
    getHWClassStats: {
      name: "getHWClassStats",
      I: TeacherHWClassStatsRequest,
      O: TeacherHWClassStatsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.getStudentHWResponse
     */
    getStudentHWResponse: {
      name: "getStudentHWResponse",
      I: TeacherStudentResponseFetchRequest,
      O: TeacherStudentResponseFetchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.sendHWReminder
     */
    sendHWReminder: {
      name: "sendHWReminder",
      I: SendHWReminderRequest,
      O: SendHWReminderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.getModuleQuestionStats
     */
    getModuleQuestionStats: {
      name: "getModuleQuestionStats",
      I: GetModuleQuestionStatsRequest,
      O: GetModuleQuestionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.updateTeacherReview
     */
    updateTeacherReview: {
      name: "updateTeacherReview",
      I: UpdateHWTeacherReviewRequest,
      O: UpdateHWTeacherReviewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.scheduledSMSandEmail
     */
    scheduledSMSandEmail: {
      name: "scheduledSMSandEmail",
      I: ScheduledMsgsRequest,
      O: ScheduledMsgsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV1.studentHWPhysicalSubmissionUpdate
     */
    studentHWPhysicalSubmissionUpdate: {
      name: "studentHWPhysicalSubmissionUpdate",
      I: StudentHWPhysicalSubmissionUpdateRequest,
      O: StudentHWPhysicalSubmissionUpdateResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV2
 */
export const LmsHomewokTeacherAPIServiceV2 = {
  typeName: "geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV2",
  methods: {
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV2.homeworkCreationSubmit
     */
    homeworkCreationSubmit: {
      name: "homeworkCreationSubmit",
      I: TeacherHWCreationSubmitRequestV2,
      O: TeacherHWCreationSubmitResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV2.getModuleQuestionsWithFilters
     */
    getModuleQuestionsWithFilters: {
      name: "getModuleQuestionsWithFilters",
      I: GetModuleFilteredQuestionsV2Request,
      O: GetModuleFilteredQuestionsV2Response,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.hw.teacher.apis.LmsHomewokTeacherAPIServiceV2.getModuleQuestionStats
     */
    getModuleQuestionStats: {
      name: "getModuleQuestionStats",
      I: GetModuleQuestionStatsV2Request,
      O: GetModuleQuestionStatsV2Response,
      kind: MethodKind.Unary,
    },
  }
} as const;

