// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.assessment.common.apis.proto (package geneo.lms.assessment.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchAssessmentContentRequest, FetchAssessmentContentResponse, FetchAssessmentsByModuleRequest, FetchAssessmentsByModuleResponse } from "./lms.assessment.common.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.assessment.common.apis.LmsAssessmentCommonAPIServiceV1
 */
export const LmsAssessmentCommonAPIServiceV1 = {
  typeName: "geneo.lms.assessment.common.apis.LmsAssessmentCommonAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.assessment.common.apis.LmsAssessmentCommonAPIServiceV1.fetchAssessmentsByModule
     */
    fetchAssessmentsByModule: {
      name: "fetchAssessmentsByModule",
      I: FetchAssessmentsByModuleRequest,
      O: FetchAssessmentsByModuleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.common.apis.LmsAssessmentCommonAPIServiceV1.fetchAssessmentContent
     */
    fetchAssessmentContent: {
      name: "fetchAssessmentContent",
      I: FetchAssessmentContentRequest,
      O: FetchAssessmentContentResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

