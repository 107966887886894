import { theme } from '@geneo2-web/shared-ui';
import {
  Box,
  useMediaQuery,
  ListItemText,
  Select,
  MenuItem,
  Checkbox,
  ListItemIcon,
} from '@mui/material';

import React, { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';

interface IDataList {
  name: string;
  id: number;
  selected: boolean;
}
interface SectionListProps {
  selectAllTitle?: string;
  inputName?: string;
  optionList: string[];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  dataList: IDataList[];
  setDataList: React.Dispatch<React.SetStateAction<IDataList[]>>;
}

const styles: IStyles = {
  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
  },
  label: {
    color: 'black',
    '@media(max-width:640px)': { fontSize: pxToRem(30) },
  },

  multySelect: {
    height: { xs: pxToRem(65), md: pxTovW(95), lg: pxTovW(75), xl: pxTovW(75) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    border: '1px solid #CCE6FE',
    width: '100%',
    justifyContent: 'center',
  },
};

export const MultiSelect = (props: SectionListProps) => {
  const {
    selectAllTitle,
    inputName,
    optionList,
    handleChange,
    dataList,
    setDataList,
  } = props;

  const [options, setOptions] = React.useState(optionList);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const [selected, setSelected] = useState(defaultSelected);
  // const isAllSelected =
  //   options.length > 0 && sectionList.length === options.length;

  const SelectHandleChange = (event: any) => {
    const value = event.target.value;
    console.log('value:', value);

    if (value[value.length - 1] === 'all') {
      // If some or none are selected, select all; if all are selected, deselect all
      let boolData = false;
      if (!isAllSelected() && !isNoneSelected()) boolData = true;
      setDataList((prev) => {
        return prev.map((prevData) => ({
          ...prevData,
          selected: prevData.selected === false ? true : boolData,
        }));
      });
    } else {
      setDataList((prev) => {
        return prev.map((prevData) => {
          if (value[value.length - 1] === prevData.name)
            return {
              ...prevData,
              selected: !prevData.selected,
            };
          else return prevData;
        });
      });
    }

    handleChange(event);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },

      sx: {
        bgcolor: '#ECFFF5',
        borderRadius: '12px !important',

        '& .MuiMenuItem-root': {
          padding: 1,
          borderBottom: '1px solid #96FFC5',
          paddingTop: 0,
        },
      },
    },
    getContentAnchorEl: null,
  };

  let mobileCssToggle: object = {};

  if (isMobile) {
    mobileCssToggle = {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
      marginTop: '10px',
      flexDirection: 'row',
    };
  } else {
    mobileCssToggle = { alignItems: 'center', marginTop: '10px', flex: 1 };
  }

  const isAllSelected = () => {
    return dataList.every((val) => val.selected);
  };
  const isNoneSelected = () => {
    return dataList.every((val) => !val.selected);
  };

  return (
    <Box
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      }}
    >
      <Select
        labelId="mutiple-select-label"
        multiple
        value={['selectedSections']}
        onChange={SelectHandleChange}
        // renderValue={(selected) => selected.join(', ')}
        renderValue={(selected) => {
          if (isAllSelected()) return 'All Divisions';
          else if (isNoneSelected()) return 'Select a Division';
          else {
            return dataList
              .filter((val) => val.selected)
              .map((val) => val.name)
              .join(', ');
          }
        }}
        MenuProps={MenuProps}
        name={inputName}
        sx={styles.multySelect}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected()}
              indeterminate={!isAllSelected() && !isNoneSelected()}
            />
          </ListItemIcon>
          <ListItemText primary={selectAllTitle ?? 'Select All'} />
        </MenuItem>
        {dataList.map((option) => (
          <MenuItem key={option.id} value={option.name}>
            <ListItemIcon>
              <Checkbox checked={option.selected} />
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
